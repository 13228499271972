import React, { useState, useEffect } from 'react';
import CardProfile from '../groups/group/profiles/card_profile'

const NewMembers = ({users}) => {
  const [profiles] = useState(users)
  const [showCarousel, setShowCarousel] = useState(true)

  useEffect(() => {
    if (showCarousel) {
      $('#members').slick({
        dots: false,
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        variableWidth: true,
        prevArrow: '<button class="nav-prev"><i class="icon-b-left"></i></button>',
        nextArrow: '<button class="nav-next"><i class="icon-b-right"></i></button>'
      });
    }
  }, [showCarousel])

  const carouselForMobile = () => {
    if (window.innerWidth <= 600 && profiles.length == 2) setShowCarousel(true)
    if (window.innerWidth > 600 && profiles.length == 2) setShowCarousel(false)
  }

  useEffect(() => {
    window.addEventListener('load', carouselForMobile)
    window.addEventListener('resize', carouselForMobile)

    return () => {
      window.addEventListener('load', carouselForMobile)
      window.addEventListener('resize', carouselForMobile)
    }
  }, [])

  return (
    <>
      <div id='members' className='boxes pictorial-user-list'>
        {profiles.map(profile => (<CardProfile key={profile.id} profile={profile} />))}
      </div>
    </>
  )
}

export default NewMembers;
