import React from 'react'
import FrindowNotification from '../shared/frindow_notification'
import ReportContainer from '../reported_content/container'
import { quickMessageIcons, definedContent } from '../shared/quick_message'
import { QUICK_MESSAGE_KINDS } from '../../utils/constants'

export default class Message extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openReportModal: false,
      isReported: props.is_reported
    }
  }

  handleReportModalClose = () => {
    this.setState({openReportModal: false})
  }

  handleReportModalSubmit = (props) => {
    this.setState({openReportModal: false, isReported: props.status})
  }

  handleClearNotice = () => {
    this.setState({errors: [], messages: []})
  }

  renderReportButton = () => {
    return(<span className='message-report' onClick={() => this.setState({openReportModal: true})}>Report</span>)
  }

  renderReadUnreadStatus = () => {
    return(<span className='message-read'>{this.props.read ? 'Read' : 'Unread'}</span>)
  }

  renderReportMessageContainer = () => {
    return(this.state.isReported ? <span>Reported</span> : this.renderReportButton())
  }

  renderMessageText = () => {
    return(this.props.from_you ? this.renderReadUnreadStatus() : this.renderReportMessageContainer())
  }

  renderSystemMessages = () => {
    const { content } = this.props

    return content.split('\n').map((item, key) => {
      return <span key={key} dangerouslySetInnerHTML={{__html: `${item}<br>`}} />
    })
  }

  renderWithNewLines = () => {
    const { content, from_you, message_kind } = this.props

    return definedContent(message_kind, content, from_you).split('\n').map((item, key) => {
      return <span key={key}>{item}<br/></span>
    })
  }

  renderContent = () => {
    return this.props.system_conversation ? this.renderSystemMessages() : this.renderWithNewLines()
  }

  renderQuickMsgIcon = () => {
    const { message_kind, from_you } = this.props
    if(QUICK_MESSAGE_KINDS.includes(message_kind)) {
      return(
        <div className='col-auto'>
          <img alt={message_kind} className='img-fluid rounded-circle circle-avatar size-md' src={quickMessageIcons(message_kind, from_you)} />
        </div>
      )
    }

    return null
  }

  render = () => {
    const { renderContent, handleReportModalClose, handleReportModalSubmit,
            handleClearNotice, renderMessageText, props, state } = this
    const { from_you, created_at, id, system_conversation } = props
    const { openReportModal, messages, errors } = state
    return(
      <React.Fragment>
        <div className={from_you ? 'message from-user' : 'message to-user'}>
          <div className='row'>
            {this.renderQuickMsgIcon()}
            <div className='col'>
              <p className='message-content'>
                {renderContent()}
              </p>
              <div className='message-footer'>
                {!system_conversation && renderMessageText()}
                <span className='message-date'>{created_at}</span>
              </div>
            </div>
          </div>
        </div>
        {openReportModal &&
          <ReportContainer  onReportModalClose={handleReportModalClose}
                            objectId={id}
                            objectType='Message'
                            onReportModalSubmit={handleReportModalSubmit} />}
        <FrindowNotification
          errors={errors}
          messages={messages}
          clearNotices={handleClearNotice} />
      </React.Fragment>
    )
  }
}
