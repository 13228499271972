import React, { Component } from 'react'
import Button from '../shared/button'
import FrindowNotification from '../shared/frindow_notification'
import { VALIDATION_MSG } from '../../utils/constants'
import { makePutRequest } from '../shared/api'

import TextArea from '../shared/text_area'
import TextField from '../shared/text_field'
import Select from 'react-select'
import CheckBoxField from '../shared/checkbox_field'
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, ContentState, convertFromHTML, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';

const toolbarConfig = {
  options: ['inline', 'fontSize', 'list', 'textAlign']
}
export default class EditAbout extends Component {
  constructor(props){
    super(props)
    const {
      about_me = '',
      training = '',
      fees = '',
    } = props.profile


    const aboutMeContentState = this.prepareContentState(about_me)
    // props.profile.about_me = EditorState.createWithContent(aboutMeContentState)

    const trainingContentState = this.prepareContentState(training)
    // props.profile.training = EditorState.createWithContent(trainingContentState)

    const feesContentState = this.prepareContentState(fees)
    // props.profile.fees = EditorState.createWithContent(feesContentState)

    this.state = {
      profile: props.profile,
      languages: this.props.languages.map((obj) => ({ label: obj.name, value: obj.id })),
      user_languages: this.props.user_languages.map((obj) => ({ label: obj.name, value: obj.id })),
      profile_session_types: this.props.profile_session_types,
      messages: [],
      errors: [],
      errorMessage: [],
      selected_type_sessions: this.prepareSelectedTypeSessions(),
      selectedCoverType: props.profile_client_type_covers.map((obj) => (obj.id )),
      aboutMeEditor: EditorState.createWithContent(aboutMeContentState),
      trainingEditor: EditorState.createWithContent(trainingContentState),
      feesEditor: EditorState.createWithContent(feesContentState)
    }
  }

  prepareContentState = (plainText) => {
    const blocksFromHTML = plainText ? convertFromHTML(plainText) : convertFromHTML('')
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap,
    );

    return contentState
  }

  prepareSelectedTypeSessions = () => {
    let selected_type_sessions = {}
    for(let i = 0; i < this.props.session_types.length; i++){
      const session = this.props.session_types[i]
      const name = session.name

      let a = this.props.profile_session_types.filter(session => session.name === name)

      selected_type_sessions[name.toLowerCase().replace(' ', '_')] = a.length > 0
    }

    return selected_type_sessions
  }

  handleOnChange = (event) => {
    let { profile } = this.state
    profile[event.target.name] = event.target.value
    this.setState(profile)
  }

  handleLanguageChange = (value) => {
    const user_languages = value.map((obj) => ({ value: obj.value, label: obj.label }))
    this.setState({ user_languages, languagesError: '' })
  }

  handleSessionTypeChange = (event) => {
    let { profile_session_types, selected_type_sessions } = this.state
    const { session_types } = this.props

    let selectedSession = session_types.filter(session =>  session.id.toString() === event.target.value )

    let name = selectedSession[0].name.toLowerCase().replace(' ', '_')
    selected_type_sessions[name] = event.target.checked

    if(event.target.checked){
      const checkedOption = selectedSession[0]

      profile_session_types.push(checkedOption)
    }else{
      let toRemove = profile_session_types.map(function(sessionType){ return sessionType.id }).indexOf(selectedSession[0].id )
      profile_session_types.splice(toRemove, 1)
    }

    this.setState({selected_type_sessions: selected_type_sessions,
                   profile_session_types: profile_session_types })
  }

  handleCoverTypeChange = (event) => {
    const {selectedCoverType} = this.state

    if(event.target.checked){
      selectedCoverType.push(parseInt(event.target.value))
    }else{
      const index = selectedCoverType.indexOf(parseInt(event.target.value));
      if (index > -1) {
        selectedCoverType.splice(index, 1);
      }
    }

    this.setState({selectedCoverType})
  }


  handleOnSave = () => {
    const errors = this.validation()

    if(this.isValid(errors)) {
      this.updateProfile().then((profile) => {
        if(profile.data.success){
          this.setState({ messages: ['About has been updated'], errors: [] })
        }else{
          this.setState({errors: profile.data.errors,  errorMessage: ['About can not be updated'] })
        }
      });
    }else{
      this.setState({ errorMessage: ['About can not be updated'] })
      this.setState({errors: errors} )
    }
  }

  validation = () => {
    const errors = { }

    this.validateShortDescription(errors);
    this.validateSpecialities(errors);

    return errors
  }

  validateShortDescription = (errors) => {
    const { profile } = this.state
    if(profile.short_description.length > 140){
      Object.assign(errors, {short_description: VALIDATION_MSG.tooLong})
    }
  }

  validateSpecialities = (errors) => {
    const { profile } = this.state

    if(profile.first_specialization.length > 50){
      Object.assign(errors, {first_specialization: VALIDATION_MSG.tooLong})
    }
    if(profile.second_specialization.length > 50){
      Object.assign(errors, {second_specialization: VALIDATION_MSG.tooLong})
    }
    if(profile.third_specialization.length > 50){
      Object.assign(errors, {third_specialization: VALIDATION_MSG.tooLong})
    }
  }

  checkPresence = (value) => {
    return value === '' ? VALIDATION_MSG : ''
  }

  isValid = (errors) => {
    return Object.values(errors).every((e) => e === '')
  }

  updateProfile = () => {
    let { profile, user_languages, profile_session_types, selectedCoverType, aboutMeEditor, trainingEditor, feesEditor } = this.state

    let params = {
      profile: {
        short_description: profile.short_description,
        post_nominal_titles: profile.post_nominal_titles,
        about_me: this.convertContentToHTML(aboutMeEditor),
        training: this.convertContentToHTML(trainingEditor),
        fees: this.convertContentToHTML(feesEditor),
        language_ids: user_languages.map((obj) => (obj.value )),
        first_specialization: profile.first_specialization,
        second_specialization: profile.second_specialization,
        third_specialization: profile.third_specialization,
        session_type_ids: profile_session_types.map((obj) => ( obj.id)),
        client_type_cover_ids: selectedCoverType,
        job_title: profile.job_title,
      }
    }

    return makePutRequest({ url: `/client_profiles/${profile.id}`, body: params })
  }

  validCheck = (items) => {
    let empty = true;
    // eslint-disable-next-line no-unused-vars
    for (const [key, value] of Object.entries(items)) {
      if(value){
        empty = false
      }
    }

    return empty
  }
  renderSessionTypes = () => {
    const { selected_type_sessions, errors } = this.state
    let sessionCheckboxes = []

    for(let i = 0; i < this.props.session_types.length; i++){
      const session = this.props.session_types[i]
      const name = session.name.toLowerCase().replace(' ', '_')

      sessionCheckboxes.push(
        <div className="col-auto" key={`session_col_${name}`}>
          <CheckBoxField
                  labelDesc={session.name}
                  value={session.id}
                  checked={selected_type_sessions[name]}
                  id={name}
                  key={`session_${name}`}
                  name="profile[type_of_session]"
                  onChange={this.handleSessionTypeChange} />
        </div>
      )
    }
    return <>
            { this.validCheck(selected_type_sessions) && errors.client_profile_session_types &&
              <div className="form-control is-invalid checkbox-invalid">{errors.client_profile_session_types}</div>
            }
            <div className="row">{sessionCheckboxes}</div>
           </>
  }

  renderCoverItems =() => {
    const { client_type_covers } = this.props
    const {selectedCoverType, errors} = this.state

    let coverCheckboxes = []
    for(let i = 0; i < client_type_covers.length; i++){
      const coverItem = client_type_covers[i]
      coverCheckboxes.push(
        // <div className="col-auto" key={`cover_item_${coverItem.name}`}>
          <CheckBoxField
                  labelDesc={coverItem.name}
                  value={coverItem.id}
                  checked={selectedCoverType.includes(coverItem.id)}
                  id={coverItem.name.toLowerCase().replace(' ', '_')}
                  key={coverItem.name + coverItem.id}
                  name="profile[client_cover]"
                  onChange={this.handleCoverTypeChange} />
        // </div>
      )
    }

    return <>
            { this.validCheck(selectedCoverType) && errors.client_profile_client_type_covers &&
              <div className="form-control is-invalid checkbox-invalid">{errors.client_profile_client_type_covers}</div>
            }
            <div className="cover-list">{coverCheckboxes}</div>
          </>
  }

  onAboutMeStateChange = (editorState) => {
    this.setState({aboutMeEditor: editorState})
  }

  onTrainingStateChange = (editorState) => {
    this.setState({trainingEditor: editorState})
  }

  onFeesStateChange = (editorState) => {
    this.setState({feesEditor: editorState})
  }

  convertContentToHTML = (editorState) => {
    let currentContentAsHTML = draftToHtml(convertToRaw((editorState.getCurrentContent())));

    if ( this.stripHtml(currentContentAsHTML) == ''){
      return ''
    }else{
      return currentContentAsHTML
    }
  }

  stripHtml = (originalString) => {
    return originalString.replace(/(<([^>]+)>)/gi, "").trim().replace(/(?:^(?:&nbsp;)+)|(?:(?:&nbsp;)+$)/g, '').replace(/[\n\t\r]/g,"");
  }

  render = () => {
    // eslint-disable-next-line no-unused-vars
    const { profile, errors, languages, user_languages, errorMessage, aboutMeEditor, trainingEditor, feesEditor } = this.state

    return(
      <div className='form my-4'>
        <h2>About</h2>

        <div className="form-group">
          <label>Short description <span className="text-danger">**</span></label>
          <p>This will be used on the adverts for your profile Max 140 characters.</p>
          <div className='form'>
            <TextArea
              value={profile.short_description || ''}
              onChange={this.handleOnChange}
              name='short_description'
              type='text'
              errorText={errors.short_description}
              limit='140'
              customClassName='fixed-size' />
          </div>
        </div>

        <div className="form-group">
          <label>Profile Heading</label>
          <p>This can be the letters after your name (post-nominal titles), or a short statement.</p>
          <div className='form'>
            <TextField
              className='form-control'
              classForm='w-100'
              value={profile.post_nominal_titles || ''}
              onChange={this.handleOnChange}
              name='post_nominal_titles'
              placeholder='Max 80 characters'
              maxLength='80'
              showCounter={true}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Job Title <span className="text-danger">*</span></label>

          <div className='form'>
            <TextField
              className='form-control'
              classForm='w-100'
              value={profile.job_title || ''}
              onChange={this.handleOnChange}
              name='job_title'
              placeholder='Max 30 characters'
              maxLength='30'
              showCounter={true}
            />
          </div>
        </div>

        <div className="form-group">
          <label>About me text <span className="text-danger">**</span></label>

          <div className='form'>
            <Editor
              toolbar={ toolbarConfig }
              editorClassName="form-control"
              editorState={aboutMeEditor}
              onEditorStateChange={this.onAboutMeStateChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Training, qualifications & experience <span className="text-danger">**</span></label>

          <div className='form'>
            <Editor
              toolbar={ toolbarConfig }
              editorClassName="form-control"
              editorState={trainingEditor}
              onEditorStateChange={this.onTrainingStateChange}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Fees</label>

          <div className='form'>
            <Editor
              toolbar={ toolbarConfig }
              editorClassName="form-control"
              editorState={feesEditor}
              onEditorStateChange={this.onFeesStateChange}
            />
          </div>
        </div>

        <div className="form-group">
          <div className="form-check form-multi-autocomplete">
            <label>Languages <span className="text-danger">*</span></label>
            <Select
              isMulti
              options={languages}
              onChange={this.handleLanguageChange}
              value={user_languages}
              className={'form-input-cell'}
              classNamePrefix={'autocomplete'}
            />
          </div>
        </div>

        <div className="form-group">
          <label>Type of session <span className="text-danger">*</span></label>
          {this.renderSessionTypes()}
        </div>

        <div className="form-group">
          <label>Specialities</label>
          <p>If applicable, please up to three things that you specialise in.</p>
          <div className='form'>
            <TextField
             className='form-control'
             value={profile.first_specialization || ''}
             onChange={this.handleOnChange}
             maxLength="60"
             placeholder="Max 50 characters"
             name='first_specialization'
             errorText={errors.first_specialization} />
          </div>
          <div className='form'>
            <TextField
             className='form-control'
             value={profile.second_specialization || ''}
             onChange={this.handleOnChange}
             maxLength="60"
             placeholder="Max 50 characters"
             name='second_specialization'
             errorText={errors.second_specialization} />
          </div>
          <div className='form'>
            <TextField
             className='form-control'
             value={profile.third_specialization || ''}
             onChange={this.handleOnChange}
             maxLength="60"
             placeholder="Max 50 characters"
             name='third_specialization'
             errorText={errors.third_specialization} />
          </div>
        </div>

        <div className="form-group">
          <label>What I cover <span className="text-danger">*</span></label>
          {this.renderCoverItems()}
        </div>

        <div className='form-actions mt-4'>
          <Button
          className='button btn btn-big btn-secondary btn-submit'
          title='Save'
          onClick={this.handleOnSave}
          />
        </div>

        <FrindowNotification errors={errorMessage} messages={this.state.messages} clearNotices={() => this.setState({ messages: [], errorMessage: [] })} />
      </div>
    )
  }
}
