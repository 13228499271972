import React from 'react'
import SimpleSelectField from '../shared/simple_select_field'

const Report = (props) => {
  const { handleReasonChange, reportReasons, selectedReason, reportContent, handleOnChange, handleClickSendMsg, handleClickClose } = props
  return(
    <div className='r-modal show'>
      <div className='r-modal-content wide'>
        <div className='r-modal-header no-border'>
          <h4 className='h1 modal-title text-left'>Report this message</h4>
          <button type='button' aria-label='Close' className='btn btn-close' onClick={handleClickClose}></button>
        </div>
        <div className='r-modal-body'>
          <div className='row'>
            <div className='col-12 form'>
              <div className='form-group'>
                <h2 className='text-gray'>Please select the reason for reporting this message</h2>
                <div className='form-input-cell select-cell small center'>
                  <SimpleSelectField
                    collection={reportReasons}
                    value={selectedReason}
                    onChange={handleReasonChange}
                    emptyKey='Choose'
                    errorPrompt={false}
                  />
                </div>
              </div>
              <div className='form-group'>
                <label>Additional information (optional)</label>
                <div className='form-input-cell'>
                  <textarea
                    className='form-control'
                    placeholder='Aditional info'
                    value={reportContent}
                    onChange={handleOnChange}
                    />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='r-modal-footer'>
          <div className='row'>
            <div className='col-12 col-sm text-right'>
              <button className='btn btn-secondary btn-big btn-cancel' onClick={handleClickClose}>Cancel</button>
              <button className='btn btn-primary btn-big btn-plane' onClick={handleClickSendMsg}>Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Report;
