import React from 'react'

const ProgressBar = ({ step, steps = 5 }) => {
  const percent = ( (step / steps) * 100 ) - (100 / steps / 2)
  const stepsNodes = []
  for(let i=1; i <= steps; i++) {
    stepsNodes.push(<span key={'key_step_' + i} className={'step ' + (i <= step ? 'step-active' : '') + (i < step ? ' step-check' : '')}>{i}</span>)
  }
  return(
    <div className='join-frindow-progress-bar'>
      <span  className='active-bar' style={{ width: percent + '%'}}></span>
      {stepsNodes}
    </div>
  )
}

export default ProgressBar;
