import React from 'react'
import SearchProfile from '../profile/search_profile'
import { makeGetRequest } from '../shared/api.js'
import _ from 'lodash'
import InfoBox from '../shared/info_box'
import { lozadObserver } from '../../utils/lozad_observer'
import { FAVOURITES_INFO_LINK } from '../../utils/constants'

class FavouritesContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      users: [],
      totalCount: 0,
      page: 1,
      isLastPage: false,
      loading: true
    }
  }

  componentDidMount() {
    this.fetchProfiles()
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.isLastPage) { window.removeEventListener('scroll', this.handleScroll) }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = () => {
    const { page, loading } = this.state
    if (!loading && document.getElementsByTagName('footer')[0].clientHeight + window.innerHeight > this.calculateDocument()) { this.fetchProfiles(page+1) }
  }

  searchProfiles = _.debounce(() => {
    this.fetchProfiles()
  }, 200);

  calculateDocument = () => {
    if(document.documentElement) {
      return (
        document.documentElement.offsetHeight
        - document.documentElement.scrollTop
      )
    }
    return 0
  }

  fetchProfiles = (page = 1) => {
    this.setState({loading: true})
    const { get_users_url: getUsersUrl } = this.props
    makeGetRequest({ url: `${getUsersUrl}?page=${page}` })
      .then((response) => {
        this.setState({loading: false})
        if (response.data.errors == null) {
          let fetchedUsers = response.data.profiles
          if(page > 1) { fetchedUsers = this.state.users.concat(fetchedUsers) }
          else { window.addEventListener('scroll', this.handleScroll) }
          this.setState({
            errors: '',
            users: fetchedUsers,
            totalCount: response.data.total_count,
            page: page,
            isLastPage: response.data.last_page
          })
          lozadObserver()
        }
        else { this.setState({ errors: response.data.errors }) }
      });
  }

  render = () => {
    const { users } = this.state
    return(
      <div className='container on-light'>
        { users.length > 0 && <div className='pictorial-user-list mt-2 pb-4'>
            {users.map((user) => {
              return(<SearchProfile key={user.id} {...user} />)
              })
            }
          </div>
        }
        {
          users.length == 0 && <InfoBox header={"You don’t have any favourites yet."}
                                        content={`To see the benefits of having favourites and how to add them, <a href='${FAVOURITES_INFO_LINK}' target='_blank'>click here</a>.`}/>
        }
      </div>
    )
  }
}

export default FavouritesContainer;
