import React from 'react'

const ItemImage = (props) => {
  const { imageUrl, handleImageChange, id, fullUrl, authorUrl, authorName, selectedImage } = props
  return(
    <div className={`unsplash-image ${selectedImage ? 'active' : null}`} onClick={handleImageChange} >
      <img src={imageUrl} unsplashid={id} full={fullUrl} authorurl={authorUrl} authorname={authorName} name='image' className='thumb' />
    </div>
  )
}

export default ItemImage
