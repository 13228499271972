import React from 'react'
import IntroVideo from './intro_video'
import VideoDate from './video_date'
import WaitingResponse from './waiting_response'
import DecideAnswear from './decide_answear'
import ScheduledChat from './scheduled_chat'

export default class VideoSchedulingContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      step: this.defaultStep(),
      videoRoom: props.video_room
    }
  }

  defaultStep = () => {
    const { video_room } = this.props
    if(video_room) {
      const step = video_room.status === 'pending' ? 2 : 3
      return step
    }
    return 0
  }

  handleNextStep = () => {
    this.setState({step: this.state.step + 1})
  }

  handleStep = (e) => {
    const step = parseInt(e.currentTarget.dataset['step'])
    this.setState({step: step})
  }

  updateState = (newState) => {
    this.setState(newState)
  }

  renderStep = () => {
    const { current_user, confirmation_url, profile_user, video_room_url } = this.props
    const { videoRoom } = this.state
    let page = ''

    switch(this.state.step) {
      case 0:
        page = <IntroVideo
                current_user={current_user}
                handleNextStep={this.handleNextStep}
                confirmation_url={confirmation_url} />
        break
      case 1:
        page = <VideoDate
                current_user={current_user}
                profile_user={profile_user}
                handleNextStep={this.handleNextStep}
                updateState={this.updateState}
                videoRoomUrl={video_room_url}
                videoRoom={videoRoom} />
        break
      case 2: {
          const videoUrl = `${video_room_url}\\${videoRoom.id}`
          if(videoRoom.creator_id === current_user.id) {
            page = <WaitingResponse
                    handleStep={this.handleStep}
                    videoRoom={videoRoom}
                    videoRoomUrl={videoUrl}
                    updateState={this.updateState} />
          }
          else {
            page = <DecideAnswear
                    videoRoom={videoRoom}
                    videoRoomUrl={videoUrl}
                    updateState={this.updateState} />
          }
          break
        }
      case 3:
        page = <ScheduledChat
                updateState={this.updateState}
                videoRoomUrl={`${video_room_url}\\${videoRoom.id}`}
                handleStep={this.handleStep}
                videoRoom={videoRoom} />
        break
    }

    return page
  }

  render = () => {
    return(this.renderStep())
  }
}
