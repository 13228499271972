import React from 'react'
import CreateTopicForm from './create_topic_form'
import BeforeJoinGroup from '../shared/before_join_group'
import { makePostRequest, makeGetRequest } from '../../shared/api.js'

class GroupFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      title: '',
      content: '',
      errors: '',
      inGroup: false
    }
  }

  handleClickOpen = (e) => {
    e.stopPropagation()
    e.preventDefault()
    document.body.classList.add('unpin')
    this.userInGroup()
  }

  handleClickClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    document.body.classList.remove('unpin')
    this.setState({open: false})
  }

  handleClickSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { title, content } = this.state
    const { url } = this.props
    makePostRequest({ url: url, body: { subject: { title: title, post: { content: content } } } })
       .then((response) => {
         if (response.data.errors == null) {
           this.setState({
             open: false,
             title: '',
             content: '',
             errors: ''
           })
           if (typeof ga !== 'undefined') { ga('send', 'event', 'Post', 'created') }
           window.location.href = response.data.url
         }
         else {
           this.setState({
             errors: response.data.errors
           })
         }
    })
  }

  userInGroup = () => {
    const { in_group_url } = this.props
    makeGetRequest({ url: `${in_group_url}` })
         .then((response) => {
           this.setState({ inGroup: response.data.in_group, open: true });
         });
  }

  handleTitleChange = (e) => { this.setState({ title: e.target.value }) }

  handleContentChange = (e) => { this.setState({ content: e.target.value }) }

  afterJoin = () => {
    this.setState({ inGroup: true })
    if (typeof ga !== 'undefined') { ga('send', 'event', 'Group', 'user joined') }
  }

  renderSubjectForm = () => {
    if (!this.state.open) { return null; }
    if (!this.state.inGroup) {
      return (<BeforeJoinGroup
                join={this.props.url_join_group}
                handleClickClose={this.handleClickClose}
                joined={false}
                afterJoin={this.afterJoin}
                infoText='You need to be a member of this group to create a new thread.' />);
    }
    const { title, content, errors } = this.state
    const { handleTitleChange, handleContentChange, handleClickSubmit, handleClickClose } = this
    return (
      <CreateTopicForm
        title={title}
        handleTitleChange={handleTitleChange}
        content={content}
        handleContentChange={handleContentChange}
        handleClickSubmit={handleClickSubmit}
        handleClickClose={handleClickClose}
        errors={errors}
      />
    )
  }

  render = () => {
    return(
      <React.Fragment>
      {this.renderSubjectForm()}
      {this.props.permitted && this.props.canWrite &&
        <button type='button' className='btn btn-primary btn-big btn-add' title='Add a topic' onClick={this.handleClickOpen}>Add Topic</button>
      }
      </React.Fragment>
    )
  }
}

export default GroupFormContainer;
