import lozad from 'lozad'

export const lozadObserver = () => {
  const observer = lozad('.lozad', {
    loaded: function(el) {
    const imgSrc = el.getAttribute('data-src');
    const fallbackImgSrc = el.getAttribute('data-fallbacksrc');
      try {
        var img = new Image();
        img.src = imgSrc
        img.addEventListener('error', function(e){
          el.classList.add('fallback');
          el.src = fallbackImgSrc;
        }, false);
      }catch(e){
        console.error(e);
      }
    }
  });
  return observer.observe()
}

export const forceImageUpdate = (el) => {
  const imgSrc = el.getAttribute('data-src');
  const fallbackImgSrc = el.getAttribute('data-fallbacksrc');
  try {
    var img = new Image();
    img.src = imgSrc
    img.addEventListener('load', () => {
      el.src = imgSrc;
    }, false);
    img.addEventListener('error', () => {
      el.classList.add('fallback');
      el.src = fallbackImgSrc;
    }, false);
  } catch (event) {
    console.error(event);
  }
}