import React from 'react'
import BackgroundGetTogether from './background_get_together'
import InfoGetTogether from './info_get_together'
import { GET_TOGETHER_ACTIONS } from '../../utils/constants'
import { makeGetRequest } from '../shared/api.js'
import FrindowNotification from '../shared/frindow_notification'
import CalendarContainer from './calendar_container'

class GetTogetherContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      getTogetherAction: GET_TOGETHER_ACTIONS['definitely'],
      userJoined: props.participant_in_get_together.joined,
      errors: [],
      messages: [],
      getTogetherFull: parseInt(props.get_together.max_participants) <= props.participants.length
    }
  }

  handleGetTogetherActionChange = (e) => { this.setState({getTogetherAction: e.target.value}) }

  handleGetTogetherActionClick = () => {
    const { get_together_status_url } = this.props
    const { userJoined, getTogetherAction } = this.state
    const status = Object.keys(GET_TOGETHER_ACTIONS).find(key => GET_TOGETHER_ACTIONS[key] === getTogetherAction)
    if(GET_TOGETHER_ACTIONS["definitely"] === getTogetherAction && userJoined) { return }

    let fetchUrl = `${get_together_status_url}?status=${status}`
    makeGetRequest({ url: `${fetchUrl}` })
         .then((response) => {
           if (response.data.leave) {
             this.setState({
               userJoined: false,
               getTogetherAction: GET_TOGETHER_ACTIONS['definitely'],
               getTogetherFull: false
             })
           }
           if (response.data.join) {
             this.setState({
               userJoined: true,
             })
            }
            this.setState({messages: [response.data.messages]})
         });
  }

  renderBackgroundGetTogether = () => {
    const { handleGetTogetherActionChange, handleGetTogetherActionClick, handleEditClick } = this
    const { url_join_group, in_group_url, current_user_host, imageUrl, authorUrl, authorName } = this.props

    const { title, present_date } = this.props.get_together
    const { getTogetherAction, userJoined, getTogetherFull } = this.state

    return <BackgroundGetTogether
              imageUrl={imageUrl}
              authorUrl={authorUrl}
              authorName={authorName}
              current_user_host={current_user_host}
              handleEditClick={handleEditClick}
              title={title}
              userJoined={userJoined}
              getTogetherFull={getTogetherFull}
              present_date={present_date}
              getTogetherAction={getTogetherAction}
              handleGetTogetherActionChange={handleGetTogetherActionChange}
              handleGetTogetherActionClick={handleGetTogetherActionClick}
              url_join_group={url_join_group}
              in_group_url={in_group_url} />
  }

  renderInfoGetTogether = () => {
    const { description, place, address, present_date, max_participants, status, meeting_type } = this.props.get_together
    const { hosted_by, participants, advert } = this.props

    return <InfoGetTogether
             participants={participants}
             description={description}
             place={place}
             address={address}
             present_date={present_date}
             max_participants={max_participants}
             hostedBy={hosted_by}
             status={status}
             advert={advert}
             meeting_type={meeting_type} />
  }

  render = () => {
    return(
      <div className='container on-light get-together-content'>
        <div className='row row-even-h'>
          {this.renderBackgroundGetTogether()}
          <div className='col-12 col-lg-4 order-lg-1'>
            <CalendarContainer calendarGetTogethers={this.props.calendarGetTogethers} start_date={this.props.start_date} />
          </div>
        </div>
        {this.renderInfoGetTogether()}
        <FrindowNotification errors={this.state.errors} messages={this.state.messages} clearNotices={() => this.setState({ errors: [], messages: [] })} />
      </div>
    )
  }
}

export default GetTogetherContainer;
