import React, { useState, useEffect } from 'react'
import FrindowNotification from '../shared/frindow_notification'
import FrindowErrors from '../shared/frindow_errors'
import { makePostRequest } from '../shared/api'
import { APP_ROUTES } from '../shared/routes'

const BuyContainer = (props) => {
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [chosenCredit, setChosenCredit] = useState([]);

  const onError = (response) => {
    setMessages([]);
    setProcessing(false);
    setErrors(Object.entries(response.errors).map(([_k,v]) => v));
  }

  const creditChange = (ordinalValue) => {
    setChosenCredit(props.creditSets.find((elem) => elem.ordinal === parseInt(ordinalValue)))
  }

  const handleClickSave = (e) => {
    e.preventDefault()

    makePostRequest({ url: APP_ROUTES.payments.checkout, body: { id: chosenCredit.id }}).then(res => {
      window.location = res.data.redirect_url
    }).catch(response => onError(response))
  }

  useEffect(() => {
    setChosenCredit(props.creditSets[0])
  }, [])

  return(
    <div className='container buy-credits'>
      <h1>Buy Credits</h1>
      <h3>Drag slider to set your amount</h3>
      <div className="range range-wrapper">
        <div className="addon">{ chosenCredit.sliderText }</div>
        <input defaultValue={1} step="1" min="1" max="3" type="range" onChange={(e) => creditChange(e.target.value)} className="form-range"/>
        <div className="range-legend">
        {
          props.creditSets.map((creditSet) => {
            return <span key={`credit-key-${creditSet.ordinal}`}><div>{creditSet.creditCount}</div></span>
          })
        }
        </div>
      </div>
      <div className="label-value">
        <div className="value">
          {chosenCredit.creditCount}
          <span>CR</span>
        </div>
        <div className="label">
          <div>{chosenCredit.creditPrice}</div>
          <div>{chosenCredit.perCreditText}</div>
        </div>
      </div>
      <p>What can you do with credits?</p>
      <ul className="list text-gray">
        <li>Sign up to Frindow arranged Get-togethers</li>
        <li>See how like-minded you are with others</li>
      </ul>
      <div className="form-action">
        <button type='submit' onClick={handleClickSave} className='btn btn-big btn-primary btn-submit'>
          <span className="icon-lock mr-2"></span>
          Checkout
        </button>
      </div>
      <FrindowErrors errors={errors} />
      <FrindowNotification messages={messages}
                           errors={errors}
                           clearNotices={() => {
                             setErrors([])
                             setMessages([])
                           }}
      />
    </div>
  );
}

export default BuyContainer;
