import React from 'react'
import PhotoModal from '../group/subjects/photo_modal'
import CodeConfirmationAlert from '../../shared/code_confirmation_alert'

class FormPost extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readMore: false,
      showModal: false,
      postPhoto: null,
      fileName: ''
    }
  }

  toggleReadMore = () => this.setState({
    readMore: !this.state.readMore
  })

  renderRespondTo = () => {
    if (!this.props.replyTo) { return null; }
    const { user_name, content } = this.props.postReplyTo
    const quote = this.state.readMore ? content : content.slice(0, 35).concat('...');
    const buttonText = this.state.readMore ? 'read less' : 'read more';
    return (
      <blockquote>
        <span className='replying-to'>{user_name}</span>
        <div dangerouslySetInnerHTML={{ __html: quote.split('&amp;').join('&') }} />
        <button onClick={this.toggleReadMore} className="ml-2 read-more-btn">{buttonText}</button>
      </blockquote>
    )
  }

  addImage = (image, postPhoto) => {
    this.setState({ showModal: false, postPhoto: postPhoto })
    this.props.setPostPhoto(postPhoto)
  }

  uploadStart = (file) => { this.setState({ fileName: file.name }) }

  renderFileName = () => {
    if (this.state.fileName) { return <p className="file-name mt-2 mb-0">{this.state.fileName}</p> }
  }

  renderPhotoModal = () => {
    if (!this.state.showModal) { return null; }

    return <PhotoModal
      profile={this.props.profile}
      addImage={this.addImage}
      isUserConfirmed={this.props.profile.user_confirmed}
      isOpen={this.state.showModal}
      uploadStart={this.uploadStart}
      addError={this.addError}
      closePhotoModal={this.closePhotoModal} />
  }

  closePhotoModal = () => {
    this.setState({ showModal: false })
  }

  addError = (error) => {
    this.setState({ showModal: false, error: error })
  }

  handleClickPhoto = () => {
    this.setState({ showModal: true })
  }

  allowSubmit = () => {
    return this.props.replyValue.length === 0
  }

  renderForm = () => {
    const { replyValue, handleChangeReply, handleClickPost, errors, emailMe, handleEmailMe, passRef } = this.props
    const validClassContent = errors.content ? 'form-control is-invalid' : 'form-control'
    const { postPhoto, error } = this.state

    return <div className='form'>
      <div className='form-group form-wysiwyg'>
        <div className='form-input-cell'>
          <textarea
            ref={passRef}
            id='form-for-post'
            className={validClassContent}
            placeholder='Please add your message here'
            value={replyValue} onChange={handleChangeReply}
          ></textarea>
          <div className='invalid-feedback'>{errors.content}</div>
        </div>
      </div>
      <div className='form-actions'>
        <div className='row'>
          <div className='col-12 col-sm-auto'>
            <div className='form-check'>
              <div className='form-input-cell checkbox-cell'>
                <input className='form-check-input' id='email-me-after' type='checkbox' onChange={handleEmailMe} value={emailMe} defaultChecked={emailMe} />
                <label className='form-check-label' htmlFor='email-me-after'>
                  <span className='checkbox'></span>
                  Email me replies to this post
                </label>
              </div>
            </div>
          </div>
          <div className='col-12 col-sm text-right'>
            {this.renderPhotoModal()}
            {!postPhoto && <button className='btn icon icon-camera text-muted' type='submit' onClick={this.handleClickPhoto} />}
            <button className='btn btn-primary btn-big btn-plane' disabled={this.allowSubmit()} onClick={handleClickPost}>Post</button>
            {this.renderFileName()}
            {error}
          </div>
        </div>
      </div>
    </div>
  }

  renderFormContainer = () => {
    const { replyTo } = this.props
    const titleReply = replyTo ? 'Replying to...' : 'Post a reply'

    return <div className='post-card reply-card bg-white shadow'>
      <h1 className='reply-title'>{titleReply}</h1>
      {this.renderRespondTo()}
      {this.renderForm()}
    </div>
  }

  render = () => {
    return (
      this.props.canWrite ? this.renderFormContainer() : CodeConfirmationAlert()
    )
  }
}

export default FormPost;
