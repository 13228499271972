/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

// Support component names relative to this directory:
var componentRequireContext = require.context("components", true);
var ReactRailsUJS = require("react_ujs");
ReactRailsUJS.useContext(componentRequireContext);
import { lozadObserver } from '../utils/lozad_observer'

document.addEventListener("DOMContentLoaded", () => {
  lozadObserver();
});

(function () {
  // get the output bucket
  var root = document.getElementsByTagName('html')[0];
  // create a div (could also reuse a known element)
  var el = document.createElement('div');
  // detect support
  var supports_grid = typeof el.style.grid === 'string';

  // add the output to the bucket
  root.classList.add(supports_grid ? 'grid' : 'no-grid');
})()
