import React, { Component } from 'react'
import ImagesModal from '../profile_completion/images_modal'
import CompletionHeading from '../shared/completion_heading'
import { PROFILE_TYPES } from '../../utils/constants'
import { makeGetRequest, makeDeleteRequest } from '../shared/api'
import FrindowSpinner from '../shared/frindow_spinner'
import FrindowNotification from '../shared/frindow_notification'
import _ from 'lodash'
import ProfileImage from '../shared/profile/profile_image'

export default class EditImages extends Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: null,
      showModal: false,
      images: [{}, {}, {}, {}, {}],
      errors: [],
      messages: [],
      showSpinner: false,
      selectedBox: -1
    }
  }

  componentDidMount = () => {
    const { profile } = this.props
    if(profile) {
      this.setState({ profile, showSpinner: true }, () => {
        makeGetRequest({ url: `/profiles/${this.props.profile.id}/photos` }).then((res) => {
          const { success, errors, images } = res.data
          if(success) {
            const sortedImages = this.setImagesPosition(images)
            this.setState({ images: sortedImages, showSpinner: false })
          } else {
            this.setState({ errors, showSpinner: false })
          }
        })
      })
    }
  }

  setImagesPosition = (unOrderedImages) => {
    const { images } = this.state
    const orederedImages = images.map((image, index) => {
      return unOrderedImages.find(un => un.position === index)
    })
    return orederedImages
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  uploadStart = () => {
    this.setState({ showSpinner: true })
  }

  addImage = (file) => {
    const { images, selectedBox } = this.state
    const newImages = images
    newImages[selectedBox] = file
    if(file){
      this.setState({
        images: newImages,
        showModal: false,
        showSpinner: false,
        messages: ['Your photo has been added successfully.']
      })
    }
  }

  addError = (errorMsg) => {
    this.setState({ errors: [...this.state.errors, errorMsg] })
  }

  addMessage = (msg) => {
    this.setState({ messages: [...this.state.messages, msg] })
  }

  clearNotices = () => {
    this.setState({ messages: [], errors: [] })
  }

  handleImageBtnClick = (e) => {
    e.stopPropagation();

    const index = parseInt(e.target.dataset.index)

    if (index >= 0 && _.isEmpty(this.state.images[index])) {
      this.setState({
        showModal: true,
        selectedBox: index
      })
    }
  }

  clearNotices = () => {
    this.setState({ messages: [], errors: [] })
  }

  handleImgRemoveClick = (e) => {
    e.stopPropagation();
    this.setState({ showSpinner: true })

    const { profile } = this.state
    this.id = parseInt(e.target.value)
    makeDeleteRequest({ url: `/profiles/${profile.id}/photos/${e.target.value}` }).then((res) => {
      if(res.status === 200){
        const { images } = this.state
        const newImages = images.map((image) => {
          if(image && image.id === this.id) return {}
          return image
        })
        this.setState({ images: newImages, messages: ['Photo has been successfully deleted'], showSpinner: false })
      }
    })
  }

  renderHeading = () => {
    const { profile } = this.state
    if (!profile) return null

    if (profile.type === PROFILE_TYPES.direct) {
      return (
        <CompletionHeading
          headText='Update profile photo'
          helperQuestion='Why do we need this?'
          helperText='Having a photo is optional, but having one makes you much more approachable.'
        />
      )
    }
    return (
      <CompletionHeading
        headText={`How about a photo of ${profile.first_name}`}
        helperQuestion='Do I need this?'
        helperText={`No, it’s optional. It just gives standard users and other proxy users an idea of who you're talking about. Please Get their consent before uploading.`}
      />
    )
  }

  handleNextClick = () => {
    this.props.history.push(ROUTES.proxy_images.path)
  }

  images_length = () => {
    const { images } = this.state
    return images.filter(i => !_.isEmpty(i)).length
  }

  render = () => {
    if (!this.props.profile) return null
    const { errors, messages, showModal, selectedBox } = this.state

    return (
      <React.Fragment>
        <ImagesModal
          profile={this.props.profile}
          selectedBox={selectedBox}
          isOpen={showModal}
          addImage={this.addImage}
          closeModal={this.closeModal}
          imagesLength={this.images_length()}
          addError={this.addError}
          uploadStart={this.uploadStart}
        />
        {this.renderHeading()}
        <div className='form'>
          <div className='form-group'>
            <div className='photo-upload'>
              <div className='c-2'>
                <ProfileImage
                  imgContainerIndex='0'
                  handleImageBtnClick={this.handleImageBtnClick}
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  image={this.state.images[0]}
                  fieldName='Main photo'
                />
              </div>
              <div className='c-1'>
                <ProfileImage
                  imgContainerIndex='1'
                  handleImageBtnClick={this.handleImageBtnClick}
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  image={this.state.images[1]}
                />
                <ProfileImage
                  imgContainerIndex='2'
                  handleImageBtnClick={this.handleImageBtnClick}
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  image={this.state.images[2]}
                />
              </div>
              <div className='c-1'>
                <ProfileImage
                  imgContainerIndex='3'
                  handleImageBtnClick={this.handleImageBtnClick}
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  image={this.state.images[3]}
                />
                <ProfileImage
                  imgContainerIndex='4'
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  handleImageBtnClick={this.handleImageBtnClick}
                  image={this.state.images[4]}
                />
              </div>
            </div>
          </div>
          <p className='text-gray'>
            Click or press on the boxes above to upload your images.
            <br />We accept images in JPG or PNG format.
          </p>
        </div>
        {this.state.showSpinner && <FrindowSpinner />}
        <FrindowNotification errors={this.state.errors} messages={this.state.messages} clearNotices={() => this.setState({ errors: [], messages: [] })} />
      </React.Fragment>
    )
  }
}
