import React from 'react'
import CalendarContainer from './calendar_container'
import FormGetTogether from './form_get_together'
import AttendeeseContainer from './attendeese_container'
import _ from 'lodash'
import { STATUS_GET_TOGETHER, MEETING_TYPE_GET_TOGETHER } from '../../utils/constants'
import { makePostRequest } from '../shared/api.js'
import moment from 'moment'
import axios from 'axios'
import FrindowNotification from '../shared/frindow_notification'
import SelectImageModal from '../shared/unsplash/select_image_modal'

class FormGetTogetherContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      title: props.get_together.title,
      description: props.get_together.description,
      place: props.get_together.place,
      address: props.get_together.address,
      maxParticipants: props.get_together.max_participants,
      status: STATUS_GET_TOGETHER.definitely_on,
      day: '',
      month: '',
      year: '',
      timeHour: '',
      timeMin: '',
      timePeriod: 'AM',
      meetingType: MEETING_TYPE_GET_TOGETHER.online,
      errors: [],
      messages: [],
      openModal: false,
      image: props.get_together.image,
      imageUrl: props.imageUrl,
      openImageModal: false,
      errorsNotify: []
    }
  }
  targetElement = null;

  componentDidMount() {
    if(this.props.get_together.start_date) {
      const startDate = moment.utc(this.props.get_together.start_date)
      this.setState({
        year: startDate.year(),
        month: startDate.format('MM'),
        day: startDate.format('DD'),
        timeHour: startDate.format('h'),
        timeMin: startDate.minute(),
        timePeriod: startDate.format('A'),
        status: STATUS_GET_TOGETHER[this.props.get_together.status],
        meetingType: MEETING_TYPE_GET_TOGETHER[this.props.get_together.meeting_type]
      })
    }
  }

  formData = (data, image) => {
    const dataToSend = new FormData()
    _.map(data, (value, key) =>{
            dataToSend.append(`get_together[${key}]`, value)
          })
    _.map(image, (value, key) => {
      dataToSend.append(`get_together[image][${key}]`, value)
    })
    return dataToSend
  }

  formDate = () => {
    const { day, month, year, timeHour, timeMin, timePeriod } = this.state
    const convertTime = moment(`${year}-${month}-${day}-${timeHour}-${timeMin}-${timePeriod}`, 'Y-M-D-h-m-A', 'UTC')
    const startDate = new Date(`${year}-${month}-${day}T${convertTime.format('HH')}:${convertTime.format('mm')}:00`)
    return startDate
  }

  validateForm = () => {
    const { day, month, year, timeHour, timeMin, imageUrl } = this.state
    const validateBody = _.pick(this.state, ['title', 'description', 'meetingType'])
    const newErrors = {}
    if (_.findIndex([day, month, year, timeHour, timeMin], d => d === '') !== -1) {
      newErrors.start_date = 'can\'t be blank'
    }
    if(newErrors.start_date == null && this.formDate().toString() === 'Invalid Date') {
      newErrors.start_date = 'Invalid Date'
    }
    _.map(validateBody, (value, key) => { if(value === '') { newErrors[key]='can\'t be blank' } })
    if(this.state.meetingType === 'In-person') {
      if (this.state.place === '') { newErrors['place']='can\'t be blank' };
      if (this.state.address === '') { newErrors['address']='can\'t be blank' };
    }
    if(imageUrl === '') { newErrors.unsplash_image_id = 'can\'t be blank' }
    this.setState({errors: newErrors})
    return _.isEmpty(newErrors)
  }

  handleClickPublish = () => {
    if(!this.validateForm()) { return null }
    const { action_get_together_url, edit } = this.props
    const { image } = this.state
    const formBody = _.pick(this.state, ['title', 'description', 'place', 'address', 'maxParticipants'])
    if(formBody.maxParticipants === 'No limit') { formBody.maxParticipants = 0 }
    formBody.status = Object.keys(STATUS_GET_TOGETHER).find(key => STATUS_GET_TOGETHER[key] === this.state.status)
    formBody.meeting_type = Object.keys(MEETING_TYPE_GET_TOGETHER).find(key => MEETING_TYPE_GET_TOGETHER[key] === this.state.meetingType)
    formBody.start_date = this.formDate()
    const sendData = this.formData(formBody, image)
    const headers = { 'content-type': 'multipart/form-data', 'X-CSRF-TOKEN': document.getElementsByTagName('meta')['csrf-token']['content'] }
    const request = edit ? axios.put : axios.post
    request(action_get_together_url, sendData, { headers: headers })
       .then((response) => {
         if (response.data.errors == null) {
           this.setState({
             errors: [],
             messages: [response.data.messages]
           })
           window.location.href = response.data.url
         }
         else {
           this.setState({
             errors: response.data.errors
           })
         }
    })
  }

  handleClickSendMsg = () => {
    const { message_url } = this.props
    const { content } = this.state
    makePostRequest({ url: message_url, body: { content: content } })
       .then((response) => {
         if (response.data.errors == null) {
           this.setState({
             openModal: false,
             content: '',
             messages: [response.data.messages],
             errors: []
           })
         }
         else {
           this.setState({
             errors: response.data.errors
           })
         }
    })
  }

  handleOnChange = (e) => {
    const { value, name } = e.target
    this.setState({ [name]: value })
  }

  updateBodyDataset = (check) => {
    document.querySelector('body').dataset.show = check ? 'modal' : '';
  }

  handleDayChange = (e) => this.setState({day: e.target.value})

  handleMonthChange = (e) => this.setState({month: e.target.value})

  handleYearChange = (e) => this.setState({year: e.target.value})

  handleTimeMinChange = (e) => this.setState({timeMin: e.target.value})

  handleTimeHourChange = (e) => this.setState({timeHour: e.target.value})

  handleTimePeriodChange = (e) => this.setState({timePeriod: e.target.value})

  handleMaxParticipantsChange = (e) => this.setState({maxParticipants: e.target.value})

  handleStatusChange = (e) => this.setState({status: e.target.value})

  handleMeetingTypeChange = (e) => this.setState({meetingType: e.target.value})

  handleClickOpen = () => this.setState({openModal: true}, () => this.updateBodyDataset(this.state.openModal))

  handleClickClose = () => this.setState({openModal: false}, () => this.updateBodyDataset(this.state.openModal))

  handlImageModal = () => this.setState({openImageModal: !this.state.openImageModal}, () => this.updateBodyDataset(this.state.openImageModal))

  handleClickSubmitImage = (image, imageUrl) => this.setState({image: image, imageUrl: imageUrl, openImageModal: false}, () => this.updateBodyDataset(this.state.openImageModal))

  render = () => {
    const { calendarGetTogethers, participants } = this.props
    const { image, openImageModal, imageUrl, content, openModal, errors, title, description, meetingType, place, address, day, month, year, timeHour, timeMin, timePeriod, maxParticipants, status, errorsNotify } = this.state
    const { handleClickSubmitImage, handlImageModal, handleOnChange, handleClickSendMsg, handleClickOpen, handleClickClose, handleClickPublish, handleDayChange, handleMonthChange, handleYearChange, handleTimeHourChange, handleTimeMinChange, handleTimePeriodChange, handleMaxParticipantsChange, handleStatusChange, handleMeetingTypeChange } = this
    return(
      <div className='container on-light get-together-content'>
        <div className='row'>
          <div className='col-lg-8 order-lg-2'>
            <SelectImageModal
              isOpen={openImageModal}
              imageUrl={imageUrl}
              image={image}
              closeModal={handlImageModal}
              handleClickSubmitImage={handleClickSubmitImage} />
            <h1 className='create-get-together-title'>Organise a Get Together</h1>
            <FormGetTogether
              imageUrl={imageUrl}
              title={title}
              description={description}
              meetingType={meetingType}
              place={place}
              address={address}
              day={day}
              month={month}
              year={year}
              timeHour={timeHour}
              timeMin={timeMin}
              timePeriod={timePeriod}
              maxParticipants={maxParticipants}
              status={status}
              handleClickPublish={handleClickPublish}
              handleDayChange={handleDayChange}
              handleMonthChange={handleMonthChange}
              handleYearChange={handleYearChange}
              handleTimeHourChange={handleTimeHourChange}
              handleTimeMinChange={handleTimeMinChange}
              handleTimePeriodChange={handleTimePeriodChange}
              handleMaxParticipantsChange={handleMaxParticipantsChange}
              handleStatusChange={handleStatusChange}
              handleMeetingTypeChange={handleMeetingTypeChange}
              handleOnChange={handleOnChange}
              handlImageModal={handlImageModal}
              errors={errors} />
            <FrindowNotification errors={errorsNotify} messages={this.state.messages} clearNotices={() => this.setState({ errorsNotify: [], messages: [] })} />
          </div>
          <div className='col-lg-4 order-lg-1'>
            <div className='calendar-holder'>
              <CalendarContainer
                calendarGetTogethers={calendarGetTogethers} />
            </div>
            <AttendeeseContainer
              openModal={openModal}
              handleClickOpen={handleClickOpen}
              handleClickClose={handleClickClose}
              content={content}
              handleOnChange={handleOnChange}
              handleClickSendMsg={handleClickSendMsg}
              participants={participants} />
          </div>
        </div>
      </div>
    )
  }
}

FormGetTogetherContainer.defaultProps = {
  get_together: {
    title: '',
    description: '',
    place: '',
    address: '',
    max_participants: 20,
    image: {}
  },
  imageUrl: '',
  participants: []
};

export default FormGetTogetherContainer;
