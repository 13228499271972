import React from 'react'
import { withRouter } from "react-router-dom";
import { makePutRequest } from '../shared/api'
import Container from '../sign_up_form/container'
import { getRoute } from '../shared/routes'

class WelcomeStep extends React.Component {
  componentDidMount = () => {
    const { current_step } = this.props.user
    if(current_step) {
      current_step < 6 ? this.redirectToPath() : window.location.replace('/')
    }

    if (process.env.NODE_ENV === 'production') {
      const comment = document.createComment('Event snippet for Completion - Welcome conversion page');
      document.body.appendChild(comment);

      const script = document.createElement("script");
      script.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-701811519/XAoVCOL3wJoCEL-W084C'});";
      document.body.appendChild(script);
    }
  }

  handleNextStep = () => {
    const { user, profiles } = this.props
    const { id } = user
    user.current_step = 1

    if(profiles[0].type == 'ClientProfile'){
      window.location = '/'
    }else{
      makePutRequest({ url: `/users/${id}`, body: { id, user }}).then((res) => {
        if(res.data.success) this.redirectToPath()
      })
    }
  }

  redirectToPath = () => {
    const path = getRoute(this.props.profile, this.props.user.current_step)
    this.props.history.push(path);
  }

  renderInfo = () => {
    const { client } = this.props
    if(client){
      return (
        <>
          <h1>Thank you for joining Frindow Business.</h1>
          <h2 className='font-weight-normal text-green mt-4'>Now you are ready to get started. What next?</h2>
          <h3 className='my-3 welcome-steps'>STEP 1 - <span className='font-weight-normal'>Create your profile</span></h3>
          <h3 className='my-3 welcome-steps'>STEP 2 - <span className='font-weight-normal'>Choose a plan</span></h3>
          <h3 className='my-3 welcome-steps'>STEP 3 - <span className='font-weight-normal'>Start being seen</span></h3>
          <p className='h2 font-weight-normal'>It’s that simple!</p>
        </>
      )
    }else{
      return(
        <>
        <h1>Thank you for joining Frindow.</h1>
        <h2 className='font-weight-normal'>Now just answer a few short questions so that we can help you get the best out of our community.</h2>
        <h2 className='font-weight-normal'>Let​’s​ go...</h2>
        </>
      )
    }
  }

  render = () => {
    const { client } = this.props
    const header = client ? '"The best way to find yourself is to lose yourself in the service of others."' : `“There is nothing better than a friend, unless it is a friend with chocolate.”`
    const desc = client ? `Mahatma Gandhi` : `Linda Grayson`
    return(
      <Container
        customClass='step-email-verified'
        handleNextClick={this.handleNextStep}
        headerText={header}
        descText={desc}
        business={client}
        btnLabel='Get started'
      >
        {this.renderInfo()}
      </Container>
    )
  }
}

export default withRouter(WelcomeStep)
