import React from 'react'

const ProfileImage = (props) => {
  const { imgContainerIndex, handleImageBtnClick, handleImgRemoveClick, image, fieldName } = props

  let wrapperClass = 'profile-picture'
  let content = (
    <span data-index={imgContainerIndex} className='add-file'>
      {fieldName ? fieldName : `Image ${parseInt(imgContainerIndex) + 1}`}
    </span>
  )
  let styles = {}

  if (image && image.url && image.id) {
    wrapperClass += ' with-image'
    styles = { backgroundImage: `url(${image.url})` }
    content = <button value={image.id} data-id={image.id} onClick={handleImgRemoveClick} className='btn btn-remove'></button>
  }

  return (
    <div data-index={imgContainerIndex} className={wrapperClass} onClick={handleImageBtnClick} style={styles}>
      {content}
    </div>
  )
}

export default ProfileImage
