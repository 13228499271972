import React from 'react'

const KeywordTags = (props) => {
  const { keywords, handleRemoveKeyword } = props
  return(
   <div className='groups-tags search-tags text-left my-2'>
     <h4>KEYWORDS (hobbies, interests, etc.)</h4>
     <ul className='group-tags-list row'>
      {keywords.map(keyword=>{
        return(
          <li key={keyword}>
            <a href='#' className='group-tag selected'>{keyword.toUpperCase().replace('_', ' ')}
              <button
                name={keyword}
                className='ml-1 search-reset symbol icon-cross-in-circle'
                onClick={handleRemoveKeyword} />
            </a>
          </li>
        )}
      )}
    </ul>
  </div> 
  )
}

export default KeywordTags 
