import React from 'react'

const ItemGetTogether = (props) => {
  const distanceField = () => {
    if (!props.getTogether.distance) { return; }

    return (
      <React.Fragment>
        <div className="icon-map-pin"></div>
        <div>{props.getTogether.distance} miles</div>
      </React.Fragment>
    )
  };

  const creatorField = () => {
    return (
      <a href={props.getTogether.hostedBy.profileUrl}>
        Host: {props.getTogether.hostedBy.username}
      </a>
    )
  };

  const imageOnClick= ()=> {
    window.location.href = props.getTogether.showDetailsUrl;
  };


  return(
    <div className="tile">
      <div className="tile-hero">
        <img src={props.getTogether.image} onClick={imageOnClick}/>
        <div className={`tag ${props.getTogether.meetingType}`}>{props.getTogether.meetingType}</div>
      </div>
      <div className="tile-content">
        <div className="tile-date-stamp">
          <a href={props.getTogether.showDetailsUrl}>
            <div>{props.getTogether.startDate}</div>
          </a>
          <a href={props.getTogether.showDetailsUrl}>
            <div>{props.getTogether.startTime}</div>
          </a>
        </div>
        <div className="tile-name">
          <a href={props.getTogether.showDetailsUrl}>
            {props.getTogether.title}
          </a>
        </div>
      </div>
      <div className="tile-footer">
        <div className="tile-location">
          { props.getTogether.type === 'event' ? distanceField() : creatorField() }
        </div>
        <div>{props.getTogether.placeholder}</div>
      </div>
    </div>
  )
}

export default ItemGetTogether;
