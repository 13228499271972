import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import Container from '../sign_up_form/container'
import { ROUTES } from '../shared/routes'
import FrindowAvatarSlider from '../shared/profile/frindow_avatar_slider'
import Button from '../shared/button'
import { makePutRequest } from '../shared/api.js'
import { ACCOUNT_TYPES } from '../../utils/constants'

class SelectAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      imageKeys: [],
      selectedAvatarName: props.user.avatar,
      showModal: false,
    }
  }

  getNextRoutePath = () => {
    return this.props.user.account_type === ACCOUNT_TYPES.proxy ? ROUTES.proxy_images.path : ROUTES.direct_images.path
  }

  requestUpdateAvatar = () => {
    const { user } = this.props
    const avatar = this.state.selectedAvatarName
    const body = { id: user.id, user: { avatar, current_step: ROUTES.direct_images.step } }
    return makePutRequest({ url: `/users/${user.id}`, body: body})
  }

  handleNextStep = () => {
    const { requestUpdateAvatar, getNextRoutePath } = this
    requestUpdateAvatar().then((res) => {
      if(res.data.success) {
        const { user } = this.props
        this.props.updateUser({...user, avatar: this.state.selectedAvatarName })
        const route = getNextRoutePath()
        this.props.history.push(route)
      }
    })
  }

  handleAvatarChange = (selectedAvatarName) => {
    this.setState({ selectedAvatarName })
  }

  render = () => {
    const avatar = this.state.selectedAvatarName
    return(
      <Container
        customClass='step-pick-avatar'
        headerText='Prefer to be anonymous to other users?'
        descText='We get it. You can share a photo of yourself (or not) at any time.'
        handleNextClick={avatar ? this.handleNextStep : false}
      >
        <h2>A picture paints a thousand words (but we also respect your privacy)​.</h2>
        <p>You can upload a photo later ​if you wish but for now, please select the avatar that best represents you.</p>
        <FrindowAvatarSlider
          selectedAvatarName={this.state.selectedAvatarName}
          handleAvatarChange={this.handleAvatarChange}
        />
        {!avatar &&
          <Button className='btn-primary' title='Next' disabled={true} />
        }
      </Container>
    )
  }
}

export default withRouter(SelectAvatar)
