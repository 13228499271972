import React from 'react'
import { quickMessageIcons, definedContent } from '../shared/quick_message'
import { QUICK_MESSAGE_KINDS } from '../../utils/constants'

const ListMessageContent = (props) => {
  const { conversation_url } = props.conversation
  const { read, content, message_kind, from_you } = props.conversation.message
  const { online, username } = props.conversation.profile

  const isOnlineClass = online ? 'user-name is-online' : 'user-name'

  const isRead = read ? 'last-mesage-content read' : 'last-mesage-content unread'
  
  return(
    <React.Fragment>
      {QUICK_MESSAGE_KINDS.includes(message_kind) &&
        <div className='col-auto pl-0'>
          <img className='img-fluid rounded-circle circle-avatar size-md' alt='wave' src={quickMessageIcons(message_kind)} />
        </div>
      }
      <div className='col conversation-details'>
        <h2 className={isOnlineClass}>
          <a href={conversation_url}>
            {username}
          </a>
        </h2>
        <p className={isRead}>
          <a href={conversation_url}>
            {definedContent(message_kind, content, from_you)}
          </a>
        </p>
      </div>
    </React.Fragment>
  )
}

export default ListMessageContent
