import React from 'react'
import SimpleSelectField from '../shared/simple_select_field'
import TextField from '../shared/text_field'
import TextArea from '../shared/text_area'
import SelectDateTime from './form_components/select_date_time'
import { STATUS_GET_TOGETHER, MEETING_TYPE_GET_TOGETHER } from '../../utils/constants'

class FormGetTogether extends React.PureComponent {
  rendermaxParticipants = () => {
    const { maxParticipants, handleMaxParticipantsChange } = this.props
    const participantsCollection = Array.from({length: 100}, (v, k) => k+1)
    return (
      <div className='form-input-cell select-cell small'>
        <SimpleSelectField
          key='key_participants'
          collection={participantsCollection}
          value={maxParticipants}
          onChange={handleMaxParticipantsChange}
          emptyKey='Attendees'
          errorPrompt={false}
        />
      </div>
    )
  }

  renderImage = () => {
    const { imageUrl } = this.props
    if(imageUrl.length < 1) { return null }

    return(
      <div>
        <p>Selected Image</p>
        <img src={imageUrl} name='image' className='thumb' />
      </div>
    )
  }

  render = () => {
    const { handlImageModal, handleOnChange, title, description, meetingType, place, address, year, day, month, handleClickPublish,
      status, handleYearChange, handleDayChange, handleMonthChange, handleStatusChange, handleMeetingTypeChange, errors, timeHour, timeMin,
      timePeriod, handleTimePeriodChange, handleTimeHourChange, handleTimeMinChange } = this.props
    const timeDateProps = { day, month, year, handleYearChange, handleDayChange, handleMonthChange, handleTimeHourChange,
      handleStatusChange, handleMeetingTypeChange, errors, timeHour, timeMin, timePeriod, handleTimePeriodChange, handleTimeMinChange }

    return(
      <div className='form-container create-get-together'>
        <div className='form'>
          <TextField
            className='form-control'
            classForm={''}
            placeholder='Get Together title (max 50 characters)'
            value={title}
            onChange={handleOnChange}
            name='title'
            type='text'
            maxLength={50}
            errorText={errors.title} />
          <TextArea
            className='form-control'
            value={description}
            onChange={handleOnChange}
            name='description'
            placeholder='Short Description'
            errorText={errors.description} />

          <div className='form-group'>
            <label>Meeting Type</label>
            <div className='form-input-cell select-cell small'>
              <SimpleSelectField
                collection={Object.values(MEETING_TYPE_GET_TOGETHER)}
                value={meetingType}
                emptyKey='Meeting Type'
                onChange={handleMeetingTypeChange}
                errorPrompt={errors.meetingType}
              />
            </div>
          </div>
          {
            meetingType === 'In-person' &&
              ( <div>
                  <TextField
                    className='form-control'
                    classForm={''}
                    placeholder='The name of the place'
                    value={place}
                    onChange={handleOnChange}
                    name='place'
                    type='text'
                    errorText={errors.place} />
                  <TextField
                    className='form-control'
                    classForm={''}
                    value={address}
                    onChange={handleOnChange}
                    name='address'
                    placeholder='Address'
                    type='text'
                    errorText={errors.address} />
                </div>
              )
          }
          <SelectDateTime
           {...timeDateProps}
          />
          <div className='form-group'>
            <label>MAX ATTENDEES</label>
            {this.rendermaxParticipants()}
          </div>
          <div className='form-group'>
            <label> Event status</label>
            <div className='form-input-cell select-cell small'>
              <SimpleSelectField
                collection={Object.values(STATUS_GET_TOGETHER)}
                value={status}
                onChange={handleStatusChange}
                emptyKey='Status'
                errorPrompt={false}
              />
            </div>
          </div>
          <div className='form-group'>
            <label>Select image</label>
            <button className='ml-2 btn btn-secondary btn-big btn-upload' onClick={handlImageModal}>Select Image</button>
            {this.renderImage()}
            {errors.unsplash_image_id != null &&
              <div className='form-message invalid-feedback mt-2'>{'Please select an image.'}</div>}
          </div>
          <hr/>
          <button className='btn btn-primary btn-big btn-update' onClick={handleClickPublish}>Publish</button>
        </div>
      </div>
    )
  }
}

export default FormGetTogether;
