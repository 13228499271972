import React, { PureComponent} from 'react';
import DropzoneComponent from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server'
import PropTypes from 'prop-types'

class ImageUploader extends PureComponent {
  constructor(props) {
    super(props);

    this.djsConfig = {
        dictDefaultMessage: 'Drop image here or click to upload from your device.',
        addRemoveLinks: false,
        acceptedFiles: "image/jpeg,image/png",
        previewTemplate: customPreview,
        autoProcessQueue: props.autoUpload,
        headers: {
          'X-CSRF-TOKEN': csrfToken()
        }
    };

    this.componentConfig = {
      iconFiletypes: ['.jpg', '.png'],
      postUrl: this.props.uploadUrl
    };

    this.eventHandlers = {
      init:       dz => this.dropzone = dz,
      drop:       this.callbackArray,
      addedfile:  this.addedFile,
      success:    this.success,
      error:      this.error,
    }

    this.dropzone = null;
  }

  addedFile = (file) => {
    if(this.dropzone.files.length > 1) {
      this.props.addError({image_count: 'Only one image can be uploaded at once.'})
      this.removeFile(file)
    }
    const total = this.props.imagesLength + this.dropzone.files.length
    if(total > this.props.maxFiles) {
      this.props.addError(`You can add max ${this.props.maxFiles} profile images`)
      this.removeFile(file)
    } else {
      this.props.uploadStart(file)
    }
  }

  removeFile = (file) => {
    this.dropzone.removeFile(file)
  }

  success = (_file, data) => {
    const { success, image, errors, post_photo } = data
    if(success){
      this.props.addImage(image, post_photo)
    } else {
      this.props.addError({format: errors})
    }
  }

  error = () => {
    this.props.addError({format: 'Only PNG or JPG files are permitted.'})
    if (this.props.maxFiles == 1) {
      this.dropzone.removeAllFiles()
    }
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;
    const eventHandlers = this.eventHandlers;

    return(
      <DropzoneComponent
        config={config}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
    )
  }
}
export default ImageUploader

ImageUploader.propTypes = {
  imagesLength: PropTypes.number,
  addImage:     PropTypes.func,
  addError:     PropTypes.func,
  uploadStart:  PropTypes.func
}

ImageUploader.defaultProps = {
  imagesLength: 0,
  maxFiles: 5
};

const csrfToken = () => {
  const csrf = document.getElementsByName('csrf-token')[0]
  return (csrf ? csrf.content : '')
}

const customPreview = ReactDOMServer.renderToStaticMarkup(
  <div className="dz-preview dz-file-preview">
  </div>
)
