import React from 'react'
import MiniProfile from '../profile/mini_profile'

const AttendeeseTab = (props) => {
  const { participants, showBtn, handleClickOpen, name } = props
  return(
    <React.Fragment>
      <h2 className='attendeese-subtitle'>
        <span className='number'>{participants.length}</span> {name}
      </h2>
      {showBtn &&
        <button className='btn btn-primary btn-big btn-plane' onClick={handleClickOpen}>Message all Attendees</button>
      }
      <ul className='simple-user-list clean-list'>
        {participants.map((user) => (
          <li key={user.profile.profile_path}>
            <MiniProfile {...user.profile} />
          </li>
        ))}
      </ul>
    </React.Fragment>
  )
}

export default AttendeeseTab;
