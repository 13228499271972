import React, { Component } from 'react'
import PhotoFrame from './photo_frame'

export default class PhotosList extends Component {
  render = () => {
    const { handleOnPhotoClick, photos, handleReasonChange, approvalStatusChange,
      profileImageId, handleProfileImageChange } = this.props
    return (
      <div className='row'>
        {photos.map((photo, index) => (
          <PhotoFrame
            handleProfileImageChange={handleProfileImageChange}
            profileImageId={profileImageId}
            photo={photo}
            key={`key_${photo.id}`}
            handleOnPhotoClick={handleOnPhotoClick}
            handleReasonChange={handleReasonChange}
            approvalStatusChange={approvalStatusChange}
          />
        ))
        }
      </div>
    )
  }
}
