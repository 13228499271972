import React, { PureComponent} from 'react';
import DropzoneComponent from 'react-dropzone-component';
import PropTypes from 'prop-types'

class SimpleImagesUploader extends PureComponent {
  constructor(props) {
    super(props);

    this.djsConfig = {
        dictDefaultMessage: 'Drop images here or click to upload from your device',
        addRemoveLinks: false,
        acceptedFiles: "image/jpeg,image/png",
    };

    this.componentConfig = {
      postUrl: 'no-url',
      iconFiletypes: ['.jpg', '.png'],
      maxFiles: 1
    };

    this.dropzone = null;
  }

  newFile = (file) => {
    if(this.dropzone.files.length > 1) {
      this.props.addError(`You can add 1 image to one box`)
      this.removeFile(file)
    }
    if (file.size < 1024000) { this.props.handleAddImage(file) }
    else { this.removeFile(file) }
  }

  removeFile = (file) => {
    this.dropzone.removeFile(file)
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const eventHandlers = {
        init:       dropzone => this.myDropzone = dropzone,
        drop:       this.callbackArray,
        addedfile:  image => this.newFile(image)
    }

    return(
      <DropzoneComponent
        config={config}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
    )
  }
}
export default SimpleImagesUploader

SimpleImagesUploader.propTypes = {
  image: PropTypes.shape({
    name: PropTypes.string,
    byte_size: PropTypes.integer,
    url: PropTypes.string
  })
}

SimpleImagesUploader.defaultProps = {
  imagesLength: 0,
  maxFiles: 1
};
