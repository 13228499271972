import React, { Component } from 'react'
import CreatableSelect from 'react-select/lib/Creatable'
import About from '../shared/profile/about'
import CustomSelect from '../shared/select_component'
import Button from '../shared/button'
import EditContactFilter from './edit_contact_filter'
import Select from 'react-select'
import { PROFILE_TYPES, REASON_TYPES, VALIDATION_MSG } from '../../utils/constants'
import _ from 'lodash'
import FrindowNotification from '../shared/frindow_notification'

import { makeGetRequest, makePutRequest, makePostRequest } from '../shared/api'
import { profileKeywordsPath } from '../shared/routes'

export default class EditProfile extends Component {
  constructor(props){
    super(props)
    this.state = {
      profile: null,

      maritial_status: null,
      maritial_statuses: [],
      maritialStatusError: '',

      religion: null,
      religions: [],
      religionError: '',

      children_status: null,
      children_statuses: [],
      childrenStatusError: '',

      languages: [],
      user_languages: [],

      reasons: [],
      user_reasons: [],
      personal_reason: { id: null, value: '', private: true },

      opentos: [],
      profile_opentos: [],

      keywords: [],
      availableKeywords: [],

      ageStart: props.contact_filter.ageStart || 18,
      ageEnd: props.contact_filter.ageEnd || 100,
      selectedGenderFilter: props.contact_filter.selectedGenderFilter,

      messages: [],
      errors: [],
    }
  }

  componentDidMount = () => {
    const { profile } = this.props
    this.setState({ profile }, () => {
      this.fetchAdditionalInfo()
      this.fetchReasonsList()
      this.fetchProfileReasons()
      this.fetchOpenTosData()
      this.fetchKeywords()
    })
  }

  fetchKeywords = () => {
    const { id } = this.props.profile
    return makeGetRequest({ url: profileKeywordsPath(id) }).then((res) => {
      const { data } = res
      if(data.success) {
        const keywords = this.mapKeywords(data.keywords)
        const availableKeywords = this.mapKeywords(data.available)
        this.setState({ keywords, availableKeywords })
      }
    })
  }

  parseKeywords = () => {
    return this.state.keywords.map((k) => ({ name: k.label }))
  }

  mapKeywords = (keywords) => keywords.map((obj) => ({ label: _.capitalize(obj.name), value: obj.id }))

  fetchOpenTosData = () => {
    Promise.all([this.fetchOpentos(), this.fetchProfileOpentos()]).then(([opentos, profile_opentos]) => {
      if(opentos.data.success && profile_opentos.data.success) {
        this.setState({ opentos: opentos.data.opentos, profile_opentos: profile_opentos.data.profile_opentos })
      }
      if(window.location.hash == '#whoDoIWantToHearFrom') {
        const elem = document.getElementById('whoDoIWantToHearFrom')
        if(elem) { elem.scrollIntoView() };
      }
    })
  }

  fetchOpentos = () => makeGetRequest({ url: '/opentos' })

  fetchProfileOpentos = () => makeGetRequest({ url: `/profiles/${this.state.profile.id}/opentos` })

  fetchAdditionalInfo = () => (
    makeGetRequest({ url: `/profiles/${this.props.profile.id}/additional_info` }).then((res) => {
      const { data } = res
      if(data.success){
        this.setState({ ...data.options, ...data.profile_vals})
      }
    })
  )

  fetchReasonsList = () => {
    const type = this.isProfileTypeDirect() ? REASON_TYPES.direct : REASON_TYPES.proxy
    makeGetRequest({ url: `/reasons/list?type=${type}` }).then((res) => {
      if(res.data.success) {
        const { reasons } = res.data
        this.setState({ reasons })
      }
    })
  }

  fetchProfileReasons = () => {
    const { profile } = this.state

    makeGetRequest({ url: `/profiles/${profile.id}/reasons` }).then((res) => {
      if(res.data.success) {
        const personal_reason = _.find(res.data.profile_reasons, ['private', true])

        if(personal_reason) this.setState({ personal_reason })

        const user_reasons = _.filter(res.data.profile_reasons, (e) => e.private === false)
        this.setState({ user_reasons })
      } else {
        const { errors } = res.data
        this.setState({ errors })
      }
    })
  }

  isProfileTypeDirect = () => ( this.state.profile.type === PROFILE_TYPES.direct )

  handleAbout = (e) => {
    this.setState({ profile: {...this.state.profile, about_me: e.target.value }})
  }

  handleMaritialStatuses = (e) => {
    const maritial_status = _.find(this.state.maritial_statuses, (ms) => ms.name === e.target.value)
    this.setState({ maritial_status })
  }

  handleReligion = (e) => {
    const religion = _.find(this.state.religions, (g) => g.name === e.target.value)
    this.setState({ religion, religionError: '' })
  }

  handleChildrenStatus = (e) => {
    const children_status = _.find(this.state.children_statuses, (cs) => cs.name === e.target.value)
    this.setState({ children_status, childrenStatusError: '' })
  }

  handleLanguageChange = (value) => {
    const user_languages = value.map((obj) => ({ id: obj.value, name: obj.label }))
    this.setState({ user_languages, languagesError: '' })
  }

  handlePersonalReason = (e) => {
    const personal_reason = { ...this.state.personal_reason, value: e.target.value }
    this.setState({ personal_reason })
  }

  handleCancel = () => {
    this.fetchAdditionalInfo()
    this.fetchReasonsList()
    this.fetchProfileReasons()
    this.setState({ messages: ['Form has been reseted']})
  }

  disableUnchecked = (value = true) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]:not(:checked)')
    checkboxes.forEach((c) => c.disabled = value)
  }

  handleReasonClick = (e) => {
    const { reasons, personal_reason } = this.state
    let { user_reasons} = this.state
    const { checked, value } = e.target
    let updates = {}
    const reason = _.find(reasons, ['id', parseInt(value)])
    if(checked) {
      user_reasons.push(reason)
      updates = { ...updates, user_reasons}
      if(user_reasons.length > 4) this.disableUnchecked()
    } else {
      _.remove(user_reasons, (ur) => (ur.id == value))
      if(user_reasons.length < 5) this.disableUnchecked(false)
      updates = { ...updates, user_reasons}
      if(reason.other)
      updates = { ...updates, personal_reason: { ...personal_reason, value: '' }}
    }
    this.setState(updates)
  }

  handleUpdate = () => {
    const errors = this.validation()

    if(this.isValid(errors)) {
      Promise.all([this.requestUpdateProfile(), this.requestUpdateReasons()]).then(([profile, reasons]) => {
        if(profile.data.success && reasons.data.success){
          this.setState({ messages: ['Profile has been updated'] })
        } else {
          let errors
          if(profile.data.errors &&
            (profile.data.errors.age_difference || profile.data.errors.date_invalid)) {
            errors = profile.data.errors.date_invalid || profile.data.errors.age_difference
          } else {
            errors = [ profile.data.errors, reasons.data.errors ]
          }
          this.setState({ errors })
        }
      })
    } else {
      this.setState(errors)
    }
  }

  isValid = (errors) => errors.every((e) => e === '')

  requestUpdateReasons = () => {
    const { personal_reason, user_reasons, profile } = this.state
    const generalReasons = _.filter(user_reasons, (e) => e.private == false)
    const reasonsIds = _.map(generalReasons, 'id')
    const body = { profile: { general: reasonsIds, personal: personal_reason } }

    return makePostRequest({ url: `/profiles/${profile.id}/add_reasons`, body: body })
  }

  requestUpdateProfile = () => {
    const { maritial_status, religion, children_status, user_languages, profile,
            profile_opentos, ageStart, ageEnd, selectedGenderFilter } = this.state

    const params = {
      profile: {
        about_me: profile.about_me,
        maritial_status_id: maritial_status.id,
        religion_id: religion.id,
        children_status_id: children_status.id,
        language_ids: user_languages.map((lang) => (lang.id)),
        opento_ids: profile_opentos.map((o) => o.id),
        keywords: this.parseKeywords(),
        contact_filter: { age_range_start: ageStart,
                          age_range_end: ageEnd,
                          gender_filter: selectedGenderFilter }
      }
    }

    return makePutRequest({ url: `/profiles/${profile.id}`, body: params })
  }

  checkPresence = (value) => {
    return value === '' ? VALIDATION_MSG : ''
  }

  validation = () => {
    const { maritial_status, religion, children_status} = this.state

    const maritialStatusError = this.checkPresence(maritial_status)
    const religionError       = this.checkPresence(religion)
    const childrenStatusError = this.checkPresence(children_status)

    const errors = { maritialStatusError, religionError, childrenStatusError }

    return Object.values(errors)
  }

  showPersonalReason = () => {
    return _.find(this.state.user_reasons, ['other', true])
  }

  renderMaritialStatus = () => {
    const { maritial_statuses, maritial_status } = this.state
    return(
      <CustomSelect
        collection={_.map(maritial_statuses, 'name')}
        value={maritial_status ? maritial_status.name : ''}
        handler={this.handleMaritialStatuses}
        name={'Marital Status'}
      />
    )
  }

  renderReligion = () => {
    return null

    // GDPR changes
    // const { religion, religions } = this.state
    // return(
    //   <CustomSelect
    //     collection={_.map(religions, 'name')}
    //     value={religion ? religion.name : ''}
    //     handler={this.handleReligion}
    //     name={'Religion'}
    //   />
    // )
  }

  renderChildrenStatus = () => {
    const { children_status, children_statuses, childrenStatusError } = this.state
    return(
      <CustomSelect
        collection={_.map(children_statuses, 'name')}
        value={children_status ? children_status.name : ''}
        handler={this.handleChildrenStatus}
        name={'Children status'}
        error={childrenStatusError}
      />
    )
  }

  renderLanguages = () => {
    const options = this.state.languages.map((obj) => ({ label: obj.name, value: obj.id }))
    const value = this.state.user_languages.map((obj) => ({ label: obj.name, value: obj.id }))

    return(
      <div className="form-check form-multi-autocomplete">
        <label>Languages</label>
        <Select
          isMulti
          options={options}
          onChange={this.handleLanguageChange}
          value={value}
          className={'form-input-cell'}
          classNamePrefix={'autocomplete'}
        />
      </div>
    )
  }

  renderReasons = () => {
    const { user_reasons, reasons } = this.state
    return(
      <React.Fragment>
        <h2>Statements</h2>
        <p>Please select up to 5 statements that most closely relate to you:</p>
        <div className="form-check escape-parent">
          {reasons.map((reason) => {
            const checked = !!_.find(user_reasons, ['id', reason.id])
            return(
              <label className="bullet-checkbox" key={'label_reason_' + reason.id}>
                <input
                  name="related[]"
                  onChange={this.handleReasonClick}
                  type="checkbox"
                  value={reason.id}
                  checked={checked}
                />
                <div className="bullet">{reason.value}</div>
              </label>
            )
          }
          )}
        </div>
        {this.showPersonalReason() && this.renderPersonalReason()}
      </React.Fragment>
    )
  }

  renderPersonalReason = () => {
    const { user_reasons, personal_reason } = this.state

    if(_.find(user_reasons, (ur) => (ur.other) )){
      return (
        <div className="form-group">
          <div className="form-input-cell">
            <input onChange={this.handlePersonalReason} className='form-control' placeholder={'Please provide your own statement'} type='text' value={ personal_reason.value }  />
          </div>
        </div>
      )
    }
    return null
  }

  objInArrayNotExists = (array, value) => array.findIndex((o) => o.id === value) === -1

  handleOpentoClick = (e) => {
    const { checked } = e.target
    let { value } = e.target
    value = parseInt(value)
    const index = _.findIndex(this.state.opentos, ['id', value])
    let { profile_opentos } = this.state

    if(checked) {
      if(this.objInArrayNotExists(profile_opentos, value)){
        profile_opentos = [ ...this.state.profile_opentos, this.state.opentos[index] ]
      }
    } else {
      profile_opentos = _.filter(this.state.profile_opentos, (o) => o.id !== value)
    }
    this.setState({ profile_opentos })
  }

  renderOpentos = () => {
    if (this.state.profile.type === PROFILE_TYPES.proxy) return null;
    return(
      <React.Fragment>
        <h2>I'm open to...</h2>
        <p>Please select any statements that closely relate to you:</p>
        {this.state.opentos.map((o) => (
          <label className='bullet-checkbox' key={`key_open_${o.id}`}>
            <input
              onClick={this.handleOpentoClick}
              type='checkbox'
              value={o.id}
              defaultChecked={_.find(this.state.profile_opentos, ['id', o.id])}
            />
            <div className="bullet">{o.name}</div>
          </label>
        ))}
        <hr />
      </React.Fragment>
    )
  }

  handleGenderFilterChange = (e) => {
    const { checked, value } = e.target
    const { selectedGenderFilter } = this.state
    if(checked && !selectedGenderFilter.find(a => a === value)) {
      this.setState(state => ({selectedGenderFilter: state.selectedGenderFilter.concat(value) }))
    }
    else {
      this.setState(state => ({selectedGenderFilter: state.selectedGenderFilter.filter(a => a !== value) }))
    }
  }

  handleAgeEndChange = (e) => this.setState({ageEnd: e.target.value})

  handleAgeStartChange = (e) => this.setState({ageStart: e.target.value})

  renderContactFilter = () => {
    const { ageEnd, ageStart, selectedGenderFilter, profile, errors } = this.state
    const { handleAgeStartChange, handleAgeEndChange, handleGenderFilterChange } = this
    return(
      <EditContactFilter
        ageStart={ageStart}
        handleAgeStartChange={handleAgeStartChange}
        ageEnd={ageEnd}
        handleAgeEndChange={handleAgeEndChange}
        errors={errors}
        selectedGenderFilter={selectedGenderFilter}
        isProxy={profile.type === PROFILE_TYPES.proxy}
        handleGenderFilterChange={handleGenderFilterChange} />
    )
  }

  renderKeywords = () => {
    return(
      <div className="form-check form-multi-autocomplete">
        <label>Keywords & Interests</label>
        <CreatableSelect
          isMulti
          value={this.state.keywords}
          onChange={this.handleKeywordsChange}
          className={'form-input-cell'}
          classNamePrefix={'autocomplete'}
          options={this.state.availableKeywords}
        />
      </div>
    )
  }

  handleKeywordsChange = (value) => {
    this.setState({ keywords: value })
  }

  renderActions = () => (
    <div className='form-actions mt-4'>
      <Button
        className='button btn btn-big btn-secondary btn-cancel btn-submit'
        title='Cancel'
        onClick={this.handleCancel}
      />
      <Button
        className='btn btn-big btn-primary btn-update btn-submit'
        title='Update'
        onClick={this.handleUpdate}
        disabled={this.state.selectedGenderFilter.length < 1}
      />
    </div>
  )

  render = () => {
    const { profile, reasons, opentos } = this.state
    if(!profile) return null
    return(
      <div className='form'>
        <h2>About me</h2>
        <span className='tip'>
          <span data-title-right='Just a few words to introduce yourself. Let others know a little about you, for example what you like doing and what you’re looking for on Frindow.'>Need some help? </span>
        </span>
        <About profile={this.state.profile} handleAbout={this.handleAbout} />
        {this.renderMaritialStatus()}
        <div>{this.state.maritialStatusError}</div>
        {this.renderReligion()}
        <div>{this.state.religionError}</div>
        {this.renderChildrenStatus()}
        <div>{this.state.childrenStatusError}</div>
        {this.renderLanguages()}
        {this.renderKeywords()}
        <hr />
        {reasons.length > 0 && opentos.length > 0 &&
          <React.Fragment>
            <div id='whoDoIWantToHearFrom'/>
            {this.renderOpentos()}
            {this.renderContactFilter()}
            <hr />
            {this.renderReasons()}
            <hr />
            {this.renderActions()}
          </React.Fragment>
        }
        <FrindowNotification errors={this.state.errors} messages={this.state.messages} clearNotices={() => this.setState({ errors: [], messages: [] })} />
      </div>
    )
  }
}
