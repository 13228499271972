import React from 'react'
import MiniProfile from '../../../profile/mini_profile'
import ReportContainer from '../../../reported_content/container'
import FollowSubjectToggle from '../../subjects/follow_subject_toggle'
import { makeGetRequest } from '../../../shared/api.js'
import { forceImageUpdate } from '../../../../utils/lozad_observer'
import { SystemNotification, SystemNotificationAvatar}  from '../../../shared/system_notification'
import DeletePostContainer from '../../subjects/delete_post_container'
import FrindowModal from '../../../shared/frindow_modal'
import i18n from '../../../../utils/i18n'
import QuotedPost  from '../../subjects/quoted_post'

export default class InitialPost extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      liked: props.post.liked,
      openReportModal: false,
      isReported: props.post.is_reported,
      readMore: false,
      openOriginalModal: false
    }

    this.profileRef = React.createRef(null)
  }

  componentDidMount = () => {
    this.updateImage()
  }

  componentDidUpdate = () => {
    this.updateImage()
  }

  updateImage = () => {
    if(this.profileRef.current){
      const userImg = this.profileRef.current.querySelector('.lozad')
      forceImageUpdate(userImg)
    }
  }

  renderMiniProfile = () => {
    const { publishedEventType } = this.props
    const { avatar, profile_path, photo_url, profile } = this.props.post
    const { user_name, deleted } = this.props.post.profile

    return(
      <div className='col-auto' ref={this.profileRef}>
        {<MiniProfile
          username={user_name}
          profile={profile}
          photo_url={photo_url}
          avatar={avatar}
          profile_path={profile_path}
          deleted={deleted}
          publishedEventType={publishedEventType}
          sizeClass='size-md' />}
      </div>
    )
  }

  renderReportButton = () => {
    if(this.state.isReported) {
      return(
        <span>Reported</span>
      )
    }

    return(
      <a
        href='#profile?'
        className='btn-report'
        onClick={() => this.setState({openReportModal: true})}>
          Report
      </a>
    )
  }

  renderActionsDropdown = (id) => {
    const { renderReportButton, renderFollowBtn } = this
    return(
      <div className='dropdown post-dropdown left'>
        <button className='btn' type='button' id={`${id}dropdown`} data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
          •••
        </button>
        <div className='dropdown-menu' aria-labelledby={`${id}dropdown`}>
          {renderReportButton()}
          {renderFollowBtn()}
        </div>
      </div>
    )
  }

  renderFollowBtn = () => {
    const { parentSetState, processingFollow, followUrl, isFollowed, subjectId } = this.props
    return(
      <FollowSubjectToggle
        parentSetState={parentSetState}
        procesingFollow={processingFollow}
        subjectId={subjectId}
        followUrl={followUrl}
        isFollowed={isFollowed} />
    )
  }

  handleLikeClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { likeUrl, unlikeUrl } = this.props
    const url = this.state.liked ? unlikeUrl : likeUrl
    makeGetRequest({ url: `${url}` })
         .then((response) => {
           if (response.data.errors == null) {
             this.setState({ liked: response.data.liked });
           }
         });
  }

  renderLikeBtn = () => {
    let likeText = 'Like'
    let likeClass = 'btn btn-link btn-like btn-mute mr-3'
    if (this.state.liked) {
      likeText = 'Liked'
      likeClass += ' btn-liked'
    }
    if(this.props.banned){
      return ('')
    }else{
      return (
        <button className={likeClass} onClick={this.handleLikeClick}>
          {likeText}
        </button>
      )
    }
  }

  showOriginal = () => { this.setState({openOriginalModal: true}) }

  closeModal = () => { this.setState({openOriginalModal: false}) }

  renderPostContent = () => {
    const { post, publishedEventType } = this.props
    const { profile_path, post_photo_url, desktop_post_photo_url } = post
    const { user_name, deleted } = post.profile
    const { openOriginalModal } = this.state

    const userName = publishedEventType ? i18n.t('profile.systemUserName') : user_name

    let profileUrl = (<a href={profile_path}>{userName}</a>)
    if (publishedEventType) {
      profileUrl = (<span className='text-blue-ui'>{userName}</span>)
    }

    return <>
      <div className='row align-items-center new-post-heading flex-nowrap'>
        {this.renderMiniProfile()}
        <div className='col-auto'>
          {!deleted && profileUrl}
        </div>
        <div className='col-auto ml-auto'>
        </div>
      </div>
      <div className='post-content my-3'>
        {post.parent && <QuotedPost quotedPost={post.parent} />}
        <div dangerouslySetInnerHTML={{__html: post.content.split('&amp;').join('&')}}/>
        { desktop_post_photo_url.length > 0 &&
          <div className="post-photo">
            <div className='img-wrapper'>
              <img className='lozad' data-src={desktop_post_photo_url} src={desktop_post_photo_url} onClick={this.showOriginal} />
            </div>
            <FrindowModal customClass='ultra-wide' isOpen={openOriginalModal} closeModal={this.closeModal}>
              <img src={post_photo_url} />
            </FrindowModal>
          </div>
        }
      </div>
    </>
  }

  onReportModalSubmit = (report) => {
    this.setState({openReportModal: false, isReported: report.status})
  }

  render() {
    const { subjectFormId, groupUrl, groupDisplayName, handleReplyClick, postsCount, post, title, subjectUrl, publishedEventType, handleShowPostsClick, showPosts, discardPermitted, subjectId } = this.props
    const { id } = post

      if(!this.props.post.discarded_at){
        return <div className='subject-wrapper'>
          <div className='subject-mod-actions'>
            {discardPermitted && <DeletePostContainer subjectId={subjectId} postId={id} />}
          </div>
          {
            !publishedEventType && subjectUrl && (
              <div className='post-group mb-1'>
                <a href={subjectUrl} className='thread-link'>{title}</a>
              </div>
            )
          }

          {this.renderPostContent()}

          <div className='row align-items-center post-footer'>
            {this.renderActionsDropdown(id)}
            {this.state.openReportModal &&
              <ReportContainer  onReportModalClose={() => this.setState({openReportModal: false})}
                                objectId={id}
                                objectType='Post'
                                onReportModalSubmit={this.onReportModalSubmit} />}
            <div className='col-auto'>
              {groupUrl && <a href={groupUrl} className='group-link'>{groupDisplayName}</a>}
            </div>
          </div>

          <hr className='my-3' />
          { postsCount > 0 &&
              <div className='row mobile-flex post-actions justify-content-end mb-1'>
                <div className="col-auto updated-at">{post.forum_format_updated_at}</div>
              </div>
          }
          <div className='row align-items-center post-actions'>
            { postsCount > 0 &&
              <div className='col-auto'>
                <a href="#SeeMore" onClick={handleShowPostsClick}>
                  <i className="mr-2 icon-reply"/>
                  { showPosts ? 'Hide' : 'See'} {postsCount} replies
                </a>
              </div>
            }
            <div className='col-auto mr-sm-auto text-right ml-auto ml-sm-0 mr-0'>
              {this.renderLikeBtn()}
              {!this.props.banned && this.props.canWrite &&
                <button type='button' data-post-id={id} data-post-form={subjectFormId} onClick={handleReplyClick} className='btn-mute btn btn-link btn-reply'>Reply</button>
              }
            </div>
            <div className={`col-auto ml-sm-auto mr-sm-0 updated-at ${postsCount > 0 && 'mobile-none'}`}>{post.forum_format_updated_at}</div>
          </div>
        </div>
      }else{
        return <div className='subject-wrapper'>
          <div className='post-group mb-1'>
          </div>
          <div className='row align-items-center new-post-heading flex-nowrap'>
            <div className='col-auto'>
              <SystemNotificationAvatar />
            </div>
            <div className='col-auto ml-auto'>
              <SystemNotification />
            </div>
          </div>
          <div className='post-content my-3'>
            The post that was here has been removed. This is because it violated the
            <a href="https://frindow.freshdesk.com/support/solutions/articles/60000615576-forum-policy" className="rules"> forum policy</a>
          </div>
        </div>
      }

  }
}
