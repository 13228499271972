import React from 'react'
import Container from './sign_up_form/container'
import { EMAIL_REGEX } from '../utils/constants'
import TextField from './shared/text_field'
import CheckBoxField from './shared/checkbox_field'

export default class SignIn extends React.Component {
  constructor(props){
    super(props)
    this.state = {
      email: '',
      emailErrorText: '',
      password: '',
      passwordErrorText: '',
      rememberMe: true
    }
  }

  handleEmailChange = (e) => this.setState({ email: e.target.value, emailErrorText: '' })

  handlePasswordChange = (e) => this.setState({ password: e.target.value, passwordErrorText: '' })

  handleRememberMeChange = (e) => this.setState({ rememberMe: e.target.checked })

  handleSignInClick = (e) => {
    e.preventDefault()
    this.validate(() => {
      if(!this.state.emailErrorText) document.getElementById('sign_in_form').submit()
    })
  }

  validate = (submitForm) => {
    const validation = EMAIL_REGEX.test(this.state.email.trim()) ? { emailErrorText: ''} : { emailErrorText: 'Incorrect email'}
    this.setState(validation, submitForm)
  }

  render = () => {
    const { handleEmailChange, handlePasswordChange, handleRememberMeChange, handleSignInClick, state } = this
    const { emailErrorText, email, passwordErrorText, password, rememberMe } = state

    const headerText = this.props.business ? <>"No one is useless in this world who lightens the burdens of another."</> : `“You can't stay in your corner of the Forest waiting for others to come to you. You have to go to them sometimes.”`
    const descText = this.props.business ? <span>- Charles Dickens <br/><br/> Sign in and help others find your services...</span> : <span>- A.A Milne, Winnie-the-Pooh <br/><br/> Welcome back. Sign in and start exploring...</span>
    const signUpLInk = this.props.business ? '/business/sign_up' : '/users/sign_up'
    const resetPasswordLInk = this.props.business ? '/business/password/new' : '/users/password/new'

    return(
      <Container
        customClass='step-sign-in'
        headerText={headerText}
        descText={descText}
        business={this.props.business}
      >
        <h1>Sign in</h1>
        <p>Not registered with us yet? <a href={signUpLInk} className='text-blue-ui'>Sign up</a></p>
        { this.props.business &&
          <p>Here to make new friends? <a href="/users/sign_up">Click here.</a></p>
        }
        <div className='form'>
          <TextField
            type='text'
            value={email}
            placeholder="Email"
            onChange={handleEmailChange}
            errorText={emailErrorText}
            name='user[email]'
          />
          <TextField
            type='password'
            value={password}
            errorText={passwordErrorText}
            placeholder='Password'
            onChange={handlePasswordChange}
            name='user[password]'
          />

          <input type="hidden" name='user[business]' value={this.props.business ? 1 : 0} />

          <p className="note-for-form-group"><a href={resetPasswordLInk} className='text-blue-ui'>Forgotten your password?</a></p>
          <CheckBoxField
            value={rememberMe}
            checked={rememberMe}
            onChange={handleRememberMeChange}
            id={'rememberMe'}
            labelDesc='Remember me'
            name='user[remember_me]'
          />
          <div className="form-actions">
            <button onClick={handleSignInClick} className="btn btn-primary btn-big btn-next btn-submit">Sign in</button>
          </div>
        </div>
      </Container>
    )
  }
}
