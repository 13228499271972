import React from 'react'

export const About = ({ profile, handleAbout }) => {
  return(
    <React.Fragment>
      {profile &&
        <div className="form-group">
          <div className="form-input-cell">
            <textarea value={profile.about_me || ''} onChange={handleAbout} className="form-control" placeholder="Tell us about yourself..."/>
          </div>
        </div>
      }
    </React.Fragment>
  )
}

export default About
