import React from 'react'

const PackageRow = (props) => {
  const { name, position } = props.selectedPackage
  const { index, handlePositionChange } = props

  return(
    <div className='row'>
      <div className='col-8'>
        <label>{name}</label>
      </div>
      <div className='col-4'>
        <input
          type='number'
          min='1'
          value={position}
          onChange={handlePositionChange}
          index={index}
        />
      </div>
    </div>
  )
}

export default PackageRow 
