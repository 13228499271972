import React, { useState, useRef } from 'react'
import FrindowModal from '../../shared/frindow_modal'

const PaymentsLink = (props) => {
  const [showModal, setShowModal] = useState(false)
  const printableContent = useRef(null)

  const handleClick = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const renderPayments = () => {
    let payments = []
    for(let i =0; i < props.payments.length; i++){
      payments.push( <tr key='info-${props.payments[i].id}'><td data-title="Date" key="date">{ formatToDate(new Date(props.payments[i].gateway_time)) }</td>
                         <td data-title="Package name" key="packageName">{ props.payments[i].name }</td>
                         <td data-title="Transaction ID" key="transactionCode">{ props.payments[i].transaction_code }</td>
                         <td data-title="Price" key="amount">{ formatToCurrency(props.payments[i].package_amount) }</td>
                         <td data-title="VAT">0</td>
                         <td data-title="Amount Paid">{ formatToCurrency(props.payments[i].paid_amount) }</td>
                         </tr> )
    }

    return (
      <table className="w-100 text-left payment-table">
        <thead className="border-bottom">
          <tr>
            <th>Date</th><th>Package name</th><th>Transaction ID</th><th>Price</th><th>VAT</th><th>Amount Paid</th>
          </tr>
        </thead>
        <tbody>
        {payments}
        </tbody>
      </table>
    )
  }

  const formatToCurrency = (number) => {
    return Intl.NumberFormat('en-EN', {style: 'currency', currency: 'GBP'}).format(number)
  }

  const formatToDate = (time) => {
    let options = { year: 'numeric', month: 'numeric', day: '2-digit'};
    return new Intl.DateTimeFormat('en-GB', options).format(time);
  }

  const printPreview = () => {
    window.print(printableContent.current);
  }

  return (
    <>
      <div>
        <button
          type='button'
          onClick={handleClick}
          className='btn btn-secondary btn-small'
        >
          See All Payments
        </button>
        <FrindowModal
          isOpen={showModal}
          closeModal={closeModal}
          customClass='wide payments-modal'
        >
          <div className="print-content" ref={printableContent}>
            <div className="row mb-3 flex-shrink-0">
              <div className="col-auto">
                <img src={props.logo} alt=""/>
              </div>
              <div className="col-auto ml-auto">
                <button
                  className="btn text-ui-blue font-weight-normal icon icon-printer"
                  title="Print"
                  onClick={printPreview}
                />
              </div>
            </div>
            {renderPayments()}
          </div>
        </FrindowModal>
      </div>
    </>
  )
}
export default PaymentsLink
