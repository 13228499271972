import React from 'react'

const BreadCrumbs = ({ tree, index = null, current = null, title = null, edit_permitted = null, root_url = null }) => {
  const breadcrumbs = tree.map((b, index) => (
    <li key={`key_${index}`} className='breadcrumb-item'>
      <a href={b.path}>{b.name}</a>
    </li>
  ))

  const indexNode = index ? <li className='breadcrumb-item'><a href={index.path}>{index.name}</a></li> : null

  const currentNode = current ?
    <li className='breadcrumb-item active'>
      <span className='extra-btn'>
        {current.name}
        { edit_permitted &&
          <span className='extra'>
            <a href={current.edit_path} className='btn btn-primary btn-small btn-update'>Edit</a>
          </span>
        }
      </span>
    </li>
  : null

  const titleNode = title ? <li className='breadcrumb-item active'>{title}</li> : null

  return(
    <div className='main-breadcrumbs admin'>
      <div className='container'>
        <div className='row'>
          <div className='col-12 main-breadcrumbs-content'>
            <a onClick={() => window.history.back()} className='btn btn-secondary btn-back go-back'>Back</a>
            <nav aria-label={'breadcrumb'}>
              <ol className='breadcrumb'>
                <li className='breadcrumb-item'>
                  <a href={root_url}>Admin panel</a>
                </li>
                {indexNode}
                {breadcrumbs}
                {currentNode}
                {titleNode}
              </ol>
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BreadCrumbs
