import React from 'react'
import CancelModal from './cancel_modal'
import Calendar from 'calender.svg'
import Alert from 'alert.svg'

class WaitingResponse extends React.PureComponent {

  render = () => {
    const { handleStep, videoRoomUrl, updateState, videoRoom } = this.props
    return(
      <div className='row live-chat col-lg-10 offset-lg-1'>
        <div className='col-md-12 col-lg-6 live-chat-main white text-center'>
          <h2>Waiting for a response</h2>
          <p>You can reschedule live chat</p>
          <p className='live-chat-shelude'>{videoRoom.formatted_date}</p>
          <div className='row justify-content-center mt-3 mb-4'>
            <div className='col-auto'>
              <a onClick={handleStep} data-step='1' className='text-black'>
                <div className='block-icon calendar-icon'>
                  <img src={Calendar} alt='Calendar'></img>
                </div>
                Re-schedule
              </a>
            </div>
          </div>
          <CancelModal
            videoRoomUrl={videoRoomUrl}
            updateState={updateState} />
        </div>
        <div className='col-md-12 col-lg-6 live-chat-info'>
          <img src={Alert} className='img-fluid' alt='Important'></img>
          <h2>A few safety tips</h2>
          <ul>
            <li>Only chat to people who you have corresponded with before (through messaging or a Get Together)</li>
            <li>Never disclose where you live</li>
            <li>Never disclose any private information (such as card or bank details)</li>
            <li>If necessary, have a chaperone with you on your first chat with somebody new.</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default WaitingResponse;
