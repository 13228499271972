import React from 'react'
import StartSubject from './start_subject'
import SubjectCard from './subject_card'
import ListSubjects from './list_subjects'

export default class SubjectContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fetchSubjectPostsUrl: props.fetchSubjectPostsUrl,
      createPostUrl: props.createPostUrl,
      subjectToAdd: { subjectUrl: '' },
      hideSubjectUrl: props.subjectUrl,
      isFollowed: props.isFollowed,
      subjectId: props.subjectId
    }
  }

  onCreatedSubject = (createPostUrl, fetchSubjectPostsUrl, subjectUrl, subjectId) => {
    this.setState({ createPostUrl: createPostUrl,
                    fetchSubjectPostsUrl: fetchSubjectPostsUrl,
                    hideSubjectUrl: subjectUrl,
                    subjectId: subjectId,
                    isFollowed: true })
  }

  addSubjectToList = (subject) => {
    this.setState({subjectToAdd: subject})
  }

  render = () => {
    return (
      <>
        {!this.props.banned && this.props.accessToForum && !this.props.isEventGroup && <StartSubject
          onCreatedSubject={this.onCreatedSubject}
          inGroupUrl={this.props.inGroupUrl}
          urlJoinGroup={this.props.urlJoinGroup}
          createSubjectUrl={this.props.createSubjectUrl}
          profile={this.props.currentProfile}
          canWrite={this.props.canWrite} />}
        {this.state.createPostUrl &&
          <SubjectCard
            isFollowed={this.state.isFollowed}
            subjectId={this.state.subjectId}
            fetchSubjectPosts={this.state.fetchSubjectPostsUrl}
            inGroupUrl={this.props.inGroupUrl}
            urlJoinGroup={this.props.urlJoinGroup}
            createPostUrl={this.state.createPostUrl}
            addSubjectToList={this.addSubjectToList}
            profile={this.props.currentProfile}
            banned={this.props.banned}
            isEventGroup={this.props.isEventGroup}
            canWrite={this.props.canWrite}/>}
        <ListSubjects
          fetchSubjectsUrl={this.props.fetchSubjectsUrl}
          subjectToAdd={this.state.subjectToAdd}
          subjectsExists={this.props.subjectsExists}
          hideSubjectUrl={this.state.hideSubjectUrl} />
      </>
    )
  }
}
