import React from 'react'

const TextField = ({ value, onChange, placeholder, errorText, classForm = 'small', type = 'text', name, maxLength, showCounter = false }) => {
  const className = (errorText ? 'form-control is-invalid' : 'form-control')

  let message = value
  if(maxLength){
    message = value.slice(0, maxLength)
  }

  return(
    <div className='form-group'>
      <div className={`form-input-cell ${classForm}`}>
        <input
          type={type}
          value={message}
          onChange={onChange}
          placeholder={placeholder}
          className={className}
          name={name}
          maxLength={maxLength}
        />
        {maxLength && showCounter && (
          <p className='text-muted text-small my-2'>Characters left: {+maxLength - message.length}</p>
        )}
        <div className='invalid-feedback'>{errorText}</div>
      </div>
    </div>
  )
}

export default TextField
