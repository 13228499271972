import React, { Component } from 'react'
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom"
import { ROUTES } from './shared/routes'
import WelcomeStep from './profile_completion/welcome_step'
import Statements from './profile_completion/statements'
import Details from './profile_completion/details'
import OpenTo from './profile_completion/open_to'
import Keywords from './profile_completion/keywords'
import SelectAvatar from './profile_completion/select_avatar'
import Images from './profile_completion/images'
import { PROFILE_TYPES } from '../utils/constants'

import _ from 'lodash'

class ProfileCompletion extends Component {
  constructor(props){
    super(props)
    this.state = {
      directProfile: _.find(props.profiles, ['type', PROFILE_TYPES.direct ]),
      proxyProfile:  _.find(props.profiles, ['type', PROFILE_TYPES.proxy ]),
      clientProfile:  _.find(props.profiles, ['type', PROFILE_TYPES.client ]),
      user: props.user,
    }
  }

  updateProfile = (profile) => {
    if(profile.type === PROFILE_TYPES.direct ){
      this.setState({ directProfile: profile })
    }
    if(profile.type === PROFILE_TYPES.proxy ){
      this.setState({ proxyProfile: profile })
    }

    if(profile.type === PROFILE_TYPES.client ){
      this.setState({ clientProfile: profile })
    }
  }

  updateUser = (user) => this.setState({ user })

  render = () => {
    const { props, state, updateProfile, updateUser } = this
    const { directProfile, proxyProfile, clientProfile } = state

    let mainProfile = null
    if(directProfile){
      mainProfile = directProfile
     }

     if(proxyProfile){
      mainProfile = proxyProfile
     }

     if(clientProfile){
      mainProfile = clientProfile
     }

     return(
      <Router>
        <Route path={ROUTES.welcome.path}           render={(routeProps) => <WelcomeStep {...props} {...routeProps}  profile={mainProfile}/>} />
        <Route path={ROUTES.direct_statements.path} render={(routeProps) => <Statements {...props} {...routeProps}   updateProfile={updateProfile} profile={directProfile} />} />
        <Route path={ROUTES.details.path}           render={(routeProps) => <Details {...props} {...routeProps}      updateProfile={updateProfile} profile={mainProfile} />} />
        <Route path={ROUTES.open_to.path}           render={(routeProps) => <OpenTo {...props} {...routeProps}       profile={mainProfile} />} />
        <Route path={ROUTES.keywords.path}          render={(routeProps) => <Keywords {...props} {...routeProps}     updateProfile={updateProfile} profile={mainProfile} />} />
        <Route path={ROUTES.avatars.path}           render={(routeProps) => <SelectAvatar {...props} {...routeProps} updateUser={updateUser} profile={mainProfile}/>} />
        <Route path={ROUTES.direct_images.path}     render={(routeProps) => <Images {...props} {...routeProps}       profile={directProfile}/>} />
        <Route path={ROUTES.proxy_statements.path}  render={(routeProps) => <Statements {...props} {...routeProps}   updateProfile={updateProfile} profile={proxyProfile} />} />
        <Route path={ROUTES.proxy_images.path}      render={(routeProps) => <Images step={5} {...props} {...routeProps}       profile={proxyProfile}/>} />
      </Router>
    )
  }
}

export default ProfileCompletion
