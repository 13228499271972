import React from 'react'
import CreateGroupForm from './group_form/create_group_form'
import Notification from '../notifications/notification'
import { makePostRequest } from '../shared/api.js'
import i18n from '../../utils/i18n'

class GroupFormContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      name: '',
      description: '',
      errors: '',
      messages: ''
    }
  }

  handleClickOpen = (e) => {
    e.stopPropagation()
    e.preventDefault()
    document.body.classList.add('unpin')
    this.setState({
      open: true,
      errors: '',
      messages: ''
    })
  }

  handleClickClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    document.body.classList.remove('unpin')
    this.setState({open: false})
  }

  handleClickSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { name, description } = this.state
    const { url, parent } = this.props
    makePostRequest({ url: url, body: { group: { name: name, description: description, parent: parent } } })
       .then((response) => {
         if (response.data.errors == null) {
           document.body.classList.remove('unpin')
           this.setState({
             open: false,
             name: '',
             description: '',
             errors: '',
             messages: response.data.messages
           })
         }
         else {
           this.setState({
             errors: response.data.errors
           })
         }
    })
  }

  handleNameChange = (e) => { this.setState({ name: e.target.value }) }

  handleDescriptionChange = (e) => { this.setState({ description: e.target.value }) }

  renderGroupForm = () => {
    if (!this.state.open) { return null; }
    const { name, description, errors } = this.state
    const { handleNameChange, handleDescriptionChange, handleClickSubmit, handleClickClose } = this
    return (
      <CreateGroupForm
        name={name}
        handleNameChange={handleNameChange}
        description={description}
        handleDescriptionChange={handleDescriptionChange}
        handleClickSubmit={handleClickSubmit}
        handleClickClose={handleClickClose}
        errors={errors}
      />
    )
  }

  renderNotifications = () => {
    const { messages } = this.state
    if (messages.length < 1) { return null; }
    return <Notification messages={messages} />
  }

  render = () => {
    return(
      <React.Fragment>
      {this.renderGroupForm()}
      {this.props.permitted &&
        <button
          type='button'
          className='btn btn-primary btn-big btn-add'
          title={i18n.t('groups.addSubgroup')}
          onClick={this.handleClickOpen}
        >
          {i18n.t('groups.addSubgroup')}
        </button>
      }
      {this.renderNotifications()}
      </React.Fragment>
    )
  }
}

export default GroupFormContainer;
