import React from 'react'
import FrindowModal from '../shared/frindow_modal'

const BeforeScheduledModal = (props) => {
  const { closeModal, isOpen, date } = props
  return(
    <FrindowModal
      isOpen={isOpen}
      closeModal={closeModal}
      title='Video chat is in future'
      customClass='border-danger'
      customTitleClass='text-ruby mr-3'
    >
      <div className='col-12 form text-left'>
        <div className='form-group'>
          <div className='form-input-cell'>
            <h2>Chat is in future</h2>
            <h3>{date}</h3>
          </div>
        </div>
      </div>
    </FrindowModal>
  )
}

export default BeforeScheduledModal
