import React from 'react'
import SimpleSelectField from '../shared/simple_select_field'
import { CONFIRM_STATUS } from '../../utils/constants'

const ApprovalForm = (props) => {
  const { object_type, handleConfirmChange, status, errors, description, handleDescriptionChange, handleClickSubmit, handleClickClose } = props
  const validClassMessage = errors.message ? 'form-control is-invalid' : 'form-control'
  return(
    <div className='r-modal show'>
      <div className='r-modal-content'>
        <div className='r-modal-header'>
          <h4 className='h1 modal-title'>Confirm {object_type}</h4>
          <button type='button' aria-label='Close' className='btn btn-close' onClick={handleClickClose}></button>
        </div>
        <div className='r-modal-body'>
          <div className='row'>
            <div className='col-12 form text-left'>
              <div className='form-group'>
                  <label>Choose status</label>
                  <div className='form-input-cell select-cell small'>
                    <SimpleSelectField
                      collection={CONFIRM_STATUS}
                      value={status}
                      onChange={handleConfirmChange}
                      emptyKey='Validate'
                      errorText={errors.status}
                    />
                  </div>
              </div>
              <div className='form-group'>
                <label>Custom message to user(optional when approved)</label>
                <div className='form-input-cell'>
                  <textarea
                    className={validClassMessage}
                    placeholder='Message'
                    value={description}
                    onChange={handleDescriptionChange}
                    >
                  </textarea>
                  <div className='invalid-feedback'>{errors.message}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='r-modal-footer'>
          <button type='button' className='btn btn-big btn-primary btn-add' onClick={handleClickSubmit}>Confirm</button>
        </div>
      </div>
    </div>
  )
}

export default ApprovalForm;
