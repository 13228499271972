import React from 'react'
import moment from 'moment'
import TimePicker from 'rc-time-picker'
import { dates, genYears } from '../../utils/dates'
import SimpleSelectField from '../shared/simple_select_field'
import { makePostRequest, makePutRequest } from '../shared/api.js'
import Alert from 'alert.svg'

class VideoDate extends React.PureComponent {
  constructor(props) {
    super(props)

    const time = this.roomTime()
    this.state = {
      day: time.format('DD'),
      month: time.format('MM'),
      year: time.format('YYYY'),
      dayTime: time,
      errors: []
    }
    this.timeFormat = 'h:mm a'
  }

  roomTime = () => {
    if(this.props.videoRoom != null) { return moment(this.props.videoRoom.start_date) }
    return moment()
  }

  onClickRequest = (e) => {
    e.preventDefault()
    const { current_user, profile_user, updateState, handleNextStep, videoRoomUrl, videoRoom } = this.props
    const date = this.validateDate()
    if(date != null) {
      let action = ''
      let room_url = ''
      if(videoRoom){
        action = makePutRequest
        room_url = `${videoRoomUrl}\\${videoRoom.id}`
      } else {
        action = makePostRequest
        room_url = videoRoomUrl
      }
      action({ url: room_url, body: { video_room: {
                                                   start_date: date,
                                                   creator_id: current_user.id,
                                                   receiver_id: profile_user.id,
                                                   status: 'pending'
                                                  } } })
      .then((response) => {
        this.setState({errors: []})
        updateState({videoRoom: response.data.video_room})
        handleNextStep()
      }, (response) => {
        if(response.errors.redirect === true) { window.location.href = videoRoomUrl }
        else {
          this.setState({ errors: Object.entries(response.errors).map(([k,v]) => `${k} ${v}`) })
        }
      })
    }
  }

  validateDate = () => {
    const { day, month, year, dayTime } = this.state
    const convertTime = moment(`${year}-${month}-${day}-${dayTime.hour()}-${dayTime.minute()}-${dayTime.format('A')}`, 'Y-M-D-h-m-A')
    if(convertTime.isValid()) {
      if(moment().diff(convertTime, 'seconds') < 300) {
        this.setState({errors: []})
        return convertTime
      }
      this.setState({errors: ['The date and time must be in the future.']})
      return null
    }
    this.setState({errors: ['Date is invalid']})
    return null
  }

  onTimeChange = (value) => {
    this.setState({dayTime: value})
  }

  handleDateChange = (e) => {
    const { value, name } = e.target
    this.setState({ [name]: value })
  }

  renderYearSelect = () => {
    const { handleDateChange } = this
    const { year } = this.state
    const currentYear = (new Date).getUTCFullYear()
    return (
      <div className='form-input-cell select-cell small'>
        <SimpleSelectField
          key='key_month'
          collection={genYears(false, currentYear, currentYear + 1)}
          value={year}
          onChange={handleDateChange}
          emptyKey='Year'
          errorPrompt={false}
          name='year'
        />
      </div>
    )
  }

  render = () => {
    const { onTimeChange, timeFormat, handleDateChange, onClickRequest } = this
    const { day, month, dayTime, errors } = this.state
    return(
      <div className='row'>
        <div className='row live-chat col-lg-10 offset-lg-1'>
          <div className='col live-chat-main white'>
            <h2>When would you like to chat?</h2>
            <form>
              {errors.length > 0 &&
                <div className="error">
                  {errors[0]}
                </div>
              }
              <div className='row'>
                <div className='col-4 form-group'>
                  <div className='form-input-cell select-cell small'>
                    <SimpleSelectField
                      collection={dates.days}
                      value={day}
                      onChange={handleDateChange}
                      emptyKey='Day'
                      errorPrompt={false}
                      name='day'
                    />
                  </div>
                </div>
                <div className='col-8 form-group'>
                  <div className='form-input-cell select-cell small'>
                    <SimpleSelectField
                      key='key_month'
                      collection={dates.months}
                      value={month}
                      onChange={handleDateChange}
                      emptyKey='Month'
                      errorPrompt={false}
                      name='month'
                    />
                  </div>
                </div>
                <div className='col-6 form-group'>
                  {this.renderYearSelect()}
                </div>
                <div className='col-6 form-group'>
                  <div className='form-input-cell select-cell small'>
                    <TimePicker
                     minuteStep={15}
                     showSecond={false}
                     defaultValue={dayTime}
                     className='form-control time-picker'
                     onChange={onTimeChange}
                     format={timeFormat}
                     use12Hours
                     inputReadOnly
                    />
                  </div>
                </div>
              </div>
              <div className='text-center'>
                <button onClick={onClickRequest} className='btn btn-primary'>Request Chat</button>
              </div>
            </form>
          </div>
          <div className='col live-chat-info'>
            <img src={Alert} className='img-fluid' alt='Important'></img>
            <h2>A few safety tips</h2>
            <ul>
              <li>Only chat to people who you have corresponded with before (through messaging or a Get Together)</li>
              <li>Never disclose where you live</li>
              <li>Never disclose any private information (such as card or bank details)</li>
              <li>If necessary, have a chaperone with you on your first chat with somebody new.</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default VideoDate;
