import React, { useState } from 'react'
import _ from 'lodash'

const SearchKeyword = (props) => {
  const { handleSearchChange, handleSearchClick, searchValue, proposedKeywords } = props
  const [focus, setFocus] = useState(false)

  function renderProposed() {
    if (proposedKeywords.length < 1 || !focus) { return null }
    return (
      <div className='search-suggestions'>
        {proposedKeywords.map((keyword) => <div key={keyword} name={keyword} onClick={handleSearchClick}><a href='#'>{keyword}</a></div>)}
      </div>
    )
  }
  
  function onFocus() {
    setFocus(true)
  }

  function onBlur() {
    setFocus(false)
  }

  return(
    <div className='col col-md-auto flex-shrink-1 pr-0' onFocus={onFocus} onBlur={_.debounce(onBlur, 200)}>
      <div className='form-group'>
        <div className='form-input-cell search-cell'>
          <button className='search-submit symbol icon-magnifier'></button>
          <input className='form-control' placeholder='Keyword search' type='text' onChange={handleSearchChange} value={searchValue}/>
          {renderProposed()}
        </div>
      </div>
    </div>
  )
}

export default SearchKeyword
