import React from 'react'
import ConfirmInfo from './confirm_info'
import BeforeJoinGroup from '../groups/shared/before_join_group'
import { makeGetRequest } from '../shared/api.js'

class OrganiseInfo extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      inGroup: false
    }
  }

  renderButton = () => {
    const { permitted, user_confirmed, confirmation_url } = this.props
    if(user_confirmed && !permitted)  { return null; }

    if(user_confirmed) {
      return(
        <a onClick={this.userInGroup} className='btn btn-primary btn-big btn-add'>
          Organise a get together
        </a>
      )
    } else {
      return(
        <ConfirmInfo confirmation_url={confirmation_url}/>
      )
    }
  }

  userInGroup = () => {
    const { in_group_url, createUrl } = this.props
    makeGetRequest({ url: `${in_group_url}` })
         .then((response) => {
           if(response.data.in_group) { return window.location.href = createUrl }

           this.setState({ inGroup: response.data.in_group, openModal: true });
         });
  }

  handleClickClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    document.body.classList.remove('unpin')
    this.setState({openModal: false})
  }

  afterJoin = () => {
    const { createUrl } = this.props
    this.setState({ inGroup: true, openModal: false })
    if (typeof ga !== 'undefined') { ga('send', 'event', 'Group', 'user joined') }
    return window.location.href = createUrl
  }

  render = () => {
    const { renderButton, afterJoin, handleClickClose } = this
    const { url_join_group } = this.props
    const { openModal } = this.state
    return(
      <div className='get-together-intro-container col-12 col-lg-8 order-lg-2'>
        <div className='get-together-intro bg-white shadow h-100'>
          <p className='small-label'>Get togethers</p>
          <h1 className='get-together-title'>Get Togethers for you, by you</h1>
          <div className='get-together-desc text-black'>
            <p>If you fancy meeting up with like-minded people, why not go to or arrange a Get Together? A word about safety - always meet in a public place. Never arrange a Get Together in your home or go to somebody else’s home.</p>
            <p className="text-danger">All Get Togethers must follow the current local or national COVID rules relating to meeting in person.</p>
          </div>
          {renderButton()}
          {openModal &&
            <BeforeJoinGroup
              join={url_join_group}
              handleClickClose={handleClickClose}
              joined={false}
              afterJoin={afterJoin}
              infoText='You need to be a member of this group to create a new get together.' />
          }
        </div>
      </div>
    )
  }
}

export default OrganiseInfo;
