import React from 'react'
import FilterBy from './filter_by'
import SearchProfile from './search_profile'
import { ALL_GENDERS } from '../../utils/constants'
import { makeGetRequest } from '../shared/api.js'
import { APP_ROUTES } from '../shared/routes'
import SearchKeyword from './search_keyword'
import Tags from './tags'
import UsernameModal from './username_modal'
import KeywordTags from './keyword_tags'
import _ from 'lodash'
import FrindowSpinner from '../shared/frindow_spinner'
import { lozadObserver } from '../../utils/lozad_observer'
import script_google_ads from '../../utils/script_google_ads'
import FrindowNotification from '../shared/frindow_notification'

class SearchContainer extends React.Component {
  constructor(props) {
    super(props)
    let loading
    let selectedGroupIds = localStorage['searchGroupSelectedIds']
    try {
      if (selectedGroupIds) {
        selectedGroupIds = JSON.parse(selectedGroupIds)
        const rootIds = props.siteGroups.concat(props.userGroups).map(a => a[1])
        selectedGroupIds = selectedGroupIds.filter(id => rootIds.includes(id))
      }
    } catch (err) { localStorage.removeItem('searchGroupSelectedIds') }
    selectedGroupIds = selectedGroupIds || []
    const cachedUsers = []
    cachedUsers ? loading = false : loading = true
    let cachedFilters = this.cachedFilters()
    const filters = {}
    if (cachedFilters) { cachedFilters.forEach(a => filters[a.name] = a.value) }
    else { cachedFilters = [] }
    const cachedAge = this.cachedAge()

    this.state = {
      errors: [],
      openFilterBy: false,
      male: filters.male || false,
      female: filters.female || false,
      other: filters.other || false,
      online: filters.online || false,
      confirmed: filters.confirmed || false,
      proxy_users: filters.proxy_users || false,
      in_groups: false,
      photos_only: filters.photos_only || false,
      maleTemp: filters.male || false,
      femaleTemp: filters.female || false,
      otherTemp: filters.other || false,
      onlineTemp: filters.online || false,
      confirmedTemp: filters.confirmed || false,
      proxy_usersTemp: filters.proxy_users || false,
      photos_onlyTemp: filters.photos_only || false,
      minAge: cachedAge.minAge,
      maxAge: cachedAge.maxAge,
      users: cachedUsers || [],
      searchValue: '',
      proposedKeywords: [],
      selectedKeywords: this.cachedKeywords() || [],
      totalCount: 0,
      page: this.cachedPage() || 1,
      tagFilters: this.tagFilters(cachedFilters, selectedGroupIds),
      isLastPage: false,
      loading: loading,
      selectedGroupIds: selectedGroupIds,
      selectedGroupIdsTemp: selectedGroupIds
    }
  }

  componentDidMount() {
    if (this.state.users.length < 1) { this.fetchProfiles() }
    if (this.props.advert && this.props.advert.includes('adsbygoogle.js')) {
      script_google_ads()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.selectedKeywords.length !== prevState.selectedKeywords.length) { this.searchProfiles() }
    if (this.state.isLastPage) { window.removeEventListener('scroll', this.handleScroll) }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  cachedUsers() {
    let cachedUsers = localStorage['searchUsersResult']
    try {
      if (cachedUsers) {
        cachedUsers = JSON.parse(cachedUsers)
        window.addEventListener('scroll', this.handleScroll)
      }
    } catch (err) { localStorage.removeItem('searchUsersResult') }
    return cachedUsers
  }

  cachedFilters() {
    let cachedFilters = localStorage['searchFilters']
    try {
      if (cachedFilters) {
        cachedFilters = JSON.parse(cachedFilters)
      }
    } catch (err) { localStorage.removeItem('searchFilters') }
    return cachedFilters
  }

  labelValue(val) {
    return { label: val, value: val }
  }

  cachedAge() {
    let cachedAge = localStorage['searchAge']
    try {
      if (cachedAge) {
        cachedAge = JSON.parse(cachedAge)
      } else {
        let min_age = this.props.usersAge - 10
        if(min_age < 18) { 
          min_age = 18 
        } 
        cachedAge = { 
          minAge: this.labelValue(min_age), 
          maxAge: this.labelValue(this.props.usersAge + 10)
        }
      }
    } catch (err) { localStorage.removeItem('searchAge') }
    return cachedAge
  }

  cachedPage() {
    let cachedPage = localStorage['searchUsersPage']
    try {
      if (cachedPage) { cachedPage = JSON.parse(cachedPage) }
    } catch (err) { localStorage.removeItem('searchUsersPage') }
    return cachedPage
  }

  cachedKeywords() {
    let cachedKeywords = localStorage['searchKeywords']
    try {
      if (cachedKeywords) { cachedKeywords = JSON.parse(cachedKeywords) }
    } catch (err) { localStorage.removeItem('searchKeywords') }
    return cachedKeywords
  }

  handleScroll = () => {
    const { page, loading } = this.state
    if (!loading && document.getElementsByTagName('footer')[0].clientHeight + window.innerHeight > this.calculateDocument()) { this.fetchProfiles(page + 1) }
  }

  searchProfiles = _.debounce(() => {
    this.fetchProfiles()
  }, 200);

  calculateDocument = () => {
    if (document.documentElement) {
      return (
        document.documentElement.offsetHeight
        - document.documentElement.scrollTop
      )
    }
    return 0
  }

  fetchProfiles = (page = 1) => {
    this.setState({ loading: true })
    const { get_users_url } = this.props
    const { selectedKeywords, confirmed, proxy_users, selectedGroupIds, other, male, female, online, photos_only, minAge, maxAge } = this.state
    let gender_ids = []

    if (male) { gender_ids.push(ALL_GENDERS.find(a => a.label === 'Male').value) }
    if (female) { gender_ids.push(ALL_GENDERS.find(a => a.label === 'Female').value) }
    if (other) { gender_ids = gender_ids.concat(ALL_GENDERS.filter(a => (a.label !== 'Male' && a.label !== 'Female')).map(a => a.value)) }

    let filters = `&proxy_users=${proxy_users}&photos_only=${photos_only}&selected_group_ids=${selectedGroupIds}&confirmed=${confirmed}&genders=${gender_ids}&online=${online}&keyword_search=${selectedKeywords}&min_age=${minAge.value}&max_age=${maxAge.value}`

    makeGetRequest({ url: `${get_users_url}?page=${page}${filters}` })
      .then((response) => {
        this.setState({ loading: false })
        if (response.data.errors == null) {
          this.handleCloseFilterBy()
          let fetchedUsers = response.data.profiles
          if (page > 1) { fetchedUsers = this.state.users.concat(fetchedUsers) }
          else { window.addEventListener('scroll', this.handleScroll) }
          const filters = [{ name: 'photos_only', value: photos_only }, { name: 'online', value: online }, { name: 'male', value: male }, { name: 'female', value: female }, { name: 'other', value: other }, { name: 'proxy_users', value: proxy_users }, { name: 'confirmed', value: confirmed }]
          this.setState({
            errors: [],
            users: fetchedUsers,
            page: page,
            isLastPage: response.data.last_page,
            tagFilters: this.tagFilters(filters, selectedGroupIds)
          })
          localStorage['searchKeywords'] = JSON.stringify(selectedKeywords)
          localStorage['searchFilters'] = JSON.stringify(filters)
          localStorage['searchGroupSelectedIds'] = JSON.stringify(selectedGroupIds)
          localStorage['searchUsersResult'] = JSON.stringify(fetchedUsers)
          localStorage['searchUsersPage'] = JSON.stringify(page)
          localStorage['searchAge'] = JSON.stringify({ minAge: minAge, maxAge: maxAge })
          lozadObserver()
        }
        else { this.setState({ errors: response.data.errors }) }
      });
  }

  handleSelectGroup = (e) => {
    const { selectedGroupIdsTemp } = this.state
    const id = e.currentTarget.getAttribute('group-id')
    const temporaryIds = selectedGroupIdsTemp.indexOf(id) === -1 ? selectedGroupIdsTemp.concat([id]) : selectedGroupIdsTemp.filter(a => a !== id)
    this.setState({ selectedGroupIdsTemp: temporaryIds })
  }

  handleAgeFilterChange = (e, kind) => {
    if (kind == 'minAge') {
      this.setState({ minAge: this.labelValue(e.value) })
    } else {
      this.setState({ maxAge: this.labelValue(e.value) })
    }
  }

  handleClickApplyFilters = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState((state) => {
      return ({
        selectedGroupIds: state.selectedGroupIdsTemp,
        male: state.maleTemp,
        female: state.femaleTemp,
        other: state.otherTemp,
        online: state.onlineTemp,
        confirmed: state.confirmedTemp,
        proxy_users: state.proxy_usersTemp,
        photos_only: state.photos_onlyTemp
      })
    }, this.fetchProfiles)
  }

  handleOpenFilterBy = () => this.setState({ openFilterBy: true })

  handleCloseFilterBy = () => this.setState({ openFilterBy: false })

  handleCheckboxChange = (e) => {
    const name = e.currentTarget.getAttribute('name')
    this.setState({ [name]: !this.state[name] })
  }

  handleSearchChange = (e) => {
    const { allKeywords } = this.props
    const value = e.target.value
    const valueUppercase = value.toUpperCase()
    const proposedKeywords = allKeywords.filter(a => a.startsWith(valueUppercase))
    this.setState({ searchValue: value, proposedKeywords: proposedKeywords })
  }

  handleSearchClick = (e) => {
    const { selectedKeywords } = this.state
    const keyword = e.currentTarget.getAttribute('name')
    const temporaryKeywords = selectedKeywords.indexOf(keyword) === -1 ? selectedKeywords.concat(keyword) : selectedKeywords
    this.setState({ searchValue: '', proposedKeywords: [], selectedKeywords: temporaryKeywords })
  }

  handleRemoveKeyword = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const keyword = e.currentTarget.getAttribute('name')
    this.setState(state => ({ selectedKeywords: state.selectedKeywords.filter(a => a !== keyword) }))
  }

  handleRemoveFilter = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const name = e.currentTarget.getAttribute('name')
    const group = e.currentTarget.getAttribute('date-group') === 'true'
    if (group) {
      const { userGroups, siteGroups } = this.props
      const id = userGroups.concat(siteGroups).find(a => a[0] === name)[1]
      this.setState(state => {
        const ids = state.selectedGroupIds.filter(a => a !== id)
        return ({ selectedGroupIds: ids, selectedGroupIdsTemp: ids })
      }, this.searchProfiles)
    } else { this.setState({ [name]: false, [`${name}Temp`]: false }, this.searchProfiles) }
  }

  renderNoResults = () => (
    <div className='tile-container live-chat-info'>
      <div className='info-icon' />
      <h2>No results for the filters that you have selected.</h2>
      <p>Please make your search criteria less strict to see results.</p>
      <button
        className='btn btn-white with-border'
        onClick={this.handleOpenFilterBy}
      >EDIT FILTERS</button>
    </div>
  );

  renderUsersList = () => {
    const { advert } = this.props
    const { users } = this.state
    const advertSlot = (
      <div className='tile-container search-advert'>
        <div dangerouslySetInnerHTML={{ __html: advert }} style={{ height: '250px', width: '300px', margin: '0 auto' }} />
      </div>
    )
    if (users.length === 0) return this.renderNoResults();
    return users.map((user, index) => {
      const showAdvertSlot = advert && index !== 0 && index % 20 === 0 && index < users.length - 1 && window.innerWidth <= 767
      return (
        <React.Fragment key={index} >
          {showAdvertSlot && advertSlot}
          <SearchProfile key={user.id} {...user} />
        </React.Fragment>
      )
    })
  }

  tagFilters = (filters, selectedGroupIds) => {
    const { siteGroups, userGroups } = this.props
    let tagFilters = filters.filter(a => a.value === true).map(a => ({ name: a.name, group: false }))
    tagFilters = tagFilters.concat(siteGroups.concat(userGroups).filter(a => selectedGroupIds.includes(a[1])).map(a => ({ name: a[0], group: 'true' })))

    return tagFilters
  }

  handleClickSubmitSearch = (username) => {
    this.setState({ loading: true, openFilterUsername: false })
    const page = 1
    makeGetRequest({ url: `${APP_ROUTES.search.by_username}?page=${page}&username=${username}` })
      .then((response) => {
        this.setState({ loading: false })
        if (response.data.errors == null) {
          let fetchedUsers = response.data.profiles
          this.setState({
            errors: [],
            users: fetchedUsers,
            page: page,
            isLastPage: true,
          })
          lozadObserver()
        }
        else { this.setState({ errors: response.data.errors }) }
      });
  }

  renderUsernameModal = () => {
    const { openFilterUsername, username } = this.state
    if (!openFilterUsername) return null

    const { handleClickSubmitSearch } = this

    return (
      <UsernameModal
        handleCloseUsername={() => this.setState({ openFilterUsername: false })}
        handleUsernameChange={(e) => this.setState({ username: e.target.value })}
        handleClickSubmitSearch={handleClickSubmitSearch}
        username={username} />
    )
  }

  render = () => {
    const { loading, tagFilters, proposedKeywords, selectedKeywords, searchValue, openFilterBy, confirmedTemp, proxy_usersTemp, maleTemp, femaleTemp, onlineTemp, photos_onlyTemp, selectedGroupIdsTemp, otherTemp, minAge, maxAge } = this.state
    const { siteGroups, userGroups } = this.props
    const { handleAgeFilterChange, handleSearchChange, renderUsernameModal, handleRemoveKeyword, handleRemoveFilter, handleSelectGroup, handleSearchClick, handleClickApplyFilters, handleGenderChange, handleOpenFilterBy, handleCloseFilterBy, handleCheckboxChange, renderUsersList } = this
    const filters = [{ name: 'photos_onlyTemp', value: photos_onlyTemp }, { name: 'onlineTemp', value: onlineTemp }, { name: 'maleTemp', value: maleTemp }, { name: 'femaleTemp', value: femaleTemp }, { name: 'otherTemp', value: otherTemp }, { name: 'proxy_usersTemp', value: proxy_usersTemp }, { name: 'confirmedTemp', value: confirmedTemp }]

    return (
      <React.Fragment>
        <div className='search-horizontal-bar bg-white shadow pt-2 pb-2 mb-2'>
          <div className='container on-light'>
            <div className='row'>
              <SearchKeyword
                proposedKeywords={proposedKeywords}
                handleSearchClick={handleSearchClick}
                handleSearchChange={handleSearchChange}
                searchValue={searchValue} />
              <div className='col-auto ml-md-auto text-right'>
                <button className='btn btn-primary btn-big btn-filter filers-name' name='openFilterBy' onClick={handleOpenFilterBy}></button>
              </div>
              <FilterBy
                filters={filters}
                minAge={minAge}
                maxAge={maxAge}
                handleCloseFilterBy={handleCloseFilterBy}
                handleCheckboxChange={handleCheckboxChange}
                handleAgeFilterChange={handleAgeFilterChange}
                handleGenderChange={handleGenderChange}
                siteGroups={siteGroups}
                userGroups={userGroups}
                selectedGroupIds={selectedGroupIdsTemp}
                handleSelectGroup={handleSelectGroup}
                handleClickApplyFilters={handleClickApplyFilters}
                is_open={openFilterBy} />
            </div>
          </div>
        </div>
        <div className='container on-light'>
          {tagFilters.length > 0 &&
            <Tags tagFilters={tagFilters} handleRemoveFilter={handleRemoveFilter} />
          }
          {selectedKeywords.length > 0 &&
            <KeywordTags keywords={selectedKeywords} handleRemoveKeyword={handleRemoveKeyword} />
          }
          <button className='btn btn-link' name='openFilterUsername' onClick={() => this.setState({ openFilterUsername: true })}>Search by username</button>
          {renderUsernameModal()}
          <div className='pictorial-user-list mt-2 pb-4'>
            {renderUsersList()}
            {loading && <FrindowSpinner />}
          </div>
        </div>
        <FrindowNotification errors={this.state.errors} messages={null} clearNotices={() => this.setState({ errors: []})} />
      </React.Fragment>
    )
  }
}

export default SearchContainer;
