import React from 'react'
import { getPhotoUrl } from '../profile/profile_button'
import { PROFILE_TYPES } from '../../utils/constants'
import ConversationImage from '../shared/profile/conversation_image'
import ListMessageContent from './list_message_content'
import i18n from '../../utils/i18n'

const ConversationsListItem = (props) => {
  const { handleClickOpenModal } = props
  const { updated, id, system_info } = props.conversation
  const { from_you, read } = props.conversation.message
  const { avatar, photo_url, profile_path, type, deleted } = props.conversation.profile

  const linkclassName = type === PROFILE_TYPES.direct ? 'circle-avatar size-md' : 'circle-avatar size-md proxy-marker'
  const url = getPhotoUrl(photo_url, avatar)
  let status = null;

  if (!from_you && !read) {
    status = (<strong className='new-message'>NEW</strong>);
  }
  if(from_you) {
    status = (<span className='message-status text-small'>{read? i18n.t('conversations.read') : i18n.t('conversations.unread')}</span>)
  }

  return(
    <div className='col-12'>
      <div className='conversation bg-white shadow'>
        <div className='text-right'>{status}</div>
        <div className='row'>
          <div className='col-auto child-middle'>
            <ConversationImage
              profile_path={profile_path}
              linkclassName={linkclassName}
              url={url}
              deleted={deleted} />
          </div>
          <ListMessageContent conversation={props.conversation} />
          <div className='col-12 conversation-footer'>
            <span className='message-date'>{updated}</span>
            {!system_info &&
              <button
                className='btn btn-outline pr-0 ml-2 remove-conversation'
                onClick={handleClickOpenModal}
                data-conversationid={id}><i className='icon-trash-o' />
              </button>
            }
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConversationsListItem
