import React from 'react';
import TextField from '../shared/text_field'
import Container from './container'
import i18n from '../../utils/i18n'

const NameFields = ({ firstName, handleFirstNameChange, handleNextClick, firstNameErrorText, emailErrorText,
                      username, handleUserNameChange, usernameErrorText, email, handleEmailChange, step,
                      code, handleCodeChange, codeErrorText }) => {

  const fields = [<TextField
                   key='key_username'
                   value={username}
                   onChange={handleUserNameChange}
                   placeholder={i18n.t('signUp.username')}
                   errorText={usernameErrorText}
                 />,
                 <TextField
                   key='key_first_name'
                   value={firstName}
                   onChange={handleFirstNameChange}
                   placeholder={i18n.t('signUp.firstname')}
                   errorText={firstNameErrorText}
                 />,
                 <TextField
                   key='key_email'
                   value={email}
                   onChange={handleEmailChange}
                   placeholder={i18n.t('signUp.email')}
                   errorText={emailErrorText}
                 />,
                 (<div key='key_code' className="row mx-0 business-field align-items-center flex-nowrap">
                    <TextField
                      value={code}
                      onChange={handleCodeChange}
                      placeholder={i18n.t('signUp.marketingCode')}
                      errorText={codeErrorText}
                    />
                    <div className="tip ml-2 mb-3">
                      <span data-title-right={`Please enter a code here if you have been given one. Otherwise, leave blank.`} >
                        <i className="icon icon-i-in-circle" />
                      </span>
                    </div>
                 </div>),
                 <p key='key_info' style={{ fontSize: '13px', fontWeight: 500, maxWidth: '370px'}}>
                   For more information on how we use your personal data, please refer to our <a href="/pages/privacy">Privacy Policy​.</a>
                 </p>
                ]

  const nextDisabled = !firstName || !username || !email || firstNameErrorText || emailErrorText || usernameErrorText || codeErrorText

  return(
    <Container
      customClass='step-sign-up'
      step={step}
      handleNextClick={handleNextClick}
      nextDisabled={nextDisabled}
      // eslint-disable-next-line no-irregular-whitespace
      headerText={`“Friendship is born at that moment when one person says to another ‘What! You too? I thought I was the only one.​’​”`}
      descText={`- C.S. Lewis`}
    >
      <h1>Sign up</h1>
      <p>Join the Frindow community today or <a href='/users/sign_in' className='text-blue-ui'>log in here</a>.</p>
      <div className='form'>
        {fields}
      </div>
    </Container>
  )
}

export default NameFields;
