import React from 'react'
import ProgressBar from '../../shared/progress_bar'
import AddressPng from 'address.png'
import AlertSvg from 'alert.svg'
import FrindowNotification from './../../shared/frindow_notification'

import { POSTCODE_REGEX } from './../../../utils/constants'

import { getAddressData } from './../../../utils/postcodes'
import { makePutRequest } from './../../shared/api'

export default class AddressDetails extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      post_code: props.address.post_code,
      errors: []
    }

    this.postCodeFields = null
  }

  componentDidMount() {
    this.postCodeFields = this.initializepostCodeFields()
  }

  initializepostCodeFields = () => {
    return ({
      'post_code': document.getElementById('post_code'), 'admin_district': document.getElementById('admin_district'),
      'admin_county': document.getElementById('admin_county'), 'region': document.getElementById('region'),
      'longitude': document.getElementById('longitude'), 'latitude': document.getElementById('latitude')
    })
  }

  validatePostCode = (e) => {
    e.preventDefault()

    const { postCodeFields } = this
    if(!POSTCODE_REGEX.test(postCodeFields['post_code'].value)) { return this.setState({ errors: { post_code: 'Please enter a valid postcode' } }) }

    e.persist()
    getAddressData(postCodeFields['post_code'].value).then((response) => {
      const { data } = response
      if(data.admin_district) postCodeFields['admin_district'].value = data.admin_district
      if(data.admin_county)   postCodeFields['admin_county'].value = data.admin_county
      if(data.region)         postCodeFields['region'].value = data.region
      if(data.longitude)      postCodeFields['longitude'].value = data.longitude
      if(data.latitude)       postCodeFields['latitude'].value = data.latitude

      this.handleOnSubmit(e)
    }).catch(() => {
      this.setState({ errors: { post_code: 'Please enter a valid postcode' } })
    })
  }

  handlePostcodeChange = (e) => this.setState({ post_code: e.target.value, errors: [] })

  handleOnSubmit = (e) => {
    const { shippingAddressPath, onNextStep } = this.props

    makePutRequest(
      { url: `${shippingAddressPath}.json`, body: { shipping_address: this.parseRequestParams(e.target.elements) }}
    ).then(() => {
      this.setState({errors: []})
      onNextStep()
    }).catch((response) => {
      this.setState({
        errors: response.errors
      })
    })
  }

  parseRequestParams = (elements) => {
    return(
      Array.from(elements).reduce((map, obj) => {
        if(obj.id !== '' && obj.value !== '') { map[obj.id] = obj.value }
        return map;
      }, {})
    )
  }

  render = () => {
    const { validatePostCode, handleClearNotice } = this
    const { address } = this.props
    const { errors } = this.state

    return(
      <React.Fragment>
        <div className='container confirmation-page'>
          <div className='row align-items-start mt-5 mb-5 sign-in-form'>
            <div className='col-md-8 col-lg-4 offset-md-0 offset-lg-4 mb-5'>
              <ProgressBar step={1} steps={4}></ProgressBar>
              <img src={AddressPng}></img>
              <h3>Your address details</h3>
              <div className='tip-wraper mb-2'>
                <div className='tip'>
                  <span data-title-bottom='We need this if you want to confirm. Confirmation is optional'>Why do we need this?</span>
                </div>
              </div>
              <form onSubmit={validatePostCode} className='form mb-3'>
                <div className='form-group'>
                  <div className='form-input-cell'>
                    <input id='name' className='form-control' placeholder='Name' defaultValue={address.name}></input>
                    {errors.name != null &&
                      <div className='form-message invalid-feedback'>{errors.name}</div>}
                  </div>
                </div>
                <div className='form-group'>
                  <div className='form-input-cell'>
                    <input id='address_line_1' className='form-control' placeholder='Address Line 1' defaultValue={address.address_line_1}></input>
                    {errors.address_line_1 != null &&
                      <div className='form-message invalid-feedback'>{errors.address_line_1}</div>}
                  </div>
                </div>
                <div className='form-group'>
                  <div className='form-input-cell'>
                    <input id='address_line_2' className='form-control' placeholder='Address Line 2' defaultValue={address.address_line_2}></input>
                    <div className='invalid-feedback'>Error msg!</div>
                  </div>
                </div>
                <div className='form-group'>
                  <div className='form-input-cell'>
                    <input id='town' className='form-control' placeholder='Town' defaultValue={address.town}></input>
                    {errors.town != null &&
                      <div className='form-message invalid-feedback'>{errors.town}</div>}
                  </div>
                </div>
                <div className='form-group'>
                  <div className='form-input-cell'>
                    <input id='country' className='form-control' placeholder='Country' defaultValue={address.country}></input>
                    {errors.country != null &&
                      <div className='form-message invalid-feedback'>{errors.country}</div>}
                  </div>
                </div>
                <div className='form-group'>
                  <div className='form-input-cell'>
                    <input id='post_code' className='form-control' placeholder='Postcode' defaultValue={address.post_code}></input>
                    {errors.post_code != null &&
                      <div className='form-message invalid-feedback'>{errors.post_code}</div>}
                  </div>
                </div>
                <input id='admin_district' defaultValue={address.admin_district} hidden></input>
                <input id='admin_county' defaultValue={address.admin_county} hidden></input>
                <input id='region' defaultValue={address.region} hidden></input>
                <input id='longitude' defaultValue={address.longitude} hidden></input>
                <input id='latitude' defaultValue={address.latitude} hidden></input>
                <button type='submit' className='btn btn-primary btn-next'>Next</button>
              </form>
            </div>
            <div className='col-md-4 col-lg-3'>
              <div className='info-box mt-5'>
                <img src={AlertSvg}></img>
                <h3 className='mt-2'>We will never share these details with anybody else.</h3>
                <p>
                  These details will never be shared with users, partners, affiliates or other third parties.
                </p>
                <p>
                  See our <a href='/pages/privacy'>Privacy Policy</a> for more details
                </p>
              </div>
            </div>
          </div>
        </div>
      </React.Fragment>
    )
  }
}
