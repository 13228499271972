import React from 'react'
import { getPhotoUrl } from '../profile/profile_button'
import { PROFILE_TYPES } from '../../utils/constants'

const VideoRoomsListItem = (props) => {
  const { formatted_date, updated, video_room_url: videoRoomUrl, status } = props.videoRoom
  const { avatar, online, photo_url: photoUrl, profile_path: profilePath, type, username } = props.videoRoom.profile
  const linkclassName = type === PROFILE_TYPES.direct ? 'circle-avatar size-md' : 'circle-avatar size-md proxy-marker'
  const url = getPhotoUrl(photoUrl, avatar)
  const isOnlineClass = online ? 'user-name is-online' : 'user-name'
  return(
    <div className='col-12'>
      <div className='row conversation bg-white shadow'>
        <div className='col-auto child-middle'>
          <a href={profilePath} className={linkclassName}>
            <img src={url} className='img-fluid' />
          </a>
        </div>
        <div className='col conversation-details'>
          <h2 className={isOnlineClass}>
            <a href={profilePath}>
              {username}
            </a>
          </h2>
          <p>
            <a href={videoRoomUrl}>
              {`${formatted_date} (${status})`}
            </a>
          </p>
        </div>
        <div className='col-12 conversation-footer'>
          <span className='message-date'>{updated}</span>
        </div>
      </div>
    </div>
  )
}

export default VideoRoomsListItem
