import React from 'react'
import SimpleSelectField from '../shared/simple_select_field'

const AgeRangeSelect = (props) => {
  const { ageStart, handleAgeStartChange, ageEnd, handleAgeEndChange, errors } = props
  return(
    <React.Fragment>
      <div className='form-check escape-parent'>
        <div className='align-items-baseline row'>
          <div className='col-auto form-group'>
            <div className='form-input-cell select-cell small'>
              <SimpleSelectField
                collection={Array.from({ length: (75 - 17)}, (_, i) => 18 + i)}
                value={ageStart}
                onChange={handleAgeStartChange}
                emptyKey='Min age'
                errorPrompt={false}
              />
            </div>
          </div>
          <div className='col-auto'>to</div>
          <div className='col-auto form-group'>
            <div className='form-input-cell select-cell small'>
              <SimpleSelectField
                collection={Array.from({ length: (100 - 22)}, (_, i) => 100 - i)}
                value={ageEnd}
                onChange={handleAgeEndChange}
                emptyKey='Max age'
                errorPrompt={false}
              />
            </div>
          </div>
        </div>
      </div>
      {errors.ageRange != null &&
        <div className='form-message invalid-feedback'>{errors.ageRange}</div>}
    </React.Fragment>
  )
}

export default AgeRangeSelect
