import React, { Component } from 'react'
import Area from './area'

export default class GroupAreasContainer extends Component {
  constructor(props){
    super(props)
    this.state = {
      countries: props.countries,
      areas: props.areas,
    }
  }

  addArea = (e) => {
    e.preventDefault()
    const id = this.getNewAreaIndex()
    this.setState({ areas: [...this.state.areas, { area_id: id, locations: {}, created: true}] })
  }

  getNewAreaIndex = () => {
    if(this.state.areas.length === 0) return 0
    return this.state.areas.map((a) => a.area_id).sort((a, b) => a - b).pop() + 1
  }

  renderAddAreaBtn = () => (
    <button style={{fontWeight: 'bold'}} onClick={this.addArea}>
      Add area
    </button>
  )

  renderAreas = () => {
    return(
      this.state.areas.map((area) => (
        <React.Fragment key={`key_${area.area_id}`}>
          <Area
            area_id={area.area_id}
            regions={area.locations}
            created={area.created}
            countries={this.state.countries}
            name_field={this.props.name_field}
            url={this.props.url}
          />
          <hr/>
        </React.Fragment>
      ))
    )
  }

  render = () => (
    <React.Fragment>
      {this.renderAreas()}
      {this.renderAddAreaBtn()}
    </React.Fragment>
  )
}
