import React from 'react'

const CompletionHeading = ({ headText, descriptionText, helperQuestion, helperText, optional }) => {
  const optionalText = (<>- ​<em style={{ fontSize: '0.75em', fontWeight: 400 }}>(optional)</em></>)
  return(
    <React.Fragment>
      <h2>{headText} {optional && optionalText}</h2>
      { descriptionText &&
        <p>{descriptionText}</p>
      }
      { helperText && <div className="tip-wraper mb-2">
        <div className="tip">
          <span data-title-right={helperText}>{helperQuestion}</span>
        </div> 
      </div> }
    </React.Fragment>
  )
}

export default CompletionHeading