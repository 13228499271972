import React from 'react'
import FrindowModal from '../frindow_modal'

export default class AvatarModal extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedAvatarName: null
    }
  }

  componentWillReceiveProps = (nextProps) => {
    const { selectedAvatarName } = nextProps
    this.setState({selectedAvatarName})
  }

  selectAvatar = (e) => {
    const name = e.target.name
    const current = this.state.selectedAvatarName
    const target = ( current === name ? null : name)
    this.setState({ selectedAvatarName: target })
  }

  confirmSelection = () => {
    const { selectedAvatarName } = this.state
    if(!selectedAvatarName) return
    this.props.selectAvatar(selectedAvatarName)
    this.props.closeModal()
  }

  renderImages = () => {
    const { images } = this.props
    const { selectedAvatarName } = this.state
    return(
      Object.keys(images).map((key) => (
        <button name={key} onClick={this.selectAvatar} key={`key_image_${key}`}
                className={`circle-image proxy-avatar small-picker ${selectedAvatarName == key ? 'active' : ''}`}>
          <img className='img-fluid' name={key} src={images[key]} />
        </button>
      ))
    )
  }

  render = () => {
    return(
      <FrindowModal
        title='Select your avatar'
        closeModal={this.props.closeModal}
        isOpen={this.props.isOpen}
        customClass={'wide'}
      >
        <div className="row">
          <div className="col-12 form">
            <div className="form-group pick-proxy-avatar">
              {this.renderImages()}
            </div>
          </div>
        </div>
        <div className='r-modal-footer'>
          <button onClick={this.props.closeModal} className='btn btn-big btn-secondary btn-cancel'>
            Cancel
          </button>
          <button onClick={this.confirmSelection} className='btn btn-big btn-primary btn-select'>
            Select
          </button>
        </div>
      </FrindowModal>
    )
  }
}
