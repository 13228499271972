import React from 'react'
import SimpleSelectField from '../../shared/simple_select_field'
import YearSelect from './year_select'
import TimeSelect from './time_select'
import { dates } from '../../../utils/dates'

const SelectDateTime = (props) => {
  const { day, month, year, handleYearChange, handleDayChange, handleMonthChange, handleTimeHourChange,
    errors, timeHour, timeMin, timePeriod, handleTimePeriodChange, handleTimeMinChange } = props
  return(
    <div className='day-time-select'>
      <div className='row'>
        <div className='col-sm form-group'>
          <div className='form-input-cell select-cell small'>
            <SimpleSelectField
              collection={dates.days}
              value={day}
              onChange={handleDayChange}
              emptyKey='Day'
              errorPrompt={false}
            />
          </div>
        </div>
        <div className='col-sm form-group'>
          <div className='form-input-cell select-cell small'>
            <SimpleSelectField
              key='key_month'
              collection={dates.months}
              value={month}
              onChange={handleMonthChange}
              emptyKey='Month'
              errorPrompt={false}
            />
          </div>
        </div>
        <div className='col-sm form-group'>
          <YearSelect
            year={year}
            handleYearChange={handleYearChange}
          />
        </div>
      </div>
      <div className='form-group'>
        <TimeSelect
          timeHour={timeHour}
          timeMin={timeMin}
          timePeriod={timePeriod}
          handleTimePeriodChange={handleTimePeriodChange}
          handleTimeHourChange={handleTimeHourChange}
          handleTimeMinChange={handleTimeMinChange}
        />
        {errors.start_date != null &&
          <div className='form-message invalid-feedback'>{errors.start_date}</div>}
      </div>
    </div>
  )
}

export default SelectDateTime;
