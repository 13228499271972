import React from 'react'
import i18n from '../../utils/i18n'

const RemoveModal = (props) => {
  const { handleClickClose, handleClickRemove } = props

  return(
    <div className='r-modal show'>
      <div className='r-modal-content'>
        <div className='r-modal-header'>
          <h4 className='h1 modal-title text-dark'>{i18n.t('shared.oneMoment')}</h4>
          <button className='btn btn-close' aria-label='Close' type='button' onClick={handleClickClose}/>
        </div>
        <div className='r-modal-body'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='h3 text-gray'>{i18n.t('conversations.removeThisConversation')}</h3>
            </div>
          </div>
        </div>
        <div className='r-modal-footer'>
        <button className='btn btn-big btn-primary' onClick={handleClickRemove}>{i18n.t('shared.yes')}</button>
          <button className='btn btn-big btn-primary' onClick={handleClickClose}>{i18n.t('shared.cancel')}</button>
        </div>
      </div>
    </div>
  )
}

export default RemoveModal
