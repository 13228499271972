import React from 'react'
import { checkChangeLenght } from '../../utils/helpers'

const PaymentReview = (props) => {
  const {amount, duration, id, long_description} = props.plan
  let full = '0'
  let change = '0'

  if (+amount > 0) {
    [full, change] = amount.split('.')
  }
  return (
    <div className='payment-review' key={`${amount}=${duration}=${id}`}>
      <strong>{`Today's Payment`}</strong>

      {duration && <div dangerouslySetInnerHTML={{ __html: long_description }} />}
      <div className='row align-items-end'>
        <div className='col-auto ml-auto'>
          <b className='price'>{full}<span>.{checkChangeLenght(change)}</span></b>
        </div>
      </div>
    </div>
  );
}

export default PaymentReview;
