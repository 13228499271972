export const adjustFloatingAdvertPosition = () => {
  const content = document.querySelector('.container.on-light')
  const floatingAdvert = document.querySelector('[class^="floating"][class*="advert"]')
  const contentRightBorder = content.getBoundingClientRect().x + content.getBoundingClientRect().width

  floatingAdvert.style.left = `${contentRightBorder + 15}px`
  floatingAdvert.style.right = 'inherit'
}

export const clearAdvertsFromContent = () => {
  if (window.innerWidth <= 767 ) return
  const adverts = document.querySelectorAll('.site-groups-advert');
  for (let i = 0; i < adverts.length; i += 1) {
    adverts[i].remove()
  }
}

export const adjustConversationAdvertTopPosition = (target, top) => {
  const floatingAdvert = document.querySelector('[class^="floating"][class*="advert"]')
  const targetElement = document.querySelector(target)

  if (targetElement) {
    const targetElementOffset = targetElement.getBoundingClientRect().top
    if (window.scrollY <= targetElementOffset) floatingAdvert.style.top = `${top - window.scrollY}px`
  }
}