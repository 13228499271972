import React from 'react'

import ReportContainer from '../../../reported_content/container'
import { makeGetRequest } from '../../../shared/api.js'
import DiscardedPost from './discarded_post'
import QuotedPost from '../../subjects/quoted_post'
import FrindowModal from '../../../shared/frindow_modal'

export default class NewPostReply extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      liked: props.post.liked,
      openReportModal: false,
      isReported: props.post.is_reported,
      readMore: false,
      quoteReadMore: false,
      openOriginalModal: false
    }
  }

  seeMoreHandler = (e) => {
    const button = e.target;
    const divElement = button.parentElement;
    if (divElement.classList.contains('is-open')) {
      button.textContent = '... See more';
    } else {
      button.textContent = '... See less';
    }
    divElement.classList.toggle('is-open');
  }

  renderReportButton = () => {
    if (this.state.isReported) { return (<span>Reported</span>) }

    return (
      <button
        className='btn btn-small font-weight-normal btn-report'
        onClick={() => this.setState({ openReportModal: true })}>
        Report
      </button>
    )
  }

  renderActionsDropdown = (id) => {
    const { renderReportButton } = this
    return (
      <div className='dropdown post-dropdown right'>
        <button className='btn' type='button' id={`${id}dropdown`} data-toggle='dropdown' aria-haspopup='true' aria-expanded='false'>
          •••
        </button>
        <div className='dropdown-menu dropdown-menu-right' aria-labelledby={`${id}dropdown`}>
          {renderReportButton()}
        </div>
      </div>
    )
  }

  handleLikeClick = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { likeUrl, unlikeUrl } = this.props
    const url = this.state.liked ? unlikeUrl : likeUrl
    makeGetRequest({ url: `${url}` })
      .then((response) => {
        if (response.data.errors == null) {
          this.setState({ liked: response.data.liked });
        }
      });
  }

  renderLikeBtn = () => {
    let likeText = 'Like'
    let likeClass = 'font-weight-normal btn-mute btn btn-small btn-secondary border-0 btn-like mr-2'
    if (this.state.liked) {
      likeText = 'Liked'
      likeClass += ' btn-liked'
    }
    return (
      <button className={likeClass} onClick={this.handleLikeClick}>
        {likeText}
      </button>
    )
  }

  toggleQuoteReadMore = () => this.setState({
    quoteReadMore: !this.state.quoteReadMore
  })

  showOriginal = () => { this.setState({ openOriginalModal: true }) }

  closeModal = () => { this.setState({ openOriginalModal: false }) }

  renderReplyBtn = (post) => {
    return <button
      data-post-id={post.id}
      data-post-form={this.props.subjectFormId}
      onClick={this.props.handleReplyClick}
      className='btn btn-small btn-mute font-weight-normal btn-reply mr-2'
    >
      Reply
    </button>
  }

  render = () => {
    const { seeMoreHandler } = this
    const { post, renderMiniProfile, canWrite } = this.props
    const { openOriginalModal } = this.state
    const shortContentCheck = post.content.length < 200 && post.content.split('<br>').length <= 4
    let profileUrl = (<a href={post.profile_path} className='mr-3'>{post.profile.user_name}</a>)
    if (post.profile.deleted) {
      profileUrl = (<span className='text-blue-ui mr-3'>{post.profile.user_name}</span>)
    }

    const postContent = () => {
      if (!post.discarded_at) {
        return (
          <>
            <div className='post-reply-body my-2'>
              {!post.profile.deleted && profileUrl}
              {post.parent && <QuotedPost quotedPost={post.parent} />}

              <div className={shortContentCheck ? 'full-msg' : ''}>
                <span dangerouslySetInnerHTML={{ __html: post.content.split('&amp;').join('&') }} />
                <button onClick={seeMoreHandler}>... See more</button>
              </div>
            </div>
            {post.desktop_post_photo_url.length > 0 &&
              <div className="post-photo">
                <div className='img-wrapper'>
                  <img className='lozad' data-src={post.desktop_post_photo_url} onClick={this.showOriginal} />
                </div>
                <FrindowModal customClass='ultra-wide' isOpen={openOriginalModal} closeModal={this.closeModal}>
                  <img src={post.post_photo_url} />
                </FrindowModal>
              </div>
            }
          </>
        )
      } else {
        return <div className='post-reply-body mb-2'>
          <DiscardedPost />
        </div>
      }
    }

    return (
      <div className='post-reply row mb-3 subject-wrapper'>
        {renderMiniProfile(post)}
        <div className='col'>
          {postContent()}
          <div className='post-footer post-actions text-right text-small'>
            {!post.discarded_at && this.renderLikeBtn()}
            {canWrite && this.renderReplyBtn(post)}
            <span className="updated-at">{post.forum_format_updated_at}</span>
            {this.renderActionsDropdown(post.id)}
            {this.state.openReportModal &&
              <ReportContainer onReportModalClose={() => this.setState({ openReportModal: false })}
                objectId={this.props.post.id}
                objectType='Post'
                onReportModalSubmit={(props) => this.setState({ openReportModal: false, isReported: props.status })} />}
          </div>
        </div>
      </div>
    )
  }
}
