import React, { PureComponent} from 'react';
import DropzoneComponent from 'react-dropzone-component';
import ReactDOMServer from 'react-dom/server'
import PropTypes from 'prop-types'

class FrindowImagesUploader extends PureComponent {
  constructor(props) {
    super(props);

    this.djsConfig = {
        dictDefaultMessage: 'Drop image here or click to upload from your device.',
        addRemoveLinks: false,
        acceptedFiles: "image/jpeg,image/png",
        previewTemplate: customPreview,
        headers: {
          'X-CSRF-TOKEN': csrfToken()
        }
    };

    this.componentConfig = {
      iconFiletypes: ['.jpg', '.png'],
      postUrl: `/profiles/${this.props.profile.id}/photos?position=${this.props.selectedBox}`
    };

    this.dropzone = null;
  }

  addedFile = (file) => {
    if(this.dropzone.files.length > 1) {
      this.props.addError('Only one image can be uploaded at once.')
      this.removeFile(file)
    }
    const total = this.props.imagesLength + this.dropzone.files.length
    if(total > this.props.maxFiles) {
      this.props.addError(`You can add max ${this.props.maxFiles} profile images`)
      this.removeFile(file)
    } else {
      this.props.uploadStart()
    }
  }

  removeFile = (file) => {
    this.dropzone.removeFile(file)
  }

  success = (_file, data) => {
    const { success, image, errors } = data
    if(success){
      this.props.addImage(image)
    } else {
      this.props.addError(errors)
    }
  }

  error = () => {
    this.props.addError('Only PNG or JPG files are permitted.')
  }

  render() {
    const config = this.componentConfig;
    const djsConfig = this.djsConfig;

    const eventHandlers = {
        init:       dz => this.dropzone = dz,
        drop:       this.callbackArray,
        addedfile:  this.addedFile,
        success:    this.success,
        error:      this.error,
    }

    return(
      <DropzoneComponent
        config={config}
        eventHandlers={eventHandlers}
        djsConfig={djsConfig}
      />
    )
  }
}
export default FrindowImagesUploader

FrindowImagesUploader.propTypes = {
  imagesLength: PropTypes.number,
  addImage:     PropTypes.func,
  addError:     PropTypes.func,
  uploadStart:  PropTypes.func
}

FrindowImagesUploader.defaultProps = {
  imagesLength: 0,
  maxFiles: 5
};

const csrfToken = () => {
  const csrf = document.getElementsByName('csrf-token')[0]
  return (csrf ? csrf.content : '')
}

const customPreview = ReactDOMServer.renderToStaticMarkup(
  <div className="dz-preview dz-file-preview">
  </div>
)
