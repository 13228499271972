import React from 'react'
import SimpleSelectField from '../shared/simple_select_field'
import GenderFilterLabel from './gender_filter_label'
import { CONTACT_FILTER_GENDERS, FILTERED_MAIL_SUPPORT_URL } from '../../utils/constants'
import i18n from '../../utils/i18n'

const EditContactFilter = (props) => {
  const { ageStart, handleAgeStartChange, ageEnd, handleAgeEndChange, errors,
    handleGenderFilterChange, selectedGenderFilter, isProxy = false, proxyName } = props
  return(
    <React.Fragment>
      <h2>{isProxy ? i18n.t('profileCompletion.opento.proxyHappyToBeContactedBy', {name: proxyName}) : i18n.t('profileCompletion.opento.whoDoIWantToHear')}</h2>
      <p dangerouslySetInnerHTML={{__html: i18n.t('profileCompletion.opento.whoDoIWantToHearInfo', { url: FILTERED_MAIL_SUPPORT_URL })}}></p>
      <div className='form-check escape-parent'>
        {CONTACT_FILTER_GENDERS.map((genderFilter) => {
          return(
            <GenderFilterLabel
              key={`key_open_${genderFilter.value}`}
              handleGenderFilterChange={handleGenderFilterChange}
              genderFilter={genderFilter}
              selectedGenderFilter={selectedGenderFilter} />
          )}
        )}
      </div>
      <div className='form-check'>
        <h2>{isProxy ? i18n.t('profileCompletion.opento.proxyIdealAgeRangeTxt') : i18n.t('profileCompletion.opento.idealAgeRangeTxt')}</h2>
        <div className='form-check escape-parent'>
          <div className='align-items-baseline row'>
            <div className='col-auto form-group'>
              <div className='form-input-cell select-cell small'>
                <SimpleSelectField
                  collection={Array.from({ length: (75 - 17)}, (_, i) => 18 + i)}
                  value={ageStart}
                  onChange={handleAgeStartChange}
                  emptyKey='Min age'
                  errorPrompt={false}
                />
              </div>
            </div>
            <div className='col-auto'>to</div>
            <div className='col-auto form-group'>
              <div className='form-input-cell select-cell small'>
                <SimpleSelectField
                  collection={Array.from({ length: (100 - 22)}, (_, i) => 100 - i)}
                  value={ageEnd}
                  onChange={handleAgeEndChange}
                  emptyKey='Max age'
                  errorPrompt={false}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {errors.ageRange != null &&
        <div className='form-message invalid-feedback'>{errors.ageRange}</div>}
    </React.Fragment>
  )
}

export default EditContactFilter
