import React, { useState, useEffect } from 'react'
import ClientCardProfile from '../client_card_profile'
import { lozadObserver } from '../../../utils/lozad_observer'

const ClientsContainer = ({clientProfiles, color}) => {
  const [limit, setLimit] = useState(20);
  const renderClients = () => {
    let clients = []

    clientProfiles.forEach(
      (clientProfile, index) => {
        if (index >= limit) return;
        clients.push(
          <div className="col-sm-12 col-md-6 col-xl-4 my-5">
            <ClientCardProfile key={`profile-${clientProfile.id}`}
                                        profile={clientProfile}
                                        color={color} />
          </div>
        )
      }
    )

    return clients
  }

  const calculateDocument = () => {
    if(document.documentElement) {
      return (
        document.documentElement.offsetHeight
        - document.documentElement.scrollTop
      )
    }
    return 0
  }

  const handleScroll = () => {
    if (
      clientProfiles.length >= limit
      && document.getElementsByTagName('footer')[0].clientHeight + window.innerHeight > calculateDocument()
    ) {
      setLimit(prevState => prevState + 20)
    }
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)

    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [clientProfiles])

  useEffect(() => {
    lozadObserver()

    return lozadObserver()
  }, [limit])

  return (
    <div className="members row">
      {renderClients()}
    </div>
  )
}

export default ClientsContainer;