import React from 'react'
import Slider from 'react-slick';
import ArrowPng from 'arrow.png'
import AddressPng from 'address.png'
import PostboxPng from 'postbox.png'
import CodePng from 'code.png'
import BadgePng from 'badge.png'

const Intro = (props) => {
  const settings = {
    slidesToShow: 2,
    slidesToScroll: 2,
    speed: 500,
    arrows: false,
    dots: true,
    infinite: false
  };

  const contentBox1 = (
    <div className='col'>
      <h2 className='font-weight-bold'>
        What is 
        <br/>
        this?
      </h2>
      <p>Confirming your account means that you confirm your home address with us. It is entirely optional. It helps to protect against scammers by indicating to others that you are who you say you are.</p>
    </div>
  );

  const contentBox2 = (
    <div className='col'>
      <h2 className='font-weight-bold'>
        Make more
        <br/>
        friends
      </h2>
      <p>Some users (especially the elderly and Proxy users), may feel more comfortable only speaking to confirmed users. Get confirmed, make more contacts.</p>
    </div>
  );

  const contentBox3 = (
    <div className='col'>
      <h2 className='font-weight-bold'>
        Have more
        <br /> 
        control
      </h2>
      <p>As a confirmed user, you have a little more flexibility. You can create Get Togethers and invite others to live video chat sessions.</p>
    </div>
  );

  return(
    <React.Fragment>
      <div className='main-breadcrumbs'>
        <div className='container'>
          <div className='row'>
            <div className='col-12 main-breadcrumbs-content'>
              <a onClick={() => window.history.back()} className='btn btn-secondary btn-back go-back'>Back</a>
              <nav aria-label='breadcrumb'>
                <ol className='breadcrumb'>
                  <li className='breadcrumb-item'>
                    <a href='/'>Home</a>
                  </li>
                  <li className='breadcrumb-item active'>Confirmation</li>
                </ol>
              </nav>
            </div>
          </div>
        </div>
      </div>
      <div className='container text-center confirmation-page'>
        <h1>
          Get your account
          <br/>
          confirmed!
        </h1>
        <a onClick={props.onNextStep} className='btn btn-primary btn-next mt-1 mb-5'>LET’S GET STARTED</a>
        <Slider className='mobile-intro' {...settings}>
          {
            contentBox1,
            contentBox2,
            contentBox3
          }
        </Slider>
        <div className='row desktop-intro'>
          {contentBox1}
          <div className='col-2 pt-3'>
            <img src={ArrowPng}></img>
          </div>
          {contentBox2}
          <div className='col-2 pt-3'>
            <img src={ArrowPng}></img>
          </div>
          {contentBox3}
        </div>
        <div className='row mt-5 mb-5'>
          <div className='col-md-12 col-lg-3 mb-md-4 mb-lg-0 confirmation-step active'>
            <div className='step-number'>
              <span>1</span>
            </div>
            <div className='step-bar'></div>
            <img src={AddressPng} className='mt-3 mb-3'></img>
            <h3>Give your address</h3>
          </div>
          <div className='col-md-12 col-lg-3 mb-md-4 mb-lg-0 confirmation-step'>
            <div className='step-number'>
              <span>2</span>
            </div>
            <div className='step-bar'></div>
            <img src={PostboxPng} className='mt-3 mb-3'></img>
            <h3>We'll post you the code</h3>
          </div>
          <div className='col-md-12 col-lg-3 mb-md-4 mb-lg-0 confirmation-step'>
            <div className='step-number'>
              <span>3</span>
            </div>
            <div className='step-bar'></div>
            <img src={CodePng} className='mt-3 mb-3'></img>
            <h3>Add the code to your account</h3>
          </div>
          <div className='col-md-12 col-lg-3 mb-md-4 mb-lg-0 confirmation-step'>
            <div className='step-number'>
              <span>4</span>
            </div>
            <img src={BadgePng} className='mt-3 mb-3'></img>
            <h3>You are confirmed!</h3>
          </div>
        </div>
        <div className='confirmation-box pt-4 pb-4 pl-5 pr-5 mb-5'>
          <h2>Confirm ​your​ account today</h2>
          <p>More trust, more access, more contacts</p>
          <a onClick={props.onNextStep} className='btn btn-primary btn-next'>LET’S GET STARTED</a>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Intro;
