import React from 'react'
import SubjectCard from '../groups/group/subjects/subject_card'
import StartSubjectContainer from './start_subject/start_subject_container'
import ItemGetTogether from '../groups/group/get_together/item_get_together'
import { makeGetRequest } from '../shared/api.js'
import ClientsCarousel from '../clients/services/clients_carousel'

export default class NewsfeedContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      newsfeed: props.newsfeed,
      clientProfiles: props.clientProfiles,
      clientTypes: props.clientTypes,
      isLastPage: props.isLastPage,
      usedClientNames: [],
      page: 1
    }
  }

  fetchNewsfeed(page) {
    const { fetchNewsfeedPath } = this.props
    makeGetRequest({ url: `${fetchNewsfeedPath}?page=${page}` })
      .then((response) => {
        this.setState({ loading: false })
        if(response.data.errors == null) {
          const fetchedNewsfeed = this.state.newsfeed.concat(response.data.newsfeed)
          let clientProfiles = {...this.state.clientProfiles, ...response.data.clientProfiles }
          let clientTypes = {...this.state.clientTypes, ...response.data.clientTypes }

          this.setState({
            errors: '',
            newsfeed: fetchedNewsfeed,
            page: page,
            clientProfiles: clientProfiles,
            clientTypes: clientTypes,
            isLastPage: response.data.last_page
          })
        }
        else { this.setState({ errors: response.data.errors }) }
      })
  }

  handleClickShowMore = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.fetchNewsfeed(this.state.page + 1)
  }

  renderSubject = (subject) => {
    const profile = this.props.currentProfile
    return(
      <div key={subject.n_id} className='left-column mb-4 subject-wrapper'>
        <SubjectCard
          fetchSubjectPosts={subject.fetchSubjectPostsUrl}
          urlJoinGroup={subject.group.urlJoinGroup}
          inGroupUrl={subject.group.inGroupUrl}
          groupDisplayName={subject.group.display_name}
          groupUrl={subject.group.url}
          createPostUrl={subject.createPostUrl}
          isFollowed={subject.isFollowed}
          subjectId={subject.id}
          profile={profile}
          canWrite={this.props.canWrite} />
      </div>
    )
  }

  renderGetTogether = (gt) => {
    return(
      <div key={gt.n_id} className='left-column mb-4'>
        <ItemGetTogether getTogether={gt} newsfeed={true} toplinks={false}/>
      </div>
    )
  }

  renderBespokeItem = (bespokeItem) => {
    const html = this.props.mobile? bespokeItem.mobile_content : bespokeItem.desktop_content
    const containerClass = bespokeItem.container ? 'card' : 'bs-empty'

    if(bespokeItem['carousel?']){
      let client_name = bespokeItem['client_name'].toLowerCase()

      if( this.state.clientProfiles[client_name] && !this.state.usedClientNames.includes(client_name)){
        return (
            <div className='left-column mb-4'>
              <ClientsCarousel clientProfiles={this.state.clientProfiles[client_name]} type={this.state.clientTypes[client_name]} />
            </div>
        )
      }else{
        return ('')
      }
    }else{
      return(
        <div key={bespokeItem.n_id} className={`left-column mb-4 ${containerClass}`} dangerouslySetInnerHTML={{__html: html.split('&amp;').join('&')}} />
      )
    }
  }

  renderNewsfeedItem = (newsfeedItem) => {
    if (!newsfeedItem) return
    switch(newsfeedItem.type) {
      case 'subject': return this.renderSubject(newsfeedItem)
      case 'getTogether': return this.renderGetTogether(newsfeedItem)
      case 'bespokeItem': return this.renderBespokeItem(newsfeedItem)
    }
  }

  updateNewsfeed = (newItem) => void this.setState((prevState) => ({
    ...prevState,
    newsfeed: [
      newItem,
      ...prevState.newsfeed.slice(0, prevState.newsfeed.length - 1)
    ]
  }))

  render() {
    const { handleClickShowMore, updateNewsfeed } = this
    const { userGroupsPresent, userGroups, currentProfile, banned, canWrite } = this.props
    const { isLastPage } = this.state
    return(
      <>
        {userGroupsPresent && !banned &&
          <StartSubjectContainer
            canWrite={canWrite}
            userGroups={userGroups}
            currentProfile={currentProfile}
            update={updateNewsfeed} />}
        {this.renderNewsfeedItem(this.state.newsfeed[0])}
        {this.renderNewsfeedItem(this.state.newsfeed[1])}
        {this.state.newsfeed.slice(2).map(item => this.renderNewsfeedItem(item))}
        <div className='text-center mb-5'>
          {!isLastPage &&
            <button type='button' className='btn btn-big btn-secondary show-all' onClick={handleClickShowMore}>
              Show more
              <i className="symbol icon-a-right ml-3" />
            </button>}
        </div>
      </>
    )
  }
}
