import React from 'react'
import { makePutRequest } from '../shared/api.js'
import CancelModal from './cancel_modal'
import Alert from 'alert.svg'

class DecideAnswear extends React.PureComponent {

  decideProposal = (e) => {
    const { name } = e.currentTarget
    const { videoRoomUrl, updateState } = this.props
    makePutRequest({ url: videoRoomUrl, body: { video_room: { status: name } } })
    .then((response) => {
      this.setState({errors: []})
      if(response.data.video_room.status === 'accepted') {
        updateState({videRoom: response.data.video_room, step: 3})
      } else {
        updateState({videRoom: null, step: 0})
      }
    }, (response) => {
      this.setState({
        errors: Object.entries(response.errors).map(([k,v]) => `${k} ${v}`)
      })
    })
  }

  render = () => {
    const { videoRoomUrl, updateState, videoRoom } = this.props
    const { decideProposal } = this
    return(
      <div className='row live-chat col-lg-10 offset-lg-1'>
        <div className='col live-chat-main white text-center'>
          <h2>You&apos;ve been invited to live chat</h2>
          <p className='live-chat-shelude'>{videoRoom.formatted_date}</p>
          <div className='row justify-content-center mt-3 mb-4'>
            <div className='col-auto mr-2'>
              <a onClick={decideProposal} name='accepted' className='text-black'>
                <div className='block-icon accept-icon'>
                  <i className='icon icon-check text-white'></i>
                </div>
                Accept
              </a>
            </div>
            <div className='col-auto'>
              <a onClick={decideProposal} name='rejected' className='text-black'>
                <div className='block-icon decline-icon'>
                  <i className='icon icon-cross text-white'></i>
                </div>
                Decline
              </a>
            </div>
          </div>
          <CancelModal
            videoRoomUrl={videoRoomUrl}
            updateState={updateState} />
        </div>
        <div className='col live-chat-info'>
          <img src={Alert} className='img-fluid' alt='Important'></img>
          <h2>A few safety tips</h2>
          <ul>
            <li>Only chat to people who you have corresponded with before (through messaging or a Get Together)</li>
            <li>Never disclose where you live</li>
            <li>Never disclose any private information (such as card or bank details)</li>
            <li>If necessary, have a chaperone with you on your first chat with somebody new.</li>
          </ul>
        </div>
      </div>
    )
  }
}

export default DecideAnswear;
