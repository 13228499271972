import React, { useState } from 'react'
import PublishLink from './publish_link'

const Notifications = (props) => {
  const { user_id } = props.profile;
  const [infoVisible, setInfoVisible] = useState(sessionStorage.getItem(`clientInfoNotification${[user_id]}`) === 'false' ? false : true);
  const [warningVisible, setWarningVisible] = useState(sessionStorage.getItem(`clientWarningNotification${[user_id]}`) === 'false' ? false : true);
  const [publishVisible, setPublishVisible] = useState(sessionStorage.getItem(`clientPublishNotification${[user_id]}`) === 'false' ? false : true);
  const [liveInfoVisible, setLiveInfoVisible] = useState(sessionStorage.getItem(`clientLiveInfoNotification${[user_id]}`) === 'false' ? false : true);

  const hideInfo = () => {
    sessionStorage.setItem(`clientInfoNotification${[user_id]}`, false);
    setInfoVisible(false);
  }
  const hideWarning = () => {
    sessionStorage.setItem(`clientWarningNotification${[user_id]}`, false);
    setWarningVisible(false);
  }
  const hidePublish = () => {
    sessionStorage.setItem(`clientPublishNotification${[user_id]}`, false);
    setPublishVisible(false);
  }
  const hideLiveInfo = () => {
    sessionStorage.setItem(`clientLiveInfoNotification${[user_id]}`, false);
    setLiveInfoVisible(false);
  }


  const renderCreatingProfile = () => {
    return (
      <span className='notification info'>
        <i className='icon icon-alert-circle'/>
        Welcome {props.userFirstName}! Get started by <a href="business/my_profile">creating your profile.</a>
        <button
          type='button'
          className='icon icon-cross'
          onClick={hideInfo}
        />
      </span>
    )
  }

  const renderCompleteProfile = () => {
    return (
      <span className='notification warning'>
        <i className='icon icon-alert-circle'/>
        You must <a href="business/my_profile">complete your profile</a> {!props.accountActive && <>and <a href="payments/new">activate your account</a></>} before it can be published.
      </span>
    )
  }

  const renderVisibleFalse = () => {
    return (
      <span className='notification error'>
        <i className='icon icon-alert-circle'/>
        Your profile is not visible. Change the visibility to start showing it again.
      </span>
    )
  }

  const renderProfileLive = () => {
    return (
      <span className='notification info'>
        <i className='icon icon-alert-circle'/>
        Your profile is now live.
        <button
          type='button'
          className='icon icon-cross'
          onClick={hideLiveInfo}
        />
      </span>
    )
  }

  const renderPublishLink = () => {
    return (
      <PublishLink
        profile={props.profile}
        linkText='Publish your profile'
        published={props.published}
        fillCompletely={props.fillCompletely}
        showNotification={true}
        handleNotificationClose={hidePublish}/>
    )
  }

  const renderNotifications = () => {
    // No profile
    if(!props.profileUpdated && infoVisible){
      return (
        renderCreatingProfile()
      )
    }
    if(props.accountActive){
      if(props.fillCompletely){
        if( !props.published && props.profileUpdated && publishVisible){
          return (
            renderPublishLink()
          )
        }

        if(!props.visibility && props.profileUpdated && props.published){
          return(
            renderVisibleFalse()
          )
        }

        if(props.visibility && props.profileUpdated && props.published && liveInfoVisible){
          return(
            renderProfileLive()
          )
        }
      }else{
        if(((props.profileUpdated || !props.fillCompletely)) && warningVisible){
          return(
            renderCompleteProfile()
          )
        }
      }
    }else{
      if(props.profileUpdated || props.expiredAccount){
        return(
          renderCompleteProfile()
        )
      }
    }
  }

  return (
    <div className='client-notifications'>
      {
        renderNotifications()
      }
    </div>
  )
}
export default Notifications
