import React, { Component } from 'react'
import { makeGetRequest } from '../../shared/api'
import Select from 'react-select'
import { findIndex, filter } from 'lodash'
import SimpleSelectField from '../../shared/simple_select_field'

export default class Region extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countries: props.countries,
      selectedCountry: props.selectedCountry,
      locations: [],
      selectedLocations: props.selectedLocations
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.selectedCountry !== prevState.selectedCountry) {
      this.setState({selectedLocations: []})
      this.fetchLocationsFor(this.state.selectedCountry)
    }
  }

  componentDidMount(){
    if(this.state.selectedCountry) this.fetchLocationsFor(this.state.selectedCountry)
  }

  fetchLocationsFor = (selectedCountry) => {
    makeGetRequest({ url: `${this.props.url}?country=${selectedCountry}`}).then((res) => {
      if(res.data) this.setState({ locations: res.data.locations }, this.checkAllOption)
    })
  }

  checkAllOption = () => {
    if(this.state.selectedLocations.length === this.state.locations.length) {
      this.setState({selectedLocations: [{ id: '', name: 'All'}]})
    }
  }

  handleCountryChange = (e) => {
    const country = e.target.value
    const oldCountry = this.state.selectedCountry

    if(country !== oldCountry) this.setState({selectedCountry: country})
  }

  handleLocationChange = (locations) => {
    const allIndex = findIndex(locations, (l) => l.value === 'all')
    const selectedLocations = allIndex > -1 ? this.state.locations : this.parseLocations(locations)
    this.setState({country: this.state.country, selectedLocations: selectedLocations})
  }

  parseLocations = (locations) => {
    const locationIds = locations.map((l) => l.value)
    return filter(this.state.locations, (cl) => locationIds.includes(cl.id))
  }

  locationOptions = () => this.state.locations.map((obj) => ({ label: obj.name, value: obj.id }))

  locationValues = () => {
    return this.state.selectedLocations.map((obj) => ({ label: obj.name, value: obj.id }))
  }

  render = () => {
    const { locationValues, handleCountryChange, handleLocationChange, locationOptions } = this
    const { countries, selectedCountry } = this.state
    return(
      <React.Fragment>
        <SimpleSelectField
          collection={['All', ...countries]}
          value={selectedCountry}
          onChange={handleCountryChange}
        />
        <div className='form-check form-multi-autocomplete'>
          <Select
            isMulti
            options={[{ label: 'All', value: 'all' }, ...locationOptions()]}
            onChange={handleLocationChange}
            value={locationValues()}
            className='form-input-cell'
            classNamePrefix='autocomplete'
            name='[location_ids][]'
          />
        </div>
      </React.Fragment>
    )
  }
}
