import React from 'react';
import { makeGetRequest } from '../../../shared/api.js'
import ItemListSubject from './item_list_subject'

export default class ListSubjects extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      subjects: [],
      shownSubjects: [],
      loading: false,
      page: 0,
      lastPage: false
    }
  }

  componentDidMount() {
    if(this.props.subjectsExists) { this.fetchSubjects(this.state.page + 1) }
  }

  componentDidUpdate(prevProps) {
    if(this.props.subjectToAdd.subjectUrl !== prevProps.subjectToAdd.subjectUrl) {
      const temporaryShownSubjects = this.state.shownSubjects
      const temporarySubjects = this.state.subjects
      temporaryShownSubjects.unshift(this.props.subjectToAdd)
      temporarySubjects.unshift(this.props.subjectToAdd)
      this.setState({
        subjects: temporarySubjects,
        shownSubjects: temporaryShownSubjects
      })
    }
  }

  loadMore = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if(this.state.lastPage) {
      this.setState({shownSubjects: this.state.subjects})
      return
    }

    this.fetchSubjects(this.state.page + 1)
  }

  fetchSubjects = (page = 1) => {
    if(this.state.lastPage || this.state.loading) { return null }
    this.setState({loading: true})

    makeGetRequest({ url: `${this.props.fetchSubjectsUrl}?page=${page}`})
      .then((response) => {
        const hideSubject = response.data.subjects.find(s => s.subjectUrl === this.props.hideSubjectUrl)
        const urls = this.state.subjects.map(s => s.subjectUrl)
        urls.push(this.props.hideSubjectUrl)
        const newSubjects = response.data.subjects.filter(s => !urls.includes(s.subjectUrl))
        this.setState({ subjects: this.state.subjects.concat(newSubjects),
                        shownSubjects: this.state.shownSubjects.concat(newSubjects),
                        hideSubject: hideSubject,
                        lastPage: response.data.last_page,
                        page: page,
                        loading: false })
       })
  }

  showLess = () => {
    this.setState({shownSubjects: []})
  }

  renderBtn = () => {
    const sharedClassNames= 'font-weight-normal btn btn-small btn-secondary mb-4';
    if(this.state.lastPage && (this.state.subjects.length === this.state.shownSubjects.length)) {
      return(
        <button
          onClick={this.showLess}
          className={`${sharedClassNames} show-less`}>
          SHOW LESS
          <i className='symbol icon-a-right ml-3' />
        </button>
      )
    }

    return(
      <button
        onClick={this.loadMore}
        className={`${sharedClassNames} show-all`}>
        SHOW MORE
        <i className='symbol icon-a-right ml-3' />
      </button>
    )
  }

  render = () => {
    return (
      <>
        {this.state.shownSubjects.map(subject=>(<ItemListSubject key={subject.subjectUrl} subject={subject} />))}
        {this.props.subjectsExists && this.renderBtn()}
      </>
    )
  }
}
