import React from 'react'
import StartSubject from './start_subject'
import CodeConfirmationAlert from '../../shared/code_confirmation_alert'

export default class StartSubjectContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedGroup: {
        display_name: ''
      }
    }
  }

  onCreatedSubject = (createPostUrl, fetchSubjectPostsUrl, url, subjectId) => {
    const item = {
      createPostUrl: createPostUrl,
      fetchSubjectPostsUrl: fetchSubjectPostsUrl,
      isFollowed: true,
      subjectId: subjectId,
      group: this.state.selectedGroup,
      type: 'subject'
    }
    this.props.update(item)
  }

  handleGroupChange = (e) => {
    const group = this.props.userGroups.find(a => a.display_name === e.currentTarget.value)
    this.setState({ selectedGroup: group })
  }

  renderSubject = () => {
    return <StartSubject
      selectedGroup={this.state.selectedGroup}
      onCreatedSubject={this.onCreatedSubject}
      userGroups={this.props.userGroups}
      handleGroupChange={this.handleGroupChange}
      profile={this.props.currentProfile} />
  }

  render = () => {
    return (
      <div className='left-column mb-4'>
        { this.props.canWrite ? this.renderSubject() : CodeConfirmationAlert()}
      </div>
    )
  }
}
