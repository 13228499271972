import React from 'react'
import i18n from '../../utils/i18n'

const ConversationsType = (props) => {
  const { filtered, handleClickUnfiltered, handleClickFiltered, counters } = props

  return(
    <div className='row justify-content-center inbox-filters'>
      <button className={`btn btn-inbox ${!filtered? 'active' : ''}`} type='button' onClick={handleClickUnfiltered}>
        {i18n.t('shared.inbox')} ({counters.inbox_count})
      </button>
      <button className={`btn btn-inbox ${filtered? 'active' : ''}`} type='button' onClick={handleClickFiltered}>
        {i18n.t('shared.filtered')} ({counters.filtered_count})
      </button>
    </div>
  )
}

export default ConversationsType
