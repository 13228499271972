import React from 'react'
import ProgressBar from '../../shared/progress_bar'
import BadgePng from 'badge.png'
import AlertSvg from 'alert.svg'

const Outro = (props) => {
  return(
    <React.Fragment>
      <div className="container confirmation-page">
        <div className="row align-items-start mt-5 mb-5 sign-in-form">
          <div className="col-md-8 col-lg-4 offset-md-0 offset-lg-4 mb-5">
            <ProgressBar step={4} steps={4}></ProgressBar>
            <img src={BadgePng}  className='mt-3 mb-3'></img>
            <h2>Hurray! You have confirmed your profile.</h2>
            <a href={props.rootPath} className='btn btn-primary btn-next mt-4'>Continue exploring</a>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="info-box mt-5">
              <img src={AlertSvg}></img>
              <h3 className="mt-2">Start enjoying some of the benefits of being a confirmed user:</h3>
              <ul>
                <li>Start video chats</li>
                <li>Organise Get Togethers</li>
                <li>Make more friends</li>
                <li>Enjoy!</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Outro;
