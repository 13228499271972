import React from 'react'
import { PROFILE_TYPES } from '../../utils/constants'
import UserAvatar from '../shared/profile/user_avatar'
import { SystemNotificationAvatar}  from '../shared/system_notification'

const MiniProfile = ({ profile_path, profile, photo_url, avatar, deleted, sizeClass='size-sm', post=false, username='', publishedEventType=false }) => {
  const classBase = `circle-avatar ${sizeClass}`
  const linkClass = profile.type === PROFILE_TYPES.proxy ? `${classBase} proxy-marker` : classBase

  if(deleted) {
    return(
      <p className={linkClass} data-label-top={username}>
        <UserAvatar photo_url={photo_url} avatar={avatar} />
      </p>
    )
  }
  if(post.discarded_at) {
    return(
      <SystemNotificationAvatar />
    )
  }

  if(!profile.profile_visible){
    return (
      <span className={linkClass} data-label-top={username}>
        <UserAvatar photo_url={photo_url} avatar={avatar} />
      </span>
    )
  }

  if(publishedEventType) {
    return (
      <span className={linkClass}>
        <UserAvatar avatar={avatar} />
      </span>
    )
  }

  return(
      <a href={profile_path} className={linkClass} data-label-top={username}>
        <UserAvatar photo_url={photo_url} avatar={avatar} />
      </a>
  )
}

export default MiniProfile
