import React from 'react'

const EmailsRadio = React.memo((props) => {
  const { emailStatusChange, emailStatus, nameRadio } = props
  return(
    <React.Fragment>
      <td className="pr-2 mobile-column-50">
        <div className='form-input-cell radiobox-cell'>
          <input
            type='radio'
            id={`${nameRadio}_on`}
            name={`${nameRadio}_on`}
            value='on'
            className='form-check-input mb-3'
            onChange={emailStatusChange}
            checked={emailStatus}
          />
          <label htmlFor={`${nameRadio}_on`}><span className='checkbox'></span>
            On
          </label>
        </div>
      </td>
      <td className="pr-2 mobile-column-50">
        <div className='form-input-cell radiobox-cell'>
          <input
            type='radio'
            id={`${nameRadio}_off`}
            name={`${nameRadio}_off`}
            value='off'
            className='form-check-input mb-3'
            onChange={emailStatusChange}
            checked={!emailStatus}
          />
          <label htmlFor={`${nameRadio}_off`}><span className='checkbox'></span>
            Off
          </label>
        </div>
      </td>
    </React.Fragment>
  )
})

EmailsRadio.displayName = 'emails-radio'

export default EmailsRadio
