import React from 'react'

class CountrySelect extends React.PureComponent {
  renderCountries = () => {
    const { area_id, selectedCountry, countries } = this.props
    const options = selectedCountry === '' ? countries : ['', ...countries]

    return(
      options.sort()
              .map((country) => {
                return(
                  <option
                    key={`key_country_${area_id}_${country}`}
                    value={country}>
                    {country}
                  </option>
                )
              })
    )
  }


  render() {
    const { area_id, selectedCountry, handleCountryChange } = this.props
    return(
      <select
        key={`key_country_select_${area_id}`}
        className='form-control'
        value={selectedCountry}
        onChange={handleCountryChange}>
          {this.renderCountries()}
      </select>
    )
  }
}

export default CountrySelect
