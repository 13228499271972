import React from 'react'
import logoImg from '../../images/logo-frindow.svg'
import businessLogoImg from '../../images/logo.svg'

const Logo = ({ business }) => {
  const logo = business ? businessLogoImg : logoImg
  return(
    <a href="/" title="Back to home page">
      <img className={`img-fluid logo-frindow ${business ? 'business-logo' : ''}`} src={logo} alt='Logo' />
    </a>
  )
}

export default Logo;
