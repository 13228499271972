import React from 'react'
import TextField from '../../shared/text_field';
import CheckBoxField from '../../shared/checkbox_field'
import Container from './container'
import Button from '../../shared/button'

export default class PasswordFields extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      directTermsChecked: false,
    }

    this.privacyPolicy = "<a className='text-blue-ui' href='/pages/privacy' target='_blank'>Privacy Policy</a>"
    this.termsURL = this.props.business ? '/pages/client-terms': '/pages/terms'
    this.termsLabel = `By clicking on Sign Up, you agree to our <a class='text-blue-ui' href='${this.termsURL}' target='_blank'>Terms of Service</a> and <a class='text-blue-ui' href='/pages/acceptable_use' target='_blank'>Acceptable Use
    Policy</a>. Learn how we collect, use and share your personal data, in our ${this.privacyPolicy}.`
  }

  handleDirectTermsChange = (e) => {
    const termsChecked = e.target.checked

    this.setState({
      directTermsChecked: e.target.checked
    })

    if(!e.target.checked || termsChecked) { this.props.handleTermsChange(e) }
  }

  btnDisabled = () => {
    const {
      password, passwordConfirmation, termsChecked,
      passwordErrorText, passwordConfirmationErrorText
    } = this.props

    return(
      !password || !passwordConfirmation || !termsChecked ||
      passwordErrorText || passwordConfirmationErrorText
    )
  }

  termsLabelDesc = () => {
    return(<div dangerouslySetInnerHTML={{__html: this.termsLabel.split('&amp;').join('&')}}/>)
  }

  signUpBtn = () => {
    return(
      <Button
        key='key_sign_up_btn'
        className='btn-primary btn-submit'
        title='Sign Up'
        onClick={this.props.handleSignUpClick}
        disabled={this.btnDisabled()}
      />
    )
  }

  render = () => {
    const { signUpBtn, termsLabelDesc } = this
    const {
      password, step, passwordErrorText, termsChecked,
      passwordConfirmation, passwordConfirmationErrorText, handlePasswordConfirmationChange,
      termsErrorText, handlePasswordChange, handleTermsChange, business
    } = this.props

    return (
      <Container
        customClass='step-set-passoword'
        step={step}
        actionButton={signUpBtn()}
        headerText={`We take your security very seriously.`}
        descText={`Help us by making your password a good one (a mixture of big and small letters with numbers and even punctuation all helps). Don’t forget to make it easy to remember!`}
        business={business}
      >
        <h2>Nearly there, just need to set a password</h2>

        <div className='form'>
          <TextField
            type='password'
            value={password}
            errorText={passwordErrorText}
            placeholder='Password'
            onChange={handlePasswordChange}
            name='user[password]'
          />
          <TextField
            type='password'
            value={passwordConfirmation}
            errorText={passwordConfirmationErrorText}
            placeholder='Confirm password'
            onChange={handlePasswordConfirmationChange}
          />
        </div>
        <div className=''>
          <CheckBoxField
            value={termsChecked}
            errorText={termsErrorText}
            onChange={handleTermsChange}
            id={'acceptTerms'}
            labelDesc={termsLabelDesc()}
            name='user[terms]'
          />
        </div>
      </Container>
    )
  }
}
