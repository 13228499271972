import React from 'react'

const CheckBoxField = ({ value, checked, onChange, errorText, id, labelDesc, name }) => {
  const className = (errorText ? 'form-control is-invalid' : 'form-control')

  return (
    <div className='form-check'>
      <div className='form-input-cell checkbox-cell'>
        <input
          name={name}
          type='checkbox'
          value={value}
          checked={checked}
          onChange={onChange}
          className={className}
          id={id}
        />
        <label className='form-check-label' htmlFor={id}>
          <span className='checkbox'></span>
          {labelDesc}
        </label>
        <div className='invalid-feedback'>{errorText}</div>
      </div>
    </div>
  )
}

export default CheckBoxField
