import React from 'react'
import CancelModal from './cancel_modal'
import Calendar from 'calender.svg'
import LiveChat from 'live-chat.svg'
import Alert from 'alert.svg'
import BeforeScheduledModal from './before_scheduled_modal'
import { makeGetRequest } from '../shared/api.js'

class ScheduledChat extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      isOpen: false
    }
  }

  onLaunchClick = () => {
    const { videoRoomUrl } = this.props
    makeGetRequest({ url: `${videoRoomUrl}/running` })
    .then((response) => {
      if(response.status === 422) {
        window.location.href = response.data.redirect_url
        return null
      }
      if(!response.data.running) { this.setState({errors: [], isOpen: true}) }
      else {
        this.setState({errors: [], isOpen: false})
        window.location.href = videoRoomUrl
      }
    }, () => {
      window.location.href = videoRoomUrl
    })
  }

  closeModal = () => this.setState({isOpen: false})

  openModal = () => this.setState({isOpen: true})

  render = () => {
    const { onLaunchClick, closeModal, openModal } = this
    const { isOpen } = this.state
    const { handleStep, videoRoomUrl, updateState, videoRoom } = this.props
    return(
      <div className='row'>
        <div className='row live-chat col-lg-10 offset-lg-1'>
          <div className='col live-chat-main white text-center'>
            <h2>Scheduled Chat</h2>
            <p className='live-chat-shelude'>{videoRoom.formatted_date}</p>
            <div className='row justify-content-center mt-3 mb-4'>
              <div className='col-auto mr-2'>
              <BeforeScheduledModal
                isOpen={isOpen}
                closeModal={closeModal}
                openModa={openModal}
                date={videoRoom.formatted_date}
                />
                <a onClick={onLaunchClick} name='accepted' className='text-black'>
                  <div className='block-icon chat-icon'>
                    <img src={LiveChat} alt='Live Chat'></img>
                  </div>
                  Launch Chat
                </a>
              </div>
              <div className='col-auto'>
                <a onClick={handleStep} data-step='1' className='text-black'>
                  <div className='block-icon calendar-icon'>
                    <img src={Calendar} alt='Calendar'></img>
                  </div>
                  Re-schedule
                </a>
              </div>
            </div>
            <CancelModal
              videoRoomUrl={videoRoomUrl}
              updateState={updateState} />
          </div>
          <div className='col live-chat-info'>
            <img src={Alert} className='img-fluid' alt='Important'></img>
            <h2>A few safety tips</h2>
            <ul>
              <li>Only chat to people who you have corresponded with before (through messaging or a Get Together)</li>
              <li>Never disclose where you live</li>
              <li>Never disclose any private information (such as card or bank details)</li>
              <li>If necessary, have a chaperone with you on your first chat with somebody new.</li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

export default ScheduledChat;
