import React, { Component } from 'react'
import About from '../shared/profile/about'
import Button from '../shared/button'
import FrindowNotification from '../shared/frindow_notification'

import { makePutRequest } from '../shared/api'

export default class EditAboutMeJoint extends Component {
  constructor(props){
    super(props)
    this.state = {
      profile: props.profile,

      messages: [],
      errors: [],
    }
  }

  handleAbout = (e) => {
    this.setState({ profile: {...this.state.profile, about_me: e.target.value }})
  }

  handleCancel = () => {
    this.setState({
      messages: ['Form has been reseted'],
      profile: this.props.profile
    })
  }

  handleUpdate = () => {
    const { profile } = this.state
    const { profile_url } = this.props
    if(profile.about_me.length < 1){
      this.setState({errors: ['About me can\'t be blank']})
      return
    }

    makePutRequest({ url: profile_url, body: { profile: { about_me: profile.about_me } } })
                  .then((response) => {
                    if (response.data.errors == null) {
                      this.setState({
                        messages: ['Profile has been updated'],
                        errors: []
                      })
                    }
                    else {
                      this.setState({errors: response.data.errors})
                    }
                  })
  }

  renderActions = () => (
    <div className='form-actions mt-4'>
      <Button
        className='button btn btn-big btn-secondary btn-cancel btn-submit'
        title='Cancel'
        onClick={this.handleCancel}
      />
      <Button
        className='btn btn-big btn-primary btn-update btn-submit'
        title='Update'
        onClick={this.handleUpdate}
      />
    </div>
  )

  render = () => {
    const { profile, errors, messages } = this.state
    return(
      <div className='form my-4'>
        <h2>{`${profile.first_name}’s profile`}</h2>
        <About profile={profile} handleAbout={this.handleAbout} />
        {this.renderActions()}
        <FrindowNotification errors={errors} messages={messages} clearNotices={() => this.setState({ errors: [], messages: [] })} />
      </div>
    )
  }
}
