import React from 'react'
import i18n from '../../utils/i18n'
import { APP_ROUTES } from '../shared/routes'
import { QUESTIONNAIRE_SUPPORT_URL } from '../../utils/constants'

export default function CompletedPage(props) {
  const { questionnaireState } = props
  const saveExitBtn = document.getElementById('save-exit-bar-btn')
  saveExitBtn.classList.add('hide')

  return (
    <>
      <header class="questionnaire-header complete">
        <div className="header-top container">
          <div className="progress-bars mobile-hide">
            { props.categories.map((category) => {
                return <div key={`category-${category.id}`}>
                  <p>{category.name}</p>
                  <div className='progress'>
                    <div className='progress-bar' role='progressbar' style={{width: '100%'}} aria-valuenow={0}></div>
                  </div>
                </div>
            })}
          </div>
        </div>
        <div className="header-title">
          { questionnaireState == 'completed' && <h1>Congratulations!</h1> }
          <div className="progress mobile-show">
            <div className="progress-bar" role="progressbar" style={{width: '100%'}} aria-valuenow="0"></div>
          </div>
          <h2>{questionnaireState == 'done' ? "You have already completed the questionnaire." : "You have completed the questionnaire!"}</h2>
        </div>
      </header>
      <div className='questionnaire-content complete'>
        <div className='questionnaire-content-wrapper'>
          <div dangerouslySetInnerHTML={{__html: i18n.t(`questionnaire.${questionnaireState}Text`, { credits: 100, url: QUESTIONNAIRE_SUPPORT_URL, home_page_url: '/' })}}/>
        </div>
      </div>
      <div className="questionnaire-footer fixed">
        <div className="footer-actions">
          <button className='btn btn-big btn-primary' onClick={()=> {window.location = APP_ROUTES.search.page}}>
            Find like-minded people!
          </button>
        </div>
      </div>
    </>
  )
}
