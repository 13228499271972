import React, { useState, useEffect } from 'react'
import TextField from '../../shared/text_field'
import FrindowNotification from '../../shared/frindow_notification'
import FrindowErrors from '../../shared/frindow_errors'
import Select from 'react-select'
import { makePostRequest, makePutRequest } from '../../shared/api'

export default function AudienceContainer (props) {
  const customListHeightStyles = {
    menuList: base => ({
      ...base,
      height: 400,
      minHeight: 400,
      scrollbarWidth: "auto !important",
      '-ms-overflow-style': "auto !important",
      "::-webkit-scrollbar": {
        width: "4px",
        height: 0
      }
    })
  }

  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [processing, setProcessing] = useState(false);

  const labelValue = (value) => ({ label: value, value: value });

  const [name, setName] = useState(props.audience.name || '');
  const [minAge, setMinAge] = useState(labelValue(props.audience.min_age || 18));
  const [maxAge, setMaxAge] = useState(labelValue(props.audience.max_age || 105));
  const [proxySetting, setProxySetting] = useState(props.audience.proxy_user_setting || '');

  const [selectedGroups, setSelectedGroups] = useState(
    props.audience.groups.length && props.audience.groups || [{ label: 'All', value: 'all' }]
  );
  const [selectedKeywords, setSelectedKeywords] = useState(props.audience.keywords || []);
  const [keywordMatchType, setKeywordMatchType] = useState(props.audience.keyword_match_type || 'and');
  const [selectedLanguages, setSelectedLanguages] = useState(props.audience.languages || []);

  const groupValues = () => props.groups.map((obj) => ({ label: obj[0], value: obj[1] }))
  const keywordValues = () => props.keywords.map((obj) => ({ label: obj[0], value: obj[1] }))
  const languageValues = () => props.languages.map((obj) => ({ label: obj[0], value: obj[1] }))

  const ageRangeOptions = Array.from({ length: 88 }, (v, k)=> ({ label: k + 18, value: k + 18 }));

  useEffect(() => {
    props.audience.genders.forEach((gender) => {
      $('.bullet-checkbox').find(`input[value=${gender}]`).prop('checked', true);
    })
  }, [props.audience])

  const toggleLgbt = () => {
    const currentState = $('.bullet-checkbox input').eq(2).is(':checked');
    $('.bullet-checkbox').slice(2).find('input').prop('checked', !currentState);
  }

  const formBody = () => {
    let genders = []
    $('.bullet-checkbox input:checked').map((k, elem) => {
      genders.push(elem.value)
    });

    return {
      audience: {
        name: name,
        min_age: minAge.value,
        max_age: maxAge.value,
        keyword_match_type: keywordMatchType,
        proxy_user_setting: proxySetting,
        genders: genders,
        groups: selectedGroups.map((group) => group.value ),
        keywords: selectedKeywords.map((keyword) => keyword.value),
        languages: selectedLanguages.map((language) => language.value)
      }
    }
  }


  const create = () => {
    makePostRequest({ url: props.audienceUrl,
                      body: formBody() }).then((res) => {
                        setErrors([]);
                        setProcessing(false);
                        setMessages([res.data.message]);
                        window.location = res.data.redirect_url
                      }).catch(response => onError(response))
  }

  const update = () => {
    makePutRequest({ url: `${props.audienceUrl}/${props.audience.id}`,
                      body: formBody() }).then((res) => {
                        setErrors([]);
                        setProcessing(false);
                        setMessages([res.data.message]);
                        window.location = res.data.redirect_url
                      }).catch(response => onError(response))
  }

  const handleClickSave = (e) => {
    e.preventDefault()

    if(processing) { return null }

    setProcessing(true)

    if(props.audience.id != null) { return update() }

    return create()
  }

  const onError = (response) => {
    setMessages([]);
    setProcessing(false);
    setErrors(Object.entries(response.errors).map(([_k,v]) => v));
  }

  const renderGender = () => {
    let gendersLabels = []

    props.genders.forEach((gender) => {
      gendersLabels.push(
        <label className="bullet-checkbox" key={gender[0]}>
          <input type="checkbox" value={gender[0]} name="audience[gender_ids][]"/>
          <span className="bullet" >{gender[1]}</span>
        </label>
      )
    })

    return(
      <div className='col-md-12'>
        <div className="form-check">
          <h2 className="row">
            <div className="col-auto">
              Genders
            </div>
            <div className="col text-right">
              <button className="btn btn-small btn-primary align-middle" onClick={toggleLgbt} type="button">
                Toggle LGBT
              </button>
            </div>
          </h2>
        </div>
        <div>
          {gendersLabels}
        </div>
      </div>
    )
  }

  return(
    <div className='container pb-5 admin-form-group'>
      <FrindowErrors errors={errors} />
      <div className='row'>
        <div className='col-md-2 form-group d-flex'>
          <label className='control-label'>Audience Name</label>
        </div>
        <div className='col-md-10'>
          <TextField
            type='text'
            value={name}
            maxLength={200}
            onChange={(e) => setName(e.currentTarget.value)} />
        </div>
      </div>
      <div className='row'>
        <div className='col-md-2 form-group d-flex'>
          <label className='control-label'>Age Range</label>
        </div>
        <div className='col-md-1 form-group d-flex'>
          <label className='control-label'>min</label>
        </div>
        <div className='col-md-1 form-group d-flex'>
          <label className='control-label'>max</label>
        </div>
      </div>
      <div className='row'>
        <div className='col-md-1 offset-md-2'>
          <Select
            options={ageRangeOptions}
            onChange={setMinAge}
            value={minAge}
            className={'form-input-cell'}
          />
        </div>
        <div className='col-md-1'>
          <Select
            options={ageRangeOptions}
            onChange={setMaxAge}
            value={maxAge}
            className={'form-input-cell'}
          />
        </div>
      </div>
      <div className='row'>
        {renderGender()}
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h2>
            Proxy Users
          </h2>
        </div>
        {
          props.proxy_settings.map((elem) => {
            return (
              <div className="col-md-3 offset-md-1 form-group d-flex" key={`key-${elem[1]}`}>
                <div className="form-input-cell">
                  <input type='radio' name='proxy_settings' className='form-check-input' value={elem[1]} checked={proxySetting === elem[1]} onChange={(e) => setProxySetting(e.currentTarget.value)}/>
                </div>
                <label className='control-label' htmlFor='proxy_settings'>
                  {elem[0]}
                </label>
              </div>
            )
          })
        }
      </div>
      <div className='row'>
        <div className='col-md-12'>
          <h2>Groups</h2>
          <div className='col-md-6 form-multi-autocomplete'>
            <Select
              isMulti
              classNamePrefix={'autocomplete'}
              options={[{ label: 'All', value: 'all' }, ...groupValues()]}
              onChange={setSelectedGroups}
              value={selectedGroups}
              styles={customListHeightStyles}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h2>Keywords & Interests</h2>
          <div className='col-md-6 form-multi-autocomplete'>
            <Select
              isMulti
              options={keywordValues()}
              onChange={setSelectedKeywords}
              value={selectedKeywords}
              className={'form-input-cell'}
              classNamePrefix={'autocomplete'}
            />
          </div>
        </div>
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h3>Keywords and interests Match Type</h3>
        </div>
        {
          ['and', 'or'].map((match_type) => {
            return (
              <div className="col-md-2 offset-md-1 form-group d-flex" key={`key-${match_type}`}>
                <div className="form-input-cell">
                  <input type='radio' name='match_type' className='form-check-input' value={match_type} checked={match_type === keywordMatchType} onChange={(e) => setKeywordMatchType(e.currentTarget.value)}/>
                </div>
                <label className='control-label' htmlFor='match_type'>
                  {match_type.toUpperCase()}
                </label>
              </div>
            )
          })
        }
      </div>

      <div className='row'>
        <div className='col-md-12'>
          <h2>Languages</h2>
          <div className='col-md-6 form-multi-autocomplete'>
            <Select
              isMulti
              options={languageValues()}
              onChange={setSelectedLanguages}
              value={selectedLanguages}
              className={'form-input-cell'}
              classNamePrefix={'autocomplete'}
            />
          </div>
        </div>
      </div>

      <br></br>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-actions text-left'>
            <button type='submit' onClick={handleClickSave} className='btn btn-primary btn-big btn-plane btn-submit'>
              Save Audience
            </button>
          </div>
        </div>
      </div>
      <FrindowNotification messages={messages}
                           errors={errors}
                           clearNotices={() => {
                             setErrors([])
                             setMessages([])
                           }}
      />
      <br></br>
      {
        props.total_count != null && (
          <div className='row'>
            <div className='col-md-12'>
              <h3>
                { `Total Count: ${props.total_count}` }
              </h3>
            </div>
          </div>
        )
      }
    </div>
  )
}
