import React from 'react'
import TextField from '../shared/text_field';
import CheckBoxField from '../shared/checkbox_field'
import Container from './container'
import Button from '../shared/button'
import Modal from '../shared/modal'

export default class PasswordFields extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      directTermsChecked: false,
      proxyTermsChecked: false,
      openModal: false
    }

    this.privacyPolicy = "<a className='text-blue-ui' href='/pages/privacy' target='_blank'>Privacy Policy</a>"
    this.termsLabel = `By clicking on Sign Up, you agree to our <a class='text-blue-ui' href='/pages/terms' target='_blank'>Terms of Service</a> and ​<a class='text-blue-ui' href='/pages/acceptable_use' target='_blank'>Acceptable Use
    Policy</a>. Learn how we collect, use and share your personal data, in our ${this.privacyPolicy}.`
    this.proxyModalContent = `By using the site on behalf of someone else, you will be acting as a Proxy User. You must get consent to use the site on that person’s behalf. If you don’t have consent now, you can rejoin the site again later. Please see the Proxy User section in our ${this.privacyPolicy}`
  }

  handleDirectTermsChange = (e) => {
    const termsChecked = e.target.checked && this.state.proxyTermsChecked

    this.setState({
      directTermsChecked: e.target.checked
    })

    if(!e.target.checked || termsChecked) { this.props.handleTermsChange(e) }
  }

  handleProxyTermsChange = (e) => {
    const termsChecked = e.target.checked && this.state.directTermsChecked

    this.setState({
      proxyTermsChecked: e.target.checked
    })

    if(!e.target.checked || termsChecked) { this.props.handleTermsChange(e) }
  }

  btnDisabled = () => {
    const {
      password, passwordConfirmation, termsChecked,
      passwordErrorText, passwordConfirmationErrorText
    } = this.props

    return(
      !password || !passwordConfirmation || !termsChecked ||
      passwordErrorText || passwordConfirmationErrorText
    )
  }

  proxyTermsLabel = () => {
    return(
      <div>
        You have explicit consent from your {this.props.proxyRelationship} to use Frindow on their behalf.
        &nbsp;<a className='text-blue-ui' onClick={this.handleProxyTermsLabel}>Click here to find out more</a>.
      </div>
    )
  }

  handleProxyTermsLabel = (e) => {
    e.preventDefault()

    this.setState({openModal: true})
  }

  jointAccountCheckboxes = () => {
    const { handleDirectTermsChange, handleProxyTermsChange, termsLabel, proxyTermsLabel } = this
    const { accountType, termsErrorText } = this.props

    if(accountType === 'direct') return null;

    const { directTermsChecked, proxyTermsChecked } = this.state

    return(
      <React.Fragment>
        <CheckBoxField
          value={directTermsChecked}
          errorText={!directTermsChecked ? termsErrorText : ''}
          onChange={handleDirectTermsChange}
          id={'acceptDirectTerms'}
          labelDesc={<div dangerouslySetInnerHTML={{__html: termsLabel.split('&amp;').join('&')}} />}
          name='user[terms]'
        />

        <CheckBoxField
          value={proxyTermsChecked}
          errorText={!proxyTermsChecked ? termsErrorText : ''}
          onChange={handleProxyTermsChange}
          id={'acceptProxyTerms'}
          labelDesc={proxyTermsLabel()}
          name='user[terms]'
        />
      </React.Fragment>
    )
  }

  termsLabelDesc = () => {
    const { accountType } = this.props

    if(accountType === 'direct') {
      return(<div dangerouslySetInnerHTML={{__html: this.termsLabel.split('&amp;').join('&')}}/>)
    }
  }

  signUpBtn = () => {
    return(
      <Button
        key='key_sign_up_btn'
        className='btn-primary btn-submit' title='Sign Up'
        onClick={this.props.handleSignUpClick}
        disabled={this.btnDisabled()}
      />
    )
  }

  render = () => {
    const { signUpBtn, termsLabelDesc, jointAccountCheckboxes, proxyModalContent } = this
    const {
      password, step, passwordErrorText, accountType, termsChecked,
      passwordConfirmation, passwordConfirmationErrorText, handlePasswordConfirmationChange,
      termsErrorText, handlePasswordChange, handleTermsChange
    } = this.props
    const { openModal } = this.state

    return (
      <Container
        customClass='step-set-passoword'
        step={step}
        actionButton={signUpBtn()}
        headerText={`We take your security very seriously.`}
        descText={`Help us by making your password a good one (a mixture of big and small letters with numbers and even punctuation all helps). Don’t forget to make it easy to remember!`}
      >
        <h2>Nearly there, just need to set a password</h2>

        <div className='form'>
          <TextField
            type='password'
            value={password}
            errorText={passwordErrorText}
            placeholder='Password'
            onChange={handlePasswordChange}
            name='user[password]'
          />
          <TextField
            type='password'
            value={passwordConfirmation}
            errorText={passwordConfirmationErrorText}
            placeholder='Confirm password'
            onChange={handlePasswordConfirmationChange}
          />
          {jointAccountCheckboxes()}
        </div>
        <div className={accountType === 'direct' ? '' : 'd-none'}>
          <CheckBoxField
            value={termsChecked}
            errorText={accountType === 'direct' ? termsErrorText : ''}
            onChange={handleTermsChange}
            id={'acceptTerms'}
            labelDesc={termsLabelDesc()}
            name='user[terms]'
          />
        </div>

        {openModal &&
          <Modal id='proxyTermsOfService'
                 content={proxyModalContent}
                 onModalClose={() => this.setState({openModal: false})} />}
      </Container>
    )
  }
}
