import React from 'react'
import OrganiseInfo from './organise_info'
import ItemGetTogether from './item_get_together'

const getTogetherContainer = (props) => {
  const { get_together: getTogether, create_url: createUrl, permitted, site_gt: siteGt, user_confirmed: userConfirmed, confirmation_url: confirmationUrl, group } = props
  if(!permitted) return null;
  if(!getTogether) {
    return (
      <OrganiseInfo
        createUrl={createUrl}
        permitted={permitted}
        siteGt={siteGt}
        userConfirmed={userConfirmed}
        confirmationUrl={confirmationUrl}
        group={group} />
    )
  }
  return (
    <ItemGetTogether getTogether={getTogether} />
  )
}

export default getTogetherContainer;
