import React, { Component } from 'react'
import Button from '../shared/button'
import TextArea from '../shared/text_area'
import { makePutRequest, makePostRequest } from '../shared/api'
import FrindowNotification from '../shared/frindow_notification'

export default class Availability extends Component {
  constructor(props){
    super(props)

    this.state = {
      business_contact: this.props.business_contact,
      messages: [],
      errors: [],
    }
  }

  handleOnChange = (event) => {
    let { business_contact } = this.state
    business_contact[event.target.name] = event.target.value
    this.setState(
      { business_contact: business_contact }
    )
  }

  handleOnSave = () => {
    let { business_contact } = this.state

    this.updateContact().then((business_contact) => {
      if(business_contact.data.success){
        this.setState({ messages: ['Availability has been saved'], errors: [] })
      }else{
        this.setState({errors: business_contact.data.errors})
      }
    });
  }

  updateContact = () => {
    let { business_contact } = this.state

    let params = {
      business_contact: {
        availability: business_contact.availability,
        profile_id: business_contact.profile_id,
      }
    }

    if(business_contact.id){
      return makePutRequest({ url: `/business_contacts/${business_contact.id}`, body: params })
    }else{
      return makePostRequest({ url: `/business_contacts`, body: params })
    }
  }

  render = () => {
    const { business_contact, errors } = this.state

    return(
      <div className='form my-4'>
        <div className='form'>
        <label>Please describe your general availability or give office hours</label>
        <TextArea
          className='form-control'
          classForm={''}
          value={business_contact.availability || ''}
          onChange={this.handleOnChange}
          name='availability'
          type='text'
          errorText={errors.availability} />
        </div>

        <div className='form-actions mt-4'>
          <Button
          className='button btn btn-big btn-secondary btn-submit'
          title='Save'
          onClick={this.handleOnSave}
          />
        </div>

        <FrindowNotification errors={this.state.errors} messages={this.state.messages} clearNotices={() => this.setState({ errors: [], messages: [] })} />
      </div>
    )
  }
}
