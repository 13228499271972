import React, { useState } from 'react'

const SiteGroupsSelector = (props) => {
  const [showMore, setShowMore] = useState(false)
  const { siteGroups, userGroups, selectedGroupIds, handleSelectGroup } = props
  return(
   <div className='groups-tags text-left'>
     <h4>Users in groups:</h4>
     <ul className={`group-tags-list ${showMore ? 'is-open' : ''}`}>
      {userGroups.map(gr =>{
        return(
          <li key={gr[1]}>
            <a href='#' onClick={handleSelectGroup} group-id={gr[1]} className={`group-tag ${selectedGroupIds.includes(gr[1]) ? 'selected' : ''}`}>{gr[0]}</a>
          </li>
        )}
      )}
      {showMore && siteGroups.map(gr =>{
        return(
          <li key={gr[1]}>
            <a href='#' onClick={handleSelectGroup} group-id={gr[1]} className={`group-tag ${selectedGroupIds.includes(gr[1]) ? 'selected' : ''}`}>{gr[0]}</a>
          </li>
        )}
      )}
    </ul>
     <button className='btn btn-small btn-secondary' onClick={() => setShowMore(!showMore)}>
       {`SHOW ${showMore ? 'LESS' : ' MORE'}`}
     </button>
  </div> 
  )
}

export default SiteGroupsSelector
