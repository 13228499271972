import React, { Component } from 'react'
import TextField from '../shared/text_field'
import SelectField from '../shared/select_field'
import { countries } from '../../utils/countries'

export default class BillingDetails extends Component {
  constructor(props) {
    super(props)

    this.state = {
      payment: this.props.payment
    }
  }

  handleOnChange = (event) => {
    let {payment, errors} = this.state
    payment[event.target.name] = event.target.value
    this.setState({payment})
  }

  render = () => {
    const {payment} = this.state
    const {errors} = this.props

    return (
      <React.Fragment>
      <div>
        <TextField
          className='form-control'
          value={payment.first_name}
          onChange={this.handleOnChange}
          name='first_name'
          placeholder='First name'
          errorText={errors.first_name}
          classForm='w-100'/>
        <TextField
          className='form-control'
          value={payment.last_name}
          onChange={this.handleOnChange}
          name='last_name'
          placeholder='Last name'
          errorText={errors.last_name}
          classForm='w-100'/>
        <TextField
          className='form-control'
          value={payment.address_line1}
          onChange={this.handleOnChange}
          name='address_line_1'
          placeholder='Address Line 1'
          errorText={errors.address_line_1}
          classForm='w-100'/>
        <TextField
          className='form-control'
          value={payment.address_line2}
          onChange={this.handleOnChange}
          name='address_line_2'
          placeholder='Address Line 2'
          errorText={errors.address_line_2}
          classForm='w-100'/>
        <TextField
          className='form-control'
          value={payment.town}
          onChange={this.handleOnChange}
          name='town'
          placeholder='Town'
          errorText={errors.town}
          classForm='w-100'/>
        <div className='form-group'>
          <SelectField
            collection={countries()}
            value={payment.country}
            onChange={this.handleOnChange}
            name='country'
            placeholder='Country'
            emptyKey='Country'
            errorText={errors.country}
            classForm='w-100'
            preserveFormating={true} />
        </div>
        <TextField
          className='form-control'
          value={payment.post_code}
          onChange={this.handleOnChange}
          name='post_code'
          placeholder='Post code'
          errorText={errors.post_code}/>
      </div>
      </React.Fragment>
    )
  }
}

