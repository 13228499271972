import React, { useState } from 'react'
import { makePostRequest, makeGetRequest } from '../../shared/api.js'
import i18n from '../../../utils/i18n'
import FrindowModal from '../../shared/frindow_modal'

const PublishLink = (props) => {
  const [published, setPublished] = useState(props.published)
  const [showModal, setShowModal] = useState(false)

  const handlePublish = () => {
    const publishUrl = `/client_profiles/${props.profile.id}/publish`
    makePostRequest({ url: publishUrl, body: {} })
      .then((response) => {
        if (response.data.errors == null) {
          closeModal()
          publishSummaryRedirect(response.data.redirect)
        } else {

        }
      })
  }

  const handleClick = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const publishSummaryRedirect = (redirect) => {
    window.location.replace(redirect)
  }

  return (
    <>
    { !props.published && props.fillCompletely &&
      <div className={props.customClasses ? '' : 'position-relative'}>
        <button type='button' className={props.customClasses ? props.customClasses : 'notification'} onClick={handleClick}>
          {!props.noIcon && <i className={`icon ${props.customClasses ? 'icon icon-check-in-circle' : 'icon-alert-circle'}`}/>}
          {props.linkText}
        </button>
        {!props.customClasses && <button
          type='button'
          className='icon icon-cross'
          onClick={props.handleNotificationClose}
        />}
        <FrindowModal
          title='Confirm'
          isOpen={showModal}
          closeModal={closeModal}
        >
        Are you sure that you want to publish your profile?

        <br/>
        <div className="row mx-0 justify-content-center">
          <div className="col-auto"><button onClick={handlePublish} className='btn btn-primary m-2'>Yes</button></div>
          <div className="col-auto"><button onClick={closeModal} className='btn btn-danger m-2'>Cancel</button></div>
        </div>
        </FrindowModal>
      </div>
    }
    { ((props.published && !props.showNotification) || !props.fillCompletely) &&
      <button className="btn btn-primary" disabled>
        <i className="icon icon icon-check-in-circle mr-1" />
        {props.linkText}
      </button>
    }
    </>

  )
}
export default PublishLink
