import React from 'react'
import ReportContainer from '../reported_content/container'

export default class Report extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      openReportModal: false,
      isReported: props.is_reported
    }
  }

  renderReportButton = () => {
    if(this.state.isReported) { return(<span>Reported</span>) }

    return(<a className='btn-report' onClick={() => this.setState({openReportModal: true})}>Report this profile</a>)
  }

  handleReportModalSubmit = (props) => {
    this.setState({openReportModal: false, isReported: props.status})
  }

  handleReportModalClose = () => {
    this.setState({openReportModal: false})
  }

  render = () => {
    const { renderReportButton, handleReportModalClose, handleReportModalSubmit, props, state } = this
    const { object_id } = props
    const { openReportModal } = state
    return(
      <React.Fragment>
        {renderReportButton()}
        {openReportModal && 
          <ReportContainer  onReportModalClose={handleReportModalClose}
                            objectId={object_id} 
                            objectType='Profile' 
                            onReportModalSubmit={handleReportModalSubmit} />}
      </React.Fragment>
    )
  }
}
