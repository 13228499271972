import React from 'react'
import ItemImage from './item_image'

class ListImages extends React.PureComponent {
  renderImages = () => {
    return (this.props.images.map((image) => {
              return (<ItemImage
                        key={`image_${image.id}`}
                        imageUrl={image.imageUrl}
                        fullUrl={image.fullUrl}
                        authorUrl={image.authorUrl}
                        authorName={image.authorName}
                        selectedImage={this.props.selectedImage.unsplash_id === image.id}
                        handleImageChange={this.props.handleImageChange}
                        id={image.id} />)
          }))
  }

  render = () => {
    return(
      <div className='row mx-0 get-togethers-list'>
        {this.renderImages()}
      </div>
    )
  }
}

export default ListImages
