import Wave from 'wave.svg'
import Smile from 'smile.svg'
import Hug from 'hug.svg'
import Handshake from 'handshake.svg'
import WaveAlt from 'wave-alt.svg'
import SmileAlt from 'smile-alt.svg'
import HugAlt from 'hug-alt.svg'
import HandshakeAlt from 'handshake-alt.svg'

export const quickMessageIcons = (message_kind, from_you) => {
  if (from_you) {
    switch(message_kind) {
      case 'wave':
        return(WaveAlt)
      case 'handshake':
        return(HandshakeAlt)
      case 'supportive_hug':
        return(HugAlt)
      case 'smile':
        return(SmileAlt)
    }
  } else {
    switch(message_kind) {
      case 'wave':
        return(Wave)
      case 'handshake':
        return(Handshake)
      case 'supportive_hug':
        return(Hug)
      case 'smile':
        return(Smile)
    }
  }
}

export const definedContent = (message_kind, content, from_you) => {
  if(message_kind == null || message_kind === 'system_note') return content 

  if(from_you) {
    return(`You have sent a ${message_kind.replace('_', ' ')}`)
  }
  return(`You received a ${message_kind.replace('_', ' ')}`)
}
