import React, { useEffect, useState } from 'react'
import IntroductoryPage from './introductory_page'
import QuestionnairePage from './questionnaire_page'
import CompletedPage from './completed_page'
import { makePostRequest, makeGetRequest } from '../shared/api'
import { nextQuestionPath } from '../shared/routes'
import FrindowNotification from '../shared/frindow_notification'

export default function QuestionnaireContainer (props) {
  const [questionnaireState, setQuestionnaireState] = useState('');
  const [showIntro, setShowIntro] = useState(true);
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [question, setQuestion] = useState();

  const onError = (response) => {
    setErrors(Object.entries(response.errors).map(([_k,v]) => v));
  }

  const startQuestionnaire = () => {
    makeGetRequest({url: props.startQuestionnaireUrl}).then((response)=>{
      const { data } = response

      setQuestion(data.question)
      setQuestionnaireState(data.questionnaireState)
    })
    setShowIntro(false)
  }

  const nextQuestion = (params, continueLater) => {
    makePostRequest({url: nextQuestionPath(question.ordinalNumber), body: params }).then((response)=> {
      if (continueLater) {
        setMessages(['Your answers have been saved.'])
        setTimeout(window.location.replace('/'), 1500)
      } else {
        const { data } = response

        setQuestion(data.question)
        setQuestionnaireState(data.questionnaireState)
      }
    }).catch((response) => onError(response))
  }

  const previousQuestion = () => {
    makeGetRequest({url: `${props.previousQuestionUrl}?ordinal=${question.ordinalNumber}`}).then((response)=>{
      const { data } = response

      setQuestion(data.question)
    })
  }

  useEffect(() => {
    setQuestionnaireState(props.questionnaireState)
    setQuestion(props.question)
  }, [])

  const renderPage = () => {
    if(questionnaireState == 'completed' || questionnaireState == 'done'){
      return <CompletedPage categories={props.categories} questionnaireState={questionnaireState}/>
    }
    if(!question) return null;

    if(showIntro){
      return <IntroductoryPage categories={props.categories}
                               startQuestionnaire={startQuestionnaire}
                               ordinal={question.ordinalNumber}/>
    } else {
      return <QuestionnairePage questionsCount={props.questionsCount}
                                categories={props.categories}
                                nextQuestion={nextQuestion}
                                previousQuestion={previousQuestion}
                                question={question}/>
    }
  }

  return (
    <>
      { renderPage() }
      <FrindowNotification messages={messages}
                           errors={errors}
                           clearNotices={() => {
                             setErrors([])
                             setMessages([])
                           }}
      />
    </>
  )
}
