import React from 'react'

const Modal = React.memo(({ title, onModalClose, content }) => {
  return(
    <div className='r-modal' tabIndex='-1' role='dialog'>
      <div className='r-modal-content wide'>
        <div className='r-modal-header no-border'>
          <h4 className='h1 modal-title text-left'>{title}</h4>
          <button type='button' aria-label='Close' className='btn btn-close' onClick={onModalClose}/>
        </div>
        <div className='r-modal-body' dangerouslySetInnerHTML={{__html: content.split('&amp;').join('&')}}/>
        <div className='r-modal-footer'>
          <div className='row'>
            <div className='col-12 col-sm text-right'>
              <button className='btn btn-secondary btn-big btn-cancel' onClick={onModalClose}>
                Close
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
})

export default Modal;
