import React, { useState, useEffect } from 'react'
import TextField from '../../shared/text_field'
import FrindowNotification from '../../shared/frindow_notification'
import FrindowErrors from '../../shared/frindow_errors'
import Select from 'react-select'
import { makePostRequest, makePutRequest, makeGetRequest } from '../../shared/api'

export default function EventHostContainer (props) {
  const [errors, setErrors] = useState([]);
  const [messages, setMessages] = useState([]);
  const [processing, setProcessing] = useState(false);

  const labelValue = (value) => ({ label: value, value: value });
  const venueTypesValues = props.venueTypes.map((obj) => ({ label: obj[0], value: obj[1] }))
  const eventTypesValues = props.eventTypes.map((obj) => ({ label: obj[0], value: obj[1] }))
  const weekDaysValues = props.weekDays.map((obj) => ({ label: obj[0], value: obj[1] }))

  const [username, setUsername] = useState(props.eventHost.username || '');
  const [selectedVenueType, setSelectedVenueType] = useState(
    venueTypesValues.find((e) => e.value === props.eventHost.venue_type_id)
  );
  const [venuesValues, setVenuesValues] = useState([]);
  const [selectedVenue, setSelectedVenue] = useState(props.eventHost.venue_id);
  const [selectedEventType, setSelectedEventType] = useState(
    eventTypesValues.find((e) => e.value === props.eventHost.event_type_id)
  );
  const [selectedDayOfWeek, setSelectedDayOfWeek] = useState(weekDaysValues[props.eventHost.day_of_week]);
  const [selectedHour, setSelectedHour] = useState(props.eventHost.hour && labelValue(props.eventHost.hour));
  const [selectedMinute, setSelectedMinute] = useState(props.eventHost.minute && labelValue(props.eventHost.minute));
  const [selectedLiveStatus, setSelectedLiveStatus] = useState(props.eventHost.live_status || false);

  const hourOptions = Array.from({ length: 24 }, (v, k)=> ({ label: k.toString().padStart(2, '0'), value: k }));
  const minuteOptions = Array.from({ length: 12 }, (v, k)=> ({ label: (k * 5).toString().padStart(2, '0'), value: k * 5 }));

  useEffect(() => {
    if(selectedVenueType) {
      makeGetRequest(
        { url: `${props.venuesUrl}?venue_type_id=${selectedVenueType.value}` }
        ).then((res) => {
          setVenuesValues(res.data.map((obj) => ({ label: obj[0], value: obj[1] })))
        }).catch(response => onError(response))
      }
    }, [selectedVenueType, setSelectedVenueType])

    useEffect(() => {
      if(props.eventHost.venue_id) {
        setSelectedVenue(venuesValues.find((e) => e.value === props.eventHost.venue_id))
      }
    }, [venuesValues])

    const formBody = () => {
      return {
        event_host: {
          username: username,
          venue_id: selectedVenue && selectedVenue.value,
          event_type_id: selectedEventType && selectedEventType.value,
          day_of_week: selectedDayOfWeek && selectedDayOfWeek.value,
          time: `${selectedHour?.value}:${selectedMinute?.value}`,
          live_status: selectedLiveStatus
        }
      }
    }

  const create = () => {
    makePostRequest({ url: props.eventHostUrl,
                      body: formBody() }).then((res) => {
                        setErrors([]);
                        setProcessing(false);
                        setMessages([res.data.message]);
                        window.location = res.data.redirect_url
                      }).catch(response => onError(response))
  }

  const update = () => {
    makePutRequest({ url: `${props.eventHostUrl}/${props.eventHost.id}`,
                      body: formBody() }).then((res) => {
                        setErrors([]);
                        setProcessing(false);
                        setMessages([res.data.message]);
                        window.location = res.data.redirect_url
                      }).catch(response => onError(response))
  }

  const handleClickSave = (e) => {
    e.preventDefault()

    if(processing) { return null }

    setProcessing(true)

    if(props.eventHost.id != null) { return update() }

    return create()
  }

  const onError = (response) => {
    setMessages([]);
    setProcessing(false);
    setErrors(Object.entries(response.errors).map(([_k,v]) => v));
  }

  return(
    <div className='container pb-5 admin-form-group'>
      <FrindowErrors errors={errors}/>
      <div className='row'>
        <div className='col-3 form-group d-flex'>
          <label className='control-label'>Host username</label>
        </div>
        <div className='col-9'>
          <TextField
            type='text'
            value={username}
            maxLength={200}
            onChange={(e) => setUsername(e.currentTarget.value)} />
        </div>
      </div>
      <div className='row'>
        <div className='col-3 form-group d-flex'>
          <label className='control-label'>Venue Type</label>
        </div>
        <div className='col-9'>
          <Select
            options={venueTypesValues}
            onChange={setSelectedVenueType}
            value={selectedVenueType}
            className={'form-input-cell'}
            placeholder={'Choose'}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-3 form-group d-flex'>
          <label className='control-label'>Venue</label>
        </div>
        <div className='col-9'>
          <Select
            options={venuesValues}
            onChange={setSelectedVenue}
            value={selectedVenue}
            className={'form-input-cell'}
            placeholder={'--'}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-3 form-group d-flex'>
          <label className='control-label'>Event Type</label>
        </div>
        <div className='col-9'>
          <Select
            options={eventTypesValues}
            onChange={setSelectedEventType}
            value={selectedEventType}
            className={'form-input-cell'}
            placeholder={'Choose'}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-3 form-group d-flex'>
          <label className='control-label'>Day of the week</label>
        </div>
        <div className='col-9'>
          <Select
            options={weekDaysValues}
            onChange={setSelectedDayOfWeek}
            value={selectedDayOfWeek}
            className={'form-input-cell'}
            placeholder={'Choose'}
          />
        </div>
      </div>
      <div className='row'>
        <div className='col-3 form-group d-flex'>
          <label className='control-label'>Time</label>
        </div>
        <div className='col-2'>
          <Select
            options={hourOptions}
            onChange={setSelectedHour}
            value={selectedHour}
            className={'form-input-cell'}
            placeholder={'Hour'}
            defaultValue={{ label: "Hour", value: -1 }}
          />
        </div>
        <div className='col-2'>
          <Select
            options={minuteOptions}
            onChange={setSelectedMinute}
            value={selectedMinute}
            className={'form-input-cell'}
            placeholder={'Minute'}
            defaultValue={{ label: "Minute", value: -1 }}
          />
        </div>
      </div>

      <div className='row'>
        <div className='col-3 form-group d-flex'>
          <label className='control-label'>Status</label>
        </div>
        <div className="col-2 form-group d-flex radio-group" key={`key-live`}>
          <div className="form-input-cell">
            <input type='radio' name='live_status' className='form-check-input' value={true} checked={selectedLiveStatus === true} onChange={(e) => setSelectedLiveStatus(e.currentTarget.value === 'true')}/>
          </div>
          <label className='control-label'>
            Active
          </label>
        </div>
        <div className="col-2 form-group d-flex radio-group" key={`key-not-live`}>
          <div className="form-input-cell">
            <input type='radio' name='live_status' className='form-check-input' value={false} checked={selectedLiveStatus === false} onChange={(e) => setSelectedLiveStatus(e.currentTarget.value === 'true')}/>
          </div>
          <label className='control-label'>
            Not Active
          </label>
        </div>
      </div>

      <div className='row'>
        <div className='col-12'>
          <div className='form-actions text-left'>
            <button type='submit' onClick={handleClickSave} className='btn btn-primary btn-big btn-plane btn-submit'>
              Save
            </button>
          </div>
        </div>
      </div>
      <FrindowNotification messages={messages}
                           errors={errors}
                           clearNotices={() => {
                             setErrors([])
                             setMessages([])
                           }}
      />
    </div>
  )
}
