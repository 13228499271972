import React from 'react'

const ConversationImage = React.memo((props) => {
  const { profile_path, linkclassName, url, deleted, system = false } = props

  if(deleted || system) {
    return (
      <p className={linkclassName}><img src={url} className='img-fluid' /></p>
    )
  }

  return (
    <a href={profile_path} className={linkclassName}>
      <img src={url} className='img-fluid' />
    </a>
  )
})

export default ConversationImage
