import React from 'react'

const AmbassadorLink = ({ambassadorlink}) => {
  let link = (
    <a href={ambassadorlink}>
      <i className='symbol svg-icon icon-ambassador-badge grow-1' />
    </a>
  )
  if (ambassadorlink && ambassadorlink.trim().length < 1) {
    link = (
      <i className='symbol svg-icon icon-ambassador-badge grow-1' />
    )
  }
  return (
    <span className='user-badge ambassador' data-label-bottom='Ambassador'>
      {link}
    </span>
  )
}

export default AmbassadorLink
