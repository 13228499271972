import React from 'react'
import { withRouter } from "react-router-dom";
import { makeGetRequest, makePutRequest } from '../shared/api'
import _ from 'lodash'
import { ROUTES } from '../shared/routes'
import Select from 'react-select'
import CompletionHeading from '../shared/completion_heading'
import Container from '../sign_up_form/container'
import SelectField from '../shared/select_field'

class Details extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      user: null,
      gender: null,
      maritial_status: null,
      religion: null,
      children_status: null,
      genderError: '',
      maritialStatusError: '',
      religionError: '',
      languagesError: '',

      languages: [],
      user_languages: [],
      genders: [],
      religions: [],
      maritial_statuses: [],
      children_statuses: [],
      open_tos: [],

      dataFetched: false,
    }
  }

  componentDidMount = () => {
    this.fetchAdditionalInfo().then((response) => {
      const { data } = response
      if(data.success){
        this.setState({ ...data.options, ...data.profile_vals, dataFetched: true })
        this.setState({religion: data.options.religions[0]})
      }
    })
  }

  fetchAdditionalInfo = () => (
    makeGetRequest({ url: `/profiles/${this.props.profile.id}/additional_info` })
  )

  handleMaritialStatuses = (e) => {
    const maritial_status = _.find(this.state.maritial_statuses, (ms) => ms.name === e.target.value)
    this.setState({ maritial_status, maritialStatusError: '' })
  }

  handleGender = (e) => {
    const gender = _.find(this.state.genders, (g) => g.name === e.target.value)
    this.setState({ gender, genderError: '' })
  }

  handleReligion = (e) => {
    const religion = _.find(this.state.religions, (g) => g.name === e.target.value)
    this.setState({ religion, religionError: '' })
  }

  handleChildrenStatus = (e) => {
    const children_status = _.find(this.state.children_statuses, (cs) => cs.name === e.target.value)
    this.setState({ children_status, childrenStatusError: '' })
  }

  handleNextStep = () => {
    this.validate()
  }

  allErrorsEmpty = (errors) => (
    Object.values(errors).every((e) => e === '')
  )

  prepareRequestBody = () => {
    const { gender, religion, maritial_status, children_status, user_languages } = this.state
    return(
      {
        profile: {
          gender_id: gender.id,
          maritial_status_id: maritial_status.id,
          religion_id: religion.id,
          children_status_id: children_status.id,
          language_ids: user_languages.map((lang) => (lang.id))
        }
      }
    )
  }

  validate = () => {
    const { allErrorsEmpty, state } = this
    const { gender, maritial_status, religion, children_status, user_languages } = state
    const { account_type } = this.props.user
    let errors = {}

    const requiredMsg = 'Field is required'

    const genderError         = (gender === '' ? requiredMsg : '')
    const maritialStatusError = (maritial_status === '' ? requiredMsg : '')
    const religionError       = (religion === '' ? requiredMsg : '')
    const childrenStatusError = (children_status === '' ? requiredMsg : '')
    const languagesError      = (user_languages.length > 0 ? '' : requiredMsg)

    errors = { genderError, maritialStatusError, religionError, childrenStatusError, languagesError }
    if(allErrorsEmpty(errors)){
      Promise.all([this.requestUpdateProfile(), this.requestUpdateStep()]).then(([profile, user]) => {
        if(profile.data.success && user.data.success) {
          this.props.updateProfile({...this.props.profile, gender, maritial_status, religion, children_status })
          if(account_type === 'proxy') this.props.history.push(ROUTES.keywords.path)
          else this.props.history.push(ROUTES.open_to.path)
        }
      })
    } else {
      this.setState(errors)
    }
  }

  requestUpdateProfile = () => {
    return makePutRequest({ url: `/profiles/${this.props.profile.id}`, body: this.prepareRequestBody() })
  }

  requestUpdateStep = () => {
    const { user } = this.props
    const { id } = user

    return makePutRequest({ url: `/users/${id}`, body: { id, user: { current_step: ROUTES.keywords.step } }})
  }

  renderGender = () => {
    const { handleGender, state } = this
    const { gender, genders, genderError } = state

    return(
      <div className='form-group'>
        <label>Gender</label>
        <SelectField
          collection={_.map(genders, 'name')}
          value={gender ? gender.name : ''}
          onChange={handleGender}
          errorText={genderError}
        />
      </div>
    )
  }

  renderMaritialStatus = () => {
    const { state, handleMaritialStatuses } = this
    const { maritial_status, maritial_statuses, maritialStatusError } = state

    return(
      <div className='form-group'>
        <label>Marital Status</label>
        <SelectField
          collection={_.map(maritial_statuses, 'name')}
          value={maritial_status ? maritial_status.name : ''}
          onChange={handleMaritialStatuses}
          errorText={maritialStatusError}
        />
      </div>
    )
  }

  renderChildrenStatus = () => {
    const { state, handleChildrenStatus } = this
    const { children_status, children_statuses, childrenStatusError } = state

    return(
      <div className='form-group'>
        <label>Children status</label>
        <SelectField
          collection={_.map(children_statuses, 'name')}
          value={children_status ? children_status.name : ''}
          onChange={handleChildrenStatus}
          errorText={childrenStatusError}
        />
      </div>
    )
  }

  renderReligion = () => {
    return null

    // GDPR changes
    // const { state, handleReligion } = this
    // const { religion, religions, religionError } = state
    // return(
    //   <div className='form-group'>
    //     <label>Religion</label>
    //     <SelectField
    //       collection={_.map(religions, 'name')}
    //       value={religion ? religion.name : ''}
    //       onChange={handleReligion}
    //       errorText={religionError}
    //     />
    //   </div>
    // )
  }

  handleLanguageChange = (value) => {
    const user_languages = value.map((obj) => ({ id: obj.value, name: obj.label }))
    this.setState({ user_languages, languagesError: '' })
  }

  renderLanguages = () => {
    const options = this.state.languages.map((obj) => ({ label: obj.name, value: obj.id }))
    const value = this.state.user_languages.map((obj) => ({ label: obj.name, value: obj.id }))
    return (
      <div className="form-check form-multi-autocomplete">
        <label>Languages</label>
        <Select
                 isMulti
                 options={options}
                 onChange={this.handleLanguageChange}
                 value={value}
                 className={'form-input-cell'}
                 classNamePrefix={'autocomplete'}
               />
      </div>
    )
  }

  renderHeading = () => {
    return(
      <CompletionHeading
        headText='A little more detail'
        helperQuestion='Why do we need this?'
        helperText={'Helps other users learn a little about you and helps us to improve your Frindow experience.'}
      />
    )
  }

  render = () => {
    const { renderHeading, handleNextStep } = this
    const { gender, maritial_status, religion, children_status, user_languages } = this.state
    const nextDisabled = !gender || !maritial_status || !religion || !children_status || !user_languages.length
    return(
      <Container
        customClass='step-person-state'
        headerText='Groups'
        descText="No, it doesn’t have to be a rock group. Join any group that suits you. Giving us a little information helps us recommend groups that may be a good fit."
        handleNextClick={handleNextStep}
        nextDisabled={nextDisabled}>
        {renderHeading()}
        {this.state.dataFetched &&
          <div className="form">
            {this.renderGender()}
            {this.renderMaritialStatus()}
            {this.renderReligion()}
            {this.renderChildrenStatus()}
            <div className="form-check form-multi-autocomplete">
              {this.renderLanguages()}
            </div>
          </div>
        }
      </Container>
    )
  }
}

export default withRouter(Details)
