import React from 'react'
import TextField from '../../shared/text_field'
import i18n from '../../../utils/i18n'

const SwearWord = (props) => {
  const { index, handleChangeName, handleClickSave } = props
  const { name } = props.swearWord

  return(
    <tr>
      <td>{index}</td>
      <td>
        <TextField
          type='text'
          placeholder='swear word'
          classForm='w-100'
          value={name}
          name={index}
          onChange={handleChangeName}
        />
      </td>
      <td>
        <div className='row'>
          <div className='col-auto ml-auto'>
            <button
              className='btn btn-primary btn-big'
              type='submit'
              data-swearindex={index}
              onClick={handleClickSave} >
                {i18n.t('shared.save')}
            </button>
          </div>
        </div>
      </td>
    </tr>
  )
}

export default SwearWord
