import React from 'react'
import FrindowNotification from '../../shared/frindow_notification'
import { makePostRequest } from '../../shared/api.js'
import PlaceholderTagSelector from './placeholder_tag_selector'

export default class GroupPlaceholderTagsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      placeholderTagNames: props.allPlaceholderTags.map(a => a.tag_name),
      selectedPlaceholderTags: props.selectedPlaceholderTags,
      errors: [],
      messages: []
    }
  }


  handlePlaceholderTagChange = (e) => {
    e.stopPropagation()
    const { allPlaceholderTags } = this.props
    const { selectedPlaceholderTags } = this.state
    const selectedIndex = e.target.attributes['index'].value
    selectedPlaceholderTags[selectedIndex] = allPlaceholderTags.find(pl => pl.tag_name === e.target.value)
    this.setState({selectedPlaceholderTags: selectedPlaceholderTags})
  }

  handleClickSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { selectedPlaceholderTags } = this.state
    const { url, group_id } = this.props
    makePostRequest({ url: url, body: { group_id: group_id, placeholder_tags_ids: selectedPlaceholderTags.map(pl => pl.id) } })
                  .then((response) => {
                    if (response.data.errors == null) {
                      this.setState({errors: []})
                      window.location.href = url
                    }
                    else {
                      this.setState({errors: response.data.errors})
                    }
                  })
  }

  handleDeletePlaceholder = (e) => {
    const { selectedPlaceholderTags } = this.state
    const selectedIndex = parseInt(e.target.attributes['index'].value)
    selectedPlaceholderTags.splice(selectedIndex, 1)
    this.setState({selectedPlaceholderTags: selectedPlaceholderTags })
  }

  handleAddPlaceholder = () => {
    const { selectedPlaceholderTags } = this.state
    selectedPlaceholderTags.push(this.props.allPlaceholderTags[0])
    this.setState({selectedPlaceholderTags: selectedPlaceholderTags })
  }

  renderAddPlaceholderBtn = () => {
    if(this.state.selectedPlaceholderTags.find(a => a.tag_name ==='group_profiles')) { return null }

    return(
      <div className='footer mb-2'>
        <button type='button' className='btn btn-big btn-primary btn-add' onClick={this.handleAddPlaceholder}>Add Placeholder</button>
      </div>
    )
  }

  render = () => {
    const { handlePlaceholderTagChange, handleClickSubmit, handleDeletePlaceholder } = this
    const { placeholderTagNames , selectedPlaceholderTags } = this.state
    return(
      <div>
        {selectedPlaceholderTags.map((placeholderTag, index) => (
          <PlaceholderTagSelector
            key={index}
            index={index}
            last={selectedPlaceholderTags.length - 1 === index}
            placeholderTag={placeholderTag}
            placeholderTagNames={placeholderTagNames}
            handlePlaceholderTagChange={handlePlaceholderTagChange}
            handleDeletePlaceholder={handleDeletePlaceholder} />
        ))
        }
        {this.renderAddPlaceholderBtn()}
        <div className='footer'>
          <button type='button' className='btn btn-big btn-primary btn-add' onClick={handleClickSubmit}>Save</button>
        </div>
        <FrindowNotification errors={this.state.errors} messages={this.state.messages} clearNotices={() => this.setState({ errors: [], messages: [] })} />
      </div>
    )
  }
}
