import React, { useRef, useState, useEffect } from 'react';
import ClientCardProfile from '../client_card_profile'

const ClientsCarousel = ({ clientProfiles, type }) => {
  const nextButton = useRef(null)
  const prevButton = useRef(null)
  const [profiles] = useState(clientProfiles)
  const [showCarousel, setShowCarousel] = useState(profiles.length > 2)

  let carouselID = `client-${type.name.toLowerCase().split(' ').join('')}-${Math.random().toString(36).substring(7)}`;
  useEffect(() => {
    if (showCarousel) {
      $(`#${carouselID}`).slick({
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        dots: false,
        arrows: true,
        prevArrow: prevButton.current,
        nextArrow: nextButton.current,
        customPaging: '15px',
        responsive: [
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            }
          }
        ],
      });
    }
  }, [showCarousel])

  const carouselForMobile = () => {
    if (window.innerWidth <= 600 && profiles.length == 2) setShowCarousel(true)
    if (window.innerWidth > 600 && profiles.length == 2) setShowCarousel(false)
  }

  useEffect(() => {
    window.addEventListener('load', carouselForMobile)
    window.addEventListener('resize', carouselForMobile)

    return () => {
      window.addEventListener('load', carouselForMobile)
      window.addEventListener('resize', carouselForMobile)
    }
  }, [])

  return (
    <div className='mb-4'>
      <h2 className='search-title title-with-number mb-4'>{type.name}</h2>
      <div
        id={carouselID}
        className={`client-list pictorial-user-list ${profiles.length > 2 ? '' : 'row mx-0'}`}
      >
        {profiles.map((profile) => (
          <ClientCardProfile
            key={`profile-${profile.id}`}
            profile={profile}
            color={type.hex_code}
            fullHeight={showCarousel}
            single={profiles.length === 1}
            lazyLoading={false}
          />
        ))}
      </div>
      {showCarousel && (
        <div className='row justify-content-end mx-0'>
          <a href={`/services/${type.id}-${type.name.toLowerCase()}`} className='btn btn-secondary btn-small mr-auto'>
            See all {type.name} <i className='icon icon-a-right mx-2 font-weight-bold' />
          </a>
          <div className="row mx-0">
            <button ref={prevButton} className='btn text-gray'><i className='symbol icon-a-left' /></button>
            <button ref={nextButton} className='btn text-gray'><i className='symbol icon-a-right' /></button>
          </div>
        </div>
      )}
    </div>
  )
}

export default ClientsCarousel;
