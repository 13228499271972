import React, { Component } from 'react'
import FrindowModal from '../shared/frindow_modal'
import TextField from '../shared/text_field'
import Button from '../shared/button'

export default class AdminFormButton extends Component {
  constructor(props){
    super(props)
    this.state = {
      showModal: false,
    }
  }

  closeModal = () => this.setState({ showModal: false })

  handleSubmitBtn = (e) => {
    e.preventDefault()
    this.setState({ showModal: true })
  }

  onPasswordChange = (e) => document.getElementById('pass').value = e.target.value

  renderActions = () => (
    <div className='form-actions mt-4'>
      <Button
        className='button btn btn-big btn-secondary btn-cancel btn-submit mr-2'
        title='Cancel'
        onClick={this.closeModal}
      />
      <Button
        className='btn btn-big btn-primary btn-update btn-submit'
        title='Submit'
      />
    </div>
  )

  render = () => {
    return(
      <React.Fragment>
        <button onClick={this.handleSubmitBtn} className='button btn btn-primary btn-big btn-plane btn-submit'>
          Save
        </button>
        <FrindowModal
          isOpen={this.state.showModal}
          closeModal={this.closeModal}
          title='Authentication needed'
        >
          <TextField
            value={this.state.password}
            onChange={this.onPasswordChange}
            placeholder='Enter password'
            type='password'
          />
          {this.renderActions()}
        </FrindowModal>
      </React.Fragment>
    )
  }
}
