import React from 'react'
import Logo from '../../shared/logo'
import Button from '../../shared/button'
import ProgressBar from '../../shared/progress_bar'
import FrindowNotification from '../../shared/frindow_notification'
import PropTypes from 'prop-types'

const Container = ({ step, handleNextClick, children, nextDisabled, errors, messages, clearNotices,
                     customClass, headerText, descText, actionButton, business = false }) => {

  const actions = []

  if (handleNextClick || nextDisabled){
    actions.push(<Button key='key_next_btn'
                         className='btn-primary btn-next'
                         title='Next'
                         disabled={nextDisabled}
                         onClick={handleNextClick} />)
  }

  if (actionButton) actions.push(actionButton)

  const progressBar = (step ? <ProgressBar step={step} steps={3} /> : null)

  return (
    <section className={"sign-in-form full-height " + customClass}>
      <div className="container-fluid full-height">
        <div className='row bg-default full-height'>
          <div className='col-12 col-md-8 offset-md-2 offset-lg-0 col-lg-6 form-side full-height'>
            <div className={'step-' + step + ' lg-6-container a-right on-light'}>
              <Logo business={business}/>
              {progressBar}
              {children}
              <div className='form-actions'>{actions}</div>
            </div>
          </div>
          <div className='col-12 col-lg-6 join-frindow-slider full-height bg-green-accent'>
            <div className={`slick-slider ${business? 'business' : ''}`}>
              <div className='slide'>
                <div className='slide-content lg-6-container a-left on-green'>
                  <h1 className='slide-title'>{headerText}</h1>
                  <p>{descText}</p>
                </div>
              </div>
            </div>
            <div className='join-frindow-slider-dots'></div>
          </div>
          <FrindowNotification
            errors={errors}
            messages={messages}
            clearNotices={clearNotices}
          />
        </div>
      </div>
    </section>
  )
}

export default Container;

Container.propTypes = {
  step:             PropTypes.number,
  handleSubmitForm: PropTypes.func,
  nextDisabled:     PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  errors:           PropTypes.array,
  messages:         PropTypes.array,
  clearNotices:     PropTypes.func,
  customClass:      PropTypes.string,
  headerText:       PropTypes.string,
  descText:         PropTypes.string
}

Container.defaultProps = {
  step: null,
  nextDisabled: false,
  headerText:   'This helps us place you in the right groups.',
  descText:     'Cras justo odio, dapibus ac facilisis in, egestas eget quam. Maecenas sed diam eget risus varius blandit sit amet non magna.'
};
