import React from 'react'
import MiniProfile from '../profile/mini_profile'
import ProfileUrl from '../shared/profile/profile_url'
import script_google_ads from '../../utils/script_google_ads'

const InfoGetTogether = (props) => {
  const { description, place, address, present_date, max_participants, status, hostedBy, participants, advert, meeting_type } = props
  let infoParticipants = [
    <li key='participants' className='status status-max'>
      <span className='number'>{max_participants}</span> max attendees
    </li>
  ]

  React.useEffect(() => {
    const { advert } = props
    if(advert && advert.includes('adsbygoogle.js')) {
      script_google_ads()
    }
  }, [])

  participants.length
  return(
    <div className='row lg-flex-nowrap'>
      <div className='col-lg-6 col-xl-4'>
        <div className='row row-center'>
          <div className='col-12 col-md-6 col-lg-12 get-together-host'>
            <MiniProfile {...hostedBy.profile} sizeClass='size-lg' />
            <h3 className='host-title'>
              <span>Hosted by</span>
              <ProfileUrl
                profile_path={hostedBy.profile.profile_path}
                username={hostedBy.user.username}
                deleted={hostedBy.profile.deleted} />
            </h3>
          </div>
          <div className='col-12 col-md-6 col-lg-12'>
            <ul className='data get-together-status clean-list'>
              <li className='status status-icon status-on'>
                {status.replace('_', ' ')}
              </li>
              {infoParticipants}
            </ul>
          </div>
        </div>
        <div className='row justify-content-center pt-2'>
          {advert && <div dangerouslySetInnerHTML={{ __html: advert}} />}
        </div>
      </div>
      <div className='col flex-grow-1 get-togheter-details'>
        <div className='get-together-time-and-place on-light'>
          <div className='data'>
            <h3 className='data-label'>When</h3>
            <h2>{present_date}</h2>
            {
              meeting_type == 'in_person' && (
                <>
                  <h3 className='data-label'>Where</h3>
                  <h2>{place}, {address}</h2>
                </>
              )
            }
          </div>
          {(participants.length > 0) &&
            <div className='data'>
              <h3 className='data-label'>Attendees</h3>
              <div className='data-users'>
                {

                participants.filter((profile) => ( profile.profile.profile.profile_visible == true)).map((user) => (

                  <MiniProfile key={user.profile.profile_path} {...user.profile} />
                ))}
              </div>
            </div>
          }
          <div className='data data-about'>
            <h3 className='data-label'>About</h3>
            <div className='text wysiwyg-content'>
              <p dangerouslySetInnerHTML={{__html: description.split('&amp;').join('&')}} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoGetTogether;
