import React, { Component } from 'react'
import FrindowModal from '../shared/frindow_modal'
import FrindowNotification from '../shared/frindow_notification'
import { makePutRequest } from '../shared/api'

export default class Ban extends Component {
  constructor(props){
    super(props)
    this.state = {
      user: props.user,
      approval: props.approval || { message: '', admin_user_id: props.admin_id },
      isOpen: false,
      errors: [],
      messages: []
    }
  }

  banClick = (e) => {
    e.preventDefault()
    this.setState({ isOpen: true })
  }

  closeModal = () => this.setState({ isOpen: false })

  unbanClick = (e) => {
    e.preventDefault()
    const body = {
      user: {
        approval_attributes: { id: this.state.approval.id, _destroy: true }
      }
    }
    this.updateUserWithApproval(`User ${this.state.user.username} was unbanned successfully`, body)
  }

  handleMessageChange = (e) => this.setState({ approval: {...this.state.approval, message: e.target.value }})

  handleConfirmBanClick = (e) => {
    e.preventDefault()
    const body = {
      user: {
        approval_attributes: {...this.state.approval, status: 'banned'}
      }
    }
    this.updateUserWithApproval(`User ${this.state.user.username} was banned successfully`, body)
  }

  updateUserWithApproval = (feedback, body) => {
    makePutRequest({ url: `${this.props.url}/${this.state.user.id}`, body: body }).then(({ data }) => {
      if(data.success) {
        const approval = data.approval ? data.approval : { message: '', admin_user_id: this.props.admin_id }
        this.setState({ isOpen: false, messages: [feedback], approval })
      } else {
        this.setState({ errors: errors })
      }
    })
  }

  renderBan = () => <a href='#' onClick={this.banClick}>ban</a>

  renderUnban = () => <a href='#' onClick={this.unbanClick}>unban</a>

  render = () => {
    return(
      <React.Fragment>
        {this.state.approval && this.state.approval.status === 'banned' ? this.renderUnban() : this.renderBan()}
        <FrindowModal
          isOpen={this.state.isOpen}
          closeModal={this.closeModal}
          title={`Ban ${this.state.user.username}`}
        >
          <div className='col-12 form text-left'>
            <div className='form-group'>
              <div className='form-input-cell'>
                <textarea
                  placeholder='Please add your message here'
                  value={this.state.approval.message}
                  onChange={this.handleMessageChange}
                >
                </textarea>
              </div>
            </div>
          </div>
          <div className='r-modal-footer'>
            <button onClick={this.closeModal} className='btn btn-big btn-secondary btn-cancel'>
              Cancel
            </button>
            <button onClick={this.handleConfirmBanClick} className='btn btn-big btn-primary btn-select'>
              Confirm
            </button>
          </div>
        </FrindowModal>
        <FrindowNotification errors={this.state.errors} messages={this.state.messages} clearNotices={() => this.setState({ errors: [], messages: []})} />
      </React.Fragment>
    )
  }
}
