import React, { Component } from 'react'
import { makePostRequest } from '../shared/api.js'

export default class Maintenance extends Component {
  constructor(props){
    super(props)
    this.state = {
      maintenance_mode: props.maintenance_mode,
    }
  }

  maintenanceModeChange = (e) => {
    const { value } = e.target
    const maintenance_mode = ( value === 'on' )

    makePostRequest({ url: 'maintenance/maintenance_mode', body: { maintenance: { mode: maintenance_mode } } })
      .then((response) => {
        const { data } = response
        if (data.success) this.setState({ maintenance_mode: data.mode })
      })
  }

  render = () => {
    const { maintenance_mode } = this.state
    return(
      <div className='container pb-5 admin-form-maintenance'>
        <h2>Maintenance mode</h2>
        <form>
          <div className='row mb-3'>
            <div className='col-auto'>
              <div className='form-input-cell radiobox-cell'>
                <input
                  type='radio'
                  id='maintenance_mode_on'
                  name='maintenance_mode'
                  value='on'
                  className='form-check-input'
                  onChange={this.maintenanceModeChange}
                  checked={maintenance_mode === true}
                />
                <label htmlFor="maintenance_mode_on"><span className="checkbox"></span>
                  ON
                </label>
              </div>
            </div>
            <div className='col-auto'>
              <div className='form-input-cell radiobox-cell'>
                <input
                  type='radio'
                  id='maintenance_mode_off'
                  name='maintenance_mode'
                  value='oof'
                  className='form-check-input'
                  onChange={this.maintenanceModeChange}
                  checked={maintenance_mode === false}
                />
                <label htmlFor="maintenance_mode_off"><span className='checkbox'></span>
                  OFF
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }
}
