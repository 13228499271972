import React, { Component } from 'react'
import FrindowNotification from '../shared/frindow_notification'
import { makePostRequest } from '../shared/api'
import i18n from '../../utils/i18n'

export default class QuickMessagesContainer extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors:   [],
      messages: []
    }
  }

  handleSendMessage = (e) => {
    e.preventDefault()
    const kind = e.currentTarget.dataset.kind
    makePostRequest({ url: this.props.createMessageUrl,
      body: { conversation: { user_id: this.props.userId, message_kind: kind, content: 'default' }} }).then((res) => {
      if(res.data.success) {
        this.setState({ messages: [res.data.message],  isFavourite: !this.state.isFavourite })
      } else {
        this.setState({ errors: res.data.errors })
      }
    })
  }

  clearNotices = () => {
    this.setState({ errors: [], messages: []})
  }

  render = () => {
    return(
      <React.Fragment>
        <h1 className='quick-msssages-title'>{i18n.t('shared.connect')}</h1>
        <div className='quick-messages'>
          <button className='quick-message wave' data-kind='wave' onClick={this.handleSendMessage}>
            <div className='icon wave' />
            <div className='icon wave-alt' />
            <span>{i18n.t('profile.quickMessages.wave').toUpperCase()}</span> 
          </button>
          <button className='quick-message smile' data-kind='smile' onClick={this.handleSendMessage}>
            <div className='icon smile' />
            <div className='icon smile-alt' />
            <span>{i18n.t('profile.quickMessages.smile').toUpperCase()}</span> 
          </button>
          <button className='quick-message hug' data-kind='supportive_hug' onClick={this.handleSendMessage}>
            <div className='icon hug' />
            <div className='icon hug-alt' />
            <span>{i18n.t('profile.quickMessages.supportiveHug').toUpperCase()}</span> 
          </button>
           <button className='quick-message handshake' data-kind='handshake' onClick={this.handleSendMessage}>
            <div className='icon handshake' />
            <div className='icon handshake-alt' />
            <span>{i18n.t('profile.quickMessages.handshake').toUpperCase()}</span> 
          </button>       
        </div>
        <FrindowNotification
          messages={this.state.messages}
          errors={this.state.errors}
          clearNotices={this.clearNotices}
        />
      </React.Fragment>
    )
  }
}
