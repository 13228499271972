import React from 'react'
import Select from 'react-select'
import FrindowNotification from '../../shared/frindow_notification'
import PackageRow from './package_row'
import TextField from '../../shared/text_field' 
import TextArea from '../../shared/text_area' 
import { makePostRequest, makePutRequest } from '../../shared/api'
import SimpleSelectField from '../../shared/simple_select_field'
import i18n from '../../../utils/i18n'

export default class PackagesSetsContainer extends React.Component {
  constructor(props) {
    super(props)

    const { name, notes, status } = props.packageSet

    this.state = {
      messages: [],
      errors: [],
      processing: false,
      name: name || '',
      notes: notes || '',
      clientTypes: props.clientTypes || [],
      selectedPackage: props.packagesOptions[0],
      selectedPackages: props.selectedPackages || [],
      selectedStatus: status || props.statuses[0]
    }
  }

  formBody = () => {
    const { selectedPackages, clientTypes, name, notes, selectedStatus } = this.state
    return {
      package_set: {
        selected_packages: selectedPackages,
        client_types: clientTypes,
        name: name,
        notes: notes,
        status: selectedStatus
      }
    }
  }

  create = () => {
    makePostRequest({ url: this.props.packageSetsUrl,
                      body: this.formBody() }).then((res) => {
                        this.setState({ messages: [res.data.message],
                                        processing: false,
                                        errors: [] })
                       window.location = this.props.packageSetsUrl
                      }).catch(response => this.onError(response))
  }

  update = () => {
    const { packageSet, packageSetsUrl } = this.props
    makePutRequest({ url: `${packageSetsUrl}/${packageSet.id}`,
                     body: this.formBody() }).then((res) => {
                       this.setState({ messages: [res.data.message],
                                       errors: [],
                                       processing: false })
                       window.location = packageSetsUrl
                      }).catch(response => this.onError(response))
  }

  handleClickSave = (e) => {
    e.preventDefault()
    if(this.state.processing) { return null }

    this.setState({processing: true})

    if(this.props.packageSet.id != null) { return this.update() }

    return this.create()
  }

  onError = (response) => {
    this.setState({
      messages: [],
      errors: Object.entries(response.errors).map(([_k,v]) => v),
      processing: false
    })
  }

  handleChangeName = (e) => {
    this.setState({ name: e.currentTarget.value })
  }

  handleChangeNotes = (e) => {
    this.setState({ notes: e.currentTarget.value })
  }

  handleClientTypesChange = (clientTypes) => {
    this.setState({clientTypes: clientTypes.map(o => o.value) })
  }

  clientTypeValues = () => this.state.clientTypes.map((obj) => ({ label: obj, value: obj}))

  clientTypeOptions = () => this.props.clientTypeOptions.map((obj) => ({ label: obj, value: obj}))

  handlePackageChange = (e) => {
    this.setState({selectedPackage: e.target.value })
    e.preventDefault()
  }

  onClickAddPackage = (e) => {
    const newPackages = this.state.selectedPackages.concat({ name: this.state.selectedPackage, position: 99 })
    this.setState({selectedPackages: newPackages})
    e.preventDefault()
  }

  handlePositionChange = (e) => {
    const target = e.currentTarget
    const index = target.getAttribute('index')
    const newPackages = this.state.selectedPackages
    newPackages[index].position = target.value
    this.setState({selectedPackages: newPackages})
  }

  renderSelectedPackages = () => {
    return(this.state.selectedPackages.map((selectedPackage, index) => {
      return(
        <PackageRow
          key={index}
          index={index}
          handlePositionChange={this.handlePositionChange}
          selectedPackage={selectedPackage} />
      )
    }))
  }

  handleStatusChange = (e) => {
    this.setState({selectedStatus: e.currentTarget.value})
  }
  
  render() {
    const { packagesOptions, statuses } = this.props
    const { selectedPackage, selectedStatus, name, notes } = this.state
    const { handlePackageChange, handleStatusChange, onClickAddPackage,
      renderSelectedPackages, handleClickSave, handleChangeName, handleChangeNotes } = this

    return(
      <div className='container pb-5'>
        <div className='row'>
          <div className='col-6'>
            <div className='form-group'>
              <label>Set name</label>
              <TextField 
                type='text'
                placeholder='name'
                value={name}
                onChange={handleChangeName} />
            </div>
            <div className='form-group'>
              <label>Client Types</label>
              <div className='form-multi-autocomplete'>
                <Select
                  isMulti
                  options={this.clientTypeOptions()}
                  onChange={this.handleClientTypesChange}
                  value={this.clientTypeValues()}
                  className={'form-input-cell'}
                  classNamePrefix={'autocomplete'}
                />
              </div>
            </div>
          </div>
          <div className='col-6'>
            <div className='form-group'>
              <label>Package</label>
              <div className='row'>
                <div className='col-8'>
                  <SimpleSelectField
                    collection={packagesOptions}
                    velue={selectedPackage}
                    onChange={handlePackageChange}
                  />
                  </div>
                <div className='col-4'>
                  <button onClick={onClickAddPackage} className='btn btn-primary btn-add'>
                    {i18n.t('shared.addNew')}
                  </button>
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='row'>
                <div className='col-8'>
                  <label>Package name</label>
                </div>
                <div className='col-4'>
                  <label>Order</label>
                </div>
              </div>
              {renderSelectedPackages()}
              <div className='row my-3'>
                <div className='col-3'>
                  <label>Live Status</label>
                </div>
                <div className='col-4'>
                  <SimpleSelectField
                    collection={statuses}
                    value={selectedStatus}
                    onChange={handleStatusChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='row'>
          <div className='col-12 form-wysiwyg'>
            <label>Notes</label>
            <TextArea
              onChange={handleChangeNotes}
              placeholder='Notes'
              value={notes} />
          </div>
        </div>
        <div className='row'>
          <div className='col-auto ml-auto'>
            <button
              className='btn btn-primary btn-big'
              type='submit'
              onClick={handleClickSave} >
                {i18n.t('shared.save')}
            </button>
          </div>
        </div>
        <FrindowNotification
          messages={this.state.messages}
          errors={this.state.errors}
          clearNotices={this.clearNotices}
        />
      </div>
    )
  }
}
