import React from 'react'
import ConversationsListItem from './conversations_list_item'
import InfiniteScroll from 'react-infinite-scroller'
import FrindowSpinner from '../shared/frindow_spinner'
import { adjustFloatingAdvertPosition, adjustConversationAdvertTopPosition } from '../../utils/advert_helpers'
import script_google_ads from '../../utils/script_google_ads'

const ConversationsList = (props) => {
  const { conversations, fetchConversations, isLastPage, advert, small_advert, handleClickOpenModal } = props

  React.useEffect(() => {
    adjustFloatingAdvertPosition()
    window.addEventListener('resize', adjustFloatingAdvertPosition)
    window.addEventListener('scroll', () => adjustConversationAdvertTopPosition('.conversations-horizontal-bar', 215))
    if(advert && advert.includes('adsbygoogle.js')) {
      script_google_ads()
    }
    if(small_advert && small_advert.includes('adsbygoogle.js')) {
      script_google_ads()
    }
  }, [])

  return(
    <div className='conversations-content on-light container'>
      <div className='conversations-list'>
        <InfiniteScroll
          pageStart={1}
          loadMore={fetchConversations}
          hasMore={!isLastPage}
          initialLoad={false}
          loader={<FrindowSpinner key={0} />}
          >
          {conversations.map((conversation) => (
            <ConversationsListItem
              conversation={conversation}
              key={conversation.id}
              handleClickOpenModal={handleClickOpenModal} />
          ))
          }
        </InfiniteScroll>
        <div className='floating-conversation-advert' >
          {advert && <div className='big-advert' dangerouslySetInnerHTML={{ __html: advert}} />}
          {small_advert && <div className='small-advert' dangerouslySetInnerHTML={{ __html: small_advert}} />}
        </div>
      </div>
    </div>
  )
}

export default ConversationsList
