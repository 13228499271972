import React, { useState, useEffect, useRef } from 'react'
import { makePostRequest } from '../../shared/api.js'
import FrindowModal from '../../shared/frindow_modal'
import ModalPortal from '../../shared/modal_portal'
import FrindowNotification from '../../shared/frindow_notification'

const CancelLink = (props) => {
  const [showLink, setShowLink] = useState(true)
  const [showModal, setShowModal] = useState(false)
  const [messages, setMessages] = useState([])
  const header = useRef()

  const handleOk = () => {
    const accountUrl = `/accounts/${props.account.id}/cancel`
    makePostRequest({ url: accountUrl, body: {} })
    .then((response) => {
      if (response.data.errors == null) {
        setShowLink(false)
        closeModal()
        setMessages(['Automatic rebilling has been cancelled.'])
      }
  })
  }

  const handleClick = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  const formatToCurrency = (number) => {
    return Intl.NumberFormat('en-EN', {style: 'currency', currency: 'GBP'}).format(number)
  }


  useEffect(() => {
    if (!showModal) return;
    header.current.closest('.r-modal-content').scrollIntoView({behavior: 'smooth'})
  }, [showModal])

  return (
    <>
        { showLink &&
          <div>
          <hr className="w-100 mt-2 mb-3" />
          <div className="text-muted mb-3 text-uppercase">
            Next Payment (Billed Automatically)
          </div>
          <div className="row mx-0 mb-2">
            {props.account_start_date}
            <div className="ml-auto">
              {formatToCurrency(props.account.package_amount)}
            </div>
          </div>

          <button
            type='button'
            onClick={handleClick}
            className='btn btn-secondary btn-small'
          >
            Cancel Rebilling
          </button>

          <ModalPortal modalClass='client-modal'>
            <FrindowModal
              isOpen={showModal}
              closeModal={closeModal}
              customClass='wide'
              title='You are about to cancel automatic payments'
            >
              <style>
                {`
                  .r-modal {
                    height: ${document.firstElementChild.offsetHeight}px
                  }
                `}
              </style>
              <div className="row flex-column align-items-center payment-status" ref={header}>
                <div className="col-auto mb-3">
                  <h3>The benefits of automatic rebilling area:</h3>
                  <div className="payment-status-list px-0 px-md-3 my-3 text-left">
                    <p>
                      <i className="icon icon-check-in-circle text-blue-ui mr-2" />
                      <span>Protected from plan price increases</span>
                    </p>
                    <p>
                      <i className="icon icon-check-in-circle text-blue-ui mr-2" />
                      <span>Hassle free maintenance of your plan</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3>Are you sure that you want to cancel your rebilling?</h3>
                  <div className="form-actions">
                    <button
                      className="btn font-weight-normal btn-primary-outline mr-2 mb-3"
                      title="No"
                      onClick={closeModal}
                    >NO, I'LL KEEP IT GOING</button>
                    <button
                      className="btn font-weight-normal btn-danger mb-3"
                      title="No"
                      onClick={handleOk}
                    >YES, CANCEL MY REBILLING</button>
                  </div>
                </div>
              </div>
            </FrindowModal>
          </ModalPortal>
          </div>
        }
        <FrindowNotification errors={[]} messages={messages} clearNotices={() => this.setState({ messages: [] })} />
    </>
  )
}

export default CancelLink
