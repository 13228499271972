import React, { useState, useRef } from 'react'
import TextField from '../../../shared/text_field'
import MiniProfile from '../../../profile/mini_profile'
import { makePostRequest, makeGetRequest } from '../../../shared/api.js'
import BeforeJoinGroup from '../../shared/before_join_group'
import { removeMultipleNewlines } from '../../../../utils/tools'
import PhotoModal from './photo_modal'
import FrindowNotification from '../../../shared/frindow_notification'
import CodeConfirmationAlert from '../../../shared/code_confirmation_alert'

const StartSubject = (props) => {
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')
  const [inGroup, setInGroup] = useState(false)
  const [open, setOpen] = useState(false)
  const [showModal, setModal] = useState(false)
  const [errors, setErrors] = useState({})
  const inputElement = useRef(null)
  const [fileName, setFileName] = useState(null)
  const [postPhoto, setPostPhoto] = useState(null)

  const onClick = () => {
    const { createSubjectUrl } = props
    setOpen(false)
    const postParams = { content: content }
    if (postPhoto) {
      Object.assign(postParams, { post_photo_id: postPhoto.id })
    }

    makePostRequest({ url: createSubjectUrl, body: { subject: { title: title, post: postParams } } })
      .then((response) => {
        if (response.data.errors == null) {
          const { url, createPostUrl, fetchSubjectPostsUrl, subjectId } = response.data
          inputElement.current.textContent = ''
          setContent('')
          setTitle('')
          setErrors({})
          setErrors({})
          setFileName('')
          setPostPhoto(null)
          if (typeof ga !== 'undefined') { ga('send', 'event', 'Post', 'created') }
          props.onCreatedSubject(createPostUrl, fetchSubjectPostsUrl, url, subjectId)
        }
        else {
          setErrors(response.data.errors)
        }
      })

  }

  const handleClickPost = (e) => {
    e.preventDefault()
    e.stopPropagation()
    userInGroup()
  }

  const handleClickPhoto = (e) => {
    e.preventDefault()
    e.stopPropagation()
    setErrors({})
    setModal(true)
  }

  const userInGroup = () => {
    makeGetRequest({ url: `${props.inGroupUrl}` })
      .then((response) => {
        setInGroup(response.data.in_group)
        setOpen(true)
      });
  }

  const renderMiniProfile = () => {
    const { username, avatar, profile_path, photo_url, profile } = props.profile
    const { deleted } = profile

    return (
      <div className='col-auto'>
        {<MiniProfile
          username={username}
          profile={profile}
          photo_url={photo_url}
          avatar={avatar}
          profile_path={profile_path}
          deleted={deleted}
          sizeClass='size-md' />}
      </div>
    )
  }

  const handleClickClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    setOpen(false)
  }

  const afterJoin = () => {
    setInGroup(true)
    if (typeof ga !== 'undefined') { ga('send', 'event', 'Group', 'user joined') }
  }

  const renderGroupForm = () => {
    if (!open) { return null; }
    if (!inGroup) {
      return (<BeforeJoinGroup
        infoText='You need to be a member of this group to create a new post.'
        join={props.urlJoinGroup}
        handleClickClose={handleClickClose}
        joined={false}
        afterJoin={afterJoin} />);
    }
    onClick()
    return null;
  }

  const addImage = (image, post_photo) => {
    setModal(false)
    setPostPhoto(post_photo)
  }

  const renderPhotoModal = () => {
    if (!showModal) { return null; }

    return <PhotoModal
      profile={props.profile}
      addImage={addImage}
      isUserConfirmed={props.profile.user_confirmed}
      isOpen={showModal}
      uploadStart={uploadStart}
      addError={addError}
      closePhotoModal={closePhotoModal} />
  }

  const closePhotoModal = () => {
    setModal(false)
  }

  const addError = (errors) => {
    setModal(false)
    setErrors(errors)
  }

  const renderErrors = () => {
    if (Object.keys(errors).length != 0) { return <p className="file-errors">{Object.values(errors).join()}</p> }
  }

  const uploadStart = (file) => {
    setFileName(file.name)
  }

  const renderFileName = () => {
    if (fileName) { return <p className="file-name">{fileName}</p> }
  }

  const allowSubmit = () => {
    return title.length === 0 || content.length === 0
  }

  const clearNotices = () => {
    setErrors({})
    setPostPhoto(null)
    setFileName('')
  }

  const renderForm = () => {
    return <form className='start-conversation-wrapper card border-0 p-4 mb-4 subject-wrapper'>
      <div className='row'>
        <div className='col'>
          <TextField
            type='text'
            placeholder='Topic title (max 40 characters)'
            classForm='w-100'
            name='topic_name'
            value={title}
            onChange={e => setTitle(e.target.value)}
            errorText={errors.title}
            maxLength='40'
          />
        </div>
      </div>
      <hr className='mt-0 mb-2' />
      <div className='row'>
        {renderMiniProfile()}
        <div className='col px-0'>
          <div
            ref={inputElement}
            contentEditable={true}
            placeholder='Start typing to begin a new conversation.'
            name='topic_body'
            className={`form-control ${errors.content ? 'is-invalid' : ''}`}
            onInput={e => setContent(removeMultipleNewlines(e.target.innerText.trim()))}
          />
          {errors.content && <p className='invalid-feedback'>{errors.content}</p>}
        </div>
      </div>
      <div className='row'>
        <div className='col-auto ml-auto'>
          {renderPhotoModal()}
          {!postPhoto && <button className='btn icon icon-camera text-muted' type='submit' onClick={handleClickPhoto} />}
          <button className='btn btn-plane btn-primary btn-big' type='submit' disabled={allowSubmit()} onClick={handleClickPost} >
            POST
          </button>
        </div>
      </div>
      <div className='row'>
        {renderFileName()}
        {renderErrors()}
      </div>
      {renderGroupForm()}
      <FrindowNotification
        errors={Object.values(errors)}
        clearNotices={clearNotices}
      />
    </form>
  }

  return props.canWrite ? renderForm() : CodeConfirmationAlert()
}
export default StartSubject
