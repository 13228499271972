import React from 'react'
import FrindowSpinner from '../shared/frindow_spinner'
import Message from './message'

export default class MessageList extends React.PureComponent {
  componentDidMount() {
    this.scrollToBottom()
  }

  componentDidUpdate(prevProps) {
    if(this.props.messages.length === prevProps.messages.length + 1) { this.scrollToBottom() }
  }

  scrollToBottom = () => {
    this.messagesEnd.scrollIntoView()
  }

  render = () => {
    const { messages, system_conversation, loading, report_url, forwardRef } = this.props
    return(
        <div className='conversation-messages' ref={forwardRef} >
          <div className='messages-list'>
          {loading && <FrindowSpinner />}
            {messages.map((message) => (
              <Message
                {...message}
                system_conversation={system_conversation}
                key={message.id}
                report_url={report_url} />
            ))}
          </div>
          <div style={{ float:'left', clear: 'both' }}
               ref={(el) => { this.messagesEnd = el }}>
          </div>
        </div>
    )
  }
}
