import React from 'react'
import { withRouter } from "react-router-dom";
import CreatableSelect from 'react-select/lib/Creatable'

import { makeGetRequest, makePostRequest, makePutRequest } from '../shared/api'
import Container from '../sign_up_form/container'
import CompletionHeading from '../shared/completion_heading'
import { ROUTES } from '../shared/routes'
import About from '../shared/profile/about'

class Keywords extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: null,
      keywords: [],
      available: []
    }
    this.mapKeywords = this.mapKeywords.bind(this)
  }

  componentDidMount = () => {
    const { props, fetchKeywords } = this
    const { profile, user } = props
    this.setState({ profile, user }, () => {
      fetchKeywords().then((res) => {
        const { data } = res
        if(data.success) {
          const keywords = this.mapKeywords(data.keywords)
          const available = this.mapKeywords(data.available)
          this.setState({ keywords, available })
        }
      })
    })
  }

  mapKeywords = (keywords) => keywords.map((obj) => ({ label: obj.name, value: obj.id }))

  fetchKeywords = () => {
    const { profile } = this.props
    return makeGetRequest({ url: `/profiles/${profile.id}/keywords` })
  }

  handleAbout = (e) => {
    const { profile } = this.state
    this.setState({ profile: {...profile, about_me: e.target.value} })
  }

  handleKeywordsChange = (value) => {
    this.setState({ keywords: value })
  }

  handleNextStep = () => {
    const { requestUpdateKeywords, requestUpdateProfile, requestUpdateStep } = this
    Promise.all([requestUpdateKeywords(), requestUpdateProfile(), requestUpdateStep()]).then(([keywords, profile, user]) => {
      if(keywords.data.success && profile.data.success && user.data.success) {
        this.props.updateProfile(this.state.profile)
        this.props.history.push(ROUTES.avatars.path)
      }
    })
  }

  requestUpdateProfile = () => {
    const { profile } = this.state
    return makePutRequest({ url: `/profiles/${profile.id}`, body: { about_me: profile.about_me }})
  }

  requestUpdateKeywords = () => {
    const { profile } = this.state
    const params = { id: profile.id, profile: { keywords: this.parseKeywords() }}

    return makePostRequest({ url: `/profiles/${profile.id}/update_profile_keywords`, body: params})
  }

  parseKeywords = () => {
    return this.state.keywords.map((k) => ({ name: k.label }))
  }

  renderKeywords = () => (
    <CreatableSelect
      isMulti
      value={this.state.keywords}
      onChange={this.handleKeywordsChange}
      className={'form-input-cell'}
      classNamePrefix={'autocomplete'}
      options={this.state.available}
    />
  )

  requestUpdateStep = () => {
    const { id } = this.props.user
    return makePutRequest({ url: `/users/${id}`, body: { id, user: { current_step: ROUTES.avatars.step } }})
  }

  renderAboutMe = () => {
    const { state, handleAbout } = this

    return(
      <React.Fragment>
        <CompletionHeading
          headText='About me'
          optional={true}
          descriptionText='Just a few words to introduce yourself. This will be seen on your profile. You can come back to this later if you wish.'
        />
        <About profile={state.profile} handleAbout={handleAbout} />
      </React.Fragment>
    )
  }

  render = () => {
    const { renderAboutMe, handleNextStep, renderKeywords, props } = this
    return(
      <Container
        customClass='step-keywords'
        headerText='Friendships are often based on the things you have in common​.'
        descText='Sharing your interests and a few other things about yourself, is a great first step towards meeting new like-minded people.'
        handleNextClick={handleNextStep}
      >
        <CompletionHeading
          headText='Keywords & Interests'
          descriptionText='Type in or select a few hobbies, passions and interests below.'
        />
        <div className='form-check form-multi-autocomplete'>
          {renderKeywords()}
        </div>
        {(props.profile.type !== 'ProxyProfile') && renderAboutMe()}
      </Container>
    )
  }
}

export default withRouter(Keywords)
