import React from 'react'
import DeleteModal from '../../shared/delete_modal'
import { makeDeleteRequest } from '../../shared/api.js'

export default class DeleteThreadButton extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({showModal: true});
  }

  hideModal = () => {
    this.setState({showModal: false});
  }

  sendDeleteRequest = () => {
    makeDeleteRequest({ url: this.props.destroy_url }).then((response) => {
      if(response.data.success) { location.reload(); }
    })
  }

  render() {
    return(
      <>
        <a onClick={this.showModal} className='btn btn-small btn-danger btn-remove' href=''>
          Delete this thread
        </a>
        {this.state.showModal && 
          <DeleteModal  
            resourceType='thread'
            onModalClose={this.hideModal} 
            onDestroyConfirmation={this.sendDeleteRequest} 
            showModal={this.state.showModal}>
          </DeleteModal>}
      </>
    )
  }
}
