import React from 'react'
import { MSG_MAX_CHARS_TO_SHOW } from '../../../../utils/constants'
import PhotoModal from '../../group/subjects/photo_modal'
import FrindowNotification from '../../../shared/frindow_notification'

export default class ReplyForm extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      readMore: false,
      showModal: false,
      postPhoto: null,
      fileName: '',
      error: {},
      blockReply: true
    }
  }

  toggleReadMore = () => this.setState({
    readMore: !this.state.readMore
  })

  renderRespondTo = () =>{
    const { readMore } = this.state
    if (!this.props.replyTo) { return null; }
    const { content } = this.props.postReplyTo
    const { user_name, deleted } = this.props.postReplyTo.profile
    const isMsgLong = content.length > MSG_MAX_CHARS_TO_SHOW
    const quote = readMore? content : content.slice(0, MSG_MAX_CHARS_TO_SHOW);
    const buttonText = readMore? 'read less': 'read more';
    return (
      <blockquote>
        {!deleted && <span className='replying-to'>{user_name}</span>}
        <span dangerouslySetInnerHTML={{__html: quote.split('&amp;').join('&')}} />
        {!readMore && isMsgLong && '...'}
        {isMsgLong && <button onClick={this.toggleReadMore} className="ml-2 read-more-btn">{buttonText}</button>}
      </blockquote>
    )
  }

  addImage = (image, postPhoto) => {
    this.setState({showModal: false, postPhoto: postPhoto})
    this.props.handlePostPhotoUpload(postPhoto)
  }

  uploadStart = (file) => {this.setState({fileName: file.name})}

  renderFileName = () => {
    if (this.state.fileName) { return <p className='mt-2 mb-0'>{this.state.fileName}</p> }
  }

  renderPhotoModal = () => {
    if (!this.state.showModal) { return null; }

    return  <PhotoModal
              profile={this.props.profile}
              addImage={this.addImage}
              isUserConfirmed={this.props.profile.user_confirmed}
              isEventGroup={this.props.isEventGroup}
              isOpen={this.state.showModal}
              uploadStart={this.uploadStart}
              addError={this.addError}
              closePhotoModal={this.closePhotoModal} />
  }

  closePhotoModal = () => {
    this.setState({showModal: false})
  }

  addError = (error) =>{
    this.setState({showModal: false, error: error})
  }

  handleClickPhoto = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({showModal: true})
  }

  handleClickPost = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({fileName: '',
                   postPhoto: null,
                   error : {},
                   blockReply: true,
                   showReply: false })
    this.props.handleClickPost(e)
  }

  renderErrors = () => {
    const { error } = this.state
    if (Object.keys(error).length != 0) { return <p className="file-errors">{Object.values(error).join()}</p> }
  }

  clearNotices = () => {
    this.setState({error: {}, postPhoto: null, fileName: ''})
  }

  handleChangeReply = (e) => {
    if(e.target.innerText.trim().length == 0){
      this.setState({blockReply: true})
    }else{
      this.setState({blockReply: false})
      this.props.handleChangeReply(e)
    }
  }

  render() {
    const { error, blockReply } = this.state
    return(
      <>
        {this.renderRespondTo()}
        <form className='reply-form'>
          <div
            ref={this.props.forwardRef}
            contentEditable={true}
            placeholder='Add comment'
            className='w-100 form-control border'
            name='replyBody'
            onInput={this.handleChangeReply}
          />
          <button
            className='btn icon-camera text-muted btn-small'
            type="button"
            onClick={this.handleClickPhoto}>
              <span className='sr-only'>Upload</span>
          </button>
          <button
            className='btn btn-primary btn-plane btn-small'
            type='submit'
            disabled={blockReply}
            onClick={this.handleClickPost}>
              <span className='sr-only'>Post reply</span>
          </button>
        </form>
        {this.renderFileName()}
        {this.renderErrors()}
        {this.renderPhotoModal()}
        <FrindowNotification
          errors={Object.values(error)}
          clearNotices={this.clearNotices}
        />
      </>
    )
  }
}
