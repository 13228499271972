import React from 'react'
import NameFields from './sign_up_form/name_fields'
import BirthDayFields from './sign_up_form/birthday_fields'
import PostcodeFields from './sign_up_form/postcode_fields'
import AccountTypeFields from './sign_up_form/account_type_fields'
import PasswordFields from './sign_up_form/password_fields'
import { makePostRequest } from './shared/api.js'

import { EMAIL_REGEX, POSTCODE_REGEX, ACCOUNT_TYPE_OPTIONS,
         PERSON_RELATIONS, PASSWORD_REGEX, USERNAME_REGEX, PROXY_REGEX } from '../utils/constants'
import { getAddressData } from '../utils/postcodes'
import moment from 'moment'
import _ from 'lodash'
import i18n from '../utils/i18n'

export default class SignUpForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      firstName: '',
      firstNameErrorText: '',
      username: '',
      usernameErrorText: '',
      email: '',
      emailErrorText: '',
      code: '',
      codeErrorText: '',
      birthDate: '',
      postcode: '',
      postcodeErrorText: '',
      nameFieldsValid: false,
      accountType: 'direct',
      accountTypeErrorText: '',
      proxyName: '',
      proxyNameErrorText: '',
      proxyRelationship: '',
      proxyRelationshipErrorText: '',
      day: '',
      month: '',
      year: '',
      birthDateErrorText: '',
      password: '',
      passwordErrorText: '',
      passwordConfirmation: '',
      passwordConfirmationErrorText: '',
      termsChecked: false,
      termsErrorText: '',
    }
  }

  handleFirstNameChange = (e) => this.setState({ firstName: e.target.value, firstNameErrorText: '' })

  handleUserNameChange = (e) => this.setState({ username: e.target.value, usernameErrorText: '' })

  handleEmailChange = (e) => this.setState({ email: e.target.value, emailErrorText: '' })

  handleCodeChange = (e) => this.setState({ code: e.target.value, codeErrorText: '' })

  handleDayChange = (e) => this.setState({ day: e.target.value, birthDateErrorText: '' })

  handleMonthChange = (e) => this.setState({ month: e.target.value, birthDateErrorText: '' })

  handleYearChange = (e) => this.setState({ year: e.target.value, birthDateErrorText: '' })

  handlePostcodeChange = (e) => this.setState({ postcode: e.target.value, postcodeErrorText: '' })

  handleAccountTypeChange = (e) => this.setState({ accountType: e.target.value, accountTypeErrorText: '' })

  handleRelatedPersonRelChange = (e) =>  this.setState({ proxyRelationship: e.target.value, proxyRelationshipErrorText: '' })

  handleProxyName = (e) => this.setState({ proxyName: e.target.value, proxyNameErrorText: '' })

  handlePasswordChange = (e) => this.setState({ password: e.target.value, passwordErrorText: '', passwordConfirmationErrorText: '' })

  handlePasswordConfirmationChange = (e) => this.setState({ passwordConfirmation: e.target.value, passwordErrorText: '', passwordConfirmationErrorText: '' })

  handleTermsChange = (e) => this.setState({ termsChecked: e.target.checked, termsErrorText: '' })

  handleNextClick = () => {
    event.preventDefault();
    event.stopPropagation()
    this.validateData()
  }

  handleSignUpClick = (e) => {
    e.preventDefault()
    this.validatePasswordFields();
  }

  performSubmit = () => {
    document.getElementById('initial_signup_form').submit()
    if (typeof ga !== 'undefined') { ga('send', 'event', 'User', 'created') }
  }

  validateData = () => {
    const { step } = this.state
    switch(step) {
      case 1:
        this.validateNameFields();
        break;
      case 2:
        this.validateBirthdayFields();
        break;
      case 3:
        this.validatePostCodeFields();
        break;
      case 4:
        this.validateAccountTypeFields();
        break;
    }
  }

  validatePasswordFields = () => {
    const { validatePassword, validateTerms, validatePasswordConfirmation } = this

    const validation = { ...validatePassword(), ...validateTerms(), ...validatePasswordConfirmation()  }

    if(_.every([validation.passwordErrorText, validation.passwordConfirmationErrorText, validation.termsErrorText], (val) => val.length === 0)) {
      validation['passwordFieldsValid'] = true
      this.performSubmit()
    }
    this.setState(validation)
  }

  validatePassword = () => {
    const { password, passwordConfirmation } = this.state
    let passwordErrorText = ''

    if(!PASSWORD_REGEX.test(password)) {
      passwordErrorText = i18n.t('shared.passwordError')
    } else if (password !== passwordConfirmation) {
      passwordErrorText = 'Please ensure that your password and confirmed password are the same.'
    }

    return { passwordErrorText }
  }

  validatePasswordConfirmation = () => {
    const { password, passwordConfirmation } = this.state
    let passwordConfirmationErrorText = ''

    if(!PASSWORD_REGEX.test(passwordConfirmation)) {
      passwordConfirmationErrorText = i18n.t('shared.passwordError')
    } else if (password !== passwordConfirmation) {
      passwordConfirmationErrorText = 'Please ensure that your password and confirmed password are the same.'
    }

    return { passwordConfirmationErrorText }
  }

  validateTerms = () => {
    const { termsChecked } = this.state
    return ( termsChecked ? { termsErrorText: '' } : { termsErrorText: 'Please confirm that you have consent to act as a Proxy User.'})
  }

  validateAccountTypeFields = () => {
    const { validateAccountType, validateProxyName, validateProxyRelationship, state } = this
    const validation = { ...validateAccountType(), ...validateProxyName(), ...validateProxyRelationship() }

    if(_.every([validation.proxyNameErrorText, validation.proxyRelationshipErrorText], (val) => val.length === 0)) {
      validation['accountTypeFieldsValid'] = true
      validation['step'] = 5
      const accountTypeField = document.getElementById('user_account_type')
      const proxyNameField = document.getElementById('user_proxy_first_name')
      const proxyRelationship = document.getElementById('user_proxy_relationship')
      accountTypeField.value = this.state.accountType
      proxyNameField.value = this.state.proxyName
      proxyRelationship.value = this.state.proxyRelationship
    }
    this.setState(validation)
  }

  validateAccountType = () => {
    const { accountType } = this.state
    return ( _.includes(Object.values(ACCOUNT_TYPE_OPTIONS), accountType) ? { accountTypeErrorText: ''} : { accountTypeErrorText: 'wrong account type' })
  }

  validateProxyName = () => {
    const { accountType, proxyName } = this.state

    if(accountType === 'direct') return { proxyNameErrorText: ''}

    if(proxyName.length < 2) {
      return { proxyNameErrorText: 'The minimum length is two characters.'}
    }
    if(!proxyName.match(/^[A-Z-]+$/i)) {
      return { proxyNameErrorText: 'Sorry, special characters and numbers are not allowed.'}
    }

    return { proxyNameErrorText: ''}
  }

  validateProxyRelationship = () => {
    const { accountType, proxyRelationship } = this.state
    if(accountType === 'direct' || _.includes(PERSON_RELATIONS, proxyRelationship)) {
      return { proxyRelationshipErrorText: '' }
    } else {
      return { proxyRelationshipErrorText: 'wrong relation type'}
    }
  }

  validatePostCodeFields = () => {
    const { postcode } = this.state
    if(POSTCODE_REGEX.test(postcode)) {
      getAddressData(postcode).then((res) => {
        if(res.status === 200){
          const { data } = res

          let postcodeField      = document.getElementById('user_address_attributes_post_code')
          let adminDistrictField = document.getElementById('user_address_attributes_admin_district')
          let adminCountyField   = document.getElementById('user_address_attributes_admin_county')
          let regionField        = document.getElementById('user_address_attributes_region')
          let longitudeField     = document.getElementById('user_address_attributes_longitude')
          let latitudeField      = document.getElementById('user_address_attributes_latitude')
          let countryField       = document.getElementById('user_address_attributes_country')

          if(data.postcode)       postcodeField.value = data.postcode
          if(data.admin_district) adminDistrictField.value = data.admin_district
          if(data.admin_county)   adminCountyField.value = data.admin_county
          if(data.region)         regionField.value = data.region
          if(data.country)        countryField.value = data.country
          if(data.longitude)      longitudeField.value = data.longitude
          if(data.latitude)       latitudeField.value = data.latitude
          const validation = {
            postcodeFieldsValid: true,
            step: 4
          }
          this.setState(validation)
        } else {
          this.setState({ postcodeErrorText: 'Please enter a valid postcode' })
        }
      })
    } else {
      this.setState({ postcodeErrorText: 'Please enter a valid postcode' })
    }
  }

  validateNameFields = (e) => {
    e.preventDefault();
    e.stopPropagation()
    const {
      validateEmail, validateUsername, validateFirstName,
      checkEmailUniqueness, checkUsernameUniqueness,
      checkMarketingCodeValidity, performNextStep
    } = this
    const validation = { ...validateEmail(), ...validateUsername(), ...validateFirstName() }

    this.setState(validation, () => {
      Promise.all([checkEmailUniqueness(), checkUsernameUniqueness(), checkMarketingCodeValidity()]).then(([email, username, code]) => {
        const errors = {}
        if(this.state.emailErrorText.length === 0) {
          if(email.email_exists) errors['emailErrorText'] = 'You are already registered. Please log in.'
        }
        if(this.state.usernameErrorText.length === 0) {
          if(username.username_exists) errors['usernameErrorText'] = 'Sorry, that username is not available.'
        }
        if(this.state.codeErrorText.length === 0) {
          if(code.code_invalid) errors['codeErrorText'] = 'Code not recognised. Leave blank if you do not have one.'
        }
        (_.isEmpty(errors) ? performNextStep() : this.setState(errors))
      })
    })
  }

  performNextStep = () => {
    const { emailErrorText, usernameErrorText, firstNameErrorText, codeErrorText } = this.state
    if(_.every([emailErrorText, usernameErrorText, firstNameErrorText, codeErrorText], (val) => val.length === 0)){
      this.setState({ step: 2 })
      const emailField = document.getElementById('user_email')
      const firstNameField = document.getElementById('user_first_name')
      const userNameField = document.getElementById('user_username')
      const codeField = document.getElementById('user_code')
      emailField.value = this.state.email.trim()
      firstNameField.value = this.state.firstName.trim()
      userNameField.value = this.state.username.trim()
      codeField.value = this.state.code.trim()
    }
  }

  validateBirthdayFields = () => {
    const { day, month, year, step } = this.state
    const date = [year, month, day].join('-')
    const dateObj = moment(date, "YYYY-MM-DD", true)
    const currentDate = moment()

    const validation = {}
    if(dateObj.isValid()) {
      if(dateObj > currentDate) {
        validation['birthDateErrorText'] = 'Date from the future'
      } else {
        const duration = moment.duration(currentDate.diff(dateObj)).asYears();
        if(duration < 18) {
          validation['birthDateErrorText'] = 'Sorry, you have to be 18 or over to join this site.'
        } else {
          validation['birthDateDieldsValid'] = true
          validation['step'] = 3
          validation['birthday'] = date
          const birthDateField = document.getElementById('user_birth_date')
          birthDateField.value = date
        }
      }
    } else {
      validation['birthDateErrorText'] = 'Please give a valid date.'
    }

    this.setState(validation)
  }

  validateFirstName = () => {
    const { firstName } = this.state
    const trimFirstName = firstName.trim()

    if(trimFirstName.length < 2) {
      return this.setState({ firstNameErrorText: 'The minimum length is two characters.'})
    }
    if(!PROXY_REGEX.test(trimFirstName)) {
      return this.setState({ firstNameErrorText: 'Sorry, special characters and numbers are not allowed.'})
    }

    return this.setState({ firstNameErrorText: ''})
  }

  validateEmail = () => {
    const { email } = this.state
    return (EMAIL_REGEX.test(email.trim()) ? { emailErrorText: ''} : { emailErrorText: 'Please provide a valid email address.'})
  }

  validateUsername = () => {
    const { username } = this.state
    const trimUsername = username.trim()

    if(trimUsername.length < 2) {
      return this.setState({ usernameErrorText: 'The minimum length is two characters.'})
    }
    if(trimUsername.length > 15) {
      return this.setState({ usernameErrorText: 'The maximum length is fifteen characters.'})
    }
    if(!USERNAME_REGEX.test(trimUsername)) {
      return this.setState({
        usernameErrorText: 'Sorry, special characters are not allowed. Numbers can be used with letters, but numbers alone are not allowed.'
      })
    }

    return this.setState({ usernameErrorText: ''})
  }

  checkEmailUniqueness = () => {
    const { email } = this.state
    return makePostRequest({ url: this.props.check_email_url, body: { email }}, true)
  }

  checkUsernameUniqueness = () =>{
    const { username } = this.state
    return makePostRequest({ url: this.props.check_username_url, body: { username }}, true)
  }

  checkMarketingCodeValidity = () =>{
    const { code } = this.state
    return makePostRequest({ url: this.props.check_code_url, body: { code }}, true)
  }

  renderNameFields = () => {
    const { handleFirstNameChange, handleUserNameChange, handleEmailChange, handleCodeChange, validateNameFields, state } = this
    const { firstName, username, email, code, firstNameErrorText, usernameErrorText, emailErrorText, codeErrorText, step } = state

    return(
      <NameFields firstName={firstName}
                  firstNameErrorText={firstNameErrorText}
                  username={username}
                  usernameErrorText={usernameErrorText}
                  email={email}
                  emailErrorText={emailErrorText}
                  code={code}
                  codeErrorText={codeErrorText}
                  handleFirstNameChange={handleFirstNameChange}
                  handleUserNameChange={handleUserNameChange}
                  handleEmailChange={handleEmailChange}
                  handleCodeChange={handleCodeChange}
                  handleNextClick={validateNameFields}
                  step={step} />
    )
  }

  renderBirthDayFields = () => {
    const { handleDayChange, handleMonthChange, handleYearChange, handleNextClick, state } = this
    const { day, month, year, birthDateErrorText, step } = state

    return (
      <BirthDayFields
        day={day}
        month={month}
        year={year}
        step={step}
        birthDateErrorText={birthDateErrorText}
        handleDayChange={handleDayChange}
        handleMonthChange={handleMonthChange}
        handleYearChange={handleYearChange}
        handleNextClick={handleNextClick}
      />
    )
  }

  renderPostcodeFields = () => {
    const { handlePostcodeChange, handleNextClick, state } = this
    const { postcode, postcodeErrorText, step } = state

    return <PostcodeFields postcode={postcode}
                           postcodeErrorText={postcodeErrorText}
                           step={step}
                           handlePostcodeChange={handlePostcodeChange}
                           handleNextClick={handleNextClick} />
  }

  renderAccountTypeFields = () => {
    const { handleNextClick, handleAccountTypeChange, handleRelatedPersonRelChange, handleProxyName, state } = this
    const { accountType, accountTypeErrorText, proxyName, proxyNameErrorText, proxyRelationship, proxyRelationshipErrorText, step } = state

    return <AccountTypeFields handleNextClick={handleNextClick}
                              handleAccountTypeChange={handleAccountTypeChange}
                              handleRelatedPersonRelChange={handleRelatedPersonRelChange}
                              handleProxyName={handleProxyName}
                              accountType={accountType}
                              accountTypeErrorText={accountTypeErrorText}
                              proxyName={proxyName}
                              proxyNameErrorText={proxyNameErrorText}
                              proxyRelationship={proxyRelationship}
                              step={step}
                              proxyRelationshipErrorText={proxyRelationshipErrorText} />
  }

  renderPasswordFields = () => {
    const { password, passwordErrorText, passwordConfirmation, passwordConfirmationErrorText, termsChecked, termsErrorText, step, accountType, proxyRelationship } = this.state
    const { handlePasswordChange, handlePasswordConfirmationChange, handleTermsChange, handleSignUpClick } = this

    return <PasswordFields password={password}
                           step={step}
                           passwordConfirmation={passwordConfirmation}
                           passwordConfirmationErrorText={passwordConfirmationErrorText}
                           passwordErrorText={passwordErrorText}
                           handleSignUpClick={handleSignUpClick}
                           termsChecked={termsChecked}
                           termsErrorText={termsErrorText}
                           handlePasswordChange={handlePasswordChange}
                           handlePasswordConfirmationChange={handlePasswordConfirmationChange}
                           handleTermsChange={handleTermsChange}
                           accountType={accountType}
                           proxyRelationship={proxyRelationship} />
  }

  renderStep = () => {
    const { step } = this.state
    const renderStepContent = ((step) => {
      switch(step) {
        case 1:
          return this.renderNameFields()
        case 2:
          return this.renderBirthDayFields()
        case 3:
          return this.renderPostcodeFields()
        case 4:
          return this.renderAccountTypeFields()
        case 5:
          return this.renderPasswordFields()
      }
    })(step)

    return renderStepContent
  }

  render = () => {
    return(
      <React.Fragment>
        {this.renderStep()}
      </React.Fragment>
    )
  }
}
