import React, { useEffect, useState } from 'react'
import EmailsRadio from './emails_radio'
import Button from '../button'
import FrindowNotification from '../frindow_notification'
import { makePutRequest } from '../api.js'
import i18n from '../../../utils/i18n'

const UnsubscribeEmailsContainer = (props) => {
  const [errors, setErrors] = useState([])
  const [messages, setMessages] = useState([])
  const [settings, setSettings] = useState({})
  const [showSuccessPage, setShowSuccessPage] = useState(false)

  const headers = {
    0: 'Forums',
    5: 'Get-togethers',
    7: 'Like-minded',
    8: 'Conversations',
    9: 'Video Chat',
    11: 'Newsletters'
  }

  const handleUpdate = (e) => {
    e.preventDefault()
    e.stopPropagation()

    makePutRequest({ url: props.updateUrl, body: { user: { mailer_settings: settings} } }).then((res) => {
      if(res.data.success) {
        setMessages(['Mail settings updated'])
        if(res.data.success_page) {
          setShowSuccessPage(true)
        }
      } else {
        setErrors(res.data.errors)
      }
    })
  }

  useEffect(() => {
    setSettings(props.settings)
  }, [])

  const handleSettingChange = (key) => {
    let newSettings = settings
    newSettings[key] = !newSettings[key]
    setSettings({...newSettings})
  }

  if(showSuccessPage) {
    return (
      <div className='unsubscribe-content'>
        <h2>Your email settings have been updated.</h2>
      </div>
    )
  }

  return(
    <div className={props.withHeader ? 'unsubscribe-content' : '' }>
      { props.withHeader && <p><span>Manage the Frindow emails that you receive.</span></p> }
      { !showSuccessPage && <>
        <table className={`mobile-table ${props.withHeader ? 'unsubscribe-content-wrapper': ''}`}>
            {
              Object.entries(settings).map(([key, value], index) => {
                return <tbody key={key}>
                      {headers[index] && <tr><td colspan='3'  className='pr-2'><h2 className='mt-2'>{headers[index]}</h2></td></tr>}
                      <tr key={`setting-${key}`} className='mobile-columns'>
                        <td className='pr-2 mobile-column-100'>{i18n.t(`emailNotifications.${key}`)}</td>
                        <EmailsRadio emailStatusChange={()=> { handleSettingChange(key) }}
                                      emailStatus={value}
                                      nameRadio={key}/>
                      </tr>
                </tbody>
              })
            }
          </table>
          <div className='mt-3'>
            <Button
              className='btn btn-big btn-primary btn-update btn-submit mt-3'
              title={props.withHeader ? 'Update Mail Settings' : 'Update'}
              onClick={handleUpdate}
            />
          </div>
        </>
      }
      <FrindowNotification errors={errors}
                           messages={messages}
                           clearNotices={() => {
                             setErrors([])
                             setMessages([])
                           }}
      />
    </div>
  )
}

export default UnsubscribeEmailsContainer
