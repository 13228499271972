import React from 'react'
import { dashify } from '../../utils/tools'
import _ from 'lodash'

const SelectField = ({ collection, value, emptyKey = 'choose', onChange, errorText, errorPrompt = true }) => {
  const errorClass = ( errorText ? 'is-invalid' : '')

  const options = [
    <option disabled key={'key_'+ dashify(emptyKey)} value=''>{_.capitalize(emptyKey)}</option>,
    (Array.isArray(collection) ? optsFromArray(collection) : optsFromObject(collection))
  ]

  return(
    <div className='form-input-cell select-cell small'>
      <select onChange={onChange} value={value} className={'form-control ' + errorClass}>
        {options}
      </select>
      { errorPrompt ? <div className='invalid-feedback'>{errorText}</div> : null }
    </div>
  )
}

const optsFromObject = (collection) => Object.keys(collection).map((key) => <option key={'key_' + dashify(key)} value={collection[key]}>{_.capitalize(key)}</option>)

const optsFromArray = (collection) => collection.map((value) => <option key={'key_' + dashify(value)} value={dashify(value)}>{_.capitalize(value)}</option>)

export default SelectField;
