import React, { Component } from 'react'
import CountrySelect from './country_select'
import { makeGetRequest } from '../../shared/api'
import Select from 'react-select'
import { findIndex, filter } from 'lodash'

export default class Region extends Component {
  constructor(props) {
    super(props)
    this.state = {
      countries: props.countries,
      country: props.country,
      locationsLvl1: [],
      locationsLvl2: []
    }
  }

  componentDidMount = () => {
    if(this.props.country) { this.fetchLocationsForCountry(this.props.country) }
    if(this.props.selectedLocationsLvl1) { this.fetchLocationsForRegions(this.props.selectedLocationsLvl1) }
  }

  componentDidUpdate = (prevProps, prevState) => {
    if(this.props.selectedLocationsLvl1.length !== prevProps.selectedLocationsLvl1.length) {
      this.fetchLocationsForRegions(this.props.selectedLocationsLvl1)
    }
  }

  fetchLocationsForCountry = (country) => {
    makeGetRequest({ url: `${this.props.url}?country=${country}`}).then((res) => {
      if(res.data) this.setState({ locationsLvl1: res.data.locations }, this.checkAllOption)
    })
  }

  fetchLocationsForRegions = (regions) => {
    const { country } = this.props
    makeGetRequest({ url: `${this.props.url}?country=${country}&regions=${regions.map(a => a.name)}`}).then((res) => {
      const ids = res.data.locations.map(b => b.id)
      const selectedLocationsLvl2 = this.props.selectedLocationsLvl2.filter(a => ids.includes(a.id))
      if(res.data) this.setState({ locationsLvl2: res.data.locations }, this.checkAllOption)
      this.props.updateLocationsLvl2({ area_id: this.props.area_id, country: this.props.country, selectedLocations: selectedLocationsLvl2 })
    })
  }

  checkAllOption = () => {
    if(this.props.selectedLocationsLvl1.length === this.state.locationsLvl1.length) {
      this.setState({selectedLocationsLvl1: [{ id: 'all', name: 'All'}]})
    }
  }

  handleCountryChange = (e) => {
    const country = e.target.value
    const oldCountry = this.props.country
    if(country !== oldCountry) this.props.updateRegion(this.props.area_id, country, oldCountry)
  }

  handleLocationChange = (locations) => {
    const allIndex = findIndex(locations, (l) => l.value === 'all')
    const selectedLocations = allIndex > -1 ? this.state.locationsLvl1 : this.parseLocations(this.state.locationsLvl1, locations)
    this.props.updateLocationsLvl1({ area_id: this.props.area_id, country: this.props.country, selectedLocations: selectedLocations })
  }

  handleLocationLvl2Change = (locations) => {
    const selectedLocations = allIndex > -1 ? this.state.locations : this.parseLocations(this.state.locationsLvl2, locations)
    const allIndex = findIndex(locations, (l) => l.value === 'all')
    this.props.updateLocationsLvl2({ area_id: this.props.area_id, country: this.props.country, selectedLocations: selectedLocations })
  }

  parseLocations = (allLocations, locations) => {
    const locationIds = locations.map((l) => l.value)
    return filter(allLocations, (cl) => locationIds.includes(cl.id))
  }

  locationOptions = () => this.state.locationsLvl1.map((obj) => ({ label: obj.name, value: obj.id }))

  locationOptionsLvl2 = () => this.state.locationsLvl2.map((obj) => ({ label: obj.level_2_name, value: obj.id }))

  locationValues = () => this.props.selectedLocationsLvl1.map((obj) => ({ label: obj.name, value: obj.id }))

  locationLvl2Values = () => this.props.selectedLocationsLvl2.map((obj) => ({ label: obj.level_2_name, value: obj.id }))

  render = () => {
    return(
      <React.Fragment>
        <CountrySelect
          key={`key_country_select_${this.props.country}_${this.props.area_id}`}
          area_id={this.props.area_id}
          countries={this.state.countries}
          selectedCountry={this.props.country}
          handleCountryChange={this.handleCountryChange}
        />
        <div className='form-multi-autocomplete'>
          <Select
            isMulti
            options={[{ label: 'All', value: 'all' }, ...this.locationOptions()]}
            onChange={this.handleLocationChange}
            value={this.locationValues()}
            className={'form-input-cell'}
            classNamePrefix={'autocomplete'}
          />
        </div>
        <div className='form-check form-multi-autocomplete'>
          <Select
            isMulti
            options={[...this.locationOptionsLvl2()]}
            onChange={this.handleLocationLvl2Change}
            value={this.locationLvl2Values()}
            className={'form-input-cell'}
            classNamePrefix={'autocomplete'}
          />
        </div>
      </React.Fragment>
    )
  }
}
