import React from 'react'

const ProfileUrl = React.memo((props) => {
  const { profile_path, username, deleted, system = false } = props

  if(deleted || system) {
    return (<span> {username}</span>)
  }

  return (<a href={profile_path}> {username}</a>)
})

export default ProfileUrl
