import React, { Component } from 'react'
import Button from '../shared/button'
import FrindowNotification from '../shared/frindow_notification'
import { VALIDATION_MSG, EMAIL_REGEX, URL_REGEX, POSTCODE_REGEX } from '../../utils/constants'
import { makePostRequest, makePutRequest } from '../shared/api'
import TextField from '../shared/text_field'
import { getAddressData } from '../../utils/postcodes'

export default class ContactDetails extends Component {
  constructor(props) {
    super(props)

    let businessContact = this.props.business_contact

    this.state = {
      business_contact: businessContact,
      messages: [],
      errors: [],
      postCodeErrorText: ''
    }
  }

  handleOnChange = (event) => {
    let { business_contact } = this.state
    business_contact[event.target.name] = event.target.value
    this.setState(
      { business_contact: business_contact }
    )
  }

  handleOnSave = () => {
    const errors = this.validation()

    if (this.isValid(errors)) {
      this.updateContact().then((business_contact) => {
        if (business_contact.data.success) {
          this.setState({ messages: ['Contact has been saved.'], errors: [], errorMessage: [], postCodeErrorText: '' })
        } else {
          this.setState({ errors: business_contact.data.errors, errorMessage: ['Contact can not be updated'] })
        }
      });
    } else {
      this.setState({ errorMessage: ['Contact can not be updated'] })
      this.setState({ errors: errors })
    }
  }

  isValid = (errors) => {
    return Object.values(errors).every((e) => e === '')
  }

  validation = () => {
    const { business_contact } = this.state
    const emailError = this.checkPresence(business_contact.email)
    const emailFormatError = this.checkEmail(business_contact.email)
    let phoneError = ''
    if (business_contact.phone != '') {
      phoneError = this.checkNumber(business_contact.phone)
    }

    const emailErrors = { ...emailError, ...emailFormatError }

    let websiteErrors = ''
    if (business_contact.website != '') {
      websiteErrors = this.checkUrl(business_contact.website)
    }

    let facebookErrors = ''
    if (business_contact.facebook != '') {
      facebookErrors = this.checkUrl(business_contact.facebook)
    }
    let twitterErrors = ''
    if (business_contact.twitter != '') {
      twitterErrors = this.checkUrl(business_contact.twitter)
    }

    let youtubeErrors = ''
    if (business_contact.youtube != '') {
      youtubeErrors = this.checkUrl(business_contact.youtube)
    }

    const errors = {
      email: (Object.values(emailErrors).length > 0 ? emailErrors : ''),
      website: websiteErrors,
      facebook: facebookErrors,
      twitter: twitterErrors,
      youtube: youtubeErrors,
      phone: phoneError
    }

    this.validateAddress(errors);
    this.setState({ errors: errors })
    return errors
  }

  validateAddress = (errors) => {
    const { business_contact } = this.state
    if (!business_contact.display_address) return

    let postCodeErrorText = ''
    const addressLine1Error = this.checkPresence(business_contact.address_line1)
    const addressLine2Error = this.checkPresence(business_contact.address_line2)


    if (!POSTCODE_REGEX.test(business_contact.postcode)) {
      postCodeErrorText = 'Wrong postcode'
    }
    Object.assign(errors, {
      address_line1: addressLine1Error,
      address_line2: addressLine2Error,
      postcode: postCodeErrorText
    })

    this.setState(errors, this.validatePostCode)
  }

  checkPresence = (value) => {
    return value === '' ? { fieldRequired: VALIDATION_MSG.isRequired } : ''
  }

  checkEmail = (value) => {
    return EMAIL_REGEX.test(value) ? '' : { emailFormat: VALIDATION_MSG.isEmail }
  }

  checkNumber = (value) => {
    return Number.isInteger(parseInt(value)) ? '' : VALIDATION_MSG['numberRequired']
  }

  checkUrl = (value) => {
    return URL_REGEX.test(value) ? '' : { urlFormat: VALIDATION_MSG.isUrl }
  }

  updateContact = () => {
    let { business_contact } = this.state

    let params = {
      business_contact: {
        email: business_contact.email,
        phone: business_contact.phone,
        company_name: business_contact.company_name,
        display_address: business_contact.display_address,
        address_line1: business_contact.address_line1,
        address_line2: business_contact.address_line2,
        address_line3: business_contact.address_line3,
        address_line4: business_contact.address_line4,
        postcode: business_contact.postcode,
        website: business_contact.website,
        facebook: business_contact.facebook,
        twitter: business_contact.twitter,
        youtube: business_contact.youtube,
        instagram: business_contact.instagram,
        profile_id: business_contact.profile_id,
      }
    }

    if (business_contact.id) {
      return makePutRequest({ url: `/business_contacts/${business_contact.id}`, body: params })
    } else {
      return makePostRequest({ url: `/business_contacts`, body: params })
    }
  }

  displayAddressChange = () => {
    let { business_contact } = this.state
    business_contact.display_address = !business_contact.display_address
    this.setState({ business_contact })
  }

  validatePostCode = () => {
    const { postcode } = this.state.business_contact
    getAddressData(postcode).then((res) => {
      if (res.status === 200) {
        const business_contact = { ...this.state.business_contact, ...res.data }

        this.setState({ business_contact: business_contact })
      } else {
        this.setState({ postCodeErrorText: 'Wrong postcode' })
      }
    })

  }

  renderDisplayAddress = () => {
    const { business_contact } = this.state
    return (
      <div className="form-group">
        <label className="mr-2">Display address?</label>
        <div className="display-address">
          <div className='form-input-cell radiobox-cell'>
            <input
              type='radio'
              id={'display_address_on'}
              name={'display_address_on'}
              value='on'
              className='form-check-input mb-3'
              onChange={this.displayAddressChange}
              checked={business_contact.display_address}
            />
            <label htmlFor='display_address_on'><span className='checkbox'></span>
              YES
            </label>
          </div>
        </div>

        <div className="display-address">
          <div className='form-input-cell radiobox-cell'>
            <input
              type='radio'
              id={'display_address_off'}
              name={'display_address_off'}
              value='off'
              onChange={this.displayAddressChange}
              checked={!business_contact.display_address}
            />
            <label htmlFor='display_address_off'><span className='checkbox'></span>
              NO
            </label>
          </div>
        </div>
      </div>
    )
  }

  renderAddress = () => {
    const { business_contact, errors, postCodeErrorText } = this.state
    if (!business_contact.display_address) return

    return (
      <div className="form-group contact_details">
        <div className="address">
          <b>Main Address  <span className="text-danger">*</span></b>
          <div className="form-group">
            <label>Line 1</label>
            <div className='form'>
              <TextField
                className='form-control'
                value={business_contact.address_line1}
                onChange={this.handleOnChange}
                name='address_line1'
                errorText={errors.address_line1 && Object.keys(errors.address_line1).length > 0 ? Object.values(errors.address_line1) : null} />
            </div>
          </div>

          <div className="form-group">
            <label>Line 2</label>
            <div className='form'>
              <TextField
                className='form-control'
                value={business_contact.address_line2}
                onChange={this.handleOnChange}
                name='address_line2'
                errorText={errors.address_line2 && Object.keys(errors.address_line2).length > 0 ? Object.values(errors.address_line2) : null} />
            </div>
          </div>

          <div className="form-group">
            <label>Line 3</label>
            <div className='form'>
              <TextField
                className='form-control'
                value={business_contact.address_line3}
                onChange={this.handleOnChange}
                name='address_line3' />
            </div>
          </div>

          <div className="form-group">
            <label>Line 4</label>
            <div className='form'>
              <TextField
                className='form-control'
                value={business_contact.address_line4}
                onChange={this.handleOnChange}
                name='address_line4' />
            </div>
          </div>

          <div className="form-group">
            <label>Post code</label>
            <div className='form'>
              <TextField
                className='form-control'
                value={business_contact.postcode}
                onChange={this.handleOnChange}
                name='postcode'
                errorText={postCodeErrorText} />
            </div>
          </div>
        </div>
      </div>
    )
  }

  render = () => {
    const { business_contact, errors, errorMessage } = this.state

    return (
      <div className='form my-4'>
        <h2>Contact Details</h2>

        <div className="form-group">
          <label>Email address  <span className="text-danger">*</span></label>
          <div className='form'>
            <TextField
              className='form-control'
              value={business_contact.email}
              onChange={this.handleOnChange}
              name='email'
              errorText={errors.email ? Object.values(errors.email).join() : null} />
          </div>
        </div>

        <div className="form-group">
          <label>Phone number</label>
          <div className='form'>
            <TextField
              className='form-control'
              value={business_contact.phone}
              onChange={this.handleOnChange}
              name='phone'
              errorText={errors.phone ? Object.values(errors.phone) : null} />
          </div>
        </div>

        <div className="form-group">
          <label>Company name</label>
          <div className='form'>
            <TextField
              className='form-control'
              value={business_contact.company_name}
              onChange={this.handleOnChange}
              name='company_name' />
          </div>
        </div>

        {this.renderDisplayAddress()}
        {this.renderAddress()}

        <div className="form-group">
          <label>Website</label>
          <div className='form'>
            <TextField
              className='form-control'
              value={business_contact.website}
              onChange={this.handleOnChange}
              name='website'
              errorText={errors.website ? Object.values(errors.website) : null} />
          </div>
        </div>

        <div className="form-group">
          <label>Facebook</label>
          <div className='form'>
            <TextField
              className='form-control'
              value={business_contact.facebook}
              onChange={this.handleOnChange}
              name='facebook'
              errorText={errors.facebook ? Object.values(errors.facebook) : null} />
          </div>
        </div>

        <div className="form-group">
          <label>Twitter</label>
          <div className='form'>
            <TextField
              className='form-control'
              value={business_contact.twitter}
              onChange={this.handleOnChange}
              name='twitter'
              errorText={errors.twitter ? Object.values(errors.twitter) : null} />
          </div>
        </div>

        <div className="form-group">
          <label>Instagram</label>
          <div className='form'>
            <TextField
              className='form-control'
              value={business_contact.instagram}
              onChange={this.handleOnChange}
              name='instagram'
              errorText={errors.instagram ? Object.values(errors.instagram) : null} />
          </div>
        </div>

        <div className="form-group">
          <label>Youtube</label>
          <div className='form'>
            <TextField
              className='form-control'
              value={business_contact.youtube}
              onChange={this.handleOnChange}
              name='youtube'
              errorText={errors.youtube ? Object.values(errors.youtube) : null} />
          </div>
        </div>

        <div className='form-actions mt-4'>
          <Button
            className='button btn btn-big btn-secondary btn-submit'
            title='Save'
            onClick={this.handleOnSave}
          />
        </div>

        <FrindowNotification errors={errorMessage} messages={this.state.messages}
          clearNotices={() => this.setState({ errors: [], messages: [], errorMessage: [] })} />
      </div>
    )
  }
}
