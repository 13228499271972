import React, { useState } from 'react'
import { NOTICE_SHOW_DURATION } from '../../utils/constants.jsx'

const Notification = (props) => {
  const [isVisible, setIsVisible] = useState(true);
  const { errors, messages } = props
  let className = 'notice'
  let content = messages
  if (errors) {
    className = 'notice notice-alert'
    content = errors
  }
  setTimeout(() => setIsVisible(false), NOTICE_SHOW_DURATION);
  const html = isVisible
    ? (<div className="fixed-notices">
        <div className={className}>
        {content}
        </div>
      </div>)
    : null
  return html;
}

export default Notification;
