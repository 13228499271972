import React from 'react'
import { dashify } from '../../utils/tools'

const SimpleSelectField = ({ collection, value, emptyKey = 'Choose', onChange, errorText, errorPrompt = true, item_id = -1, index = -1, name='', small = true , wrapperclass = ''}) => {
  const errorClass = ( errorText ? 'is-invalid' : '')

  const options = [
    <option disabled key={'key_'+ dashify(emptyKey)} value=''>{emptyKey}</option>,
    (Array.isArray(collection) ? optsFromArray(collection) : optsFromObject(collection))
  ]

  return(
    <div className={`form-input-cell select-cell ${wrapperclass} ${small ? 'small' : ''}`}>
      <select name={name} onChange={onChange} value={value} className={'form-control ' + errorClass} item_id={item_id} index={index}>
        {options}
      </select>
      { errorPrompt ? <div className='invalid-feedback'>{errorText}</div> : null }
    </div>
  )
}

const optsFromObject = (collection) => Object.keys(collection).map((key) => <option key={'key_' + dashify(key)} value={collection[key]}>{collection[key]}</option>)

const optsFromArray = (collection) => collection.map((value) => <option key={'key_' + dashify(value)} value={value}>{value}</option>)

export default SimpleSelectField;
