import React from 'react'
import { makeGetRequest } from '../../shared/api.js'

class BeforeJoinGroup extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      joined: props.joined
    }
  }

  handleClickJoin = (e) => {
    const { join } = this.props;
    e.stopPropagation()
    e.preventDefault()
    makeGetRequest({ url: `${join}` })
         .then((response) => {
           this.setState({joined: response.data.joined});
           if (this.props.afterJoin) { this.props.afterJoin() }
         });
  }

  render = () => {
    const { handleClickClose, infoText } = this.props
    return(
      <div className='r-modal show'>
        <div className='r-modal-content'>
          <div className='r-modal-header'>
            <h4 className='h1 modal-title text-dark'>One moment...</h4>
            <button className='btn btn-close' aria-label='Close' type='button' onClick={handleClickClose}/>
          </div>
          <div className='r-modal-body'>
            <div className='row'>
              <div className='col-12'>
                <h3 className='h3 text-gray'>{infoText}</h3>
              </div>
            </div>
          </div>
          <div className='r-modal-footer'>
            <button className='btn btn-big btn-primary btn-join-group' onClick={this.handleClickJoin}>Join group</button>
          </div>
        </div>
      </div>
    )
  }
}

export default BeforeJoinGroup;
