import React from 'react'

class RefreshModal extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      timer: 0
    }
  }

  componentDidMount() {
    setInterval(() => this.setState(state => ({timer: state.timer + 1})), 1000)
  }

  render = () => {
    const { handleClickConfirm } = this.props
    const { timer } = this.state
    return(
      <div className='r-modal show'>
        <div className='r-modal-content'>
          <div className='r-modal-header'>
            <h4 className='h1 modal-title text-left'>Confirm you are online</h4>
          </div>
          <div className='r-modal-body'>
            <div className='row'>
              <div className='col-12 form text-left'>
                <div className='form-group'>
                  <label>
                    Please click on the button below to confirm that you are still online. Otherwise, you will be logged out after 60 seconds.
                  </label>
                  <div style={{ fontSize: '40px', textAlign: 'center'}}>
                    {timer}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='r-modal-footer text-right'>
            <button type='button' className='btn btn-big btn-primary btn-add' onClick={handleClickConfirm}>Confirm you are online</button>
          </div>
        </div>
      </div>
    )
  }
}

export default RefreshModal;
