import React from 'react'
import avatar from '../../images/proxy-avatars/avatar-system.png'

export class SystemNotification extends React.Component {
  render = () => {
    return (
        <h3 className='post-autor-notice'>
          Posted by{' '} System
        </h3>
    )
  }
}

export class SystemNotificationAvatar extends React.Component {
  render = () => {
    return (
        <div className='post-avatar circle-avatar size-md'>
          <img src={avatar} className='img-fluid' />
        </div>
    )
  }
}
