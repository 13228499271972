import React, { Component } from 'react'
import { checkChangeLenght } from '../../utils/helpers'

export default class Plan extends Component {
  constructor(props) {
    super(props)
  }

  render = () => {
    const { amount, featured_text, id, featured, display_name, package_text } = this.props.plan
    const { onChange } = this.props
    const [full, change] = amount.split('.')

    return (
      <div className='col-md-4 col-sm-6 plan-card'>
        <input
          id={`plan${id}`}
          type="radio"
          name="plan"
          onChange={onChange}
          value={id}
        />
        <label htmlFor={`plan${id}`} className='plan-label' >
          { featured && <span className='featured'>{featured_text}</span>}
          <span className='plan-option'>{display_name}</span>
          <div className='row info'>
            <b className='price'>{full}<span>.{checkChangeLenght(change)}</span></b>
            <span className="description align-text-bottom" dangerouslySetInnerHTML={{ __html: package_text }}/>
          </div>
        </label>
      </div>
    )
  }
}

