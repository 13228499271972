import React from 'react'

const SearchUsername = (props) => {
  const { handleSearchChange, searchValue } = props
  return(
    <div className='col-12 col-lg-auto conversation-search child-middle'>
      <div className='form-group'>
        <div className='form-input-cell search-cell'>
          <button className='search-submit symbol icon-magnifier'></button>
          <input id='searchUserInput' className='form-control' placeholder='Search by username' type='text' onChange={handleSearchChange} value={searchValue}/>
        </div>
      </div>
    </div>
  )
}

export default SearchUsername
