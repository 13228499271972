import React from 'react'
import OrganiseInfo from './organise_info'
import ListGetTogethers from './list_get_togethers'

export default function GetTogethersContainer(props) {
  const { create_url, site_gt, permitted, user_confirmed,
    confirmation_url, url_join_group, in_group_url,
    fetch_event_get_togethers_url, include_expired } = props

  return(
    <div className='container on-light get-together-content'>
      {/* <div className="get-together-header">
        <div className="tags">
          <button className={`in-person`}>in-person</button>
          <button className={`online`}>online</button>
        </div> 
      </div> */}
      <div className='row row-even-h'>
        {
          !site_gt && <OrganiseInfo
            createUrl={create_url}
            permitted={permitted}
            user_confirmed={user_confirmed}
            confirmation_url={confirmation_url}
            url_join_group={url_join_group}
            in_group_url={in_group_url}
          />
        }
      </div>
      <ListGetTogethers fetchEventGetTogethersUrl={fetch_event_get_togethers_url} includeExpired={include_expired}/>
    </div>
  )
};
