import React from 'react'
import SimpleSelectField from '../../shared/simple_select_field'

const PlaceholderTagSelector = (props) => {

  const { placeholderTagNames, index, last, placeholderTag, handlePlaceholderTagChange, handleDeletePlaceholder } = props

  const tagNames = last ? placeholderTagNames : placeholderTagNames.filter(tg => tg !=='group_profiles')
  
  return(
    <div className='col-12 form text-left mb-3'>
      <div className='form-group'>
        <div className='form-input-cell radiobox-cell row align-items-center'>
          <SimpleSelectField
            index={index}
            item_id={placeholderTag.id}
            collection={tagNames}
            value={placeholderTag.tag_name}
            onChange={handlePlaceholderTagChange}
            emptyKey='Reason for rejection' />
          <button index={index} className='search-reset symbol icon-cross-in-circle ml-2' onClick={handleDeletePlaceholder}></button>
        </div>
      </div>
    </div>
  )
}

export default PlaceholderTagSelector
