import React from 'react'
import MessageAll from './message_all'
import AttendeeseTab from './attendeese_tab'

const AttendeeseContainer = (props) => {
  const { participants, openModal, handleClickOpen, handleClickClose, handleClickSendMsg, handleOnChange, content } = props
  const showBtn = participants.length > 0
  return(
    <div className='attendeese-container'>
      <h1 className='attendeese-title'>Attendees</h1>
      <nav>
        <ul className='nav nav-tabs clean-list'>
          <li className='nav-item'>
            <a className='active' aria-controls='definite' aria-selected='true' data-toggle='tab' href='#definite' role='tab'> Definite </a>
          </li>
        </ul>
      </nav>
      {openModal &&
        <MessageAll
          handleClickClose={handleClickClose}
          handleClickSendMsg={handleClickSendMsg}
          content={content}
          handleOnChange={handleOnChange} />
       }
      <div className='tab-content mb-4'>
        <div id='definite' className='tab-pane fade show active' role='tabpanel'>
          <AttendeeseTab participants={participants} showBtn={showBtn} handleClickOpen={handleClickOpen} name='Definite'/>
        </div>
      </div>
    </div>
  )
}

export default AttendeeseContainer;
