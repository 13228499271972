import React from 'react';
import dates, { genYears } from '../../utils/dates'
import SelectField from '../shared/select_field'
import Container from './container'

const BirthDayFields = ({ day, month, year, handleDayChange, handleMonthChange, 
                          handleYearChange, birthDateErrorText, handleNextClick, step }) => {

  const fields = [
    <div className='col-sm form-group' key='key_day'>
      <SelectField
        collection={dates.days}
        value={day}
        onChange={handleDayChange}
        errorText={birthDateErrorText}
        emptyKey='day'
        errorPrompt={false}
      />
    </div>,
    <div className='col-sm form-group' key='key_month'>
      <SelectField
        key='key_month'
        collection={dates.months}
        value={month}
        onChange={handleMonthChange}
        errorText={birthDateErrorText}
        emptyKey='month'
        errorPrompt={false}
      />
    </div>,
    <div className='col-sm form-group' key='key_year'>
      <SelectField
        collection={genYears(true)}
        value={year}
        onChange={handleYearChange}
        errorText={birthDateErrorText}
        emptyKey='year'
        errorPrompt={false}
      />
    </div>
  ]

  const errorMsg = (birthDateErrorText ?
                    <div className='form-message invalid-feedback'>
                      {birthDateErrorText}
                    </div> : null)

  const nextDisabled = !day || !month || !year || birthDateErrorText
  return(
    <Container
      customClass='step-birthday'
      step={step}
      handleNextClick={handleNextClick}
      nextDisabled={nextDisabled}
      headerText={`Making new friends is easy at any age…`}
      descText={`...you just need to be in the right place at the right time and be surrounded by the right people.`} 
    >
      <h2>When&#39;s your birthday?</h2>
      <div className='tip-wraper mb-2'>
        <div className='tip'>
          <span data-title-right='Knowing your age confirms that you are an adult and enables ​us to direct you to the most appropriate groups and activities to ensure that you get the very best out of your Frindow experience.'>Why do we need this?</span>
        </div>
      </div>
      <div className='form'>
        {errorMsg}
        <div className='row'>
          {fields}
        </div>
      </div>
    </Container>
  )
}

export default BirthDayFields;
