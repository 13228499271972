import React from 'react'
import FrindowModal from '../shared/frindow_modal'

export default class ConfirmInfo extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      isOpen: false
    }
  }

  closeModal = () => this.setState({isOpen: false})

  openModal = () => this.setState({isOpen: true})

  render = () => {
    const { openModal, closeModal } = this
    const { isOpen } = this.state
    const { confirmation_url } = this.props
    return(
      <React.Fragment>
        <button onClick={openModal} className='btn btn-white'>Invite to live chat</button>
        <FrindowModal
          isOpen={isOpen}
          closeModal={closeModal}
          title='You need to be confirmed'
          customClass='border-danger'
          customTitleClass='text-ruby'
        >
        <div className='r-modal-body'>
          <div className='row'>
            <div className='col-12'>
              <h3 className='h2 text-gray'>You need to be a confirmed user to invite someone for a live chat.</h3>
            </div>
          </div>
        </div>
          <div className='r-modal-footer'>
            <button onClick={closeModal} className='btn btn-big btn-secondary btn-cancel'>
              Cancel
            </button>
            <a href={confirmation_url} className='btn btn-big btn-primary'>Confirm account</a>
          </div>
        </FrindowModal>
      </React.Fragment>
    )
  }
}
