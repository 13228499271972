import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { ROUTES } from '../shared/routes'
import Container from '../sign_up_form/container'
import CompletionHeading from '../shared/completion_heading'
import AgeRangeSelect from './age_range_select'
import { makeGetRequest, makePutRequest } from '../shared/api';
import { CONTACT_FILTER_GENDERS } from '../../utils/constants'
import i18n from '../../utils/i18n'
import _ from 'lodash'


class OpenTo extends Component {
  constructor(props) {
    super(props)
    this.state = {
      opentos: [],
      profile_opentos: [],
      disableBtn: false,
      ageStart: 18,
      ageEnd: 100,
      selectedGenderFilter: CONTACT_FILTER_GENDERS.map(o => o.value),
      errors: []
    }
  }

  componentDidMount = () => {
    Promise.all([this.fetchOpenTos(), this.fetchProfileOpenTos()]).then(([opentos, profile_opentos]) => {
      if(opentos.data.success && profile_opentos.data.success) {
        this.setState({ opentos: opentos.data.opentos, profile_opentos: profile_opentos.data.profile_opentos })
      }
    })
  }

  componentDidUpdate = () => {
    const { disableBtn, selectedGenderFilter } = this.state
    if(!disableBtn && selectedGenderFilter.length < 1) { this.setState({ disableBtn: true }) }
    if(disableBtn && selectedGenderFilter.length > 0) { this.setState({ disableBtn: false }) }
  }

  handleNextStep = () => {
    const { profile_opentos, ageStart, ageEnd, selectedGenderFilter } = this.state
    const params = {
      profile: {
        opento_ids: profile_opentos.map((o) => o.id),
        contact_filter: { gender_filter: selectedGenderFilter,
                          age_range_start: ageStart,
                          age_range_end: ageEnd }
      }
    }
    makePutRequest({ url: `/profiles/${this.props.profile.id}`, body: params }).then((res) => {
      if(res.data.success){
        this.props.history.push(ROUTES.keywords.path)
      }
      else {
        const { age_difference: ageDifference, date_invalid: dateInvalid } = res.data.errors
        if (dateInvalid) { this.setState({ errors: { ageRange:  dateInvalid } }) }
        else if(ageDifference) { this.setState({ errors: { ageRange: ageDifference } }) }
      }
    })
  }

  handleOpentoClick = (e) => {
    const { checked } = e.target
    let { value } = e.target
    value = parseInt(value)
    const index = _.findIndex(this.state.opentos, ['id', value])
    let { profile_opentos } = this.state

    if(checked) {
      if(this.objInArrayNotExists(profile_opentos, value)){
        profile_opentos = [ ...this.state.profile_opentos, this.state.opentos[index] ]
      }
    } else {
      profile_opentos = _.filter(this.state.profile_opentos, (o) => o.id !== value)
    }
    this.setState({ profile_opentos })
  }

  handleGenderFilterChange = (e) => {
    const { checked, value } = e.target
    const { selectedGenderFilter } = this.state
    if(checked && !selectedGenderFilter.find(a => a === value)) {
      this.setState(state => ({selectedGenderFilter: state.selectedGenderFilter.concat(value) }))
    }
    else {
      this.setState(state => ({selectedGenderFilter: state.selectedGenderFilter.filter(a => a !== value) }))
    }
  }

  objInArrayNotExists = (array, value) => array.findIndex((o) => o.id === value) === -1

  fetchProfileOpenTos = () => makeGetRequest({ url: `/profiles/${this.props.profile.id}/opentos` })

  fetchOpenTos = () => makeGetRequest({ url: '/opentos' })

  renderLabel = (genderFilter) => {
    return(
      <label className='bullet-checkbox' key={`key_open_${genderFilter.value}`}>
        <input
          onChange={this.handleGenderFilterChange}
          type='checkbox'
          value={genderFilter.value}
          checked={this.state.selectedGenderFilter.includes(genderFilter.value)}
        />
        <div className="bullet">{genderFilter.label}</div>
      </label>
    )
  }

  handleAgeEndChange = (e) => this.setState({ageEnd: e.target.value})

  handleAgeStartChange = (e) => this.setState({ageStart: e.target.value})

  renderAgeRangeSelect = () => {
    const { ageStart, ageEnd, errors } = this.state
    return(
      <AgeRangeSelect
        ageStart={ageStart}
        handleAgeStartChange={this.handleAgeStartChange}
        ageEnd={ageEnd}
        handleAgeEndChange={this.handleAgeEndChange}
        errors={errors} />
    )
  }

  render = () => {
    const { opentos, profile_opentos } = this.state
    const moreOptions = false
    return(
      <Container
        customClass='step-opento'
        headerText='New friendships, peer support, finding a partner or getting involved.'
        descText='Whatever you’re looking for in Frindow, we’ll try our best to get you there or point you in the right direction.'
        handleNextClick={this.handleNextStep}
        nextDisabled={this.state.disableBtn}
      >
        <CompletionHeading
          headText="I'm open to​..."
          descriptionText='Please select any statements that closely relate to you:'
        />
        <div className="form-check escape-parent">
          {opentos.map((o) => (
            <label className='bullet-checkbox' key={`key_open_${o.id}`}>
              <input
                onClick={this.handleOpentoClick}
                type='checkbox'
                value={o.id}
                defaultChecked={_.find(profile_opentos, ['id', o.id])}
              />
              <div className="bullet">{o.name}</div>
            </label>
          ))}
        </div>
        {moreOptions && <React.Fragment>
          <CompletionHeading
            headText={i18n.t('profileCompletion.opento.whoDoIWantToHear')}
            descriptionText={i18n.t('profileCompletion.opento.whoDoIWantToHearInfo')}
          />
          <div className='form-check escape-parent'>
            {CONTACT_FILTER_GENDERS.map((o) => (this.renderLabel(o)))}
          </div>
          <CompletionHeading
            headText={i18n.t('profileCompletion.opento.idealAgeRangeTxt')}
          />
          {this.renderAgeRangeSelect()}
        </React.Fragment>}
      </Container>
    )
  }
}

export default withRouter(OpenTo)
