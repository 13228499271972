import React from 'react'
import FrindowModal from '../frindow_modal'
import { makeGetRequest } from '../api.js'
import ListImages from './list_images'
import Scrollbar from '../scrollbar'
import { APP_ROUTES } from '../routes'

class SelectImagedModal extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      images: [],
      image: props.image,
      imageUrl: props.imageUrl
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.props.isOpen &&
      this.state.images.length < 1) {
        this.fetchImages()
      }
  }

  fetchImages = () => {
    makeGetRequest({ url: APP_ROUTES.unsplash })
      .then((response) => {
        if (response.data.errors == null) {
          this.setState({
            errors: '',
            images: response.data.images
          })
        }
        else {
          this.setState({
            errors: response.data.errors
          })
        }
      })
  }

  handleImageChange = (e) => {
    this.setState({image: { full: e.target.getAttribute('full'),
                            unsplash_id: e.target.getAttribute('unsplashid'),
                            author_name: e.target.getAttribute('authorname'),
                            author_url: e.target.getAttribute('authorurl') },
                   imageUrl: e.target.src})
  }

  renderListImages = () => {
    const { image, images } = this.state
    const { handleImageChange } = this
    if(images.length < 1) { return (<div>Loading images</div>) }
    return (
      <Scrollbar style={ {height: '50vh' } }>
        <ListImages selectedImage={image} images={images} handleImageChange={handleImageChange} />
      </Scrollbar>)
  }

  render = () => {
    const { closeModal, isOpen, handleClickSubmitImage } = this.props
    const { image, imageUrl } = this.state
    return(
      <FrindowModal
        isOpen={isOpen}
        closeModal={closeModal}
        title='Select Get Together image'
        customClass='border-danger wide'
        customTitleClass='text-ruby'
      >
        <div className='col-12 form text-left'>
          <div className='form-group'>
            <div className='form-input-cell unsplash-images'>
              <h2>Select image</h2>
              {this.renderListImages()}
              <div className='m-2'>
                <button className='mr-2 mb-2 mb-md-0 btn btn-primary btn-big btn-update' onClick={() => handleClickSubmitImage(image, imageUrl)}>Submit</button>
                <button type='button' className='btn btn-big btn-secondary btn-cancel' onClick={closeModal}>Cancel</button>
              </div>
            </div>
          </div>
        </div>
      </FrindowModal>
    )
  }
}

export default SelectImagedModal
