import React from 'react'

const Tags = (props) => {

  function renderName(name) {
    let localName = name
    if (localName === 'other') localName += ' gender'
    return localName.charAt(0).toUpperCase() + localName.replace('_', ' ').slice(1)
  }

  const { tagFilters, handleRemoveFilter } = props
  return(
   <div className='groups-tags search-tags text-left my-2'>
     <h4 className='text-uppercase'>Filtered By</h4>
     <ul className='group-tags-list row'>
      {tagFilters.map(gr =>{
        return(
          <li key={gr.name}>
            <a href='#' className='group-tag selected'>
              {renderName(gr.name)}
              <button
                date-group={`${gr.group}`}
                name={gr.name}
                className='ml-1 search-reset symbol icon-cross-in-circle'
                onClick={handleRemoveFilter} />
            </a>
          </li>
        )}
      )}
    </ul>
  </div> 
  )
}

export default Tags 
