import React, { Component } from 'react'
import moment from 'moment'
import TextField from '../../shared/text_field'
import Button from '../../shared/button'
import { POSTCODE_REGEX } from '../../../utils/constants'
import { makePutRequest } from '../../shared/api'
import { getAddressData } from '../../../utils/postcodes'
import FrindowNotification from '../../shared/frindow_notification'

export default class EditPersonalDetails extends Component {
  constructor(props) {
    super(props)
    this.state = {
      user: props.user,
      postCodeErrorText: '',
      messages: [],
      errorsNotify: [],
      errors: {}
    }
  }

  onPostCodeChange = (e) => {
    const { value } = e.target
    const address = { ...this.state.user.address, post_code: value}
    this.setState({ user: {...this.state.user, address: address }, postCodeErrorText: '' })
  }

  handleCancel = () => {
    this.setState({ user: this.props.user})
  }

  handleUpdate = () => {
    this.performValidation()
  }

  prepareRequestBody = () => ({
    user: {
      first_name: this.state.user.first_name,
      address_attributes: this.state.user.address
    }
  })

  performValidation = () => {
    const { post_code } = this.state.user.address
    const staticValidation = {
      postCodeErrorText: ''
    }

    if(!POSTCODE_REGEX.test(post_code)){
      staticValidation.postCodeErrorText = 'Wrong postcode'
    }

    this.setState(staticValidation, this.dynamicPostCodeValidation)
  }

  dynamicPostCodeValidation = () => {
    if(this.state.postCodeErrorText || this.state.firstNameErrorText) return
    const { post_code } = this.state.user.address
    getAddressData(post_code).then((res) => {
      if(res.status === 200){
        const address = { ...this.state.user.address, ...res.data }

        this.setState({ user: {...this.state.user, address: address } }, this.updateUser)
      } else {
        this.setState({ postCodeErrorText: 'Wrong postcode' })
      }
    })
  }

  updateUser = () => {
    const url = `/users/${this.state.user.id}`
    const body = this.prepareRequestBody()
    makePutRequest({ url, body }).then((res) => {
      if(res.data.success){
        this.setState({ messages: ['Personal details have been updated'], errors: {}})
      } else {
        this.setState({ errors: res.data.errors})
      }
    })
  }


  renderActions = () => (
    <div className='form-actions mt-4'>
      <Button
        className='button btn btn-big btn-secondary btn-cancel btn-submit'
        title='Cancel'
        onClick={this.handleCancel}
      />
      <Button
        className='btn btn-big btn-primary btn-update btn-submit'
        title='Update'
        onClick={this.handleUpdate}
      />
    </div>
  )

  render = () => {
    return(
      <React.Fragment>
        <table className='table table-borderless'>
          <tbody>
            <tr>
              <td className='pr-2 font-weight-bold'>First name:</td>
              <td>{this.state.user.first_name}</td>
            </tr>
            <tr>
              <td className='pr-2 font-weight-bold'>Last name:</td>
              <td>{this.state.user.last_name}</td>
            </tr>
            <tr>
              <td className='pr-2 font-weight-bold'>Client ID:</td>
              <td>{this.state.user.client_id}</td>
            </tr>
            <tr>
              <td className='pr-2 font-weight-bold'>Profile link:</td>
              <td>{this.props.profile.public_link}</td>
            </tr>
            <tr>
              <td className='pr-2 font-weight-bold'>Registration date:</td>
              <td>{moment(this.state.user.created_at).format('MMMM Do YYYY')}</td>
            </tr>
            <tr>
              <td className='pr-2 font-weight-bold'>Registered email address:</td>
              <td>
                {this.state.user.email}
                <i> - please <a className='text-blue-ui' href="https://frindow.freshdesk.com/support/tickets/new" rel="noopener noreferrer" target="_blank">contact us </a> if you wish to update this.</i>
              </td>
            </tr>
            <tr>
              <td className='pr-2 font-weight-bold'>Main postcode:</td>
              <td>
                <TextField
                  value={this.state.user.address.post_code}
                  onChange={this.onPostCodeChange}
                  placeholder='Postcode'
                  errorText={this.state.postCodeErrorText}
                />
              </td>
            </tr>
          </tbody>
        </table>

        {this.renderActions()}
        <FrindowNotification errors={this.state.errorsNotify} messages={this.state.messages} clearNotices={() => this.setState({ errorsNotify: [], messages: [] })} />
      </React.Fragment>
    )
  }
}
