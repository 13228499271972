import React from 'react'

const DeleteModal = (props) => {
  return(
    <div className='r-modal show'>
      <div className='r-modal-content'>
        <div className='r-modal-header'>
          <h4 className='h1 modal-title'>Delete {props.resourceType}</h4>
          <button aria-label='Close' className='btn btn-close' type='button' onClick={props.onModalClose}></button>
        </div>
        <div className='r-modal-body'>
          <h2 className='text-gray'>Are you sure you want to delete this {props.resourceType}?</h2>
          <p className='text-gray'>This action cannot be undone once you click delete.</p>
        </div>
        <div className='r-modal-footer'>
          <button className='btn btn-big btn-secondary btn-cancel' onClick={props.onModalClose}>Cancel</button>
          <button className='btn btn-big btn-primary btn-delete' onClick={props.onDestroyConfirmation}>Delete {props.resourceType}</button>
        </div>
      </div>
    </div>
  )
}

export default DeleteModal