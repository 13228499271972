import React from 'react'
import SimpleSelectField from '../shared/simple_select_field'
import BeforeJoinGroup from '../groups/shared/before_join_group'
import { GET_TOGETHER_ACTIONS } from '../../utils/constants'
import { makeGetRequest } from '../shared/api.js'

class BackgroundGetTogether extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      openModal: false,
      inGroup: false
    }
  }

  handleEditClick = () => {
    window.location += '/edit'
  }

  renderActions = () => {
    if (this.props.current_user_host) { return (
      <div className='col-md'>
        <p className='form-notice'>You are host of this get together</p>
        <div className='form-actions'>
          <button onClick={this.handleEditClick} className='btn btn-primary btn-update'>Edit</button>
        </div>
      </div>
    ) }
    const { userInReserve, userJoined, getTogetherAction, handleGetTogetherActionChange, getTogetherFull } = this.props
    if(getTogetherFull && !userJoined) { return (<p className='text-danger font-weight-bold col'>THIS GET TOGETHER IS NOW FULL.</p>) }

    const { userInGroup } = this
    const selectOption = userJoined ? Object.values(GET_TOGETHER_ACTIONS) : Object.values(GET_TOGETHER_ACTIONS).slice(0,-1)
    return (
      <React.Fragment>
        <div className='col-md-auto'>
          <div className='form-group'>
            <SimpleSelectField
              collection={selectOption}
              value={getTogetherAction}
              onChange={handleGetTogetherActionChange}
              emptyKey='Please select'
              errorPrompt={false}
            />
          </div>
        </div>
        <div className='col-md'>
          <div className='form-actions'>
            <button onClick={userInGroup} className='btn btn-primary btn-update'>Save</button>
          </div>
        </div>
        {(userJoined && !userInReserve) && <p className='user-status u-in'>You are in</p>}
      </React.Fragment>
    )
  }

  allowToJoin = () => {
    e.stopPropagation()
    e.preventDefault()
    const { userInReserve, userJoined, handleGetTogetherActionClick } = this.props
    if(userJoined || userInReserve) { return handleGetTogetherActionClick() }

    return this.userInGroup()
  }

  userInGroup = () => {
    const { in_group_url, handleGetTogetherActionClick } = this.props
    makeGetRequest({ url: `${in_group_url}` })
         .then((response) => {
           if(response.data.in_group) { return handleGetTogetherActionClick() }

           this.setState({ inGroup: response.data.in_group, openModal: true });
         });
  }

  handleClickClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    document.body.classList.remove('unpin')
    this.setState({openModal: false})
  }

  afterJoin = () => {
    const { handleGetTogetherActionClick } = this.props
    this.setState({ inGroup: true, openModal: false })
    if (typeof ga !== 'undefined') { ga('send', 'event', 'Group', 'user joined') }
    return handleGetTogetherActionClick(Event.new)
  }

  renderJoinModal = () => {
    const { afterJoin, handleClickClose } = this
    const { openModal } = this.state
    const { url_join_group } = this.props

    if(!openModal) { return null }

    return(
      <BeforeJoinGroup
        join={url_join_group}
        handleClickClose={handleClickClose}
        joined={false}
        afterJoin={afterJoin}
        infoText='You need to be a member of this group to join get together.' />
    )
  }

  render = () => {
    const { present_date, title, imageUrl } = this.props
    return(
      <div className='col-12 col-lg-8 order-lg-2'>
        <div className='get-together-tile tile tile-overlay on-dark child-distance h-100' style={{backgroundImage: `url(${imageUrl})`}}>
          <div className='tile-header'>
            <h2 className='get-together-date'>{present_date}</h2>
            <h1 className='get-together-title'>{title}</h1>
          </div>
          <div className='tile-footer row'>
            {this.renderActions()}
            {this.renderJoinModal()}
          </div>
        </div>
      </div>
    )
  }
}

export default BackgroundGetTogether;
