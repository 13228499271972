import React, { Component } from 'react'
import ImageUploader from '../shared/image_uploader'
import FrindowSpinner from '../shared/frindow_spinner'
import FrindowNotification from '../shared/frindow_notification'
import { makeDeleteRequest } from '../shared/api'

export default class Image extends Component {
  constructor(props){
    super(props)

    this.state = {
      messages: [],
      errors: [],
      showSpinner: false,
      images: this.props.photos
    }
  }

  addError = (errorMsg) => {
    this.setState({ errors: [...this.state.errors, errorMsg], showSpinner: false })
  }

  addImage = (file) => {
    const { images } = this.state
    const newImages = images
    newImages[0] = file
    if(file){
      this.setState({
        images: newImages,
        showSpinner: false,
        messages: ['Your photo has been added successfully.']
      })
    }
  }

  handleImgRemoveClick = (e) => {
    e.stopPropagation();
    this.setState({ showSpinner: true })

    const { client_profile } = this.props
    this.id = parseInt(e.target.value)
    makeDeleteRequest({ url: `/client_profiles/${client_profile.id}/photos/${e.target.value}` }).then((res) => {
      if(res.status === 200){
        this.setState({ images: [], messages: ['Photo has been successfully deleted'], showSpinner: false })
      }
    })
  }

  uploadStart = () => {
    this.setState({ showSpinner: true })
  }

  render = () => {
    if (!this.props.client_profile.id) return null
    // eslint-disable-next-line no-unused-vars
    const { client_profile, errors } = this.props
    const { images } = this.state

    let styles = {}

    if(images.length > 0){
      styles = { backgroundImage: `url(${images[0].url})` }
    }

    return(
      <div className='form my-4'>
        <p>
          Please press or click on the box below up upload your profile image.
          We accept image in JPG or PNG format. <span className="text-danger">**</span>
        </p>
        <b>Please make your image as square as possible for best results.</b>
        <div className='form mt-3'>

          {images.length > 0 ?
            <div className="profile-picture with-image" style={styles}>
               <button value={images[0].id}
                       className='btn btn-remove'
                       onClick={this.handleImgRemoveClick}></button>
            </div>
             :  <ImageUploader
                  uploadUrl={`/client_profiles/${client_profile.id}/photos`}
                  addImage={this.addImage}
                  imagesLength={1}
                  addError={this.addError}
                  uploadStart={this.uploadStart}
                  autoUpload={true}
                />
          }
        </div>
        {this.state.showSpinner && <FrindowSpinner />}

        <FrindowNotification errors={this.state.errors} messages={this.state.messages} clearNotices={() => this.setState({ errors: [], messages: [] })} />
      </div>
    )
  }
}
