import React, { useState, useEffect, useRef } from 'react';
import TextField from '../../shared/text_field'
import Container from './container'
import { EMAIL_REGEX, PROXY_REGEX } from '../../../utils/constants'
import { makePostRequest } from '../../shared/api.js'
import { APP_ROUTES } from '../../shared/routes'
import _ from 'lodash'
import i18n from '../../../utils/i18n'

const NameFields = (props) => {
  const { firstName, lastName, email, confirmEmail, code, availableCodes, step, business } = props
  const [errors, setErrors] = useState({})
  const [loading, setLoading] = useState(false)
  const isFirstRender = useRef(true)

  useEffect(() => {
    if(!isFirstRender.current && !loading) {
      if(!errorsExists()) props.handleNextClick()
    }
  }, [loading])

  useEffect(() => {
    isFirstRender.current = false
  }, [])

  function handleTextChange(e) {
    props.handleTextChange(e)
    const name = e.currentTarget.getAttribute('name')
    setErrors(errors => ({...errors, [name]: ''})) 
  }

  function errorsExists() {
    return !_.isEmpty(_.pickBy(errors, (a => a !== '')))
  }

  const fields = [<TextField
                   key='key_firstname'
                   value={firstName}
                   name='firstName'
                   onChange={handleTextChange}
                   placeholder={i18n.t('signUp.firstName')}
                   errorText={errors.firstName}
                 />,
                 <TextField
                   key='key_lastName'
                   value={lastName}
                   name='lastName'
                   onChange={handleTextChange}
                   placeholder={i18n.t('signUp.lastName')}
                   errorText={errors.lastName}
                 />,
                  <TextField
                   key='key_email'
                   value={email}
                   name='email'
                   onChange={handleTextChange}
                   placeholder={i18n.t('signUp.email')}
                   errorText={errors.email}
                 />,
                 <TextField
                   key='key_confirmEmail'
                   value={confirmEmail}
                   name='confirmEmail'
                   onChange={handleTextChange}
                   placeholder={i18n.t('signUp.confirmEmail')}
                   errorText={errors.confirmEmail}
                 />,
                 (<div key='key_code' className="row mx-0 align-items-center business-field flex-nowrap">
                  <TextField
                    value={code}
                    name='code'
                    onChange={handleTextChange}
                    placeholder={i18n.t('shared.code')}
                    errorText={errors.code}
                  />
                  <div className="tip ml-2 mb-3">
                    <span data-title-right={`Frindow Business is an invitation only service. You will only be able to join if you have been provided with a code. If you would like you join, please write to business@frindow.com.`} >
                      <i className="icon icon-i-in-circle" />
                    </span>
                  </div>
                 </div>),
                 // eslint-disable-next-line react/jsx-key
                 <p key='key_par' style={{ fontSize: '13px', fontWeight: 500, maxWidth: '370px'}}>
                   For more information on how we use your personal data, please refer to our <a href="/pages/privacy">Privacy Policy​.</a>
                 </p>
                ]

  function validateEmail (){
    setLoading(true)
    if(email !== confirmEmail) {
      setErrors(errors => ({ ...errors, confirmEmail: "Email addresses must match" }))
    }
    if(EMAIL_REGEX.test(email.trim())) {
      makePostRequest({ url: APP_ROUTES.users.checkEmail, body: { email }}).then(res => {
        if(res.data.email_exists) { setErrors(errors => ({ ...errors, email: 'You are already registered. Please login.'})) }
        setLoading(false)
      })
    } else {
      setErrors(errors => ({ ...errors, email: 'Please provide a valid email address.'}))
    }
  }

  function validateName(name, key) {
    const trimName = name.trim()

    if(trimName.length < 2) {
      return setErrors(errors => ({...errors,  [key]: 'The minimum length is two characters.'}))
    }
    if(!PROXY_REGEX.test(trimName)) {
      return setErrors(errors => ({...errors,  [key]: 'Sorry, special characters and numbers are not allowed.'}))
    }
  }

  function validateCode() {
    if(!availableCodes.includes(code)) {
      return setErrors(errors => ({...errors, code: 'Please provide a valid code'}))
    }
  }

  function handleNextClick(e) {
    e.preventDefault()
    e.stopPropagation()
    validateName(firstName, 'firstName')
    validateName(lastName, 'lastName')
    validateCode()
    validateEmail()
  }

  const nextDisabled = !firstName || !lastName || !email || !confirmEmail || !code || errorsExists() 

  return(
    <Container
      customClass='step-sign-up'
      step={step}
      handleNextClick={handleNextClick}
      nextDisabled={nextDisabled}
      // eslint-disable-next-line no-irregular-whitespace
      headerText={`​Your target audience is inside. Start attracting users who need your services.`}
      descText={``}
      business={business}
    >
      <h1>Sign up</h1>
      <p>Here to make new friends? <a href="/users/sign_up">Click here</a>.</p>
      <div className='form'>
        {fields}
      </div>
    </Container>
  )
}

export default NameFields;
