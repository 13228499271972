import React from 'react'
import ProgressBar from '../../shared/progress_bar'
import PostboxPng from 'postbox.png'
import AlertSvg from 'alert.svg'

const PendingInfo = (props) => {
  return(
    <React.Fragment>
      <div className="container confirmation-page">
        <div className="row align-items-start mt-5 mb-5 sign-in-form">
          <div className="col-md-8 col-lg-4 offset-md-0 offset-lg-4 mb-5">
          <ProgressBar step={2} steps={4}></ProgressBar>
            <img src={PostboxPng} className='mt-3 mb-3'></img>
            <h2>Your code will be posted shortly.</h2>
            <p> Thank you for submitting your details.</p>
            <p> Once it has been processed and we have posted the code, we will send you an email.</p>
            <a href={props.rootPath} className='btn btn-primary btn-next mt-4'>Continue exploring</a>
          </div>
          <div className="col-md-4 col-lg-3">
            <div className="info-box mt-5">
              <img src={AlertSvg}></img>
              <h3 className="mt-2">Check your post..</h3>
              <p>...that’s your actual post through your door. Once you receive an email from us, you should receive the letter within 2-3 working days.</p>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default PendingInfo;
