import React from 'react'
import { withRouter } from 'react-router-dom'
import Container from '../sign_up_form/container'
import ImagesModal from './images_modal'
import CompletionHeading from '../shared/completion_heading'
import { ROUTES } from '../shared/routes'
import { PROFILE_TYPES } from '../../utils/constants'
import { makeGetRequest, makeDeleteRequest, makePutRequest } from '../shared/api'
import FrindowSpinner from '../shared/frindow_spinner'
import _ from 'lodash'
import { ACCOUNT_TYPES } from '../../utils/constants'
import Button from '../shared/button'
import ProfileImage from '../shared/profile/profile_image'

class Images extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      profile: null,
      showModal: false,
      images: [{}, {}, {}, {}, {}],
      errors: [],
      messages: [],
      showSpinner: false,
      selectedBox: -1,
      processing: false
    }
  }

  componentDidMount = () => {
    const { profile } = this.props
    if(profile) {
      this.setState({ profile, showSpinner: true }, () => {
        makeGetRequest({ url: `/profiles/${this.props.profile.id}/photos` }).then((res) => {
          const { success, errors, images } = res.data
          if(success) {
            const sortedImages = this.setImagesPosition(images)
            this.setState({ images: sortedImages, showSpinner: false })
          } else {
            this.setState({ errors, showSpinner: false })
          }
        })
      })
    }

    if (process.env.NODE_ENV === 'production') {
      const comment = document.createComment('Event snippet for Profile Complete conversion page');
      document.body.appendChild(comment);

      const script = document.createElement("script");
      script.innerHTML = "gtag('event', 'conversion', {'send_to': 'AW-701811519/GQuOCLmZwZoCEL-W084C'});";
      document.body.appendChild(script);
    }
  }

  setImagesPosition = (unOrderedImages) => {
    const { images } = this.state
    const orederedImages = images.map((image, index) => {
      return unOrderedImages.find(un => un.position === index)
    })
    return orederedImages
  }

  closeModal = () => {
    this.setState({ showModal: false })
  }

  uploadStart = () => {
    this.setState({ showSpinner: true })
  }

  addImage = (file) => {
    const { images, selectedBox } = this.state
    const newImages = images
    newImages[selectedBox] = file
    if(file){
      this.setState({
        images: newImages,
        showModal: false,
        showSpinner: false,
        messages: ['Your photo has been added successfully.']
      })
    }
  }

  addError = (errorMsg) => {
    this.setState({ errors: [...this.state.errors, errorMsg] })
  }

  addMessage = (msg) => {
    this.setState({ messages: [...this.state.messages, msg] })
  }

  clearNotices = () => {
    this.setState({ messages: [], errors: [] })
  }

  handleImageBtnClick = (e) => {
    e.stopPropagation();

    const index = parseInt(e.target.dataset.index)

    if (index >= 0 && _.isEmpty(this.state.images[index])) {
      this.setState({
        showModal: true,
        selectedBox: index
      })
    }
  }

  clearNotices = () => {
    this.setState({ messages: [], errors: [] })
  }

  handleImgRemoveClick = (e) => {
    e.stopPropagation();
    this.setState({ showSpinner: true })

    const { profile } = this.state
    this.id = parseInt(e.target.value)
    makeDeleteRequest({ url: `/profiles/${profile.id}/photos/${e.target.value}` }).then((res) => {
      if(res.status === 200){
        const { images } = this.state
        const newImages = images.map((image) => {
          if(image && image.id === this.id) return {}
          return image
        })
        this.setState({ images: newImages, messages: ['Photo has been successfully deleted'], showSpinner: false })
      }
    })
  }

  renderHeading = () => {
    const { profile } = this.state
    if (!profile) return null

    if (profile.type === PROFILE_TYPES.direct) {
      return (
        <CompletionHeading
          headText='How about a photo of the real you?'
          helperQuestion='Why do we need this?'
          helperText='Having a photo is optional, but having one makes you much more approachable.'
        />
      )
    }
    return (
      <CompletionHeading
        headText={`How about a photo of ${profile.first_name}`}
        helperQuestion='Do I need this?'
        helperText={`No, it’s optional. It just gives standard users and other proxy users an idea of who you're talking about. Please Get their consent before uploading.`}
      />
    )
  }

  handleNextClick = () => {
    this.props.history.push(ROUTES.proxy_statements.path)
  }

  handleFinish = () => {
    if(this.state.processing) { return null }

    const { user } = this.props
    const { id } = user
    this.setState({ processing: true})
    makePutRequest({ url: `/users/${id}`, body: { id, user: { current_step: 6 } }}).then((res) => {
      if(res.data.success){
        document.getElementById('finish_form').submit()
      }
      else { this.setState({ processing: false}) }
    })
  }

  handleComeBackLater = () => {
    //TODO clarify path
    this.handleFinish()
  }

  renderActions = () => {
    if(this.jointUserDirectView()){
      return(
        <div className='form-actions'>
          <Button className='btn-primary btn-next' title='Next' onClick={this.handleNextClick} />
        </div>
      )
    }
    return(
      <div className="form-actions space-between">
        <form action='/' id='finish_form' />
        <Button className="btn btn-primary btn-next btn-submit" title='Finish!' onClick={this.handleFinish} />
        <Button className="btn btn-secondary" title='Come back to this later' onClick={this.handleComeBackLater} />
      </div>
    )
  }

  jointUserDirectView = () => (
    this.props.location.pathname === ROUTES.direct_images.path && this.props.user['account_type'] === ACCOUNT_TYPES.joint
  )

  current_step = () => {
    return (this.props.step || ROUTES.direct_images.step)
  }

  images_length = () => {
    const { images } = this.state
    return images.filter(i => !_.isEmpty(i)).length
  }

  render = () => {
    if (!this.props.profile) return null
    const { errors, messages, showModal, selectedBox } = this.state

    let profileHeaderText = 'Did you know that smiling is contagious?';
    let profileDescText = 'Yes, a nice friendly grin can make others smile too. Try it. So say ‘cheese’ (‘vegan cheese’ if you prefer), take a snap and start spreading a little happiness.';
    if (this.props.profile.type === 'ProxyProfile') {
      profileHeaderText = 'Show them off...';
      profileDescText = '..with their consent! Show how friendly your friend or family member looks with a photo. They’ll get more contacts.';
    }

    return (
      <Container
        customClass={'step-upload-photo '+( this.props.profile.type === 'DirectProfile' ? '' : 'proxy')}
        headerText={profileHeaderText}
        descText={profileDescText}
        errors={errors}
        messages={messages}
        clearNotices={this.clearNotices}
      >
        <ImagesModal
          profile={this.props.profile}
          selectedBox={selectedBox}
          isOpen={showModal}
          addImage={this.addImage}
          closeModal={this.closeModal}
          imagesLength={this.images_length()}
          addError={this.addError}
          uploadStart={this.uploadStart}
        />
        {this.renderHeading()}
        <div className='form'>
          <div className='form-group'>
            <div className='photo-upload'>
              <div className='c-2'>
                <ProfileImage
                  fieldName='Main photo'
                  imgContainerIndex='0'
                  handleImageBtnClick={this.handleImageBtnClick}
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  image={this.state.images[0]}
                />
              </div>
              <div className='c-1'>
                <ProfileImage
                  imgContainerIndex='1'
                  handleImageBtnClick={this.handleImageBtnClick}
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  image={this.state.images[1]}
                />
                <ProfileImage
                  imgContainerIndex='2'
                  handleImageBtnClick={this.handleImageBtnClick}
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  image={this.state.images[2]}
                />
              </div>
              <div className='c-1'>
                <ProfileImage
                  imgContainerIndex='3'
                  handleImageBtnClick={this.handleImageBtnClick}
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  image={this.state.images[3]}
                />
                <ProfileImage
                  imgContainerIndex='4'
                  handleImgRemoveClick={this.handleImgRemoveClick}
                  handleImageBtnClick={this.handleImageBtnClick}
                  image={this.state.images[4]}
                />
              </div>
            </div>
          </div>
          <p className='text-gray'>
            Click or press on the boxes above to upload your images.
            <br />We accept images in JPG or PNG format.
          </p>
        </div>
        {this.renderActions()}
        {this.state.showSpinner && <FrindowSpinner />}
      </Container>
    )
  }
}

export default withRouter(Images)
