import React from 'react';
import Container from './container'
import TextField from '../shared/text_field'

const PostcodeFields = ({ handleNextClick, step, postcode, postcodeErrorText, handlePostcodeChange }) => {
  const nextDisabled = !postcode.length || postcodeErrorText

  return (
    <Container
      customClass='step-postcode'
      step={step}
      handleNextClick={handleNextClick}
      nextDisabled={nextDisabled}
      headerText={`From cups of tea to days out…`}
      descText={`...discover a local Frindow community in a social Get Together created by our members. Find events that interest you in one of our many groups.`}
    >
      <h2>What's your postcode?</h2>
      <div className='tip-wraper mb-2'>
        <div className='tip'>
          <span data-title-right={`By knowing where you live, we can assist you in finding friends, activities and communities in your local area. Don't worry, your postcode won't be made public to other members.
`}>Why do we need this?</span>
        </div>
      </div>
      <div className='form'>
        <TextField
          value={postcode}
          onChange={handlePostcodeChange}
          placeholder='Postcode'
          errorText={postcodeErrorText}
        />
      </div>
    </Container>
  )
}

export default PostcodeFields
