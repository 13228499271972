import React, { useState } from 'react';
import Container from './container'
import { getAddressData } from '../../../utils/postcodes'
import { POSTCODE_REGEX } from '../../../utils/constants'
import _ from 'lodash'
import TextField from '../../shared/text_field' 

const PostcodeFields = (props) => {
  const { step, postcode, adminDistrictRef, adminCountyRef, regionRef, countryRef, longitudeRef, latitudeRef, business } = props
  const [errors, setErrors] = useState('')

  function handleTextChange(e) {
    props.handleTextChange(e)
    const name = e.currentTarget.getAttribute('name')
    setErrors(errors => ({...errors, [name]: ''}))
  }

  function validatePostCodeField() {
    if(POSTCODE_REGEX.test(postcode)) {
      getAddressData(postcode).then((res) => {
        if(res.status === 200){
          const { data } = res

          if(data.admin_district) adminDistrictRef.current.value = data.admin_district
          if(data.admin_county)   adminCountyRef.current.value = data.admin_county
          if(data.region)         regionRef.current.value = data.region
          if(data.country)        countryRef.current.value = data.country
          if(data.longitude)      longitudeRef.current.value = data.longitude
          if(data.latitude)       latitudeRef.current.value = data.latitude

          props.handleNextClick()
        } else {
          setErrors({ postcode: 'Please enter a valid postcode' })
        }
      })
    } else {
      setErrors({ postcode: 'Please enter a valid postcode' })
    }
  }

  function handleNextClick(e) {
    e.preventDefault()
    e.stopPropagation()
    validatePostCodeField()
  }

  function errorsExists() {
    return !_.isEmpty(_.pickBy(errors, (a => a !== '')))
  }

  const nextDisabled = !postcode.length || errorsExists()

  return (
    <Container
      customClass='step-postcode'
      step={step}
      handleNextClick={handleNextClick}
      nextDisabled={nextDisabled}
      headerText={`Attract local clients`}
      descText={`We’ll prioritise your profile to clients who live near your service. You could eventually see these clients in person.`}
      business={business}
    >
      <h2>{`Where are you?`}</h2>
      <p>The postcode given here can be for your main office, practice or personal address. It will not be shared. However, you can choose to share your address on your profile.</p>
      <div className='form'>
        <TextField
          value={postcode}
          name='postcode'
          onChange={handleTextChange}
          placeholder='Postcode'
          errorText={errors.postcode}
        />
      </div>
    </Container>
  )
}

export default PostcodeFields
