import React from 'react'
import SimpleImagesUploader from '../shared/simple_images_uploader'

class UploadDropzoneContainer extends React.Component {
  render = () => {
    const { closeModal, handleAddImage } = this.props
    return(
      <div className='r-modal show'>
        <div className='r-modal-content wide'>
          <div className="r-modal-header no-border">
            <h4 className="h1 modal-title">Select get together picture</h4>
            <button aria-label="Close" className="btn btn-close" type="button" onClick={closeModal} />
          </div>
          <div className='r-modal-body'>
            <SimpleImagesUploader handleAddImage={handleAddImage}/>
          </div>
        </div>
      </div>
    )
  }
}

export default UploadDropzoneContainer;
