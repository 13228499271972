import React from 'react'
import { ACCOUNT_TYPE_OPTIONS, PERSON_RELATIONS, ACCOUNT_TYPES } from '../../utils/constants'
import Container from './container'
import SelectField from '../shared/select_field'
import TextField from '../shared/text_field'

const AccountTypeFields = ({ handleAccountTypeChange, handleRelatedPersonRelChange, handleNextClick, handleProxyName,
  accountType, proxyNameErrorText, accountTypeErrorText, proxyName,
  proxyRelationship, proxyRelationshipErrorText, step }) => {

  const fields = [
    <div className='form-group' key='key_proxy_account_type'>
      <SelectField
        collection={ACCOUNT_TYPE_OPTIONS}
        value={accountType}
        onChange={handleAccountTypeChange}
        errorText={accountTypeErrorText}
      />
    </div>
  ]

  if (accountType !== ACCOUNT_TYPES.direct) {
    const proxyFields = [
      <div className='form-group' key='key_proxy_name' >
        <label>What is their name?</label>
        <TextField
          classForm='medium'
          onChange={handleProxyName}
          value={proxyName}
          placeholder='First name'
          errorText={proxyNameErrorText}
        />
      </div>,
      <div className='form-group' key='key_proxy_relationship'>
        <label>What is their relationship with you?</label>
        <SelectField
          collection={PERSON_RELATIONS}
          value={proxyRelationship}
          onChange={handleRelatedPersonRelChange}
          errorText={proxyRelationshipErrorText}
        />
      </div>
    ]
    fields.push(...proxyFields)
  }

  const isNextDisabled = (accountType !== ACCOUNT_TYPES.direct && (!proxyName || !proxyRelationship || proxyNameErrorText || proxyRelationshipErrorText))

  return (
    <Container
      customClass='step-who-are-joining'
      step={step}
      handleNextClick={handleNextClick}
      nextDisabled={isNextDisabled}
      headerText={`Did you know that you can use Frindow for a friend or family member too?`}
      descText={`Why not bring more companionship into the life of someone that you care about? They don’t need to use Frindow themselves, but they can enjoy all of the benefits through you.`}
    >
      <h2>Who are you joining for?</h2>
      <div className='form'>
        {fields}
      </div>
    </Container>
  )
}

export default AccountTypeFields;
