import React, { Component } from 'react'
import DeleteModalWithPasswordInput from '../shared/delete_modal_with_password'
import { makeDeleteRequest } from '../shared/api.js'
import FrindowNotification from '../shared/frindow_notification'

export default class DeleteAccount extends Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false,
      errors: [],
      messages: []
    }
  }

  showModal = (e) => {
    e.preventDefault();
    this.setState({showModal: true});
  }

  hideModal = () => {
    this.setState({showModal: false});
  }

  sendDeleteRequest = (password, reason) => {
    makeDeleteRequest({ url: this.props.deletePath, body: { password: password, reason: reason } }).then((response) => {
      if(response.status === 422) {
        this.setState({errors: response.data.errors})
      }
      if(response.data.success) window.location.href = response.data.path
    })
  }

  handleClearNotice = () => {
    this.setState({errors: [], messages: []})
  }

  render = () => {
    const { hideModal, handleClearNotice, state } = this
    const { showModal, errors, messages } = state
    return(
      <React.Fragment>
        <a onClick={this.showModal} className='btn btn-danger' href=''>
          Delete account
        </a>
        {showModal &&
          <DeleteModalWithPasswordInput
            resourceType='account'
            onModalClose={hideModal}
            onDestroyConfirmation={(password, reason) => this.sendDeleteRequest(password, reason)}>
          </DeleteModalWithPasswordInput>}
        <FrindowNotification
          errors={errors}
          messages={messages}
          clearNotices={handleClearNotice} />
      </React.Fragment>
    )
  }
}
