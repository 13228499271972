import React from 'react'
import SimpleSelectField from '../../shared/simple_select_field'
import { TIME_PERIOD } from '../../../utils/constants'

const TimeSelect = (props) => {
  const { timeHour, timeMin, timePeriod, handleTimePeriodChange, handleTimeHourChange, handleTimeMinChange } = props
  return(
    <div className='row'>
      <div className='col-sm form-group'>
        <div className='form-input-cell select-cell small'>
          <SimpleSelectField
            collection={Array.from({length: 12}, (v, k) => k+1)}
            value={timeHour}
            onChange={handleTimeHourChange}
            emptyKey='Hours'
            errorPrompt={false}
          />
        </div>
      </div>
      <div className='col-sm form-group'>
        <div className='form-input-cell select-cell small'>
          <SimpleSelectField
            collection={Array.from({length: 4}, (v, k) => { if(k === 0) return '00'
                                                            return k*15 })}
            value={timeMin}
            onChange={handleTimeMinChange}
            emptyKey='Minutes'
            errorPrompt={false}
          />
        </div>
      </div>
      <div className='col-sm form-group'>
        <div className='form-input-cell select-cell small'>
          <SimpleSelectField
            collection={TIME_PERIOD}
            value={timePeriod}
            onChange={handleTimePeriodChange}
            emptyKey='Period'
            errorPrompt={false}
          />
        </div>
      </div>
    </div>
  )
}

export default TimeSelect
