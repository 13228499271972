import React from 'react'
import NameFields from './name_fields'
import PostcodeFields from './postcode_fields'
import PasswordFields from './password_fields'
import { PASSWORD_REGEX } from '../../../utils/constants'
import _ from 'lodash'
import i18n from '../../../utils/i18n'

export default class SignUpForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      step: 1,
      firstName: '',
      lastName: '',
      email: '',
      confirmEmail: '',
      code: '',
      postcode: '',
      password: '',
      passwordErrorText: '',
      passwordConfirmation: '',
      passwordConfirmationErrorText: '',
      termsChecked: false,
      termsErrorText: '',
      errors: {}
    }

    this.adminDistrictRef = React.createRef(null)
    this.adminCountyRef = React.createRef(null)
    this.regionRef = React.createRef(null)
    this.countryRef = React.createRef(null)
    this.longitudeRef = React.createRef(null)
    this.latitudeRef = React.createRef(null)
  }

  handleTextChange = (e) => {
    const name = e.currentTarget.getAttribute('name')
    this.setState({ [name]: e.currentTarget.value })
  }

  handleNextClick = () => {
    this.setState(state => ({ step: state.step + 1 }) )
  }

  renderNameFields = () => {
    const { handleTextChange, handleNextClick } = this
    const { firstName, lastName, email, confirmEmail, code, errors, step } = this.state
    const { availableCodes, business } = this.props

    return(
      <NameFields firstName={firstName}
                  lastName={lastName}
                  availableCodes={availableCodes}
                  email={email}
                  confirmEmail={confirmEmail}
                  code={code}
                  handleTextChange={handleTextChange}
                  handleNextClick={handleNextClick}
                  errors={errors}
                  step={step}
                  business={business} />
    )
  }

  renderPostcodeFields = () => {
    const { handleTextChange, handleNextClick, adminDistrictRef, adminCountyRef, regionRef, countryRef, longitudeRef, latitudeRef } = this
    const { postcode, step } = this.state
    const { business } = this.props

    return <PostcodeFields postcode={postcode}
                           step={step}
                           adminDistrictRef={adminDistrictRef}
                           adminCountyRef={adminCountyRef}
                           regionRef={regionRef}
                           countryRef={countryRef}
                           longitudeRef={longitudeRef}
                           latitudeRef={latitudeRef}
                           handleTextChange={handleTextChange}
                           handleNextClick={handleNextClick}
                           business={business} />
  }

  handlePasswordChange = (e) => this.setState({ password: e.target.value, passwordErrorText: '', passwordConfirmationErrorText: '' })

  handlePasswordConfirmationChange = (e) => this.setState({ passwordConfirmation: e.target.value, passwordErrorText: '', passwordConfirmationErrorText: '' })

  handleTermsChange = (e) => this.setState({ termsChecked: e.target.checked, termsErrorText: '' })

  handleSignUpClick = (e) => {
    e.preventDefault()
    this.validatePasswordFields();
  }

  performSubmit = () => {
    document.getElementById('initial_signup_form').submit()
    // Google Analytics
    // if (typeof ga !== 'undefined') { ga('send', 'event', 'User', 'created') }
  }

  validatePasswordFields = () => {
    if(this.validatePassword().length < 1) {
      this.performSubmit()
    }
  }

  validatePassword = () => {
    const { password, passwordConfirmation } = this.state
    let passwordErrorText = ''

    if(!PASSWORD_REGEX.test(password)) {
      passwordErrorText = i18n.t('shared.passwordError')
    } else if (password !== passwordConfirmation) {
      passwordErrorText = 'Please ensure that your password and confirmed password are the same.'
    }
    this.setState({passwordErrorText: passwordErrorText, passwordConfirmationErrorText: passwordErrorText})
    return passwordErrorText
  }

  renderPasswordFields = () => {
    const { password, passwordErrorText, passwordConfirmation, passwordConfirmationErrorText, termsChecked, step } = this.state
    const { handlePasswordChange, handlePasswordConfirmationChange, handleTermsChange, handleSignUpClick } = this
    const { business } = this.props

    return <PasswordFields password={password}
                           step={step}
                           passwordConfirmation={passwordConfirmation}
                           passwordConfirmationErrorText={passwordConfirmationErrorText}
                           passwordErrorText={passwordErrorText}
                           handleSignUpClick={handleSignUpClick}
                           termsChecked={termsChecked}
                           handlePasswordChange={handlePasswordChange}
                           handlePasswordConfirmationChange={handlePasswordConfirmationChange}
                           handleTermsChange={handleTermsChange}
                           business={business} />
  }

  renderStep = () => {
    const { step } = this.state
    let page = ''
    switch(step) {
      case 1:
        page = this.renderNameFields()
        break
      case 2:
        page = this.renderPostcodeFields()
        break
      case 3:
        page = this.renderPasswordFields()
        break
    }
    return page
  }

  render = () => {
    const { email, firstName, lastName, postcode, code } = this.state
    const { adminDistrictRef, adminCountyRef, regionRef, countryRef, longitudeRef, latitudeRef } = this
    return(
      <React.Fragment>
        {this.renderStep()}
        <input type='hidden' name='user[email]' value={email.trim()} />
        <input type='hidden' name='user[first_name]' value={firstName.trim()} />
        <input type='hidden' name='user[last_name]' value={lastName.trim()} />
        <input type='hidden' name='user[code]' value={code.trim()} />
        <input type='hidden' name='user[address_attributes][post_code]' value={postcode.trim()} />
        <input type='hidden' name='user[address_attributes][admin_district]' ref={adminDistrictRef} />
        <input type='hidden' name='user[address_attributes][admin_county]' ref={adminCountyRef} />
        <input type='hidden' name='user[address_attributes][region]' ref={regionRef} />
        <input type='hidden' name='user[address_attributes][country]' ref={countryRef} />
        <input type='hidden' name='user[address_attributes][longitude]' ref={longitudeRef} />
        <input type='hidden' name='user[address_attributes][latitude]' ref={latitudeRef} />
      </React.Fragment>
    )
  }
}
