import React from 'react'
import ReportModal from './modal'
import FrindowNotification from '../shared/frindow_notification'
import { makePostRequest } from '../shared/api'

export default class Container extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      errorsNotify: [],
      errors: {},
      messages: []
    }
    this.handleReportModalSubmit = this.handleReportModalSubmit.bind(this);
  }

  clearNotices = () => {
    this.setState({ errorsNotify: [], messages: [] })
  }

  handleReportModalSubmit = (reason, additional_info) => {
    const params = { reason, additional_info, reportable_id: this.props.objectId, reportable_type: this.props.objectType }
    makePostRequest(
      { url: '/reported_contents', body: { reported_content: params }}
    ).then((response) => {
      this.setState({messages: [response.data.messages]});
      this.props.onReportModalSubmit({status: true});
    }).catch((response) => this.setState({errors: response.errors}));
  }

  render = () => {
    const { handleReportModalSubmit, props, state } = this
    const { objectType, onReportModalClose } = props
    const { messages, errors, errorsNotify, clearNotices } = state
    return(
      <React.Fragment>
        <ReportModal  onReportModalSubmit={handleReportModalSubmit}
                      onReportModalClose={onReportModalClose}
                      resourceType={objectType}
                      errors={errors} />
        <FrindowNotification
          messages={messages}
          errors={errorsNotify}
          clearNotices={clearNotices}
        />
      </React.Fragment>
    )
  }
}
