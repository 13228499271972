import React from 'react'
import { makeGetRequest } from '../shared/api.js'
import _ from 'lodash'

class SearchGroup extends React.Component {
 constructor(props) {
   super(props)
   this.state = {
     proposed: [],
     nameFilter: '',
     focus: true
   }
 }

 componentDidUpdate(prevProps, prevState) {
   if (this.state.nameFilter !== prevState.nameFilter) {
     this.fetchGroups()
   }
 }

 fetchGroups = _.debounce(() => {
   const { url } = this.props
   let fetchUrl = `${url}?name_filter=${this.state.nameFilter}`
   makeGetRequest({ url: `${fetchUrl}` })
         .then((response) => {
           this.setState({proposed: response.data});
         });
 }, 200);

 handleOnChange = (e) => { this.setState({nameFilter: e.target.value}) }

 handleResetClick = (e) => {
   e.stopPropagation()
   e.preventDefault()
   this.setState({ nameFilter: '' })
 }

 proposedGroups = () => {
   return (this.state.proposed.map((group) => <div key={group.id}><a href={group.url}>{group.display_name}</a></div>))
 }

 renderProposedGroup = () => {
   if (this.state.proposed.length === 0 || !this.state.focus) { return null }
   return (
     <div className='search-suggestions'>
      {this.proposedGroups()}
     </div>
   )
 }

 renderResetClick = () => {
   if (this.state.nameFilter.length === 0) { return null }
   return (<button className='search-reset symbol icon-cross-in-circle' onClick={this.handleResetClick}></button>)
 }

 onFocus = () => {
   this.setState({ focus: true })
 }

 onBlur = _.debounce(() => {
   this.setState({ focus: false })
 }, 200);

 render = () => {
   const cssClass = `form-input-cell search-cell ${this.props.cssClass}`
   return(
     <div className={cssClass} onFocus={this.onFocus} onBlur={this.onBlur}>
       <button className='search-submit symbol icon-magnifier'></button>
       <input className='form-control' value={this.state.nameFilter} onChange={this.handleOnChange} placeholder='Search Groups' type='text'/>
       {this.renderResetClick()}
       {this.renderProposedGroup()}
     </div>
   )
 }
}

SearchGroup.defaultProps = {
  cssClass: ''
};

export default SearchGroup;
