import React, { Component } from 'react'
import Button from '../shared/button'
import { makePutRequest } from '../shared/api.js'
import FrindowAvatarSlider from '../shared/profile/frindow_avatar_slider'
import FrindowNotification from '../shared/frindow_notification'

export default class EditAvatar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedAvatarName: null,
      errors: [],
      messages: []
    }
  }

  componentDidMount = () => {
    const selectedAvatarName = this.props.user.avatar
    this.setState({ selectedAvatarName })
  }

  requestUpdateAvatar = () => {
    const { user } = this.props
    const avatar = this.state.selectedAvatarName
    const body = { id: user.id, user: { avatar } }
    return makePutRequest({ url: `/users/${user.id}`, body: body}).then((res) => {
      if(res.data.success) {
        this.setState({ messages: ['Avatar has been updated'], errors: [] })
      } else {
        const avatar_errors = res.data.errors.avatar
        this.setState({ errors: avatar_errors })
      }
    })
  }

  clearNotices = () => {
    this.setState({ errors: [], messages: [] })
  }

  renderActions = () => (
    <div className='form-actions mt-4'>
      <Button
        className='button btn btn-big btn-secondary btn-cancel btn-submit'
        title='Cancel'
        onClick={() => this.setState({ selectedAvatarName: this.props.user.avatar, messages: ['Form has been reseted'] })}
      />
      <Button
        className='btn btn-big btn-primary btn-update btn-submit'
        title='Update'
        onClick={this.requestUpdateAvatar}
      />
    </div>
  )

  render = () => {
    return(
      <React.Fragment>
        <FrindowAvatarSlider
          selectedAvatarName={this.state.selectedAvatarName}
          handleAvatarChange={(selectedAvatarName) => this.setState({ selectedAvatarName })}
        />
        {this.renderActions()}
        <FrindowNotification
          errors={this.state.errors}
          messages={this.state.messages}
          clearNotices={this.clearNotices}
        />
      </React.Fragment>
    )
  }
}
