import React from 'react'
import { withRouter, Redirect } from "react-router-dom";
import { makeGetRequest, makePostRequest, makePutRequest } from '../shared/api'
import _ from 'lodash'
import Container from '../sign_up_form/container'
import CompletionHeading from '../shared/completion_heading'
import AgeRangeSelect from './age_range_select'
import { ROUTES } from '../shared/routes';
import { PROFILE_TYPES, ACCOUNT_TYPES, REASON_TYPES,  CONTACT_FILTER_GENDERS } from '../../utils/constants'
import i18n from '../../utils/i18n'

class Statements extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      reasons: [],
      user_reasons: [],
      personal_reason: { id: null, value: '', private: true },
      dataFetched: false,
      about: '',
      ageStart: 18,
      ageEnd: 100,
      selectedGenderFilter: CONTACT_FILTER_GENDERS.map(o => o.value),
      profile: {},
      errors: []
    }
  }

  componentDidMount = () => {
    const { getReasonsList, getProfileReasons } = this
    const type = this.isDirectProfile() ? REASON_TYPES.direct : REASON_TYPES.proxy
    getReasonsList(type)
    getProfileReasons()
  }

  getReasonsList = (type) => {
    makeGetRequest({ url: `/reasons/list?type=${type}`}).then((res) => {
      if(res.data.success) {
        const { reasons } = res.data
        this.setState({ reasons })
      }
    })
  }

  isDirectProfile = () => this.props.profile.type === PROFILE_TYPES.direct

  getProfileReasons = () => {
    const { profile } = this.props
    this.setState({ profile })

    makeGetRequest({ url: `/profiles/${profile.id}/reasons` }).then((res) => {
      if(res.data.success) {
        const { profile_reasons, about } = res.data
        const personal_reason = _.find(profile_reasons, ['private', true])

        if(personal_reason) this.setState({ personal_reason })

        const user_reasons = _.filter(profile_reasons, (e) => e.private === false)
        this.setState({ user_reasons, about })
      } else {
        console.error(res.data.errors)
      }
    })
  }

  disableUnchecked = (value = true) => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]:not(:checked)')
    checkboxes.forEach((c) => c.disabled = value)
  }

  handleReasonClick = (e) => {
    const { state } = this
    const { reasons, personal_reason } = state
    let { user_reasons} = state
    const { checked, value } = e.target
    let updates = {}
    const reason = _.find(reasons, ['id', parseInt(value)])
    if(checked) {
      user_reasons.push(reason)
      updates = { ...updates, user_reasons}
      if(user_reasons.length > 4) this.disableUnchecked()
    } else {
      _.remove(user_reasons, (ur) => (ur.id == value))
      if(user_reasons.length < 5) this.disableUnchecked(false)
      updates = { ...updates, user_reasons}
      if(reason.other)
      updates = { ...updates, personal_reason: { ...personal_reason, value: '' }}
    }
    this.setState(updates)
  }

  handleNextStep = () => {
    const { state, props } = this
    const { user, profile, location, history } = props
    const { personal_reason, user_reasons, about, ageStart, ageEnd, selectedGenderFilter } = state
    const generalReasons = _.filter(user_reasons, (e) => e.private == false)
    const reasonsIds = _.map(generalReasons, 'id')
    const body = { profile: { general: reasonsIds, personal: personal_reason }}

    makePostRequest({ url: `/profiles/${profile.id}/add_reasons`, body: body }).then((res) => {
      if(res.data.success){
        const params = { profile: { about_me: about } }
        if(user.account_type === ACCOUNT_TYPES.proxy) {
          params.profile.contact_filter = { age_range_start: ageStart,
                                            age_range_end: ageEnd,
                                            gender_filter: selectedGenderFilter }
        }
        makePutRequest({ url: `/profiles/${profile.id}`, body: params }).then((res) => {
          if(res.data.success) {
            this.props.updateProfile({...profile, about_me: about })
            if(user['account_type'] === 'joint'){
              if(location.pathname === ROUTES.direct_statements.path) history.push(ROUTES.details.path)
              else history.push(ROUTES.proxy_images.path)
            } else {
              const { id } = user
              user.current_step = 2
              makePutRequest({ url: `/users/${id}`, body: { id, user }}).then((res) => {
                if(res.data.success) history.push(ROUTES.details.path)
              })
            }
          } else {
            const { age_difference: ageDifference, date_invalid: dateInvalid } = res.data.errors
            if (dateInvalid) { this.setState({ errors: { ageRange:  dateInvalid } }) }
            else if(ageDifference) { this.setState({ errors: { ageRange: ageDifference } }) }
          }
        })
      }
    })
  }

  handlePersonalReason = (e) => {
    const personal_reason = { ...this.state.personal_reason, value: e.target.value }
    this.setState({ personal_reason })
  }

  handleAbout = (e) => {
    this.setState({ about: e.target.value })
  }

  handleAgeEndChange = (e) => this.setState({ageEnd: e.target.value})

  handleAgeStartChange = (e) => this.setState({ageStart: e.target.value})

  handleGenderFilterChange = (e) => {
    const { checked, value } = e.target
    const { selectedGenderFilter } = this.state
    if(checked && !selectedGenderFilter.find(a => a === value)) {
      this.setState(state => ({selectedGenderFilter: state.selectedGenderFilter.concat(value) }))
    }
    else {
      this.setState(state => ({selectedGenderFilter: state.selectedGenderFilter.filter(a => a !== value) }))
    }
  }

  renderLabel = (genderFilter) => {
    return(
      <label className='bullet-checkbox' key={`key_open_${genderFilter.value}`}>
        <input
          onChange={this.handleGenderFilterChange}
          type='checkbox'
          value={genderFilter.value}
          defaultChecked={this.state.selectedGenderFilter.includes(genderFilter.value)}
        />
        <div className="bullet">{genderFilter.label}</div>
      </label>
    )
  }

  renderReasons = () => {
    const { state } = this
    const { user_reasons, reasons } = state

    return(
      <div className="form-check escape-parent">
        {reasons.map((reason) => (
          <label className="bullet-checkbox" key={'label_reason_' + reason.id}>
            <input
              name="related[]"
              onClick={this.handleReasonClick}
              type="checkbox"
              value={reason.id}
              defaultChecked={_.find(user_reasons, ['id', reason.id])}
            />
            <div className="bullet">{reason.value}</div>
          </label>
        ))}
      </div>
    )
  }

  showPersonalReason = () => {
    return _.find(this.state.user_reasons, ['other', true])
  }

  renderPersonalReason = () => {
    const { state, handlePersonalReason } = this
    const { user_reasons, personal_reason } = state

    if(_.find(user_reasons, (ur) => (ur.other) )){
      return (
        <div className="form-group">
          <div className="form-input-cell">
            <input onChange={handlePersonalReason} className="form-control" placeholder={'Please provide your own statement'} type='text' value={ personal_reason.value }  />
          </div>
        </div>
      )
    }
    return null
  }

  renderHeading = () => {
    const { profile } = this.props
    if(profile.type === 'DirectProfile') {
      return(
        <CompletionHeading
          headText='Tell us a bit about yourself…'
          descriptionText='Please select up to 5 statements that most closely relate to you:'
          helperQuestion='Why do we need this?'
          helperText={'Having an idea of what motivates you to use Frindow helps us to pick out the best stuff for you.'}
        />
      )
    } else {
      return(
        <CompletionHeading
          headText={`You are using this site because ${profile.first_name}`}
        />
      )
    }
  }

  renderAbout = () => {
    const { props, handleAbout } = this
    const { profile } = props
    if(profile.type === 'ProxyProfile') {
      return(
        <React.Fragment>
          <CompletionHeading
            headText={`About ${profile.first_name}`}
            helperQuestion='Need some help?'
            helperText={'Use the space below to explain why you want them to be more connected.'}
          />
          <div className="form-group">
            <div className="form-input-cell">
              <textarea value={this.state.about} onChange={handleAbout} className="form-control" placeholder={`Tell us about ${profile.first_name}...`}/>
            </div>
          </div>
        </React.Fragment>
      )
    }
  }

  renderContactFilter = () => {
    const { user } = this.props
    const { ageStart, ageEnd, errors } = this.state
    const hideAdditionalOptions = true

    if(user.account_type !== ACCOUNT_TYPES.proxy || hideAdditionalOptions) { return null }

    return(
      <React.Fragment>
        <CompletionHeading
          headText={i18n.t('profileCompletion.opento.proxyHappyToBeContactedBy')}
        />
        <div className='form-check escape-parent'>
          {CONTACT_FILTER_GENDERS.map((o) => (this.renderLabel(o)))}
        </div>
        <CompletionHeading
          headText={i18n.t('profileCompletion.opento.proxyIdealAgeRangeTxt')}
        />
        <AgeRangeSelect
          ageStart={ageStart}
          handleAgeStartChange={this.handleAgeStartChange}
          ageEnd={ageEnd}
          handleAgeEndChange={this.handleAgeEndChange}
          errors={errors} />
      </React.Fragment>
    )
  }

  isNextDisabled = () => {
    if(this.isDirectProfile()){
      if(this.state.user_reasons.length === 0) return true
    } else {
      if(!this.state.about || this.state.selectedGenderFilter.length < 1) return true
    }

    return false
  }

  current_step = () => {
    if(this.props.user.account_type === 'joint') return 5
    return 1
  }

  render = () => {
    const { state, renderAbout, renderReasons, renderPersonalReason,
      showPersonalReason, handleNextStep, props, renderHeading, renderContactFilter } = this
    const { reasons } = state

    let statementsHeaderText = 'A little info about you...';
    let statementsDescText = `...goes a long way in helping us show you stuff that is relevant.`;
    if (this.props.profile.type === 'ProxyProfile') {
      statementsHeaderText = 'Changing lives';
      statementsDescText = `For someone who may be lonely or
      isolated, getting connected could be a lifesaver. Start here by letting others know a little about them.`;
    }

    if(props.location === ROUTES.direct_statements.path && props.user.account_type === 'proxy') {
      return <Redirect to={ROUTES.proxy_statements.path} />
    }
    return(
      <Container customClass={'step-statments '+( this.props.profile.type === 'DirectProfile' ? '' : 'proxy')}
                 headerText={statementsHeaderText}
                 descText={statementsDescText}
                 handleNextClick={handleNextStep}
                 nextDisabled={this.isNextDisabled()}
      >
        {renderHeading()}
        {reasons.length > 0 &&
          <div className='form'>
            {renderReasons()}
            {showPersonalReason() && renderPersonalReason()}
            {renderAbout()}
            {renderContactFilter()}
          </div>
        }
      </Container>
    )
  }
}

export default withRouter(Statements)
