import React from 'react'
import SwearWord from './swear_word'
import FrindowNotification from '../../shared/frindow_notification'
import { makePostRequest, makePutRequest } from '../../shared/api'
import i18n from '../../../utils/i18n'

export default class SwearWordsContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      messages: [],
      errors: [],
      processing: false,
      swearWords: props.swearWords
    }
  }

  createSwearWord = (swearWord, index) => {
    makePostRequest({ url: this.props.createSwearWordUrl,
                      body: { swear_word: swearWord } }).then((res) => {
                        const { swearWords } = this.state
                        swearWords[index].id = res.data.id
                        this.setState({ messages: [res.data.message],
                                        processing: false,
                                        errors: [],
                                        swearWords: swearWords })
                      }).catch(response => this.onError(response))
  }

  updateSwearWord = (swearWord) => {
    makePutRequest({ url: `${this.props.createSwearWordUrl}/${swearWord.id}`,
                      body: { swear_word: swearWord } }).then((res) => {
                        this.setState({ messages: [res.data.message],
                                        errors: [],
                                        processing: false })
                      }).catch(response => this.onError(response))
  }

  handleClickSave = (e) => {
    e.preventDefault()
    if(this.state.processing) { return null }

    this.setState({processing: true})
    const index = e.currentTarget.dataset.swearindex
    const swearWord = this.state.swearWords[index]

    if(parseInt(swearWord.id) > 0) { return this.updateSwearWord(swearWord) }

    return this.createSwearWord(swearWord, index)
  }

  onError = (response) => {
    this.setState({
      messages: [],
      errors: Object.entries(response.errors).map(([_k,v]) => v),
      processing: false
    })
  }

  handleChangeName = (e) => {
    const { swearWords } = this.state
    const index = parseInt(e.currentTarget.getAttribute('name'))
    swearWords[index].name = e.currentTarget.value

    this.setState({ swearWords: swearWords })
  }

  handleClickAdd = (e) => {
    e.preventDefault()
    this.setState(state => ({swearWords: state.swearWords.concat({name: '', id: ''}) }) )
  }

  render() {
    return(
      <div className='container admin-users-table pt-3'>
        <div className='table-responsive overflow-hidden'>
          <table className='table'>
            <thead>
              <tr>
              <th></th>
              <th>Name</th>
              <th></th>
            </tr>
            </thead>
            <tbody>
              {this.state.swearWords.map((swearWord, index) => {
                return(
                  <SwearWord
                    key={index}
                    swearWord={swearWord}
                    index={index}
                    handleChangeName={this.handleChangeName}
                    handleClickSave={this.handleClickSave}
                  />
                )
                }
              )}
            </tbody>
          </table>
          <div className='row'>
            <div className='col-auto ml-auto'>
              <button
                className='btn btn-primary btn-big'
                type='submit'
                onClick={this.handleClickAdd} >
                  {i18n.t('shared.add')}
              </button>
            </div>
          </div>
          <FrindowNotification
            messages={this.state.messages}
            errors={this.state.errors}
            clearNotices={this.clearNotices}
          />
        </div>
      </div>
    )
  }
}
