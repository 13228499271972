import React from 'react'
import { MSG_MAX_CHARS_TO_SHOW } from '../../../utils/constants'

class QuotedPost extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      readMore: false,
    }
  }

  toggleReadMore = () => this.setState({readMore: !this.state.readMore})

  render = () => {
    const { quotedPost }= this.props
    const { readMore } = this.state

    if (!quotedPost) { return null }
    if(quotedPost.discarded_at) { return null }

    const { user_name, deleted_user } = quotedPost

    const isMsgLong = quotedPost.content.length > MSG_MAX_CHARS_TO_SHOW
    let quote = quotedPost.content.slice(0, MSG_MAX_CHARS_TO_SHOW)
    let buttonText = 'read more';
    if (readMore) {
      quote = quotedPost.content
      buttonText = 'read less'
    }
    return (
      <blockquote>
        {!deleted_user && <a href=''>{user_name}</a>}
        <span dangerouslySetInnerHTML={{__html: quote.split('&amp;').join('&')}} />
        {!readMore && isMsgLong && '...'}
        {isMsgLong && <button onClick={this.toggleReadMore} className="ml-2 read-more-btn">{buttonText}</button>}
      </blockquote>
    )

  }
}

export default QuotedPost;
