import React from 'react'

export default function CodeConfirmationAlert(interlocutorName) {
  if (interlocutorName) {
    return <div className='alert alert-danger'>
      Please confirm your account to write to {interlocutorName}.
      <a href='/code_confirmation'> Please click here</a> to learn more.
    </div>
  } else {
    return <div className='alert alert-danger'>
      Please confirm your account to write a forum message.
      <a href='/code_confirmation'> Please click here</a> to learn more.
    </div>
  }
}
