import React from 'react'
import SiteGroupsSelector from './site_groups_selector'
import Select from 'react-select'

class FilterBy extends React.PureComponent {
  renderName = (name) => {
    let localName = name.slice(0,-4);
    if (localName === 'other') localName += ' gender'
    return localName.charAt(0).toUpperCase() + localName.replace('_', ' ').slice(1)
  }

  render = () => {
    const { filters, siteGroups, userGroups, selectedGroupIds, handleSelectGroup, handleCloseFilterBy, handleCheckboxChange, handleClickApplyFilters, is_open, handleAgeFilterChange, minAge, maxAge } = this.props
    const tabindex = is_open? 0: -1;
    const open = is_open? 'is-open': '';
    const minAgeRangeOptions = Array.from({ length: 53 }, (_v, k)=> ({ label: k + 18, value: k + 18 }));
    const maxAgeRangeOptions = Array.from({ length: 76 }, (_v, k)=> ({ label: k + 25, value: k + 25 }));

    return(
      <div className={`search-filter ${open}`} tabIndex={tabindex} aria-hidden={!is_open}>
        <h4 className='search-filter-title filers-name'></h4>
        <button aria-label='Close' className='btn btn-close' type='button' onClick={handleCloseFilterBy}></button>
        {filters.map((filter) => {
          return (
            <div key={filter.name} className='form-check'>
              <div className='form-input-cell checkbox-cell' >
                <input className='form-check-input' type='checkbox' onChange={handleCheckboxChange} name={filter.name} id={filter.name} value={filter.value} checked={filter.value} />
                <label className='form-check-label' htmlFor={filter.name}>
                  <span className='checkbox'></span>
                  {this.renderName(filter.name)}
                </label>
              </div>
            </div>
          )
        })}
        <h4>Age range:</h4>
        <div className='row'>
          <div className='col-md-4'>
            <Select
              options={minAgeRangeOptions}
              onChange={(e) => { handleAgeFilterChange(e, 'minAge') }}
              value={minAge}
              className={'form-input-cell'}
              placeholder={'--'}
            />
          </div>
          <div className='col-md-2'>
            <h3 style={{paddingTop: '6px'}}>to</h3>
          </div>
          <div className='col-md-4'>
            <Select
              options={maxAgeRangeOptions}
              onChange={(e) => { handleAgeFilterChange(e, 'maxAge') }}
              value={maxAge}
              className={'form-input-cell'}
              placeholder={'--'}
            />
          </div>
        </div>
        <SiteGroupsSelector
          siteGroups={siteGroups}
          userGroups={userGroups}
          selectedGroupIds={selectedGroupIds}
          handleSelectGroup={handleSelectGroup}/>
        <button
          className='btn btn-primary btn-big btn-select'
          onClick={(event) => {
            handleClickApplyFilters(event);
          }}
        >
          Apply
        </button>
      </div>
    )
  }
}

export default FilterBy;
