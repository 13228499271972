import React from 'react'
import { getPhotoUrl } from '../profile/profile_button'
import { PROFILE_TYPES } from '../../utils/constants'
import ProfileUrl from '../shared/profile/profile_url'
import ConversationImage from '../shared/profile/conversation_image'
import script_google_ads from '../../utils/script_google_ads'

const InfoHorizontalBar = (props) => {
  const { advert, interlocutor_profile } = props;
  const { location, username, avatar, online, photo_url, groups, profile_path, profile, deleted, system } = interlocutor_profile
  const linkclassName = profile.type === PROFILE_TYPES.direct ? 'circle-avatar size-md' : 'circle-avatar size-md proxy-marker'
  const url = getPhotoUrl(photo_url, avatar)
  const isOnlineClass = online ? 'user-name is-online' : 'user-name'
  const clickable = deleted || system
  const hasAdvertClass = advert? 'with-advert' : ''

  React.useEffect(() => {
    if(advert && advert.includes('adsbygoogle.js')) {
      script_google_ads()
    }
  }, []);

  return(
    <div className={`conversation-horizontal-bar bg-white shadow pt-1 pb-1 ${hasAdvertClass}`}>
      { advert && <div className='container py-3 px-0 conversation-header-advert'>
        <div className='advert' style={{ height: 50, width: 320, margin: '0 auto'}} dangerouslySetInnerHTML={{ __html: advert}} />
      </div> }
      <div className='container'>
        <div className='row justify-content-center conversation-header-content '>
          <div className='col-12 col-md-auto user-profile text-center'>
            <ConversationImage
              profile_path={profile_path}
              linkclassName={linkclassName}
              url={url}
              deleted={deleted}
              system={system} />
            <h2 className={isOnlineClass}>
              <ProfileUrl
                profile_path={profile_path}
                username={username}
                deleted={deleted}
                system={system} />
            </h2>
            {!clickable &&
              <p className='h3 user-location hide-on-mobile'>{location}</p>}
          </div>
        </div>
      </div>
    </div>
  )
}

export default InfoHorizontalBar
