export const ROUTES = {
  welcome           : { path: '/profile_completion/welcome',           step: 0 },
  direct_statements : { path: '/profile_completion/direct_statements', step: 1 },
  details           : { path: '/profile_completion/details',           step: 1 },
  open_to           : { path: '/profile_completion/open_to',           step: 2 },
  keywords          : { path: '/profile_completion/keywords',          step: 3 },
  avatars           : { path: '/profile_completion/select_avatar',     step: 4 },
  direct_images     : { path: '/profile_completion/images',            step: 4 },
  proxy_statements  : { path: '/profile_completion/proxy_statements',  step: 5 },
  proxy_images      : { path: '/profile_completion/proxy_images',      step: 5 },
}

export const APP_ROUTES = {
  unsplash: '/unsplash',
  follows: '/follows',
  keywords: '/keywords',
  search: {
    page: 'search',
    by_username: 'search/by_username',
    proposed_usernames: 'search/proposed_usernames'
  },
  users: {
    checkEmail: '/users/check_email',
    checkUsername: '/users/check_username'
  },
  payments: {
    checkout: '/credit_purchases'
  },
  my_account: '/my_account',
  buy_credits: '/buy_credits',
  buy_a_badge: '/buy_a_badge',
  services: '/services'
}

export const EXTERNAL_ROUTES = {
  blog: 'https://www.frindow.com/blog',
  support: 'https://frindow.freshdesk.com/support/home'
}

const paths = {
  DirectProfile: {
    1: ROUTES.direct_statements.path,
    2: ROUTES.details.path,
    3: ROUTES.keywords.path,
    4: ROUTES.avatars.path,
    5: ROUTES.direct_images.path,
  },
  ProxyProfile: {
    1: ROUTES.proxy_statements.path,
    2: ROUTES.details.path,
    3: ROUTES.keywords.path,
    4: ROUTES.avatars.path,
    5: ROUTES.proxy_images.path,
  }
}

export function getRoute (profile, key){
  if(key) return paths[profile.type][key]
}

export function unFollowPath(id) {
  return `/follows/${id}`
}

export function profileKeywordsPath(profile_id) {
  return `/profiles/${profile_id}/keywords`
}

export function nextQuestionPath(ordinal) {
  return `/next_question/${ordinal}`
}

export function gainQuestionnaireScoreAccessPath(ownerId) {
  return `/access_questionnaire_score/${ownerId}`
}
