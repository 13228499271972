import React, { useEffect, useState } from 'react'
import i18n from '../../utils/i18n'
import _ from 'lodash'

export default function QuestionnairePage (props) {
  const [questionWeightChecked, setQuestionWeightChecked] = useState(false)
  const [currentCategory, setCurrentCategory] = useState(null)
  const [responseId, setReponseId] = useState()
  const { question } = props
  const saveExitBtn = document.getElementById('save-exit-bar-btn')

  const prepareParams = () => {
    return {
      user_response: {
        question_response_id: responseId,
        question_weight_important: questionWeightChecked
      }
    }
  }

  useEffect(() => {
    setQuestionWeightChecked(question.weightImportant)
    setCurrentCategory(props.categories.find((category) => category.id == question.questionCategoryId))
    const response = _.find(question.responses, (r) => r.checked)
    if(response) { setReponseId(response.value) } else { setReponseId(null) }
  }, [question.id])

  useEffect(() => {
    if(responseId === null){
      saveExitBtn.classList.add('btn-disabled')
      saveExitBtn.setAttribute('disabled', 'disabled')
    } else {
      saveExitBtn.classList.remove('btn-disabled')
      saveExitBtn.removeAttribute('disabled')
    }
    saveExitBtn.classList.remove('hide')
    const clickFunction = (e) => {
      e.preventDefault()

      if(responseId) {
        props.nextQuestion(prepareParams(), true)
      }
    }
    saveExitBtn.addEventListener('click', clickFunction);
    return () => {
      saveExitBtn.removeEventListener('click', clickFunction);
    };
  }, [responseId])

  if(!currentCategory) { return null; }
  return (
    <>
      <header class='questionnaire-header'>
        <div className='header-top container'>
          { question.previousQuestion &&
            <a onClick={() => {props.previousQuestion()}}><i className='icon-a-left'></i></a>
          }
          <div className='progress-bars mobile-hide'>
            { props.categories.map((category) => {
                const current = category.id == currentCategory.id
                const percentage = category.ordinal < currentCategory.ordinal ? 100 : 0

                return <div key={`category-${category.id}`} className={current ? 'active' : ''}>
                  <p>{category.name}</p>
                  <div className='progress'>
                    <div className='progress-bar' role='progressbar' style={{width: `${current ? question.percentagePerCategory : percentage}%`}} aria-valuenow={0}></div>
                  </div>
                </div>
            })}
          </div>
          <div class='per-page mobile-hide'>{question.ordinalNumber} of {props.questionsCount}</div>
        </div>
        <div className='header-title'>
          <h1 className='mobile-show'>
            {currentCategory.name}
          </h1>
          <div className='progress mobile-show'>
            <div className='progress-bar' role='progressbar' style={{width: `${question.percentagePerCategory}%`}} aria-valuenow='0'></div>
          </div>
          <h2>
            {question.headerText}
          </h2>
        </div>
      </header>

      <div className='questionnaire-content'>
        {
          question.responses.map((response) => {
            return <div className='questionnaire-row' key={`response-${response.value}`}>
              <label className='radio-btn'>
                <input checked={responseId == response.value} type='radio' required='required' name='anwsers' value={response.value} onChange={(e) => setReponseId(e.currentTarget.value)}/>
                <div className='btn no-text-transform btn-switch-outline btn-big'>{response.text}</div>
              </label>
            </div>
          })
        }
      </div>

      <div className='questionnaire-footer'>
        <div className='footer-notice'>
            <div className="form-input-cell checkbox-cell">
              <input id="questionWeight" type={'checkbox'} checked={questionWeightChecked} onChange={(e) => setQuestionWeightChecked(!questionWeightChecked)}/>
              <label htmlFor="questionWeight">
                <div className="checkbox"></div>
                {i18n.t('questionnaire.weightMatchingInfo')}
              </label>
            </div>


        </div>
        <div className='footer-actions no-shadow'>
          <button className={`btn btn-big btn-primary ${responseId === null ? 'btn-disabled' : ''}`}
                disabled={responseId === null ? 'disabled' : ''}
                onClick={() => {responseId != null && props.nextQuestion(prepareParams(), false)}}>SAVE & CONTINUE</button>
        </div>
      </div>
    </>
  )
}
