import React from 'react';
import { ACCOUNT_TYPES } from '../../../../utils/constants'
import AmbassadorLink from '../../../shared/ambassador_link'
import UserAvatar from '../../../shared/profile/user_avatar'

const CardProfile = (props) => {
  const { age, online, gender, photo_url, avatar, profile_path, username, location, type, confirmed, host = false, ambassador = false, ambassador_link = '' } = props.profile
  const linkclassName = type === ACCOUNT_TYPES.direct ? 'circle-avatar' : 'circle-avatar proxy-marker'
  const genderName = gender ? `, ${gender.name.charAt(0).toUpperCase() + gender.name.slice(1)}` : ''
  const isOnlineClass = online? 'text-info is-online' : 'text-info'

  return (
    <div className='tile-container new-member'>
      <div className='tile h-100 shadow bg-white'>
        <div className='tile-content'>
          <a href={profile_path} className={linkclassName}>
            <UserAvatar photo_url={photo_url} avatar={avatar} />
          </a>
          <h3 className={isOnlineClass}>{username}</h3>
          <p className='user-spaced-location'>{location}</p>
          <p className='user-summary'>{age}{genderName}</p>
          <div className='row align-items-center justify-content-center'>
            {confirmed && (<span className='user-badge' data-label-bottom='Confirmed user'><i className='symbol icon-order grow-1' /></span>)}
            {host && (<span className='user-badge host' data-label-bottom='Host'><i className='symbol svg-icon icon-host-badge  grow-1' /></span>)}
            {ambassador && <AmbassadorLink ambassadorlink={ambassador_link} />}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CardProfile
