import React from 'react'
import { makeGetRequest, makePostRequest } from '../shared/api.js'
import FormMessage from './form_message'
import MessageList from './message_list'
import script_google_ads from '../../utils/script_google_ads'
import { adjustFloatingAdvertPosition, adjustConversationAdvertTopPosition } from '../../utils/advert_helpers'
import { removeMultipleNewlines } from '../../utils/tools'

export default class Conversation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      newContent: '',
      messages: props.messages,
      isLastPage: props.is_last_page,
      conversationUpdatedAt: '',
      page: 1,
      loading: false,
      sendingMessage: false
    }
    this.inputElementRef = React.createRef(null)
    this.listRef = React.createRef(null)
    this.containerRef = React.createRef(null)
  }

  componentDidMount() {
    this.interval = setInterval(this.fetchMessages, 7000)
    window.addEventListener('scroll', () => {
      this.handleScroll()
    })
    if(this.props.advert && this.props.advert.includes('adsbygoogle.js')) {
      script_google_ads()
    }
    if(this.props.small_advert && this.props.small_advert.includes('adsbygoogle.js')) {
      script_google_ads()
    }
    this.hideFoooter()
    this.updateContainerClassList()
    adjustFloatingAdvertPosition()
    window.addEventListener('resize', adjustFloatingAdvertPosition)
    window.addEventListener('scroll', () => adjustConversationAdvertTopPosition('.conversations-horizontal-bar', 215))
  }

  updateContainerClassList() {
    const { listRef, containerRef } = this
    if (listRef.current && containerRef.current) {
      const listHeight = listRef.current.getBoundingClientRect().height
      const containerHeight = containerRef.current.getBoundingClientRect().height
      if (containerHeight - listHeight <= 190) containerRef.current.classList.add('messages')
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    return(
      this.state.conversationUpdatedAt !== nextState.conversationUpdatedAt ||
      this.state.newContent !== nextState.newContent ||
      this.state.messages.length < nextState.messages.length ||
      this.state.loading !== nextState.loading
    )
  }

  componentWillUnmount() {
    clearInterval(this.interval)
    window.removeEventListener('scroll', () => {
      this.handleScroll
    })
  }

  componentDidUpdate() {
    if(this.state.isLastPage) { window.removeEventListener('scroll', this.handleScroll) }
  }

  hideFoooter() {
    if (window.innerWidth <= 991) {
      document.querySelector('.main-footer').style.display = 'none'
    }
    window.addEventListener("resize", function() {
      if (window.innerWidth <= 991) {
        document.querySelector('.main-footer').style.display = 'none'
      } else {
        document.querySelector('.main-footer').style.display = 'block'
      }
    });
  }

  handleScroll = () => {
    const { page, loading, isLastPage } = this.state
    if (isLastPage) return
    if (!loading && window.pageYOffset < 50) {
      this.fetchMessages(page + 1, true)
      this.setState({loading: true})
    }
  }

  fetchMessages = (page = 1, loading = false) => {
    if (loading) { this.setState({loading: true}) }
    const { get_messages_url } = this.props
    const { conversationUpdatedAt } = this.state

    makeGetRequest({ url: `${get_messages_url}?updated_at=${conversationUpdatedAt}&page=${page}` })
      .then((response) => {
        this.setState({loading: false})
        if (response.data.errors == null) {
          let fetchedMessages = response.data.messages
          if(!fetchedMessages) { return null }

          if(page > 1) { fetchedMessages = response.data.messages.concat(this.state.messages)}
          if(response.data.updated_at !== conversationUpdatedAt || page > 1) {
            this.setState({
              errors: '',
              messages: fetchedMessages,
              conversationUpdatedAt: response.data.updated_at,
              page: page,
              isLastPage: response.data.last_page
            })
          }
        }
        else {
          this.setState({
            errors: response.data.errors
          })
        }
      })
  }

  handleOnChange = (e) => {
    const { innerText, dataset } = e.target
    this.setState({ [dataset.name]: removeMultipleNewlines(innerText) })
  }

  sendMessage = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { newContent, sendingMessage } = this.state
    if (sendingMessage === true || newContent.trim() === '') return null;

    const { create_message_url } = this.props
    this.setState(
      {sendingMessage: true},
      () => {
        makePostRequest({ url: create_message_url, body: { message: { content: newContent } } })
           .then((response) => {
             this.setState({sendingMessage: false})
             if (response.data.errors == null) {
               this.setState({
                 errors: '',
                 newContent: '',
                 messages: this.state.messages.concat(response.data.created_message),
                 conversationUpdatedAt: response.data.created_message.conversation_updated_at
               }, () => this.inputElementRef.current.innerText = '')
             }
             else {
               this.setState({
                 errors: response.data.errors
               })
             }
        })
      }
    )
  }

  render = () => {
    const { sendMessage, handleOnChange, inputElementRef, listRef, containerRef } = this
    const { messages, newContent, loading } = this.state
    const { report_url, system_conversation, permitted, advert, small_advert } = this.props
    return(
      <div
        ref={containerRef}
        className='conversation-content on-light container position-relative'
      >
        {messages.length > 0 &&
          <MessageList
            system_conversation={system_conversation}
            report_url={report_url}
            messages={messages}
            loading={loading}
            forwardRef={listRef} />}
        {permitted && !system_conversation &&
          <FormMessage
            interlocutorName={this.props.interlocutorName}
            canWrite={this.props.canWrite}
            sendMessage={sendMessage}
            newContent={newContent}
            handleOnChange={handleOnChange}
            forwardRef={inputElementRef} />
        }
        <div className='floating-conversation-advert' >
          {advert && <div className='big-advert' dangerouslySetInnerHTML={{ __html: advert}} /> }
          {small_advert &&  <div className='small-advert' dangerouslySetInnerHTML={{ __html: small_advert}} />}
        </div>
      </div>
    )
  }
}
