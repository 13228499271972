import React from 'react'
import FrindowModal from '../shared/frindow_modal'
import FrindowImagesUploader from '../shared/frindow_images_uploader'


const ImagesModal = ({ isOpen, closeModal, addImage, addError,
                       imagesLength, profile, uploadStart, selectedBox = -1 }) => {

  if(!isOpen) return null

  return(
    <FrindowModal title='Upload photo' isOpen={isOpen} closeModal={closeModal}>
      <FrindowImagesUploader
        selectedBox={selectedBox}
        profile={profile}
        addImage={addImage}
        imgContainerIndex={isOpen}
        closeModal={closeModal}
        imagesLength={imagesLength}
        addError={addError}
        uploadStart={uploadStart}
      />
    </FrindowModal>
  )
}

export default ImagesModal
