import React from 'react';
import { Scrollbars } from 'react-custom-scrollbars';

export default class Scrollbar extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
          ballSize: 32,
          progressbarWidth: 4,
          percent: 0 
        }
        this.handleUpdate = this.handleUpdate.bind(this);
    }

    handleUpdate = ( values ) => {
      const { top } = values
      const percent = top * 100
      this.setState({ percent });
    }

    renderTrack = ({ style, ...props }) => {
      const backgroundSize = `${this.state.progressbarWidth}px ${this.state.percent}%, ${this.state.progressbarWidth}px ${(100-this.state.percent)}%`
      return( <div {...props} className='scrollbar-track' style={{...style, backgroundSize }}/> )
    }

    renderBall = (props) => {
      return( <div {...props} className='scroll-ball'/> )
    }

    renderContent = (props) => {
      return( <div {...props} className='content-to-scroll'/> )
    }

    render() {
      return (
        <Scrollbars
          className='custom-scrollbar'
          style={this.props.style}
          thumbSize={this.state.ballSize}
          onUpdate={this.handleUpdate}
          renderTrackVertical={this.renderTrack}
          renderThumbVertical={this.renderBall}
          renderView={this.renderContent}
          hideTracksWhenNotNeeded={true}>
          {this.props.children}
        </Scrollbars>
      );
    }
}