import React, { Component } from 'react'
import FrindowNotification from '../shared/frindow_notification'
import { makePostRequest } from '../shared/api'
import i18n from '../../utils/i18n'

export default class BanStatusOnForumsToggle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBanned: props.is_banned,
      errors:   [],
      messages: []
    }
  }

  banStatusChange = (e) => {
    e.preventDefault()
    makePostRequest({ url: `/users/${this.props.moderator_id}/toggle_ban_status_on_forums`,
                      body: { user_id: this.props.user_id } }).then((res) => {
      if(res.data.success) {
        this.setState({ messages: [res.data.message], isBanned: res.data.banned })
      } else {
        this.setState({ errors: res.data.errors })
      }
    })
  }

  clearNotices = () => {
    this.setState({ errors: [], messages: []})
  }

  render = () => {
    return(
      <>
        <a onClick={this.banStatusChange} className='btn-block' href=''>
          {this.state.isBanned ? i18n.t('profile.blockOnForum') : i18n.t('profile.unblockOnForum')}
        </a>
        <FrindowNotification
          messages={this.state.messages}
          errors={this.state.errors}
          clearNotices={this.clearNotices}
        />
      </>
    )
  }
}
