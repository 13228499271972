import React from 'react'

export default function FrindowErrors (props) {
  const errors = props.errors || []

  return (
    <React.Fragment>
      { errors.length != 0 && (
          <div className='form-message invalid-feedback' role='alert'>
            <ul>
              { errors.map((error, index) => {
                return <li key={`key_error_${index}`}>{error}</li>
              })}
            </ul>
          </div>
      )}
    </React.Fragment>
  )
}
