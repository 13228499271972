import React, { useState, useEffect } from 'react'
import ItemGetTogether from './item_get_together'
import { makeGetRequest } from '../shared/api.js'
import FrindowSpinner from '../shared/frindow_spinner'
import { lozadObserver } from '../../utils/lozad_observer'
import _ from 'lodash'
import InfoBox from '../shared/info_box'

export default function ListGetTogethers(props) {
  const [errors, setErrors] = useState([]);
  const [page, setPage] = useState(1);
  const [lastPage, setLastPage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [listExpired, setListExpired] = useState(false);
  const [eventGetTogethers, setEventGetTogethers] = useState([])

  const fetchEventGetTogethers = (pageNr) => {
    const { fetchEventGetTogethersUrl } = props

    setLoading(true)
    let url = `${fetchEventGetTogethersUrl}?page=${pageNr}`
    if (props.includeExpired) {
      url += `&expired=${listExpired}`
    }
    makeGetRequest(
      { url: url }
    ).then((response) => {
      setLoading(false)
      if (response.data.errors == null) {
        setErrors([])
        if (page === 1) {
          setEventGetTogethers(response.data.event_get_togethers)
        } else {
          setEventGetTogethers(eventGetTogethers.concat(response.data.event_get_togethers))
        }
        setLastPage(response.data.last_page)
        lozadObserver()
      } else {
        setErrors(response.data.errors)
      }
    });
  }

  const handleScroll = () => {
    if (!loading && document.getElementsByTagName('footer')[0].clientHeight + window.innerHeight > calculateDocument()) { incrementPageDebounced() }
  }

  const incrementPageDebounced = _.debounce(() => {
    setPage(currPage => currPage + 1)
  }, 300);

  const calculateDocument = () => {
    if(document.documentElement) {
      return (
        document.documentElement.offsetHeight
        - document.documentElement.scrollTop
      )
    }
    return 0
  }

  const renderGetTogethers = () => {
    if (!loading && eventGetTogethers.length < 1) {
      return <InfoBox header={"No get-togethers at the moment."}
                      content={"Please come back very soon as this page is updated daily."}/>
    } else {
      return <div className="tile-grid mt-3">
          {
            (eventGetTogethers.map((getTogether) => {
                  return (<ItemGetTogether
                            key={`getTogether_${getTogether.type}_${getTogether.id}`}
                            getTogether={getTogether} />)
              }))
          }
      </div>
    }
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(()=>{
    if (lastPage) { window.removeEventListener('scroll', handleScroll) };
  }, [lastPage])

  useEffect(() => {
    if (!lastPage) { fetchEventGetTogethers(page) }
  }, [page])

  useEffect(() => {
    setEventGetTogethers([]);
    if(page === 1) {
      fetchEventGetTogethers(page);
    } else {
      setPage(1);
      setLastPage(false);
    }
  }, [listExpired])

  return(
    <React.Fragment>
      {
        props.includeExpired && (
          <div className='filter-bar'>
            <button onClick={() => setListExpired(true)} className={`btn btn-small btn-toggle ${listExpired ? 'active' : ''}`}>PAST</button>
            <button onClick={() => setListExpired(false)} className={`btn btn-small btn-toggle ${listExpired ? '' : 'active'}`}>CURRENT</button>
          </div>
        )
      }
      {renderGetTogethers()}

      { loading && <FrindowSpinner /> }
    </React.Fragment>
  )
};
