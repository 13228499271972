import React from 'react'
import VideoRoomsList from './video_rooms_list'
import { makeGetRequest } from '../shared/api.js'

export default class VideoRoomsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      videoRooms: props.video_rooms,
      page: 1,
      isLastPage: props.last_page,
      renderList: true
    }
  }

  fetchVideoRooms = (page = 1) => {
    const { get_video_rooms_url: getVideoRoomsUrl } = this.props
    makeGetRequest({ url: `${getVideoRoomsUrl}?page=${page}` })
      .then((response) => {
        if (response.data.errors == null) {
          let fetchedVideoRooms = response.data.video_rooms
          if(page > 1) { fetchedVideoRooms = this.state.videoRooms.concat(fetchedVideoRooms) }
          this.setState({
            errors: '',
            videoRooms: fetchedVideoRooms,
            isLastPage: response.data.last_page,
            renderList: true
          })
        }
        else {
          this.setState({
            errors: response.data.errors
          })
        }
      })
  }

  render = () => {
    const { fetchVideoRooms } = this
    const { videoRooms, renderList, isLastPage } = this.state
    return(
      <React.Fragment>
        {renderList && <VideoRoomsList
          videoRooms={videoRooms}
          fetchVideoRooms={fetchVideoRooms}
          isLastPage={isLastPage} />
          }
      </React.Fragment>
    )
  }
}
