import React, { Component } from 'react'
import FrindowNotification from '../shared/frindow_notification'
import { makePostRequest } from '../shared/api'

export default class ToggleFavourite extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isFavourite: props.is_favourite,
      errors:   [],
      messages: [],
      hideLabel: props.label
    }
  }

  favouriteChange = (e) => {
    e.preventDefault()
    makePostRequest({ url: `/profiles/${this.props.profile_id}/update_favourites_by`,
                      body: { profile: { favourite_by_user_id: this.props.user_id }} }).then((res) => {
      if(res.data.success) {
        this.setState({ messages: [res.data.message],  isFavourite: !this.state.isFavourite })
      } else {
        this.setState({ errors: res.data.errors })
      }
    })
  }

  clearNotices = () => {
    this.setState({ errors: [], messages: []})
  }

  render = () => {
    return(
      <React.Fragment>
        <button onClick={this.favouriteChange} className={`${this.state.isFavourite ? 'is-favourite active' : ''} ${this.props.customClass}`}>
          <i className='symbol icon-star' />
          {!this.state.hideLabel && <span className='label'>Favourite</span>}
        </button>
        <FrindowNotification
          messages={this.state.messages}
          errors={this.state.errors}
          clearNotices={this.clearNotices}
        />
      </React.Fragment>
    )
  }
}
