import React from 'react';
import UserAvatar from '../shared/profile/user_avatar'

const ClientCardProfile = (props) => {
  const { id, fullname, short_description, location, photo_url, avatar, post_nominal_titles, client_job_title, } = props.profile
  const { fullHeight = true, single, lazyLoading } = props
  const profile_path = '/profiles/' + id

  return (
    <div className={`client-profile small tile-container new-member ${fullHeight ? 'h-100' : ''} ${single ? 'full-width' : ''}`}>
      <div className='tile h-100 shadow bg-white'>
        <div className='tile-content px-3 h-100'>
          <div className="client-image">
            <div className="circle-avatar">
              <UserAvatar photo_url={photo_url} avatar={avatar} lazyLoading={lazyLoading} />
            </div>
            <span className='tag'>{client_job_title}</span>
          </div>
          <span className="user-badge">
            <button className="symbol icon-order mr-1" />
            <span>VERIFIED</span>
          </span>
          <p className='user-name'>
            {fullname}
          </p>
          <p className="nominal-titles">
            {post_nominal_titles}
          </p>
          <p className='user-address'>
            <i className="icon-map-pin icon mr-1 text-blue-ui" />
            {location}
          </p>
          <p className='mb-4'>
            {short_description}
          </p>

          <a
            href={profile_path}
            className="btn btn-big text-center"
            style={{ backgroundColor: props.color}}
          >
            More
          </a>
          <div className='row align-items-center justify-content-center'>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ClientCardProfile
