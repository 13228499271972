import React from 'react'
import ReactCrop from 'react-image-crop'
import SimpleSelectField from '../../shared/simple_select_field'
import { REJECT_PHOTO_REASONS, PHOTO_ROTATE_ANGLE } from '../../../utils/constants'


export default class CroppingPhoto extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: ''
    }
  }

  render = () => {
    const { onCropChange, handleReasonChange, crop, handleAngleChange,
      selectedImage, errors, approvalStatusChange, handleProfileImageChange, profileImageId } = this.props
    const { approvalStatus, reasonSelected, angleSelected, id } = selectedImage
    return(
      <div className='row'>
        <div className='col-md-8 col-sm-12'>
          <ReactCrop
            src={selectedImage.url}
            onChange={onCropChange}
            crop={crop}
          />
        </div>
        <div className='col-md-4 col-sm-12'>
          <form>
            <div className='row mt-md-0 mt-sm-3 mb-3'>
              <div className='col-auto'>
                <div className='form-input-cell radiobox-cell'>
                  <input
                    type='radio'
                    photo_id={id}
                    id='approval_approved'
                    name='approval_status'
                    value='approved'
                    className='form-check-input mb-3'
                    onChange={approvalStatusChange}
                    checked={approvalStatus === 'approved'}
                  />
                  <label htmlFor='approval_approved'><span className='checkbox'></span>
                    Approve
                  </label>
                </div>
              </div>
              <div className='col-auto'>
                <div className='form-input-cell radiobox-cell'>
                  <input
                    type='radio'
                    id='approval_rejected'
                    photo_id={id}
                    name='approval_status'
                    value='rejected'
                    className='form-check-input mb-3'
                    onChange={approvalStatusChange}
                    checked={approvalStatus === 'rejected'}
                  />
                  <label htmlFor='approval_rejected'><span className='checkbox'></span>
                    Reject
                  </label>
                </div>
              </div>
              <div className='col-12 form text-left mb-3'>
                <div className='form-group'>
                  <div className='form-input-cell radiobox-cell'>
                    <SimpleSelectField
                      collection={REJECT_PHOTO_REASONS}
                      item_id={id}
                      value={reasonSelected}
                      onChange={handleReasonChange}
                      emptyKey='Reason for rejection'
                      errorText={errors.status}
                    />
                  </div>
                </div>
              </div>
              <div className='col-auto'>
                <div className='form-input-cell checkbox-cell'>
                  <input
                    type='checkbox'
                    id={`profile_${id}`}
                    name='maintenance_mode'
                    value={id}
                    className='form-check-input mb-3'
                    onChange={handleProfileImageChange}
                    checked={profileImageId.toString() === id.toString()}
                  />
                  <label htmlFor={`profile_${id}`}><span className='checkbox'></span>
                    Make Profile Image
                  </label>
                </div>
              </div>
              <div className='col-12 form text-left my-3'>
                <div className='form-group'>
                  <p>Rotate image(clockwise)</p>
                  <div className='form-input-cell radiobox-cell'>
                    <SimpleSelectField
                      collection={PHOTO_ROTATE_ANGLE}
                      item_id={id}
                      value={angleSelected}
                      onChange={handleAngleChange}
                      emptyKey='0'
                      errorText={errors.status}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    )
  }
}
