import React from 'react'
import { ACCOUNT_TYPES } from '../../utils/constants'
import ToggleFavourite from './toggle_favourite'
import UserAvatar from '../shared/profile/user_avatar'
import AmbassadorLink from '../shared/ambassador_link'

const SearchProfile = (props) => {
  const { username, confirmed, groups, gender, age, location, in_favourites , type, profile_path, photo_url, avatar, online, id, profile, host, ambassador, ambassador_link } = props
  const genderName = gender ? gender.name.charAt(0).toUpperCase() + gender.name.slice(1) : ''
  const linkclassName = type === ACCOUNT_TYPES.direct ? 'circle-avatar' : 'circle-avatar proxy-marker'
  const isOnlineClass = online ? 'user-name is-online' : 'user-name'
  return(
    <div className='tile-container'>
      <div className='tile h-100 shadow bg-white'>
        <div className='tile-header bg-green-accent-25'>
          {confirmed &&
            <span className='user-badge' data-label-bottom='Confirmed user'>
              <i className='symbol icon-order grow-1'></i>
            </span>}
          {host &&
            <span className='user-badge host' data-label-bottom='Host'>
              <i className='symbol svg-icon icon-host-badge grow-1'></i>
            </span>}
          {ambassador && <AmbassadorLink ambassadorlink={ambassador_link} />}
          <ToggleFavourite 
            user_id={id}
            profile_id={profile.id}
            is_favourite={in_favourites}
            label={true}
            customClass={'favourite'}
          />
          <a href={profile_path} className={linkclassName}>
            <UserAvatar photo_url={photo_url} avatar={avatar} />
          </a>
        </div>
        <div className='tile-content'>
          <h2 className={isOnlineClass}>
            <a href={profile_path}>{username}</a>
          </h2>
          <p className='h3 user-location'>{location}</p>
          <p className='user-summary'>
            <span className='user-age'>{age}</span>,
            <span className='user-gender'> {genderName}</span>
          </p>
          <ul className='user-labels clean-list'>
            {groups.map((group) => {
              return(<li key={group}>{group}</li>)
              })
            }
          </ul>
        </div>
      </div>
    </div>
  )
}

export default SearchProfile
