import React from 'react'

const ItemListSubject = (props) => {
  const { subjectUrl, title, postCount, updateDate } = props.subject
  return(
    <div className='mb-3'>
      <div className='topic-card bg-white shadow row align-items-center mx-0 p-3'>
        <div className='col-12 col-lg-6'>
        <h3 className='topic-title'>
        <a href={subjectUrl}>
        {title}
        </a>
        </h3>
        </div>
        <div className='col-12 col-lg-6'>
          <div className='row text-center topic-summary'>
            <div className='col-7 topic-date'>{updateDate}</div>
            <div className='col-3 topic-replies font-weight-bold'>
              {postCount}
            </div>
            <div className='col-2 topic-go-to'>
            <a href={subjectUrl}>
              <i className='symbol icon-curved-arrow'></i>
            </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ItemListSubject
