import React from 'react'
import i18n from '../../utils/i18n'
import CodeConfirmationAlert from '../shared/code_confirmation_alert'

const FormMessage = (props) => {
  const { handleOnChange, sendMessage, forwardRef, canWrite, interlocutorName } = props
  const htmlContent = (
    <>
      <div className='form-group'>
        <div className='form-input-cell'>
          <div
            ref={forwardRef}
            contentEditable={true}
            onInput={handleOnChange}
            className='form-control'
            placeholder='Type a message...'
            data-name='newContent'
          />
        </div>
      </div>
      <div className='form-actions'>
        <button className='btn btn-big btn-primary btn-plane btn-submit' onClick={sendMessage}>{i18n.t('shared.send')}</button>
      </div>
    </>
  )

  return (
    <div className='container conversation-footer'>
      <div className='send-message form'>
        { canWrite ? htmlContent : CodeConfirmationAlert(interlocutorName) }
      </div>
    </div>
  );
}

export default FormMessage
