import React from 'react'
import Select from 'react-select'
import { ALL_GENDERS } from '../../utils/constants'
import SearchUsername from './search_username'
import ConversationsType from './conversations_type'

class HorizontalFilterBar extends React.PureComponent {

  blureUserSearch = () => {
    const userInput = document.querySelector('#searchUserInput');
    if (userInput) {
      userInput.blur();
    }
  }

  componentDidMount() {
    window.addEventListener('touchstart', this.blureUserSearch);
  }

  componentWillUnmount() {
    window.removeEventListener('touchstart', this.blureUserSearch);
  }

  renderGenders = () => {
    const { is_lgbt } = this.props
    const genders = is_lgbt ? ALL_GENDERS : ALL_GENDERS.filter(o => o.value ==='male' || o.value ==='female')
    return (
      <div className='form-check form-multi-autocomplete'>
        <Select
          isMulti
          options={genders}
          onChange={this.props.handleGenderChange}
          value={this.props.genders}
          className='form-input-cell'
          classNamePrefix='autocomplete'
          placeholder='Gender'
        />
      </div>
    )
  }

  renderCheckboxFilters = () => {
    const { filters, handleCheckboxChange } = this.props
    return(
      filters.map((filter) => {
        return (
          <div key={filter.name} className='form-check'>
            <div className='form-input-cell checkbox-cell' >
              <input className='form-check-input' type='checkbox' onChange={handleCheckboxChange} name={filter.name} id={filter.name} value={filter.value} checked={filter.value} />
              <label className='form-check-label' htmlFor={filter.name}>
                <span className='checkbox'></span>
                {filter.name.charAt(0).toUpperCase() + filter.name.replace('_', ' ').slice(1)}
              </label>
            </div>
          </div>
        )
      })
    )
  }

  render = () => {
    const { handleSearchChange, counters, searchValue, filtered, handleClickUnfiltered, handleClickFiltered } = this.props
    return(
      <div className='conversations-horizontal-bar bg-white shadow pt-2 pb-2'>
        <div className='container'>
          <div className='row conversation-header-content justify-content-center'>
            <SearchUsername
              handleSearchChange={handleSearchChange}
              searchValue={searchValue} />
            <div className='col-12 col-lg conversation-filters' style={{ display: 'none' }}>
              <p className='filters-label'>Filter by</p>
              {this.renderGenders()}
              <div className='filters-checks row'>
                {this.renderCheckboxFilters()}
              </div>
            </div>
          </div>
          <ConversationsType
            counters={counters}
            filtered={filtered}
            handleClickUnfiltered={handleClickUnfiltered}
            handleClickFiltered={handleClickFiltered} />
        </div>
      </div>
    )
  }
}

export default HorizontalFilterBar
