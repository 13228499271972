import React from 'react'
import { makeGetRequest } from '../../../shared/api.js'
import FrindowSpinner from '../../../shared/frindow_spinner'
import CardProfile from './card_profile'
import i18n from '../../../../utils/i18n'
import { lozadObserver } from '../../../../utils/lozad_observer'

export default class ProfilesContainer extends React.Component {
  constructor(props) {
    super(props)

    const cachedUsers = this.cachedUsers()
    const loading = cachedUsers ? false : true
    this.state = {
      users: cachedUsers || [],
      page: this.cachedPage() || 1,
      isLastPage: false,
      loading: loading
    }

  }

  componentDidUpdate() {
    if(this.state.isLastPage) { window.removeEventListener('scroll', this.handleScroll) }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll)
  }

  cachedUsers() {
    let cachedUsers = localStorage[`groups${this.props.groupId}UsersResult`]
    try { if(cachedUsers) {
      cachedUsers = JSON.parse(cachedUsers)
      window.addEventListener('scroll', this.handleScroll)
    } } catch(err) { localStorage.removeItem(`groups${this.props.groupId}UsersResult`) }
    return cachedUsers
  }

  cachedPage() {
    let cachedPage = localStorage[`groups${this.props.groupId}UsersPage`]
    try {
      if(cachedPage) { cachedPage = JSON.parse(cachedPage) }
    } catch(err) { localStorage.removeItem(`groups${this.props.groupId}UsersPage`) }
    return cachedPage
  }

  handleScroll = () => {
    const { page, loading } = this.state
    if(!loading &&
      document.getElementsByTagName('footer')[0].clientHeight +
      window.innerHeight
      >
      this.calculateDocument()) {
        this.fetchProfiles(page + 1)
      }
  }

  calculateDocument = () => {
    if(document.documentElement) {
      return (
        document.documentElement.offsetHeight
        - document.documentElement.scrollTop
      )
    }
    return 0
  }

  fetchProfiles = (page = 1) => {
    this.setState({loading: true})
    const { fetchProfilesUrl, groupId } = this.props
    makeGetRequest({ url: `${fetchProfilesUrl}?page=${page}&selected_group_ids=${groupId}` })
      .then((response) => {
        this.setState({loading: false})
        if(response.data.errors == null) { this.onSuccesFetch(response, page) }
        else { this.setState({ errors: response.data.errors }) }
      });
  }

  onSuccesFetch = (response, page) => {
    let fetchedUsers = response.data.profiles
    if(page > 1) { fetchedUsers = this.state.users.concat(fetchedUsers) }
    else { window.addEventListener('scroll', this.handleScroll) }
    this.setState({
      errors: '',
      users: fetchedUsers,
      page: page,
      isLastPage: response.data.last_page
    })
    localStorage[`groups${this.props.groupId}UsersResult`] = JSON.stringify(fetchedUsers)
    localStorage[`groups${this.props.groupId}UsersPage`] = JSON.stringify(page)
    lozadObserver()
  }

  componentDidMount() {
    const users = $(`[data-label-bottom='Users']`)
    const usersActiveUrl = users.attr('data-link')

    if (parseInt(users.find('.number').html(), 10) > 0 && window.location.href === usersActiveUrl) {
      users.removeClass('inactive')
      users.on('click', () => {
        const target = document.querySelector('#group-members')
        target.scrollIntoView({behavior: 'smooth'})
      });
    }

    if(this.state.users.length < 1) { this.fetchProfiles() }
  }

  render() {
    const headerName = this.props.publishedEventType ? i18n.t('groups.attendees') : i18n.t('groups.members')

    return(
      <React.Fragment>
        {this.state.users.length > 0 && <h2 className='section-heading mt-3 mb-4'>{headerName}</h2>}
        <div className='pictorial-user-list'>
          {this.state.users.map(user=> <CardProfile key={user.id} profile={user} />)}
          {this.state.loading && <FrindowSpinner />}
        </div>
      </React.Fragment>
    )
  }
}
