import React, { Component } from 'react'
import Region from './region'

export default class Area extends Component {
  constructor(props){
    super(props)
    this.state = {
      area_id: props.area_id,
      countries: props.countries,
      regions: props.regions
    }
  }

  addRegion = (e) => {
    e.preventDefault()
    this.updateRegion(this.props.area_id, '')
  }

  availableCountries = () => this.state.countries.filter((c) => !Object.keys(this.state.regions).includes(c))

  updateRegion = (a, country, oldCountry) => {
    this.setState({regions: { [country]: [] } })
  }

  updateLocationsLvl1 = ({ area_id, country, selectedLocations }) => {
    const { areas, regions } = this.state
    const lvl2 = regions[country]['lvl2'] || []
    this.setState({regions: {
                    [country]: {
                      lvl1: selectedLocations,
                      lvl2: lvl2
                    }
                  } })
  }

  updateLocationsLvl2 = ({ area_id, country, selectedLocations }) => {
    const { areas, regions } = this.state
    const lvl1 = regions[country]['lvl1'] || []
    this.setState({regions: {
                    [country]: {
                      lvl1: lvl1,
                      lvl2: selectedLocations
                    }
                  } })
  }

  renderLocationsFields = () => {
    const { regions } = this.state

    return(
        Object.values(regions).map((location) => {
          if(location.length < 1) return null
          const lvl2InLvl1Names = location.lvl2.map(a => a.name)
          const ids = location.lvl1.filter(a => !lvl2InLvl1Names.includes(a.name))
                                   .map(a => a.id)
                                   .concat(location.lvl2.map(a => a.id))
          return ids.map(id => this.renderLocationField(id))
        }).flat()
    )
  }

  renderLocationField = (location_id) => {
    const { area_id, name_field } = this.props

    return(
      <input key={`location_${area_id}_${location_id}`}
             type='hidden'
             name={`${name_field}[${area_id}][location_ids][]`}
             value={location_id} />
    )
  }

  renderHiddenFields = () => {
    const { created, area_id, name_field } = this.props

    return(
      <React.Fragment key={`area_hidden_${area_id}`}>
        {!created &&
          <input type='hidden'
                 name={`${name_field}[${area_id}][id]`}
                 value={area_id} />}
        {this.renderLocationsFields()}
      </React.Fragment>
    )
  }

  renderRegions = () => {
    return(
      Object.keys(this.state.regions).map((country) => (
        <Region
          key={`key_country_${country}_${this.state.area_id}`}
          selectedLocationsLvl1={this.state.regions[country]['lvl1'] || []}
          selectedLocationsLvl2={this.state.regions[country]['lvl2'] || []}
          area_id={this.state.area_id}
          country={country}
          countries={[...this.availableCountries(), country]}
          updateRegion={this.updateRegion}
          updateLocationsLvl1={this.updateLocationsLvl1}
          updateLocationsLvl2={this.updateLocationsLvl2}
          url={this.props.url}
        />
      ))
    )
  }

  renderButtonVisible = () => {
    const countries = Object.keys(this.state.regions)

    return countries.length < this.state.countries.length && !countries.includes('')
  }

  renderAddRegionBtn = () => (
    <button onClick={this.addRegion}>
      Add region to area
    </button>
  )

  render = () => {
    return(
      <React.Fragment>
        {this.renderRegions()}
        {this.renderHiddenFields()}
        {this.renderButtonVisible() &&
          this.renderAddRegionBtn()
        }
      </React.Fragment>
    )
  }
}
