import React from 'react'
import Calendar from 'react-calendar'

class CalendarContainer extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      getTogetherDates: this.getTogethersDates(props.calendarGetTogethers)
    }
  }

  getTogethersDates(calendarGetTogethers) {
    return calendarGetTogethers.map((getTogether) => {
      return (new Date(getTogether.start_date).toLocaleDateString())
    })
  }

  onClickDate = (date) => {
    const filtersGetTogether = this.props.calendarGetTogethers.filter(a => new Date(a.start_date).toLocaleDateString() === date.toLocaleDateString())
    if(filtersGetTogether.length > 1) {
      const list_url = window.location.href.slice(0,window.location.href.lastIndexOf('/'))
      window.location.href = `${list_url}?start_date=${filtersGetTogether[0].start_date}`
    }
    else if(filtersGetTogether.length === 1) { window.location.href = filtersGetTogether[0].url }
  }

  defineDateClass = ({ date }) => {
    const showDay = date.toLocaleDateString()
    const markDates = this.state.getTogetherDates
    const className = markDates.includes(showDay) ? 'has-event' : ''
    return [className]
  }

  render = () => {
    const { start_date, onChange } = this.props
    const showDate = start_date ? new Date(start_date) : new Date()
    return(
        <div className='calendar-holder h-100'>
          <Calendar
            value={showDate}
            tileClassName={this.defineDateClass}
            onChange={onChange ? onChange : this.onClickDate} />
        </div>
    )
  }
}

export default CalendarContainer;
