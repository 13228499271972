import React, { Component } from 'react'
import Slider from 'react-slick';
import AvatarModal from './avatar_modal'
import PropTypes from 'prop-types'

export default class FrindowAvatarSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      imageKeys: [],
      showModal: false,
      selectedAvatarName: props.selectedAvatarName
    }
  }

  componentDidMount = () => {
    const imageKeys = Object.keys(images)

    this.setState({ imageKeys }, this.goToSelectedSlide)
  }

  componentWillReceiveProps = (nextProps) => {
    const { selectedAvatarName } = nextProps
    if(selectedAvatarName !== this.state.selectedAvatarName) {
      this.setState({ selectedAvatarName }, this.goToSelectedSlide)
    }
  }

  onPrevClick = () => {
    this.refs.slider.slickPrev();
  }

  onNextClick = () => {
    this.refs.slider.slickNext();
  }

  goToSelectedSlide = () => {
    const index = this.state.imageKeys.indexOf(this.state.selectedAvatarName)
    if(index >= 0) this.refs.slider.slickGoTo(index - 1)
  }

  selectAvatar = (name) => {
    const current = this.state.selectedAvatarName
    const target = ( current === name ? null : name)
    this.props.handleAvatarChange(target)
  }

  handleAvatarSelect = (e) => {
    this.selectAvatar(e.target.name)
  }

  toggleModal = () => {
    const { showModal } = this.state
    this.setState({ showModal: !showModal })
  }

  render = () => {
    const { selectedAvatarName } = this.state
    const settings = {
      adaptiveHeight: true,
      slidesToShow: 3,
      speed: 500,
      arrows: false,
    };
    const imageNodes = Object.keys(images).map((key) => (
      <div onClick={this.handleAvatarSelect} className='slide' key={`key_img_${key}`}>
        <div className={`circle-image proxy-avatar ${selectedAvatarName == key ? 'active' : ''}`}>
          <img name={key} src={images[key]} />
        </div>
      </div>
    ))
    return(
      <React.Fragment>
        <AvatarModal isOpen={this.state.showModal}
                     images={images}
                     selectedAvatarName={this.state.selectedAvatarName}
                     selectAvatar={this.selectAvatar}
                     closeModal={this.toggleModal} />
        <div className='choose-proxy-avatar'>
          <Slider className='proxy-avatars-slider' ref='slider' {...settings}>
            {imageNodes}
          </Slider>
          <div className="avatar-gallery-controls">
            <button className='prev symbol icon-a-left' onClick={this.onPrevClick} />
            <button onClick={this.toggleModal} className="gallery symbol icon-grid" />
            <button className='next symbol icon-a-right' onClick={this.onNextClick} />
          </div>
        </div>
      </React.Fragment>
    )
  }
}

FrindowAvatarSlider.propTypes = {
  selectedAvatarName: PropTypes.string,
  handleAvatarChange: PropTypes.func,
}

function importAll(r) {
  let images = {};
  r.keys().map((item) => {
    if (item.includes('avatar-55.png')) return;
    images[item.replace('./', '')] = r(item);
  });
  return images;
}

const images = importAll(require.context('../../../images/proxy-avatars', false, /[0-9]\.(png|jpe?g|svg)$/));
