
import React from 'react'
import { PROFILE_TYPES } from '../../utils/constants'
import { APP_ROUTES, EXTERNAL_ROUTES } from '../shared/routes'

const ProfileButton = ({ profile_path, profile, photo_url, log_out_path, avatar, menuLabels }) => {
  const classBase = 'notify user-profile'
  let linkClass = classBase
  let avatarClass = ''

  if(profile.type === PROFILE_TYPES.proxy){
    linkClass += ' proxy-marker'
    avatarClass = 'dot'
  }
  const url = getPhotoUrl(photo_url, avatar)

  if(profile.type === PROFILE_TYPES.client){
    profile_path = '/'
  }

  return(
    <React.Fragment>
      <div>
        <ul>
          <li>
            <a href={profile_path} className={linkClass}>
              <div className={`avatar user ${avatarClass}`}>
                <img src={url} className={'img-fluid rounded-circle'} />
              </div>
              <span>Your Profile</span>
            </a>
          </li>
          <li>
            <a href={APP_ROUTES.my_account}>
              <i className="icon-cog"></i>
              <span>Account Preferences</span>
            </a>
          </li>
          <li>
            <a href={APP_ROUTES.buy_credits}>
              <i className="icon-coins"></i>
              <span>Credits</span>
            </a>
          </li>
          {/* <li>
            <a href={APP_ROUTES.services}>
              <i className="icon-life-buoy"></i>
              <span>Expert Services</span>
            </a>
          </li> */}
          <li>
            <a href={APP_ROUTES.buy_a_badge}>
              <i className="icon-target"></i>
              <span>Buy a Badge</span>
            </a>
          </li>
          <li>
            <a href={EXTERNAL_ROUTES.blog}>
              <i className="icon-blog"></i>
              <span>Blog</span>
            </a>
          </li>
          <li>
            <a href={EXTERNAL_ROUTES.support} rel='noreferrer noopener' target='_blank'>
              <i className="icon-info"></i>
              <span>{menuLabels['help']}</span>
            </a>
          </li>
          <li>
            <a href={log_out_path} data-method='delete'>
              <i className="icon-log-out"></i>
              <span>{menuLabels['log_out']}</span>
            </a>
          </li>
        </ul>
      </div>
    </React.Fragment>
  )
}

export default ProfileButton

export function getPhotoUrl(photo_url, avatar){
  if(photo_url) return photo_url
  const avatars = require.context('../../images/proxy-avatars', true);

  return avatars('./' + avatar)
}
