import React from 'react'
import ProfileUrl from '../../../shared/profile/profile_url'
import i18n from '../../../../utils/i18n'

const ItemGetTogether = (props) => {
  const { newsfeed, toplinks = true } = props
  const { url, groupName, groupUrl, address, title, present_date: presentDate, thumb_image: thumbImage } = props.getTogether
  const { username, profileUrl, deleted } = props.getTogether.hostedBy
  const imageUrl = thumbImage.replace('w=150&h=100', 'w=305&h=206')
  const links = (
    <div>
      <span className='tag default'>{i18n.t('getTogethers.getTogether').toUpperCase()}</span>
      <a href={newsfeed? groupUrl : '#'} className='tag group'>{groupName.toUpperCase()}</a>
    </div>
  )
  const contentBody = (
    <>
      <p className='font-weight-bold'>{presentDate.split(',').slice(0,2).join(',')}</p>
      <a href={url} className='font-weight-bold h3 mb-3'>{title}{address ? `, ${address}`: ''} </a>
      <p className='mt-auto'>
        {i18n.t('getTogethers.hostedBy')}
        <ProfileUrl
          profile_path={profileUrl}
          username={username}
          deleted={deleted} />
      </p>
    </>
  )
  let content = null
  if (toplinks) {
    content = (
      <>
        {links}
        {contentBody}
      </>
    )
  } else {
    content = (
      <>
        {contentBody}
        {links}
      </>
    )
  }
  return (
    <div className='card border-0 mb-4 gt-box'>
      <div className='p-4 gt-box-body'>
        {content}
      </div>
      <img src={imageUrl}/>
    </div>
  )
}

export default ItemGetTogether;
