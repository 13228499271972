import React from 'react'

const CreateGroupForm = (props) => {
  const { errors, name, handleNameChange, description, handleDescriptionChange, handleClickSubmit, handleClickClose } = props
  const validClassName = errors.name ? 'form-control is-invalid' : 'form-control'
  const validClassDescritpion = errors.description ? 'form-control is-invalid' : 'form-control'
  return(
    <div className='r-modal show'>
      <div className='r-modal-content'>
        <div className='r-modal-header'>
          <h4 className='h1 modal-title'>Create group</h4>
          <button type='button' aria-label='Close' className='btn btn-close' onClick={handleClickClose}></button>
        </div>
        <div className='r-modal-body'>
          <div className='row'>
            <div className='col-12 form text-left'>
              <div className='form-group'>
                  <label>Group name</label>
                  <div className='form-input-cell'>
                    <input
                      className={validClassName}
                      type='text'
                      value={name}
                      onChange={handleNameChange}
                      placeholder='Group name'
                    />
                    <div className='invalid-feedback'>{errors.name}</div>
                  </div>
              </div>
              <div className='form-group'>
                <label>Describe who should join this group?</label>
                <div className='form-input-cell'>
                  <textarea
                    className={validClassDescritpion}
                    placeholder='Description'
                    value={description}
                    onChange={handleDescriptionChange}
                    >
                  </textarea>
                  <div className='invalid-feedback'>{errors.description}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='r-modal-footer'>
          <button type='button' className='btn btn-big btn-primary btn-add' onClick={handleClickSubmit}>Add group</button>
        </div>
      </div>
    </div>
  )
}

export default CreateGroupForm;
