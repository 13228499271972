import React, { Component } from 'react'
import Plan from './plan'
import PaymentReview from './payment_review';
import BillingDetails from './billing_details'
import Button from '../shared/button'
import { VALIDATION_MSG } from '../../utils/constants'
import { makePostRequest } from '../shared/api'
import FrindowNotification from '../shared/frindow_notification'

export default class Plans extends Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      messages: [],
      payment: {
        first_name: '',
        last_name: '',
        address_line_1: '',
        address_line_2: '',
        town: '',
        country: 'gb',
        post_code: '',
        plan: {
          amount: 0
        },
      }
    }
  }

  selectPlan = (event) => {
    let { payment, errors } = this.state
    payment.plan_id = event.target.value
    payment.plan = this.currentPlan(payment.plan_id)
    errors['plan'] = null
    this.setState({payment: payment, errors: errors})
  }

  currentPlan = (planId) => {
    let plan = this.props.packages.filter(p => p.id == planId)
    return plan[0]
  }

  handlePurchase = () => {
    const errors = this.validation()

    if(this.isValid(errors)){
      this.createPayment()
      this.setState({errors: []})
    }else{
      this.setState({errors: errors})
    }
  }

  isValid = (errors) => {
    return Object.values(errors).every((e) => e === '')
  }

  validation = () => {
    const { payment } = this.state
    const errors = {}

    errors['first_name'] = this.checkPresence(payment.first_name)
    errors['last_name'] = this.checkPresence(payment.last_name)
    errors['address_line_1'] = this.checkPresence(payment.address_line_1)
    errors['town'] = this.checkPresence(payment.town)
    errors['country'] = this.checkPresence(payment.country)
    errors['post_code'] = this.checkPresence(payment.post_code)

    if(!payment.plan_id){
      errors['plan'] = 'Select plan to make payment.'
    }

    return errors
  }

  createPayment = () => {
    const { payment } = this.state
    const body = {
      payment: payment
    }
    return makePostRequest({ url: '/payments', body: body }).then((response) => {
      if(response.data.success) {
        window.location.href = response.data.redirect_url
      }else{
        const errors = Object.values(response.data.errors)
        if(errors.length > 0){
          this.setState({errors: errors,
            messages: []})
        }
      }
    })
  }

  checkPresence = (value) => {
    return value === '' ? VALIDATION_MSG.isRequired : ''
  }

  onChangeAgree = (event) => {
    let { payment } = this.state
    payment.agree = event.target.checked

    this.setState(payment)
  }

  allowSubmit = () => {
    let { payment } = this.state

    if(payment.agree ){
      return true
    }else{
      return false
    }
  }

  render = () => {
    const { packages } = this.props
    const { payment, errors } = this.state

    return (
      <React.Fragment>
        <div className='payments-plans'>
          <h2 data-counter='1'>Compare and choose your billing plan</h2>

          <div className='row my-5'>
            {packages.map((plan) => {
              return <Plan key={`plan-${plan.id}`}
                          plan={plan}
                          onChange={this.selectPlan} />
            })}
            { errors['plan'] && <div>
              <div id="error_explanation">{errors['plan']}</div>
            </div>}
          </div>

        </div>
        <div className='row mt-4'>
          <div className='col-lg-6 col-md-12 mb-4'>
            <div className='card py-4 px-2 px-md-5 h-100'>
              <h2 data-counter='2'>Billing details</h2>
              <BillingDetails payment={payment} errors={errors} />
            </div>
          </div>

          <div className='col-lg-6 col-md-12 mb-4'>
            <div className='card py-4 px-2 px-md-5 h-100'>
              <h2 data-counter='3'>Review and Purchase</h2>
              <div className="my-2 ml-2">
                <p>
                  <i className='icon icon-check-in-circle mr-2 text-blue-ui' />
                  Start being seen
                </p>
                <p>
                  <i className='icon icon-check-in-circle mr-2 text-blue-ui' />
                  Connect with new prospects
                </p>
                <p>
                  <i className='icon icon-check-in-circle mr-2 text-blue-ui' />
                  Grow your client base at affordable prices
                </p>
              </div>
              <PaymentReview plan={payment.plan} />
              <p className='mt-3'>
                You will be charged for the amount above immediately. The plan will automatically renew at the end of the plan period. You can cancel automatic renewals at any time before the next payment by going to the dashboard.
              </p>
              <div className="form-input-cell checkbox-cell my-3">
                <input id="agree" type="checkbox" name="agree" className="form-check-input" onChange={this.onChangeAgree} />
                <label htmlFor="agree" className="form-check-label">
                  <span className="checkbox" />
                  Frindow Business is a trading name for Frindow Ltd. I agree to the <a href="/pages/client-terms" target="_balnk">Terms of Service</a>
                </label>
              </div>
              <Button
                className='button btn btn-big btn-primary btn-submit align-self-baseline'
                icon='icon-lock'
                title='Purchase Upgrade'
                disabled={!this.allowSubmit()}
                onClick={this.handlePurchase}
              />
            </div>
          </div>
        </div>

        <FrindowNotification
          errors={this.state.errors}
          messages={this.state.messages}
          clearNotices={this.clearNotices}
        />
      </React.Fragment>
    )
  }
}