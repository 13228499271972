import React from 'react'
import * as reasons from '../../utils/constants'
import SimpleSelectField from '../shared/simple_select_field'
import TextArea from '../shared/text_area'

export default class Modal extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      reason: '',
      additional_info: ''
    }
  }

  handleReasonChange = (e) => {
    this.setState({reason: e.target.value})
  }

  handleInfoChange = (e) => {
    this.setState({additional_info: e.target.value})
  }

  render = () => {
    const { handleReasonChange, handleInfoChange, props, state } = this
    const { onReportModalClose, onReportModalSubmit, errors } = props
    const { additional_info, reason } = state
    const resourceType = props.resourceType.toLowerCase();
    let colection = null;
    switch (resourceType) {
      case 'message':
        colection = reasons.MESSAGE_REPORT_REASONS
        break;
      case 'post':
        colection = reasons.POST_REPORT_REASONS
        break;
      default:
        colection = reasons.REPORT_REASONS
        break;
    }
    return(
      <div className='r-modal show'>
        <div className='r-modal-content wide'>
          <div className='r-modal-header no-border'>
            <h4 className='h1 modal-title text-left'>Report this {resourceType}</h4>
            <button type='button' aria-label='Close' className='btn btn-close' onClick={onReportModalClose}></button>
          </div>
          <div className='r-modal-body'>
            <div className='row'>
              <div className='col-12 form text-left'>
                <div className='form-group'>
                  <h2 className='text-gray'>Please select the reason for reporting this {resourceType}</h2>
                  <div className='form-input-cell select-cell'>
                    <SimpleSelectField
                      onChange={handleReasonChange}
                      value={reason}
                      collection={colection}
                      emptyKey='Choose'
                      errorText={errors.reason}
                      small={false}
                    />
                  </div>
                </div>
                <label>Additional information (optional)</label>
                <TextArea
                  onChange={handleInfoChange}
                  value={additional_info}
                  className='form-control'
                  placeholder='Additional info'
                  name='info'
                  errorText={errors.additional_info}
                />
              </div>
            </div>
          </div>
          <div className='r-modal-footer'>
            <div className='row'>
              <div className='col-12 col-sm text-right'>
                <button className='btn btn-secondary btn-big btn-cancel' onClick={onReportModalClose}>Cancel</button>
                <span className='btn btn-primary btn-big btn-plane' onClick={onReportModalSubmit.bind(this, reason, additional_info)}>Send</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}
