import React, { useState, useEffect } from 'react'
import { makeGetRequest } from '../shared/api.js'
import FrindowSpinner from '../shared/frindow_spinner'
import { lozadObserver } from '../../utils/lozad_observer'
import UserAvatar from '../shared/profile/user_avatar'
import _ from 'lodash'

const AttendeeTab = (attendee) => {
  return (
    <>
      <a href={attendee.userUrl} className={'circle-avatar'}>
        <UserAvatar photo_url={attendee.profilePhotoUrl} avatar={attendee.avatar} lazyLoading={true} />
      </a>
      <div className="user-content">
        <div className="user-name">
          <a href={attendee.userUrl}>
            {attendee.username}
          </a>
        </div>
        <div className="user-details">
          {`${attendee.age}, ${attendee.location}`}
        </div>
      </div>
    </>
  )
}

const AttendeeseGrid = (props) => {
  const [loading, setLoading] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [eventAttendees, setEventAttendees] = useState([])

  const fetchAttendees = () => {
    const { fetchAttendeesUrl, isMobile } = props
    const params = showMore === true ? "" : `?limit=${isMobile ? 5 : 15}`;

    setLoading(true)
    makeGetRequest(
      { url: `${fetchAttendeesUrl}${params}` }
    ).then((response) => {
      setLoading(false)
      if (response.data.errors == null) {
        setEventAttendees(response.data.attendees)
        lozadObserver()
      } else {
        setErrors(response.data.errors)
      }
    });
  }

  useEffect(() => {
    fetchAttendees()
  }, [showMore]);

  return(
    <>
      <div className="user-grid">
        {
          eventAttendees.map((attendee) =>{
            return (
              <div className="user" key={`attendee-${attendee.id}`}>
                {AttendeeTab(attendee)}
              </div>
            )
          })
        }
      </div>
      {
        (!showMore && props.attendeesSize > eventAttendees.length || showMore) &&
          <div className="text-right mt-3">
            <button className={'btn btn-white with-border pr-4 pl-4 btn-big'} onClick={()=> setShowMore(!showMore)}>
              {
                showMore ? 'Show Less' : 'Show More'
              }
            </button>
          </div>
      }
      { loading && <FrindowSpinner /> }
    </>
  )
}

export default AttendeeseGrid;
