import React, { Component } from 'react'
import _ from 'lodash'
import PropTypes from 'prop-types'
import { NOTICE_SHOW_DURATION } from '../../utils/constants.jsx'

const noticeClass = {
  errorClassName: 'notice notice-alert',
  defaultClassName: 'notice'
}

export default class FrindowNotification extends Component {
  constructor(props){
    super(props)
    this.state = {
      show: false,
      className: 'notice',
      notices: []
    }
    this.hideNotice = null
  }

  UNSAFE_componentWillReceiveProps = (props) => {
    const { errors, messages } = props
    if(errors && errors.length > 0) {
      this.setState({ notices: errors, className: noticeClass.errorClassName, show: true}, this.showCallback)
    } else if (messages && messages.length > 0) {
      this.setState({ notices: messages, className: noticeClass.defaultClassName, show: true}, this.showCallback)
    }
  }

  showCallback = () => {
    let clearCallback = this.props.clearNotices
    this.hideNotice = _.debounce(() => this.setState({ show: false}, clearCallback), NOTICE_SHOW_DURATION)
    this.hideNotice()
  }

  componentWillUnmount() {
    this.hideNotice && this.hideNotice.cancel();
  }

  renderNotices = () => (
    this.state.notices.map((n, index) => (
      <div key={`key_notice_${index}`} className={this.state.className}>{n}</div>
    ))
  )

  render = () => {
    const { errors, messages } = this.props
    const { show } = this.state
    if(!show || errors.length === 0 && messages === 0) return null
    return(
      <div className='fixed-notices'>
        {this.renderNotices()}
      </div>
    )
  }

}

FrindowNotification.propTypes = {
  errors:       PropTypes.array,
  messages:     PropTypes.array,
  clearNotices: PropTypes.func
}
