import React from 'react'
import ConfirmInfo from './confirm_info'
import LiveChat from 'live-chat.svg'
import Alert from 'alert.svg'

class IntroVideo extends React.PureComponent {

  renderBtnInvite = () => {
    const { current_user, handleNextStep, confirmation_url } = this.props
    if(current_user.account_confirmed_at) {
      return (<button onClick={handleNextStep} className='btn btn-white'>Invite to live chat</button>)
    } else {
      return(<ConfirmInfo confirmation_url={confirmation_url}/>)
    }
  }

  render = () => {
    return(
      <div className='row live-chat col-lg-10 offset-lg-1'>
        <div className='col live-chat-main'>
          <img src={LiveChat} className='img-fluid' alt='Live Chat'></img>
          <h1>Live Chat</h1>
          <p>Send an invitation to schedule a convenient ​time to chat</p>
          {this.renderBtnInvite()}
        </div>
        <div className='col live-chat-info'>
          <img src={Alert} className='img-fluid' alt='Important'></img>
          <h2>About Live Video Chat</h2>
          <p>
            Live video chat is a great way to have a conversation without having to leave home. It also works well for proxy users to help get their loved ones connected.
          </p>
          <p>
          See your scheduled chats by clicking on the Live Chat tab in your own profile.
          </p>
          <p>
            <a href='https://frindow.freshdesk.com/en/support/solutions/articles/60000601695-having-a-live-chat-problem' target='_blank' rel='noopener noreferrer'>Click here</a> for advice if you are having technical issues.
          </p>
          <p>Enjoy!</p>
        </div>
      </div>
    )
  }
}

export default IntroVideo;
