import React from 'react'
import { dashify } from '../../utils/tools'
import _ from 'lodash'

const SelectField = ({
  collection,
  value, emptyKey = 'choose',
  onChange,
  errorText,
  name,
  errorPrompt = true,
  classForm,
  customClass,
  preserveFormating = false
}) => {
  const errorClass = ( errorText ? 'is-invalid' : '')

  const options = [
    <option disabled key={'key_'+ dashify(emptyKey)} value=''>{preserveFormating ? emptyKey : _.capitalize(emptyKey)}</option>,
    (Array.isArray(collection) ? optsFromArray(collection, preserveFormating) : optsFromObject(collection, preserveFormating))
  ]

  return(
    <div className={`form-input-cell select-cell ${classForm? classForm : 'medium'}`}>
      <select onChange={onChange} value={value} className={`form-control ${customClass} ${errorClass}`} name={name}>
        {options}
      </select>
      { errorPrompt ? <div className='invalid-feedback'>{errorText}</div> : null }
    </div>
  )
}

const optsFromObject = (collection, preserveFormating) => Object.keys(collection).map((key) => <option key={'key_' + dashify(key)} value={collection[key]}>{preserveFormating ? key : _.capitalize(key)}</option>)

const optsFromArray = (collection, preserveFormating) => collection.map((value) => <option key={'key_' + dashify(value)} value={value}>{preserveFormating ? value : _.capitalize(value)}</option>)

export default SelectField;
