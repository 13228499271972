import React, { Component } from 'react'
import Slider from 'react-slick'
import { makeGetRequest } from '../shared/api'
import { ACCOUNT_TYPES } from '../../utils/constants'

export default class PhotoSlider extends Component {
  constructor(props) {
    super(props)
    this.state = {
      photos_urls: []
    }
  }

  componentDidMount = () => {
    const { photos_urls, has_main_photo } = this.props
    if(has_main_photo){
      this.setState({ photos_urls })
    } else {
      const avatarUrl = this.getAvatarImage()
      photos_urls.unshift(avatarUrl)
      this.setState({ photos_urls: photos_urls })
    }
  }

  onPrevClick = () => {
    this.refs.slider.slickPrev();
  }

  onNextClick = () => {
    this.refs.slider.slickNext();
  }

  getAvatarImage = () => {
    const avatars = require.context('../../images/proxy-avatars', true);
    return avatars('./' + this.props.avatar);
  }

  renderImageNodes = () => {
    const { photos_urls } = this.state

    if (photos_urls.length > 1) {
      const settings = {
        adaptiveHeight: true,
        slidesToShow: 1,
        speed: 500,
        arrows: false,
      };
      return (
        <Slider ref='slider' {...settings}>
          {photos_urls.map((url, index) => (
            <ImageFrame
              image_url={url}
              proxyClass={this.proxyClass()}
              key={`key_${index}`}
            />
          ))}
        </Slider>
      )
    } else if (photos_urls.length == 1) {
      const url = photos_urls[0]
      return (
        <ImageFrame
          image_url={url}
          proxyClass={this.proxyClass()}
        />
      )
    }
  }

  proxyClass = () => (this.props.user_type === ACCOUNT_TYPES.direct ? '' : 'proxy-marker')

  render = () => {
    const { photos_urls } = this.state
    if (photos_urls.length === 0) return null
    return (
      <React.Fragment>
        <div className='slick-slider'>
          {this.renderImageNodes()}
        </div>
        {photos_urls.length > 1 &&
          <div className="avatar-gallery-controls user-gallery-slider-control mb-3">
            <button className='prev symbol icon-a-left' onClick={this.onPrevClick} />
            <button className="gallery symbol icon-grid" />
            <button className='next symbol icon-a-right' onClick={this.onNextClick} />
          </div>
        }
      </React.Fragment>
    )
  }
}

const ImageFrame = ({ image_url, proxyClass }) => {
  return (
    <div className='slide pb-2'>
      <a id='photo_slider' className={'swipebox circle-avatar ' + proxyClass}
        rel='user-slider'
        href={image_url}
      >
        <img src={image_url} alt='User photo' className='img-fluid' />
      </a>
    </div>
  )
}
