import React from 'react'
import { getPhotoUrl } from '../../profile/profile_button'

const UserAvatar = ({photo_url, avatar, lazyLoading = true}) => {
  const fallbackSr = getPhotoUrl(null, avatar)
  const url = getPhotoUrl(photo_url, avatar)

  if (!lazyLoading) {
    return (
      <img src={url} className='img-fluid' />
    )
  }

  return (
    <img data-src={url} data-fallbacksrc={fallbackSr} className='lozad img-fluid' />
  )
}

export default UserAvatar;