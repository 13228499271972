import React, { Component } from 'react'
import FrindowNotification from '../../shared/frindow_notification'
import { makePostRequest, makeDeleteRequest } from '../../shared/api'
import { unFollowPath, APP_ROUTES } from '../../shared/routes'
import i18n from '../../../utils/i18n'

export default class FollowSubjectToggle extends Component {
  unFollow = (e) => {
    e.preventDefault()
    if(this.props.processingFollow) { return null }

    const { parentSetState, subjectId } = this.props
    this.props.parentSetState({processing: true})
    makeDeleteRequest({ url: unFollowPath(subjectId),
                        body: { followable_type: 'Subject',
                                followable_id: subjectId } })
                      .then(() => {
                        parentSetState({ messages: [i18n.t('shared.followed')],
                                         isFollowed: false,
                                         processingFollow: false})
                      }).catch(response => this.onError(response))
  }

  follow = (e) => {
    e.preventDefault()
    if(this.props.processingFollow) { return null }

    const { subjectId, parentSetState } = this.props
    this.props.parentSetState({processing: true})
    makePostRequest({ url: APP_ROUTES.follows ,
                      body: { follow: { followable_id: subjectId, followable_type: 'Subject'} } })
                    .then(() => {
                      parentSetState({ messages: [i18n.t('shared.unfollowed')],
                                       isFollowed: true,
                                       processing: false })
                      }).catch(response => this.onError(response))
  }

  onError = (response) => {
    this.props.parentSetState({
      errors: Object.entries(response.errors).map(([_k,v]) => v),
      processing: false
    })
  }

  clearNotices = () => {
    this.props.parentSetState({ errors: [], messages: []})
  }

  renderBtn = () => {
    if(this.props.isFollowed) {
      return(
        <a onClick={this.unFollow} className='btn-follow canceled' href=''>
          <i className='icon icon-bell' />
          {i18n.t('shared.unfollowButton')}
        </a>
      )
    }

    return(
      <a onClick={this.follow} className='btn-follow' href=''>
      <i className='icon icon-bell' />
        {i18n.t('shared.followButton')}
      </a>
    )
  }

  render = () => {
    return(
      <>
        {this.renderBtn()}
        <FrindowNotification
          messages={this.props.messages}
          errors={this.props.errors}
          clearNotices={this.clearNotices}
        />
      </>
    )
  }
}
