import React from 'react'

export default function IntroductoryPage (props) {
  return (
    <>
      <header class="questionnaire-header">
        <div className="header-top container">
          <div className="progress-bars mobile-hide">
            { props.categories.map((category) => {
                return <div key={`category-${category.id}`}>
                  <p>{category.name}</p>
                  <div className='progress'>
                    <div className='progress-bar' role='progressbar' aria-valuenow={0}></div>
                  </div>
                </div>
            })}
          </div>
        </div>
        <div className="header-title">
          <h1 className="like-h2">
            Before we start..
          </h1>
          <div className="progress mobile-show">
            <div className="progress-bar" role="progressbar" aria-valuenow="0"></div>
          </div>
        </div>
      </header>
      <div className="questionnaire-content">
        <div className="count-columns">
          <div>
            All answers are completely private & secure - these will never be displayed
          </div>
          <div>
            You can save and resume this questionnaire at any point
          </div>
          <div>
            Be honest! The more truthful you are the more accurate the results
          </div>
        </div>
      </div>
      <div className="questionnaire-footer">
        <div className="footer-actions">
          <button className='btn btn-big btn-primary' onClick={props.startQuestionnaire}>
            {props.ordinal == 1 ? 'LETS GO!' : 'CONTINUE'}
          </button>
        </div>
      </div>
    </>
  )
}
