import React from 'react'

const Button = ({ className, title, onClick, disabled = false, icon = null }) => {
  return(
    <button onClick={onClick} disabled={disabled} className={'btn btn-submit ' + className}>
      {icon && <i className={`icon ${icon} mr-2 font-weight bolder`} style={{ fontSize: '1.2em' }} />}
      {title}
    </button>
  )
}

export default Button;
