import React, { PureComponent } from 'react'

export default class DeleteModalWithPasswordInput extends PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      password: '',
      reason: ''
    }
  }

  render = () => {
    const { props, state } = this
    const { resourceType, onModalClose } = props
    const { password, reason } = state
    return(
      <div className='r-modal show'>
        <div className='r-modal-content text-left'>
          <div className='r-modal-header'>
            <h4 className='h1 modal-title text-left'>Delete {resourceType}</h4>
            <button aria-label='Close' className='btn btn-close' type='button' onClick={onModalClose}></button>
          </div>
          <div className='r-modal-body no-padding'>
            <h2 className='text-gray'>Are you sure you want to delete this {resourceType}?</h2>
            <p className='text-gray'>This action cannot be undone once you click delete.</p>
            <h3 className='text-gray'>Reason for leaving</h3>
            <textarea
                    value={reason}
                    onChange={(e) => this.setState({reason: e.target.value})}
                    placeholder='Reason'
                    className='form-control'
                    required
            />
            <input type='password'
                    value={password}
                    onChange={(e) => this.setState({password: e.target.value})}
                    placeholder='Password'
                    className='form-control my-3'
                    required
            />
          </div>
          <div className='r-modal-footer no-padding text-right'>
            <button className='btn btn-big btn-primary btn-delete'
                    onClick={() => props.onDestroyConfirmation(password, reason)}>
              Delete {resourceType}
            </button>
          </div>
        </div>
      </div>
    )
  }
}
