import React from 'react'
import Container from './sign_up_form/container'
import { EMAIL_REGEX } from '../utils/constants'

export default class Confirmation extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      email: props.email,
      emailErrorText: ''
    }
  }

  onChange = (e) => {
    const { value } = e.target
    this.setState({ email: value })
  }

  validateEmail = (e) => {
    e.stopPropagation()
    e.preventDefault()
    let emailErrorText = ''
    const { email } = this.state
    if(email != null && email.length > 1) {
      emailErrorText = EMAIL_REGEX.test(email) ? '' : 'Incorrect email'
    }
    else {
      emailErrorText = 'Please provide an email address'
    }
    this.setState({emailErrorText: emailErrorText}, this.handleSubmit)
  }

  handleSubmit = () => {
    if(this.state.emailErrorText.length > 0) { return null }

    document.getElementById('confirmation_form').submit()
  }

  render = () => {
    const { onChange, handleSubmit, state, validateEmail } = this
    const { email, emailErrorText } = state
    const { client } = this.props
    const headerText = client ? 'Nearly there…' : `Chat with Frindow members in any way that suits you…`
    const descText = client ? 'An email is winging its way to you.' : `...with our live video chat, forum discussions, one-to-one messaging and face-to-face group Get Togethers.`
    return(
      <Container
        customClass='step-email-verify'
        headerText={headerText}
        descText={descText}
        business={client}
      >
        { client && (
          <>
            <h1>Please confirm you email address</h1>
            <h3 className='text-gray'>We have sent you an email to the address below. Please click the button in the email to get started.</h3>
          </>
        )}
        { !client && (
          <>
            <h1>Just need to verify your email</h1>
            <h3 className='text-gray'>The key to Frindow is in your inbox. Simply click the verification link in the email we sent to:</h3>
          </>
        )}
        <div className='form'>
          <div className='form-group'>
            <div className='form-input-cell medium'>
              <input
                className={'form-control ' + ( emailErrorText ? 'is-invalid' : 'is-valid')}
                type='email'
                placeholder='Email'
                value={email}
                onChange={onChange}
                name='user[email]'
              />
              {(emailErrorText != null && emailErrorText.length > 0) &&
                <div className='form-message invalid-feedback'>{emailErrorText}</div>}
              <input
                type='hidden'
                name='user[original_email]'
                value={this.props.original_email}
              />
            </div>
          </div>
          <p className='text-gray'>*Wrong email address? Edit your email above.</p>
        </div>
        <div className='content-bottom'>
          <h2>Having trouble?</h2>
          <p className='text-gray'>If you did not receive an email, please check your spam/junk folder or <a className='text-blue-ui' href='https://frindow.freshdesk.com/support/tickets/new' rel="noopener noreferrer" target="_blank" >Contact us</a>.</p>
          <div className='form-actions'>
            <button className='btn btn-secondary btn-big btn-submit' onClick={validateEmail}>Re-send email verification</button>
          </div>
        </div>
      </Container>
    )
  }
}
