import React, { Component } from 'react'
import { makeGetRequest } from '../shared/api.js'
import RefreshModal from './refresh_modal'
import { SESSION_TIME } from '../../utils/constants'

export default class RefreshSessionContainer extends Component {
  constructor(props) {
    super(props)

    this.logoutTimer = {}

    this.state = {
      showModal: false
    }
  }

  componentDidMount() {
    this.setUserCheckingTimeout()
  }

  setUserCheckingTimeout = () => {
    setTimeout(this.checkIfActive, SESSION_TIME.checkSeesion)
  }

  checkIfActive = () => {
    makeGetRequest({ url: this.props.checkUnactiveUrl })
      .then((response) => {
        if (response.data.errors == null) {
          if(response.data.unactive) {
            this.logoutTimer = setTimeout(this.logoutUser, SESSION_TIME.logoutAfter)
            this.setState({showModal: true})
          }
          else { this.setUserCheckingTimeout() }
        } else { location.reload() }
      })
  }

  refreshSession = () => {
    clearTimeout(this.logoutTimer)
    this.setUserCheckingTimeout()
    makeGetRequest({ url: this.props.refreshUserUrl })
      .then((response) => {
        if (response.data.errors == null) {
          if(response.data.success) { this.setState({showModal: false}) }
        } else { location.reload() }
      })
  }

  logoutUser = () => {
    makeGetRequest({ url: this.props.logoutUserUrl })
      .then((response) => {
        location.reload()
      })
  }

  render = () => {
    const { showModal } = this.state
    const { refreshSession } = this
    return(
      <React.Fragment>
        {showModal && <RefreshModal handleClickConfirm={refreshSession} />}
      </React.Fragment>
    )
  }
}
