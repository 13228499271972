import React from 'react'
import ApprovalForm from './approval_form'
import Notification from '../notifications/notification'
import { makePostRequest } from '../shared/api.js'

class ApprovalContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      description: '',
      errors: '',
      messages: '',
      status: '',
      decided: false
    }
  }

  handleClickOpen = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({open: true})
  }

  handleClickClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({open: false})
  }

  handleClickSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { status, description } = this.state
    const { url, object_type, object_id } = this.props
    makePostRequest({ url: url, body: { approval: { message: description, status: status, approvalable_type: object_type , approvalable_id: object_id } } })
       .then((response) => {
         if (response.data.errors == null) {
           this.setState({
             open: false,
             description: '',
             errors: '',
             messages: response.data.messages,
             decided: true
           })
         }
         else {
           this.setState({
             errors: response.data.errors
           })
         }
    })
  }

  handleConfirmChange = (e) => this.setState({status: e.target.value})

  handleDescriptionChange = (e) => this.setState({ description: e.target.value })

  renderConfirmForm = () => {
    if (!this.state.open) { return null }
    const { object_type } = this.props
    const { description, errors, status } = this.state
    const { handleConfirmChange, handleDescriptionChange, handleClickSubmit, handleClickClose } = this
    return (
      <ApprovalForm
        object_type={object_type}
        description={description}
        handleDescriptionChange={handleDescriptionChange}
        handleClickSubmit={handleClickSubmit}
        handleClickClose={handleClickClose}
        errors={errors}
        status={status}
        handleConfirmChange={handleConfirmChange}
      />
    )
  }

  renderNotifications = () => {
    const { messages } = this.state
    if (messages.length < 1) { return null; }
    return <Notification messages={messages} />
  }

  renderConfirm = () => {
    if(this.state.decided) { return this.state.status }
    return (<button type='button' className='btn btn-primary btn-small btn-update' title='confirmation' onClick={this.handleClickOpen}>Confirm</button>)
  }

  render = () => {
    return(
      <React.Fragment>
      {this.renderConfirmForm()}
      {this.renderConfirm()}
      {this.renderNotifications()}
      </React.Fragment>
    )
  }
}

export default ApprovalContainer;
