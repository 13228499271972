import React from 'react'
import HorizontalFilterBar from './horizontal_filter_bar'
import ConversationsList from './conversations_list'
import RemoveModal from './remove_modal'
import { makeGetRequest, makeDeleteRequest } from '../shared/api.js'
import script_google_ads from '../../utils/script_google_ads'

export default class ConversationsContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      conversations: [],
      genders: [],
      searchValue: '',
      in_groups: false,
      confirmed: false,
      page: 1,
      isLastPage: true,
      renderList: true,
      openModal: false,
      filtered: false
    }
  }

  componentDidMount() {
    this.fetchConversations()
    if(this.props.advert && this.props.advert.includes('adsbygoogle.js')) {
      script_google_ads()
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if(this.state.searchValue !== prevState.searchValue ||
       this.state.genders.length !== prevState.genders.length ||
       this.state.in_groups !== prevState.in_groups ||
       this.state.confirmed !== prevState.confirmed ||
       this.state.filtered !== prevState.filtered)
       {
         this.setState({renderList: false})
         this.fetchConversations()
       }
  }

  fetchConversations = (page = 1) => {
    const { get_conversations_url } = this.props
    const { searchValue, genders, in_groups, confirmed, filtered } = this.state
    const gender_names = genders.map(o => o.value)
    let filters = `&in_groups=${in_groups}&confirmed=${confirmed}&genders=${gender_names}&filtered=${filtered}`
    if(searchValue.length > 0) { filters += `&user_name=${searchValue}`}
    makeGetRequest({ url: `${get_conversations_url}?page=${page}${filters}` })
      .then((response) => {
        if (response.data.errors == null) {
          let fetchedConversations = response.data.conversations
          if(page > 1) { fetchedConversations = this.state.conversations.concat(fetchedConversations) }
          this.setState({
            errors: '',
            conversations: fetchedConversations,
            isLastPage: response.data.last_page,
            renderList: true
          })
        }
        else {
          this.setState({
            errors: response.data.errors
          })
        }
      })
  }

  handleCheckboxChange = (e) => {
    const name = e.currentTarget.getAttribute('name')
    this.setState({ [name]: !this.state[name]})
  }

  handleGenderChange = (selected) => this.setState({genders: selected})

  handleSearchChange = (e) => this.setState({searchValue: e.target.value })

  handleClickClose = () => this.setState({openModal: false})

  handleClickOpenModal = (e) => {
    e.preventDefault()
    this.setState({ openModal: true,
                    selectedConversationId: e.currentTarget.dataset.conversationid })
  }

  handleClickUnfiltered = (e) => {
    e.preventDefault()
    this.setState({filtered: false})
  }

  handleClickFiltered = (e) => {
    e.preventDefault()
    this.setState({filtered: true})
  }

  handleClickRemove = (e) => {
    e.preventDefault(e)
    if(this.state.processing) { return null }

    this.setState({processing: true})
    makeDeleteRequest({ url: `${this.props.conversations_url}/${this.state.selectedConversationId}`})
                    .then((res) => {
                      const newConversations = this.state.conversations.filter(c => c.id !== res.data.id)
                      this.setState({
                        conversations: newConversations,
                        processing: false,
                        openModal: false })
                    }).catch(res => this.onError(res))

  }

  onError = (response) => {
    this.setState({
      errors: Object.entries(response.errors).map(([_k,v]) => `${v}`),
      processing: false
    })
  }

  renderModal = () => {
    return(
      <RemoveModal
        handleClickClose={this.handleClickClose}
        handleClickRemove={this.handleClickRemove} />
    )
  }

  render = () => {
    const { fetchConversations, renderModal, handleClickOpenModal, handleGenderChange,
      handleSearchChange, handleCheckboxChange, handleClickUnfiltered, handleClickFiltered } = this
    const { conversations, filtered, openModal, renderList, genders, searchValue, isLastPage, in_groups, confirmed } = this.state
    const filters = [{name: 'in_groups', value: in_groups}, {name: 'confirmed', value: confirmed}]
    const { is_lgbt, advert, small_advert, counters } = this.props
    return(
      <React.Fragment>
        <HorizontalFilterBar
          handleGenderChange={handleGenderChange}
          genders={genders}
          handleSearchChange={handleSearchChange}
          searchValue={searchValue}
          filters={filters}
          is_lgbt={is_lgbt}
          filtered={filtered}
          counters={counters}
          handleClickUnfiltered={handleClickUnfiltered}
          handleClickFiltered={handleClickFiltered}
          handleCheckboxChange={handleCheckboxChange} />
        {openModal && renderModal()}
        {renderList && <ConversationsList
          conversations={conversations}
          fetchConversations={fetchConversations}
          handleClickOpenModal={handleClickOpenModal}
          isLastPage={isLastPage}
          advert={advert}
          small_advert={small_advert} />
          }
      </React.Fragment>
    )
  }
}
