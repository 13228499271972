import React from 'react'
import InfoHorizontalBar from './info_horizontal_bar'
import Conversation from './conversation'

export default class ConversationContainer extends React.PureComponent {
  render = () => {
    const { report_url, get_messages_url, create_message_url, interlocutor_profile, advert,
      messages, is_last_page, permitted, write_permission, conversation_updated_at, infoBarAdvert, small_advert } = this.props
    return(
      <React.Fragment>
        <InfoHorizontalBar
          interlocutor_profile={interlocutor_profile}
          system={interlocutor_profile.system}
          advert={infoBarAdvert}
        />
        <Conversation
          permitted={permitted}
          canWrite={write_permission}
          interlocutorName={interlocutor_profile.username}
          messages={messages}
          isLastPage={is_last_page}
          report_url={report_url}
          get_messages_url={get_messages_url}
          system_conversation={interlocutor_profile.system}
          conversationUpdatedAt={conversation_updated_at}
          create_message_url={create_message_url}
          advert={advert}
          small_advert={small_advert}
        />
      </React.Fragment>
    )
  }
}
