import React from 'react'

const CreateTopicForm = (props) => {
  const { errors, title, handleTitleChange, content, handleContentChange, handleClickSubmit, handleClickClose } = props
  const validClassTitle = errors.title ? 'form-control is-invalid' : 'form-control'
  const validClassContent = errors.content ? 'form-control is-invalid' : 'form-control'
  return(
    <div className='r-modal show'>
      <div className='r-modal-content'>
        <div className='r-modal-header'>
          <h4 className='h1 modal-title'>Add a topic</h4>
          <button type='button' aria-label='Close' className='btn btn-close' onClick={handleClickClose}></button>
        </div>
        <div className='r-modal-body'>
          <div className='row'>
            <div className='col-12 form text-left'>
              <div className='form-group'>
                  <div className='form-input-cell'>
                    <input
                      className={validClassTitle}
                      type='text'
                      value={title}
                      onChange={handleTitleChange}
                      placeholder='Topic title (max 40 characters)'
                      maxLength='40'
                    />
                    <div className='invalid-feedback'>{errors.title}</div>
                  </div>
              </div>
              <div className='form-group'>
                <div className='form-input-cell'>
                  <textarea
                    className={validClassContent}
                    placeholder='Start typing to begin a new conversation.'
                    value={content}
                    onChange={handleContentChange}
                    >
                  </textarea>
                  <div className='invalid-feedback'>{errors.content}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='r-modal-footer'>
          <button type='button' className='btn btn-big btn-secondary btn-cancel' onClick={handleClickClose}>Cancel</button>
          <button type='button' className='btn btn-big btn-primary btn-plane' onClick={handleClickSubmit}>Post message</button>
        </div>
      </div>
    </div>
  )
}

export default CreateTopicForm;
