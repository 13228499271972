import React, { Component } from 'react'
import FrindowNotification from '../shared/frindow_notification'
import { makePostRequest, makeDeleteRequest } from '../shared/api'
import i18n from '../../utils/i18n'

export default class BlockUserToggle extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isBlocked: props.isBlocked,
      processing: false,
      errors:   [],
      messages: []
    }
  }

  unblock = (e) => {
    e.preventDefault()
    if(this.state.processing) { return null }

    this.setState({processing: true})
    makeDeleteRequest({ url: this.props.unblockUrl,
                      body: { id: this.props.userId } }).then((res) => {
                        this.setState({ messages: [res.data.message],
                                        isBlocked: res.data.isBlocked,
                                        processing: false})
                      }).catch(response => this.onError(response))
  }

  block = (e) => {
    e.preventDefault()
    if(this.state.processing) { return null }

    this.setState({processing: true})
    makePostRequest({ url: this.props.blockUrl,
                      body: { user: { id: this.props.userId } } }).then((res) => {
                        this.setState({ messages: [res.data.message],
                                        isBlocked: res.data.isBlocked,
                                        processing: false })
                      }).catch(response => this.onError(response))
  }

  onError = (response) => {
    this.setState({
      errors: Object.entries(response.errors).map(([_k,v]) => v),
      processing: false
    })
  }

  clearNotices = () => {
    this.setState({ errors: [], messages: []})
  }

  renderBtn = () => {
    if(this.state.isBlocked) {
      return(
        <a onClick={this.unblock} className='btn-block' href=''>
          {i18n.t('profile.unblockThisUser')}
        </a>
      )
    }

    return(
      <a onClick={this.block} className='btn-block' href=''>
        {i18n.t('profile.blockThisUser')}
      </a>
    )
  }

  render = () => {
    return(
      <>
        {this.renderBtn()}
        <FrindowNotification
          messages={this.state.messages}
          errors={this.state.errors}
          clearNotices={this.clearNotices}
        />
      </>
    )
  }
}
