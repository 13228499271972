import React, { useEffect, useState } from 'react'
import ImageUploader from './shared/image_uploader'
import FrindowSpinner from './shared/frindow_spinner'
import FrindowNotification from './shared/frindow_notification'

const CodeConfirmationUpload = () => {
  const [errors, setErrors] = useState([])
  const [messages, setMessages] = useState([])
  const [uploaded, setUploaded] = useState(false)
  const [showSpinner, setShowSpinner] = useState(false)

  const addImage = (_image, _postPhoto) => {
    setMessages(['Thank you for sending your code and photo. We`ll get back to you soon.'])
    setUploaded(true)
    setShowSpinner(false)
  }

  const uploadStart = () => {
    setErrors([])
    setMessages([])
    setShowSpinner(true)
  }

  const addErrors = (error) => {
    if (error.format) { setErrors([error.format]) }

    setShowSpinner(false)
  }

  return <React.Fragment>
    {
      !uploaded && <ImageUploader
        addImage={addImage}
        imagesLength={0}
        maxFiles={1}
        uploadUrl={`/code_confirmations/upload`}
        autoUpload={true}
        addError={addErrors}
        uploadStart={uploadStart}
      />
    }
    {showSpinner && <FrindowSpinner />}
    <FrindowNotification errors={errors} messages={messages} clearNotices={() => { setMessages([]); setErrors([])}} />
  </React.Fragment>
}
export default CodeConfirmationUpload
