import React from 'react'
import FrindowModal from '../shared/frindow_modal'
import { makePutRequest } from '../shared/api.js'

export default class ConfirmInfo extends React.PureComponent {
  constructor(props){
    super(props)
    this.state = {
      isOpen: false,
      message: '',
      errors: []
    }
  }

  handleMessageChange = (e) => this.setState({message: e.target.value})

  handleCancelBtn = () => {
    const { videoRoomUrl, updateState } = this.props
    const { message } = this.state
    if(message.length < 1) {
      this.setState({errors: ['Reason can\'t be blank']})
      return
    }
    makePutRequest({ url: videoRoomUrl, body: { video_room: { status: 'cancelled', message: message } } })
    .then(() => {
      this.setState({errors: []})
      updateState({videoRoom: null, step: 0})
    }, (response) => {
      this.setState({
        errors: Object.entries(response.errors).map(([k,v]) => `${k} ${v}`)
      })
    })
  }

  closeModal = () => this.setState({isOpen: false})

  openModal = () => this.setState({isOpen: true})

  render = () => {
    const { message, isOpen, errors } = this.state
    const { handleCancelBtn, handleMessageChange, openModal, closeModal } = this
    return(
      <React.Fragment>
        <a onClick={openModal} ><i className='icon icon-cross-in-circle mr-1'></i>Cancel chat</a>
        <FrindowModal
          isOpen={isOpen}
          closeModal={closeModal}
          title='Cancel Live Chat'
          customClass='border-danger'
          customTitleClass='text-ruby'
        >
          <div className='col-12 form text-left'>
            <div className='form-group'>
              <div className='form-input-cell'>
                <textarea
                  placeholder='Please provide a reason for cancelling'
                  value={message}
                  onChange={handleMessageChange}
                  className="w-100"
                >
                </textarea>
                {errors.length > 0 &&
                  <div className="error">
                    {errors[0]}
                  </div>
                }
              </div>
            </div>
          </div>
          <div className='r-modal-footer'>
            <button onClick={handleCancelBtn} className='btn btn-big btn-primary btn-select'>
              CONFIRM CANCELLATION
            </button>
          </div>
        </FrindowModal>
      </React.Fragment>
    )
  }
}
