import React from 'react'
import SimpleSelectField from '../../shared/simple_select_field'
import { REJECT_PHOTO_REASONS } from '../../../utils/constants'

export default class PhotoFrame extends React.Component {
  constructor(props) {
    super(props)
  }

  renderApproval = () => {
    const { approvalStatusChange, handleReasonChange, photo, profileImageId, handleProfileImageChange } = this.props
    const { approvalStatus, reasonSelected, errors } = photo
    return(
      <div className='col-auto mt-3'>
        <form>
          <div className='row mb-3'>
            <div className='col-auto'>
              <div className='form-input-cell radiobox-cell'>
                <input
                  type='radio'
                  photo_id={photo.id}
                  id={`approval_approved_${photo.id}`}
                  name='approval_status'
                  value='approved'
                  className='form-check-input mb-3'
                  onChange={approvalStatusChange}
                  checked={approvalStatus === 'approved'}
                />
                <label htmlFor={`approval_approved_${photo.id}`}><span className='checkbox'></span>
                  Approve
                </label>
              </div>
            </div>
            <div className='col-auto'>
              <div className='form-input-cell radiobox-cell'>
                <input
                  type='radio'
                  photo_id={photo.id}
                  id={`approval_rejected_${photo.id}`}
                  name='approval_status'
                  value='rejected'
                  className='form-check-input mb-3'
                  onChange={approvalStatusChange}
                  checked={approvalStatus === 'rejected'}
                />
                <label htmlFor={`approval_rejected_${photo.id}`}><span className='checkbox'></span>
                  Reject
                </label>
              </div>
            </div>
            <div className='col-12 form text-left mb-3'>
              <div className='form-group'>
                <div className='form-input-cell radiobox-cell'>
                  <SimpleSelectField
                    collection={REJECT_PHOTO_REASONS}
                    item_id={photo.id}
                    value={reasonSelected}
                    onChange={handleReasonChange}
                    emptyKey='Reason for rejection'
                    errorText={errors}
                  />
                </div>
              </div>
            </div>
            <div className='col-auto'>
              <div className='form-input-cell checkbox-cell'>
                <input
                  type='checkbox'
                  id={`profile_${photo.id}`}
                  name='maintenance_mode'
                  value={photo.id}
                  className='form-check-input'
                  onChange={handleProfileImageChange}
                  checked={profileImageId.toString() === photo.id.toString()}
                />
                <label htmlFor={`profile_${photo.id}`}><span className='checkbox'></span>
                  Make Profile Image
                </label>
              </div>
            </div>
          </div>
        </form>
      </div>
    )
  }

  render = () => {
    const { handleOnPhotoClick, photo } = this.props
    return (
      <div className='col-md-6'>
        <div className='pb-2 a'>
          <span>
            <img src={photo.url}  onClick={handleOnPhotoClick} id={photo.id} alt='User photo' className='img-fluid' />
            <h3 className='mt-2 text-center'>{photo.approval == null ?
              this.renderApproval() :
              `Photo ${photo.approval} main: ${photo.main}`
              }
            </h3>
          </span>
        </div>
      </div>
    )
  }
}
