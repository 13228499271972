import React, { useState } from 'react'
import FrindowModal from '../../../shared/frindow_modal'
import ImageUploader from '../../../shared/image_uploader'

const PhotoModal = (props) => {
  const [isOpen, setIsOpen] = useState(props.isOpen)

  const closeModal = () => {
    setIsOpen(false)
    props.closePhotoModal()
  }

  if(!isOpen) return null

  if(props.isUserConfirmed || props.isEventGroup){
    return <FrindowModal title='Upload photo' isOpen={isOpen} closeModal={closeModal}>
      <ImageUploader
        profile={props.profile}
        addImage={props.addImage}
        imgContainerIndex={isOpen}
        closeModal={closeModal}
        imagesLength={1}
        uploadUrl={`/posts/${props.profile.profile.id}/photos`}
        autoUpload={true}
        addError={props.addError}
        uploadStart={props.uploadStart}
      />
    </FrindowModal>
  }else{
    return (
      <FrindowModal
        title='You need to be confirmed'
        customTitleClass='text-danger'
        customClass='border-danger'
        isOpen={isOpen}
        closeModal={closeModal}
      >
        <p>You need to be a confirmed user to upload images into the forums.</p>
        <a className='btn btn-plane btn-primary btn-big' href="/code_confirmation">Confirm account</a>
      </FrindowModal>
    )
  }
}
export default PhotoModal
