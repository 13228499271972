import React from 'react';
import _ from 'lodash'
import { GENDERS } from '../../utils/constants'

const GenderFields = (props) => {
  const { gender, genderErrorText, handleGenderChange } = props
  const genderOptions = [ <option key={'gender_default'} value='' disabled>Choose</option> ]
  GENDERS.forEach((value) => genderOptions.push( <option key={'gender_' + value} value={value}>{_.upperFirst(value)}</option>))
  return(
    <div>
      <select value={gender}
              children={genderOptions}
              className='form-control'
              onChange={handleGenderChange} />
      <div>
        {genderErrorText}
      </div>
    </div>
  )
}

export default GenderFields;
