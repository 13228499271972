
import React, { useState, useRef, useEffect } from 'react'
import FrindowModal from '../shared/frindow_modal'
import ModalPortal from '../shared/modal_portal'
import TextField from '../shared/text_field'
import CheckBoxField from '../shared/checkbox_field'
import TextArea from '../shared/text_area'
import { VALIDATION_MSG, EMAIL_REGEX } from '../../utils/constants'
import { makePostRequest } from '../shared/api'
import Recaptcha from 'react-recaptcha'

const EmailButton = ({ profile , client_type, profile_image, sitekey, fullname}) => {
  const styles = { backgroundColor: client_type.hex_code }
  const [showModal, setShowModal] = useState(false)
  const [content, setContent] = useState({})
  const [errors, setErrors] = useState([])
  const [message, setMessage] = useState([])
  const [successSend, setSuccessSend] = useState(false)
  const [termsChecked, setTermsChecked ] = useState(false)
  const [isVerified, setIsVerified ] = useState(false)
  const [recaptchaError, setRecaptchaError ] = useState(false)
  const header = useRef()

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
    setErrors({})
  }

  const onChange = (event) => {
    content[event.target.name] = event.target.value
    setContent(content)
  }

  const submitForm = () => {
    if(!isVerified) {
      setRecaptchaError(true)
    }else{
      const validationErrors = validate()
      setRecaptchaError(false)
      const errors = validationErrors

      if (isValid(errors)){
        sendEmail()
        setErrors([])
      }else{
        setErrors(validationErrors)
      }
    }
    header.current.scrollIntoView({behavior: 'smooth'})
  }

  const sendEmail = () => {
    const params = {message: content}

    makePostRequest({url: `/client_profiles/${profile.id}/send_message`, body: params}).then((response) => {
      if(response.data.success){
        setSuccessSend(true)
      }else{
        setErrors(response.data.errors)
      }
    })
  }

  const validate = () => {
    const contentErrors = {}

    contentErrors['name'] = checkPresence(content.name)
    contentErrors['email'] = checkPresence(content.email)
    if(content.email != ''){
      contentErrors['email'] = checkEmail(content.email)
    }
    contentErrors['subject'] = checkPresence(content.subject)
    contentErrors['message'] = checkPresence(content.message)
    if(!termsChecked) {
      contentErrors['terms'] = 'Please confirm that you understand and agree with the statement above.'
    }

    return contentErrors
  }

  const isValid = (errors) => {
    return Object.values(errors).every((e) => e === '')
  }

  const checkPresence = (value) => {
    if(typeof value == 'undefined' || value == ''){
      return VALIDATION_MSG.isRequired
    }else{
      return ''
    }
  }

  const checkEmail = (value) => {
    return EMAIL_REGEX.test(value) ? '' : VALIDATION_MSG.isEmail
  }

  const clearNotices = () => {
    setErrors({})
  }

  const handleTermsChange = (event) => {
    setTermsChecked(event.target.checked)
  }

  const modalLabel = (<p className='text-left client-info'>I understand that I’m going to share the information above with {fullname} who is an approved Frindow third-party service provider but is not part of the Frindow organisation. Please review our <a className="text-blue-ui" href="/pages/privacy" target="_blank">Privacy Policy</a> for more information.</p>)

  const verifyCallback = (response) => {
    if (response) {
      setIsVerified(true)
    }
  }
  
  const modalAsideContent = (
    <>
      <p>
        You are about to make an enquiry to a service professional. Here is some useful information
      </p>
      <ul>
        <li>Ensure that your contact details are correct, otherwise this person will not be able to respond to you.</li>
        <li>Note that you will receive a copy of this enquiry by email. </li>
        <li>Keep your message short and to the point. Make it clear what it is that you want help with.</li>
        <li>Note that you are passing your personal contact details to someone outside of Frindow. Click here to review our <a className="text-blue-ui" href="/pages/privacy" target="_blank">Privacy Policy</a>.</li>
        <li>An inappropriate use of this form could lead to being banned from all Frindow services.</li>
        <li>Do not send repeated message to this person. They will contact you on the details that you have provided, should they decide to do so.</li>
      </ul>
    </>
  )

  useEffect(() => {
    if (!showModal) return;
    header.current.scrollIntoView({behavior: 'smooth'})
  }, [showModal])

  const clientHex= client_type.hex_code.trim()

  return(
    <React.Fragment>
      <span className='btn btn-plane btn-big text-center' style={styles} onClick={openModal}>
        Email
      </span>
      <ModalPortal modalClass='client-profile'>
        <FrindowModal
            isOpen={showModal}
            closeModal={closeModal}
            customClass='wide' >
            <style>
              {`
                .r-modal {
                  height: ${document.firstElementChild.offsetHeight}px
                }
                .r-modal-content {
                  border-color: ${clientHex};
                }
                .client-profile .btn.btn-primary:not(.get-listed) {
                  background-color: ${clientHex};
                  box-shadow: 0 0 0 0 ${clientHex}99, -10px -5px 0px 0px ${clientHex}CC inset;
                }
                .client-profile .btn.btn-primary:not(.get-listed):hover,
                .client-profile .btn.btn-primary:not(.get-listed):active,
                .client-profile .btn.btn-primary:not(.get-listed):focus  {
                  background-color: ${clientHex}FC;
                  box-shadow: 0 0 0 0 ${clientHex}CC, -10px -5px 0px 0px ${clientHex}FC inset;
                }
              `}
            </style>
            <div className='row align-items-center client-modal-header' style={styles} ref={header}>
              <span className="client-image circle-avatar">
                <img src={profile_image} className="img-fluid small" />
              </span>
              <p className='mb-0 pr-3'>Email: {profile.first_name}</p>
            </div>

            { successSend  &&
            <div className='pb-4 row'>
              <div className='col-sm-12 col-md-12'>
                <span className="client-success-message">Your message has been sent to {fullname}. We have sent a copy of your message to you for your records.</span>
              </div>
            </div>
            }
            { !successSend  &&
              <div className='pb-4 row'>
                <div className='col-sm-12 col-md-6'>
                  <TextField name="name" placeholder="Your Name" onChange={onChange}
                                        errorText={errors.name} classForm='w-100'/>
                  <TextField name="email" placeholder="Your Email Address" onChange={onChange}
                            errorText={errors.email} classForm='w-100'/>
                  <TextField name="phone" placeholder="Your Phone" onChange={onChange}
                            errorText={errors.phone} classForm='w-100'/>
                  <TextField name="subject" placeholder="Subject" onChange={onChange}
                            errorText={errors.subject} classForm='w-100'/>
                  <TextArea name="message" placeholder="Message" onChange={onChange}
                            errorText={errors.message} classForm='w-100'/>

                  { recaptchaError &&
                    <div>Please mark recaptcha field.</div> }
                  <div className="mobile-only text-left">{modalAsideContent}</div>
                  <Recaptcha sitekey={sitekey}
                            render="explicit"
                            verifyCallback={verifyCallback} />
                  <br />
                  <CheckBoxField name="confirmation"
                                id='confirmation'
                                value={termsChecked}
                                onChange={(event) => handleTermsChange(event)}
                                errorText={errors.terms}
                                labelDesc={modalLabel} />
                  <div className="text-left">
                    <button className='btn btn-plane btn-primary' style={styles} onClick={submitForm}>
                      Send Email
                    </button>
                  </div>
                </div>

                <div className='col-sm-12 col-md-6 text-left desktop-only'>
                  {modalAsideContent}
                </div>
              </div>
            }
        </FrindowModal>
      </ModalPortal>
    </React.Fragment>
  )
}

export default EmailButton
