import React from 'react'
import ProgressBar from '../../shared/progress_bar'
import CodePng from 'code.png'
import AlertSvg from 'alert.svg'

import FrindowNotification from '../../shared/frindow_notification'

import { makePutRequest } from '../../shared/api.js'

export default class VerificationCode extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = {
      errors: [],
      messages: []
    }
  }

  handleOnSubmit = (e) => {
    e.preventDefault()

    const { verifyShippingAddressPath, onNextStep } = this.props
    const code = e.target.elements[0].value.split('-').join('')
    makePutRequest(
      { url: `${verifyShippingAddressPath}.json`, body: { verification_code: code }}
    ).then(() => {
      this.setState({errors: []})
      onNextStep()
    }).catch((response) => {
      this.setState({
        errors: Object.entries(response.errors).map(([k,v]) => `${v}`)
      })
    })
  }

  handleClearNotice = () => {
    this.setState({errors: [], messages: []})
  }

  render = () => {
    const { handleOnSubmit, handleClearNotice } = this
    const { errors, messages } = this.state
    return(
      <React.Fragment>
        <div className="container confirmation-page">
          <div className="row align-items-start mt-5 mb-5 sign-in-form">
            <div className="col-md-8 col-lg-4 offset-md-0 offset-lg-4 mb-5">
              <ProgressBar step={3} steps={4}></ProgressBar>
              <img src={CodePng} className='mt-3 mb-3'></img>
              <h2 className="mb-3">Please enter your ​confirmation ​code</h2>
              <form onSubmit={handleOnSubmit} className="form mb-3">
                <div className="form-group">
                  <div className="form-input-cell">
                    <input id='verification_code' className="form-control" placeholder="Your code" required></input>
                    <div className="invalid-feedback">Error msg!</div>
                  </div>
                </div>
                <button type='submit' className='btn btn-primary btn-next mt-4'>Next</button>
              </form>
            </div>
            <div className="col-md-4 col-lg-3">
              <div className="info-box mt-5">
                <img src={AlertSvg}></img>
                <h3 className="mt-2">Where will I find this?</h3>
                <p>This can only be filled in once you receive the code in the post.</p>
                <p>Once received, please enter it exactly as it has been written.</p>
              </div>
            </div>
          </div>
        </div>
        <FrindowNotification
          errors={errors}
          messages={messages}
          clearNotices={handleClearNotice} />
      </React.Fragment>
    )
  }
}
