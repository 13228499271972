import React from 'react'
import VideoRoomsListItem from './video_rooms_list_item'
import InfiniteScroll from 'react-infinite-scroller'
import FrindowSpinner from '../shared/frindow_spinner'

const VideoRoomsList = (props) => {
  const { videoRooms, fetchVideoRooms, isLastPage } = props
  const headText = videoRooms.length > 0 ? 'Your scheduled live chats' : 'You don\'t have scheduled live chats'
  return(
    <div className='conversations-content on-ligh container'>
      <h1 className='m-2'>{headText}</h1>
      <div className='conversations-list'>
        <InfiniteScroll
          pageStart={1}
          loadMore={fetchVideoRooms}
          hasMore={!isLastPage}
          initialLoad={false}
          loader={<FrindowSpinner key={0} />}
          >
          {videoRooms.map((videoRoom) => (
            <VideoRoomsListItem videoRoom={videoRoom} key={videoRoom.id} />
          ))
          }
        </InfiniteScroll>
      </div>
    </div>
  )
}

export default VideoRoomsList
