import React from 'react'

const FrindowSpinner = () => {
  return(
    <div className="spinner">
      <i className="frindow-ring"></i>
      Loading
    </div>
  )
}

export default FrindowSpinner
