import React from 'react';
import FrindowSpinner from '../../../shared/frindow_spinner'
import BeforeJoinGroup from '../../shared/before_join_group'
import MiniProfile from '../../../profile/mini_profile'
import { makePostRequest, makeGetRequest } from '../../../shared/api.js'
import InitialPost from './initial_post'
import NewPostReply from './new_post_reply'
import ReplyForm from './reply_form'
import lozad from 'lozad'
import i18n from '../../../../utils/i18n'
import { removeMultipleNewlines } from '../../../../utils/tools'

export default class SubjectCard extends React.PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      posts: [],
      shownReplies: [],
      content: '',
      loading: true,
      replyValue: '',
      writtenPosts: [],
      inGroup: false,
      open: false,
      postReplyTo: {},
      page: 1,
      lastPage: false,
      emailMe: true,
      processingFollow: false,
      isFollowed: props.isFollowed,
      subjectId: props.subjectId,
      publishedEventType: false,
      postPhoto: null,
      showPosts: props.isEventGroup
    }

    this.observer = lozad()
    this.inputElement = React.createRef()
  }

  componentDidMount(){
    this.fetchSubject(1, true)
  }

  parentSetState = (newState) => {
    this.setState(newState)
  }

  componentDidUpdate(prevProps) {
    if(prevProps.createPostUrl !== this.props.createPostUrl) {
      this.setState({writtenPosts: [], lastPage: false, isFollowed: this.props.isFollowed }, () => this.fetchSubject(1, true))
      if (this.props.addSubjectToList){
        const subject = {
          subjectUrl: this.state.subjectUrl,
          title: this.state.title,
          postCount: this.state.postsCount,
          updateDate: this.state.posts[this.state.posts.length - 1].forum_format_updated_at
        }
        this.props.addSubjectToList(subject)
      }
    }
    this.observer.observe()
  }

  loadMore = (e) => {
    e.preventDefault()
    e.stopPropagation()
    if(this.state.shownReplies.length < 19) {
      this.setState({shownReplies: this.state.posts.slice(1)})
      return
    }
    this.fetchSubject(this.state.page + 1)
  }

  fetchSubject = (page = 1, initial = false) => {
    if(this.state.lastPage) { return null }

    return makeGetRequest({ url: `${this.props.fetchSubjectPosts}?page=${page}`})
      .then((response) => {
        const { posts, title, subjectUrl, create_post_url, last_page, permitted, postsCount, publishedEventType } = response.data
        const ids = this.state.posts.map(p => p.id)
        let newPosts = posts.filter(p => !ids.includes(p.id))
        let shownReplies = []
        if (initial) {
          shownReplies = (this.props.isEventGroup ? newPosts.slice(1) : newPosts.slice(1,3))
        } else {
          shownReplies = this.state.shownReplies.concat(newPosts)
        }
        if(!initial) { newPosts = this.state.posts.concat(newPosts) }
        this.setState({ posts: newPosts,
                        shownReplies: shownReplies,
                        title: title,
                        subjectUrl: subjectUrl,
                        createPostUrl: create_post_url,
                        lastPage: last_page - 1 <= page,
                        permitted: permitted,
                        postsCount: postsCount,
                        page: page,
                        publishedEventType: publishedEventType,
                        loading: false }, this.updateOffsetOnLoad());
       });
  }

  updateOffsetOnLoad = () => {
    setTimeout(() => {
      const { posts } = this.state;
      const elementToOffset = document.querySelector(`form-for-post-${posts[0].subject_id}`);
      const offsetSource = document.querySelector('.post-replys .col-auto');

      if(offsetSource && elementToOffset) {
      elementToOffset.style.paddingLeft = `${Math.round(offsetSource.getBoundingClientRect().width)}px`
      }

    }, 0)
  }

  renderMiniProfile = (post) => {
    const { avatar, profile_path, photo_url, profile } = post
    const { user_name, deleted } = post.profile

      return <div className='col-auto'>
        {<MiniProfile
          post={post}
          username={user_name}
          profile={profile}
          photo_url={photo_url}
          avatar={avatar}
          profile_path={profile_path}
          deleted={deleted}
          sizeClass='size-md' />}
      </div>
  }

  renderReplyForm = () => {
    const { replyValue, errors, replyTo, postReplyTo } = this.state
    return(
      this.props.canWrite && <ReplyForm
        replyValue={replyValue}
        errors={errors}
        replyTo={replyTo}
        postReplyTo={postReplyTo}
        handleClickPost={this.handleClickPost}
        handleChangeReply={this.handleChangeReply}
        forwardRef={this.inputElement}
        profile={this.props.profile}
        handlePostPhotoUpload={this.handlePostPhotoUpload}
        isEventGroup={this.props.isEventGroup}/>
    )
  }

  handlePostPhotoUpload = (postPhoto) => {
    this.setState({postPhoto: postPhoto})
  }

  handleClickPost = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.userInGroup()
  }

  userInGroup = () => {
    makeGetRequest({ url: `${this.props.inGroupUrl}` })
         .then((response) => {
           this.setState({ inGroup: response.data.in_group, open: true });
         });
  }

  handleChangeReply = (e) => { this.setState({ replyValue: removeMultipleNewlines(e.target.innerText) }) }

  handleClickClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({open: false})
  }

  afterJoin = () => {
    this.setState({ inGroup: true })
    if (typeof ga !== 'undefined') { ga('send', 'event', 'Group', 'user joined') }
  }

  renderGroupForm = () => {
    if (!this.state.open) { return null; }
    if (!this.state.inGroup) {
      return (<BeforeJoinGroup
                infoText='You need to be a member of this group to create a new post.'
                join={this.props.urlJoinGroup}
                handleClickClose={this.handleClickClose}
                joined={false}
                afterJoin={this.afterJoin} />);
    }
    this.handlePost()
    return null;
  }

  loadAll = (newPost) => {
    const result = this.fetchSubject(this.state.page + 1)
    if(!result) {
      return
    }

    result.then(() => {
      if((parseInt(this.state.postsCount) - this.state.shownReplies.length) > 0) {
        this.loadAll(newPost)
      } else {
        this.inputElement.current.scrollIntoView({behavior: 'smooth', block: 'end'});
      }
    })
  }

  handlePost = () => {
    const { replyValue, postReplyTo, emailMe, postPhoto } = this.state
    const { createPostUrl } = this.props
    const postPhotoId = postPhoto ? postPhoto.id : null
    const postParams = { content: replyValue, parent_id: postReplyTo.id, email_me: emailMe, post_photo_id: postPhotoId }

    makePostRequest({ url: createPostUrl, body: { post: postParams } })
       .then((response) => {
         if (response.data.errors == null) {
           if (typeof ga !== 'undefined') { ga('send', 'event', 'Post', 'created') }
           this.setState({
            open: false,
            replyValue: '',
            replyTo: false,
            postReplyTo: {},
            postPhoto: null,
            showPosts: true
           })
           if(this.state.shownReplies.length < 19) {
             this.setState({shownReplies: this.state.posts.slice(1)})
           }
           if(this.state.lastPage) {
              this.setState((prevState) => ({
                writtenPosts: prevState.writtenPosts.concat(response.data.post),
                posts: prevState.posts.concat(response.data.post),
                shownReplies: this.state.posts.slice(1).concat(response.data.post),
                postsCount: prevState.postsCount + 1
              }))
              this.inputElement.current.scrollIntoView({behavior: 'smooth', block: 'end'});
           } else {
             this.loadAll(response.data.post);
           }
           this.inputElement.current.textContent = ''
         }
         else {
           this.setState({
             open: false,
             errors: response.data.errors
           })
         }
    })
  }

  handleReplyClick = (e) => {
    const postId = e.target.dataset['postId']
    const postReplyTo = this.state.posts.find((post) => post.id.toString() === postId )
    const postFormId = e.target.dataset['postForm']
    const postForm = document.getElementById(`form-for-post-${postFormId}`).querySelector('[contenteditable]')
    e.stopPropagation()
    e.preventDefault()
    postForm.focus()
    this.setState({ replyTo: true, postReplyTo: postReplyTo }, () => {
      setTimeout(() => postForm.scrollIntoView({behavior: 'smooth', block: 'end'}), 100)
    })
  }

  handleShowPostsClick = () => {
    this.setState({showPosts: !this.state.showPosts});
  }

  renderReply = () => {
    const { handleReplyClick } = this
    const { shownReplies, createPostUrl, showPosts, posts } = this.state

    return(
      <div className='post-replys mt-3'>
        { shownReplies.length > 0 && showPosts && shownReplies.map((post) =>{
            return(
              <NewPostReply
                key={post.id}
                post={post}
                handleReplyClick={handleReplyClick}
                renderMiniProfile={this.renderMiniProfile}
                likeUrl={`${createPostUrl}/${post.id}/like_post`}
                unlikeUrl={`${createPostUrl}/${post.id}/unlike_post`}
                banned={this.props.banned}
                subjectFormId={posts[0].subject_id}
                canWrite={this.props.canWrite}/>
            )
          })
        }
      </div>
    )
  }

  renderSubjectContent = () => {
    if(this.state.loading) { return (<FrindowSpinner />) }

    const { parentSetState, handleReplyClick } = this
    const { posts, shownReplies, createPostUrl, processingFollow, isFollowed, followUrl, subjectId, showPosts } = this.state

    return(
      <>
        <InitialPost
          post={posts[0]}
          title={this.state.title}
          subjectUrl={this.state.subjectUrl}
          postsCount={parseInt(this.state.postsCount)}
          handleReplyClick={handleReplyClick}
          parentSetState={parentSetState}
          processingFollow={processingFollow}
          isFollowed={isFollowed}
          followUrl={followUrl}
          subjectId={subjectId}
          subjectFormId={posts[0].subject_id}
          likeUrl={`${createPostUrl}/${posts[0].id}/like_post`}
          unlikeUrl={`${createPostUrl}/${posts[0].id}/unlike_post`}
          groupUrl={this.props.groupUrl}
          groupDisplayName={this.props.groupDisplayName}
          banned={this.props.banned}
          publishedEventType={this.state.publishedEventType}
          handleShowPostsClick={this.handleShowPostsClick}
          showPosts={showPosts}
          canWrite={this.props.canWrite}/>
        <div className='post-reply-footer' id={`form-for-post-${posts[0].subject_id}`}>
          {this.renderReply()}
          {(parseInt(this.state.postsCount) - shownReplies.length) > 0 && showPosts && (
            <div className="mb-3 post-actions">
              <a href='#loadMore' onClick={this.loadMore}>
                <i className='icon-curved-arrow mr-2' />
                {i18n.t('subjects.moreXComments', { count: `${parseInt(this.state.postsCount) - shownReplies.length}` })}
              </a>
            </div>)}
          {!posts[0].discarded_at && this.state.permitted && this.renderReplyForm()}
        </div>
        {this.renderGroupForm()}
      </>
    )
  }

  render = () => {
    return (
      <div className='card border-0 p-4 mb-4'>
        {this.renderSubjectContent()}
      </div>
    )
  }
}
