import React, { Component } from 'react'
import TextField from '../shared/text_field'
import Button from '../shared/button'
import { PASSWORD_REGEX } from '../../utils/constants'
import { makePutRequest } from '../shared/api'
import FrindowNotification from '../shared/frindow_notification'
import _ from 'lodash'
import i18n from '../../utils/i18n'

export default class EditPassword extends Component {
  constructor(props) {
    super(props)
    this.state = {
      current_password: '',
      password: '',
      password_confirmation: '',
      errors: {
        current_password_error: '',
        password_error: '',
        password_confirmation_error: ''
      },
      server_errors: [],
      messages: []
    }
  }

  onCurrentPasswordChange = (e) => {
    this.setState({ current_password: e.target.value, errors: {...this.state.errors, current_password_error: ''} })
  }

  onPasswordChange = (e) => {
    this.setState({ password: e.target.value, errors: {...this.state.errors, password_error: ''} })
  }

  onPasswordConfirmationChange = (e) => {
    this.setState({ password_confirmation: e.target.value, errors: {...this.state.errors, password_confirmation_error: ''} })
  }

  validation = () => {
    const { password, password_confirmation, errors} = this.state
    if(password !== password_confirmation) {
      const msg = 'Please ensure that your password and confirmed password are the same.'
      this.setState({ errors: {...errors, password_confirmation_error: msg, password_error: msg} })
      return false
    } else {
      this.setState({ errors: { ...errors, password_confirmation_error: '', password_error: ''}})
    }
    if(!PASSWORD_REGEX.test(password)) {
      const msg = i18n.t('shared.passwordError')
      this.setState({ errors: {...errors, password_confirmation_error: msg, password_error: msg} })
      return false
    } else {
      this.setState({ errors: { ...errors, password_confirmation_error: '', password_error: ''}})
    }
    return true
  }

  handleCancel = () => {
    this.setState({
      password: '',
      current_password: '',
      password_confirmation: '',
      password_error: '',
      password_confirmation_error: '',
      current_password_error: ''
    })
  }

  handleUpdate = () => {
    const formValid = this.validation()
    const params = {
      user: {
        current_password: this.state.current_password,
        password: this.state.password,
        password_confirmation: this.state.password_confirmation
      }
    }
    if(formValid) {
      makePutRequest({ url: `/users/${this.props.user.id}/update_password`, body: params }).then((res) => {
        if(res.data.success) {
          this.setState({
            messages: ['Password has been changed'],
            password: '',
            password_confirmation: '',
            current_password: ''
          })
        } else {
          this.setState({ errors: res.data.errors})
        }
      })
    }
  }

  renderActions = () => (
    <div className='form-actions mt-4'>
      <Button
        className='button btn btn-big btn-secondary btn-cancel btn-submit'
        title='Cancel'
        onClick={this.handleCancel}
      />
      <Button
        className='btn btn-big btn-primary btn-update btn-submit'
        title='Update'
        onClick={this.handleUpdate}
      />
    </div>
  )

  render = () => {
    return(
      <React.Fragment>
        <TextField
          value={this.state.current_password}
          onChange={this.onCurrentPasswordChange}
          placeholder='Old password'
          errorText={this.state.errors.current_password_error}
          type='password'
        />
        <TextField
          value={this.state.password}
          onChange={this.onPasswordChange}
          placeholder='New password'
          errorText={this.state.errors.password_error}
          type='password'
        />
        <TextField
          value={this.state.password_confirmation}
          onChange={this.onPasswordConfirmationChange}
          placeholder='Retype password'
          errorText={this.state.errors.password_confirmation_error}
          type='password'
        />
        {this.renderActions()}
        <FrindowNotification errors={this.state.server_errors} messages={this.state.messages} clearNotices={() => this.setState({ server_errors: [], messages: [] })} />
      </React.Fragment>
    )
  }
}
