import React, { useState } from 'react'
import { makePostRequest } from '../../shared/api.js'

const VisabilityLink = (props) => {
  const [visibility, setVisibility] = useState(props.visibility)

  const handleClick = () => {
    handleVisibility()
  }

  const handleVisibility = () => {
    const visibilityUrl = `/client_profiles/${props.profile.id}/visibility`
    makePostRequest({ url: visibilityUrl, body: {} })
      .then((response) => {
        if (response.data.errors == null) {
          setVisibility(!visibility)
        } else {

        }
      })
  }


  return (
    <>
      {
        (!props.account || !props.fillCompletely) &&
          <button
            disabled
            className={`custom-toggler off`}>
          </button>
      }
      {  props.account && !props.published && props.fillCompletely &&
        <button
          disabled
          className={`custom-toggler off`}
        />
      }
      {  props.account && props.published && props.fillCompletely &&
        <button
          onClick={handleClick}
          className={`custom-toggler ${!visibility ? '' : 'on'}`}
        />
      }
    </>
  )
}
export default VisabilityLink
