import React from 'react'

const PhotoGallery = ({ urls }) => {

  const imageNodes = urls.map((url, index) => (
    <a key={`key_img_${index}`} className='swipebox' href={url} rel='user-slider'>
      <img src={url} alt='User photo' className='img-fluid rounded-circle'/>
    </a>
  ))

  return(
    <React.Fragment>
      <h3>Gallery</h3>
      <div className='user-gallery-list mb-4'>
        {imageNodes}
      </div>
    </React.Fragment>
  )
}

export default PhotoGallery
