import React, { Component } from 'react'
import FrindowNotification from '../../shared/frindow_notification'
import { POSTCODE_REGEX } from '../../../utils/constants'
import { getAddressData } from '../../../utils/postcodes'

export default class Region extends Component {
  constructor(props) {
    super(props)
    this.state = { errors: [] }
  }

  getCoordinates = (event) => {
    event.preventDefault()
    let venue_postcode = document.getElementById('venue_postcode')
    if(POSTCODE_REGEX.test(venue_postcode.value)) {
      getAddressData(venue_postcode.value).then((res) => {
        if(res.status === 200){
          const { data } = res

          let longitudeField     = document.getElementById('venue_longitude')
          let latitudeField      = document.getElementById('venue_latitude')

          if(data.longitude)      longitudeField.value = data.longitude
          if(data.latitude)       latitudeField.value = data.latitude
        } else {
          this.setState({ errors: ['Latitude and longitude cannot be found'] })
        }
      })
    } else {
      this.setState({ errors: ['Please enter a valid postcode'] })
    }
  }

  render = () => {
    const { locationValues, handleLocationChange, locationOptions } = this
    return(
      <React.Fragment>
        <button className='btn btn-small btn-secondary' onClick={this.getCoordinates}>
          Get Latitude/Longitude
        </button>

        <FrindowNotification errors={this.state.errors} clearNotices={() => this.setState({ errors: []})} />
      </React.Fragment>
    )
  }
}
