import React, { useState } from 'react'

const groupLabel = (group) => {
  return(<li key={group.id}><a href={group.url}>{group.name}</a></li>)
}

const UserGroups = (props) => {
  const [showMore, setShowMore] = useState(false)
  const { groups } = props
  const groupsLength = groups.length
  return(
    <ul className='user-labels clean-list'>
      {groups.slice(0, 2).map((group) => {
        return(groupLabel(group))
        })
      }
      {groupsLength > 2 &&
        <React.Fragment>
          <li key='button' className={`labels-brake ${showMore ? 'is-open' : ''}` }>
            <button className="show-more-labels" onClick={() => setShowMore(!showMore)}>(+{groupsLength - 2})</button>
          </li>
          <div style={{display: `${showMore ? 'block' : 'none'}`}}>
            {groups.slice(2).map(group => groupLabel(group))}
          </div>
        </React.Fragment>
      }
    </ul>
  )
}

export default UserGroups
