import React from 'react'
import 'react-image-crop/dist/ReactCrop.css'
import Notification from '../../notifications/notification'
import PhotosList from './photos_list'
import { makePostRequest } from '../../shared/api.js'
import CroppingPhoto from './cropping_photo'
import SimpleSelectField from '../../shared/simple_select_field'
import { REJECT_PHOTO_REASONS } from '../../../utils/constants'

export default class PhotosApprovalContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      errors: '',
      messages: '',
      photos: props.photos,
      selectedPhotoId: props.photos.find(p => p.approval == null).id,
      reasonAllSelected: '',
      profileImageId: props.profileImageId || ''
    }
  }

  handleClickSubmit = (e) => {
    e.stopPropagation()
    e.preventDefault()
    const { photos, reasonAllSelected, profileImageId } = this.state
    const { url } = this.props
    makePostRequest({ url: url, body: { photos: photos,
                                        reason_for_all: reasonAllSelected,
                                        profile_image_id: profileImageId } })
       .then(() => {
         window.location.href = url
    })
  }

  renderNotifications = () => {
    const { messages } = this.state
    if (messages.length < 1) { return null; }
    return <Notification messages={messages} />
  }

  onCropChange = crop => {
    const { photos, selectedPhotoId } = this.state
    photos.map((photo) => {
      if(photo.id.toString() === selectedPhotoId.toString()) { photo.crop = crop }
    })
    this.setState({photos: photos})
  }

  handleOnPhotoClick = (e) => {
    const { photos } = this.props
    this.setState({
                  selectedPhotoId: photos.find(p => p.id.toString() === e.target.id).id
                 })
  }

  handleClickChangeAll = (e) => {
    const { photos } = this.state
    photos.map((photo) => {
      if(photo.approval == null) { photo.approvalStatus = e.target.value }
    })
    this.setState({photos: photos})
  }

  approvalStatusChange = (e) => {
    e.stopPropagation()
    const { photos } = this.state
    const photo_id = e.target.attributes['photo_id'].value
    photos.map((photo) => {
      if(photo.id.toString() === photo_id) { photo.approvalStatus = e.target.value }
    })
    this.setState({photos: photos})
  }

  handleReasonChange = (e) => {
    e.stopPropagation()
    const { photos } = this.state
    const photo_id = e.target.attributes['item_id'].value
    photos.map((photo) => {
      if(photo.id.toString() === photo_id) { photo.reasonSelected = e.target.value }
    })
    this.setState({photos: photos})
  }

  handleAngleChange = (e) => {
    e.stopPropagation()
    const { photos } = this.state
    const photo_id = e.target.attributes['item_id'].value
    photos.map((photo) => {
      if(photo.id.toString() === photo_id) { photo.angleSelected = e.target.value }
    })
    this.setState({photos: photos})
  }

  handleAllReasonChange = e => this.setState({reasonAllSelected: e.target.value })

  handleProfileImageChange = e => this.setState({profileImageId: e.target.value})

  renderApproval = () => {
    const { handleProfileImageChange, handleAngleChange, onCropChange, approvalStatusChange, handleReasonChange, handleClickSubmit, selectedPhoto } = this
    const { errors, profileImageId } = this.state
    let crop = selectedPhoto().crop
    if (crop == null) { crop = { aspect: 1 } }
    return(
      <CroppingPhoto
        onCropChange={onCropChange}
        handleReasonChange={handleReasonChange}
        handleAngleChange={handleAngleChange}
        handleClickSubmit={handleClickSubmit}
        crop={crop}
        selectedImage={selectedPhoto()}
        errors={errors}
        approvalStatusChange={approvalStatusChange}
        handleProfileImageChange={handleProfileImageChange}
        profileImageId={profileImageId}
      />
    )
  }

  selectedPhoto = () => {
    const { selectedPhotoId, photos } = this.state
    return(photos.find(p => p.id.toString() === selectedPhotoId.toString()))
  }

  render = () => {
    const { handleProfileImageChange, handleAllReasonChange, handleOnPhotoClick, handleReasonChange, approvalStatusChange, handleClickSubmit, handleClickChangeAll } = this
    const { profileImageId, reasonAllSelected, photos, selectedPhotoId, errors } = this.state
    return(
      <React.Fragment>
        {selectedPhotoId && this.renderApproval()}
        <br/>
        <h2 className='text-center m3'> Click to select</h2>
        <PhotosList
          photos={photos}
          handleOnPhotoClick={handleOnPhotoClick}
          handleReasonChange={handleReasonChange}
          approvalStatusChange={approvalStatusChange}
          handleProfileImageChange={handleProfileImageChange}
          profileImageId={profileImageId}
        />
        <div className='row ml-0 mr-0 mb-3'>
          <button type='button' value='approved' className='btn btn-big btn-primary mr-2' onClick={handleClickChangeAll}>Aprove All</button>
          <button type='button' value='rejected' className='btn btn-big btn-primary mr-2' onClick={handleClickChangeAll}>Reject All</button>
          <div className='col-auto flex-grow-1 form text-left mr-2'>
            <div className='form-input-cell radiobox-cell'>
              <SimpleSelectField
                collection={REJECT_PHOTO_REASONS}
                value={reasonAllSelected}
                onChange={handleAllReasonChange}
                emptyKey='Reason for rejection'
                errorText={errors.status}
              />
            </div>
          </div>
        </div>
        <button type='button' className='btn btn-big btn-primary' onClick={handleClickSubmit}>Submit</button>
      </React.Fragment>
    )
  }
}
