import React, { useState, useEffect } from 'react'
import { makeGetRequest } from '../shared/api.js'
import { APP_ROUTES } from '../shared/routes'
import _ from 'lodash'

const UsernameModal = (props) => {
  const { handleCloseUsername, handleClickSubmitSearch } = props
  const [focus, setFocus] = useState(false)
  const [username, setUsername] = useState('')
  const [proposedUsernames, setProposedUsernames] = useState([])

  useEffect(() => {
    fetchProposedUsernames()
  }, [username])

  function fetchProposedUsernames() {
    if(username.length < 1) return null

    makeGetRequest({ url: `${APP_ROUTES.search.proposed_usernames}?username=${username}` })
      .then((response) => {
        setProposedUsernames(response.data)
      });
   }

  function renderProposed() {
    if (proposedUsernames.length < 1 || !focus) { return null }
    return (
      <div className='search-suggestions'>
        {proposedUsernames.map((proposedUsername) => <div key={proposedUsername} name={proposedUsername} onClick={() => setUsername(proposedUsername)}><a href='#'>{proposedUsername}</a></div>)}
      </div>
    )
  }
  
  function onFocus() {
    setFocus(true)
  }

  function onBlur() {
    setFocus(false)
  } 

  function clickApply() {
    handleClickSubmitSearch(username)
  }

  return(
    <div className='r-modal show'>
      <div className='r-modal-content'>
        <div className='r-modal-header'>
          <h4 className='h1 modal-title'>Search by username</h4>
          <button type='button' aria-label='Close' className='btn btn-close' onClick={handleCloseUsername}></button>
        </div>
        <div className='r-modal-body'>
          <div className='row'>
            <div className='col-12 form text-left'>
              <div className='form-group'>
                <div className='form-input-cell search-cell'  onFocus={onFocus} onBlur={_.debounce(onBlur, 200)}>
                  <button className='search-submit symbol icon-magnifier'/>
                  <input
                    className='form-control'
                    type='text'
                    value={username}
                    onChange={(e) => setUsername(e.target.value)}
                    placeholder='Enter the username here'
                  />
                  {renderProposed()}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='r-modal-footer'>
          <button type='button' className='btn btn-big btn-primary' onClick={clickApply}>Apply</button>
        </div>
      </div>
    </div>
  )
}

export default UsernameModal 
