import React from 'react'
import FormPost from './form_post'
import BeforeJoinGroup from '../shared/before_join_group'
import InitialPost from '../group/subjects/initial_post'
import { makePostRequest, makeGetRequest } from '../../shared/api.js'

class SubjectContainer extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      open: false,
      title: '',
      content: '',
      errors: '',
      inGroup: false,
      replyValue: '',
      replyTo: false,
      postReplyTo: {},
      emailMe: true,
      processingFollow: false,
      messages: [],
      isFollowed: props.isFollowed,
      postPhoto: null
    }

    this.formRef = React.createRef(null)
  }

  renderPosts = () => {
    const { handleReplyClick, parentSetState } = this
    const { create_post_url, posts, permitted, discard_permitted, subjectId, followUrl } = this.props
    const { isFollowed, processingFollow } = this.state
    return (posts.map((post) => {
                                  return(
                                    <div className='card border-0 p-4 mb-4' key={`post${post.id}`}>
                                      <InitialPost post={post}
                                                   title={this.state.title}
                                                   postsCount={0}
                                                   handleReplyClick={handleReplyClick}
                                                   parentSetState={parentSetState}
                                                   processingFollow={processingFollow}
                                                   isFollowed={isFollowed}
                                                   followUrl={followUrl}
                                                   subjectId={subjectId}
                                                   likeUrl={`${create_post_url}/${post.id}/like_post`}
                                                   unlikeUrl={`${create_post_url}/${post.id}/unlike_post`}
                                                   banned={!permitted}
                                                   discardPermitted={discard_permitted}
                                                   publishedEventType={this.props.publishedEventType} />
                                    </div>
                                  )
                                }
           ))
  }

  parentSetState = (newState) => {this.setState(newState)}

  setPostPhoto = (postPhoto) => { this.setState({postPhoto: postPhoto})}

  renderFormPost = () => {
    const { handleEmailMe } = this
    const { replyValue, errors, replyTo, postReplyTo, emailMe } = this.state
    return ( <FormPost
                handleClickPost={this.handleClickPost}
                handleChangeReply={this.handleChangeReply}
                replyValue={replyValue}
                errors={errors}
                replyTo={replyTo}
                postReplyTo={postReplyTo}
                emailMe={emailMe}
                handleEmailMe={handleEmailMe}
                passRef={this.formRef}
                profile={this.props.profile}
                setPostPhoto={this.setPostPhoto}
                canWrite={this.props.canWrite} /> )
  }

  userInGroup = () => {
    const { in_group_url } = this.props
    makeGetRequest({ url: `${in_group_url}` })
         .then((response) => {
           this.setState({ inGroup: response.data.in_group, open: true });
         });
  }

  handleClickPost = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.userInGroup()
  }

  handlePost = () => {
    const { replyValue, postReplyTo, emailMe, postPhoto } = this.state
    const { create_post_url, last_page } = this.props

    const postParams = {
      content: replyValue, parent_id: postReplyTo.id, email_me: emailMe
    }

    if (postPhoto) {
      Object.assign(postParams, {post_photo_id: postPhoto.id})
    }

    makePostRequest({ url: create_post_url, body: {post: postParams} })
       .then((response) => {
         if (response.data.errors == null) {
           const params = new URLSearchParams(location.search);
           params.set('page', last_page)
           if (typeof ga !== 'undefined') { ga('send', 'event', 'Post', 'created') }
           window.location.search = params.toString();
         } else {
           this.setState({
             open: false,
             errors: response.data.errors
           })
         }
    })
  }

  handleClickClose = (e) => {
    e.stopPropagation()
    e.preventDefault()
    this.setState({open: false})
  }

  afterJoin = () => {
    this.setState({ inGroup: true })
    if (typeof ga !== 'undefined') { ga('send', 'event', 'Group', 'user joined') }
  }

  renderGroupForm = () => {
    if (!this.state.open) { return null; }
    if (!this.state.inGroup) {
      return (<BeforeJoinGroup
                infoText='You need to be a member of this group to create a new post.'
                join={this.props.url_join_group}
                handleClickClose={this.handleClickClose}
                joined={false}
                afterJoin={this.afterJoin} />);
    }
    this.handlePost()
    return null;
  }

  handleReplyClick = (e) => {
    const postForm = this.formRef.current;
    const postId = e.target.dataset['postId']
    const postReplyTo = this.props.posts.find((post) => post.id.toString() === postId )
    e.stopPropagation()
    e.preventDefault()
    postForm.focus()
    this.setState({ replyTo: true, postReplyTo: postReplyTo }, () => {
      setTimeout(() => postForm.scrollIntoView({behavior: 'smooth', block: 'end'}), 200)
    })
  }

  handleChangeReply = (e) => { this.setState({ replyValue: e.target.value }) }

  handleEmailMe = (e) => { this.setState({ emailMe: e.target.checked }) }

  render = () => {
    return(
      <div className='col-12 posts'>
        <div className='left-column mb-4 subject-wrapper'>
          {this.renderPosts()}
          {this.props.permitted && this.renderFormPost()}
        </div>
        {this.renderGroupForm()}
      </div>
    )
  }
}

export default SubjectContainer;
