import React from 'react'
import AlertSvg from 'alert.svg'

export default function InfoBox(props) {
  return <div className='row mt-2'>
           <div className='col-12 col-lg-6'>
             <div className='text info-box md-6'>
               <img src={AlertSvg}/>
               <h3 className='mt-2'>
                 {props.header}
               </h3>
               <p dangerouslySetInnerHTML={{__html: props.content}}/>
             </div>
           </div>
         </div>
}
