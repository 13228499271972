import React from 'react'

const TextArea = ({ value, onChange, placeholder, errorText, name, customClassName, limit }) => {
  const className = (errorText ? 'form-control is-invalid' : 'form-control')

  let message = value
  if(limit){
    message = value.slice(0, limit)
  }

  return(
    <div className='form-group'>
      <div className='form-input-cell'>
        <textarea
          value={message}
          onChange={onChange}
          placeholder={placeholder}
          className={`${className} ${customClassName}`}
          name={name}
          maxLength={limit}
        />
        {limit && (
          <p className='text-muted text-small my-2'>Characters left: {+limit - message.length}</p>
        )}
        <div className='invalid-feedback'>{errorText}</div>
      </div>
    </div>
  )
}

export default TextArea
