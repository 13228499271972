import React from 'react'

const MessageAll = (props) => {
  const { content, handleOnChange, handleClickSendMsg, handleClickClose } = props
  return(
    <div className='r-modal show'>
      <div className='r-modal-content wide'>
        <div className='r-modal-header no-border'>
          <h4 className='h1 modal-title text-left'>Message all Attendees</h4>
          <button type='button' aria-label='Close' className='btn btn-close' onClick={handleClickClose}></button>
        </div>
        <div className='r-modal-body'>
          <div className='row'>
            <div className='col-12 form'>
              <div className='form-group form-wysiwyg'>
                <div className='form-input-cell'>
                  <textarea
                    className='form-control'
                    type='text'
                    value={content}
                    onChange={handleOnChange}
                    placeholder='Please add your message here'
                    name='content'
                  />
                  <div className='invalid-feedback'>errors</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='r-modal-footer'>
          <div className='row'>
            <div className='col-12 col-sm text-right'>
              <button className='btn btn-secondary btn-big btn-cancel' onClick={handleClickClose}>Cancel</button>
              <button className='btn btn-primary btn-big btn-plane' onClick={handleClickSendMsg}>Send</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MessageAll;
