import React from 'react'
import _ from 'lodash'

const CustomSelect = ({ collection, value, handler, name }) => {
  const firstOpt = <option disabled key={'key_choose_' + name} value=''>Choose</option>

  let options = collection.map((key) => {
    return <option key={'key_' + key} value={key}>{_.capitalize(key)}</option>
  })
  options = [ firstOpt, ...options ]
  return(
    <div className='form-group'>
      <label>{name}</label>
      <div className='form-input-cell select-cell small'>
        <select id='name' className='form-control' value={value} onChange={handler}>
          {options}
        </select>
      </div>
    </div>
  )
}

export default CustomSelect
