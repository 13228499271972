import React from 'react'

const GenderFilterLabel = (props) => {
  const { handleGenderFilterChange, genderFilter, selectedGenderFilter } = props
  return(
    <label className='bullet-checkbox' key={`key_open_${genderFilter.value}`}>
      <input
        onChange={handleGenderFilterChange}
        type='checkbox'
        value={genderFilter.value}
        checked={selectedGenderFilter.includes(genderFilter.value)}
      />
      <div className='bullet'>{genderFilter.label}</div>
    </label>
  )
}

export default GenderFilterLabel 
