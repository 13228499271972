import React from 'react'
import SimplePeer from 'simple-peer'
import createChannel from '../../utils/cable'
import FrindowNotification from '../shared/frindow_notification'
import _ from 'lodash'

export default class VideoRoomContainer extends React.PureComponent {
  constructor(props) {
    super(props)

    this.channel = {}
    this.peer = {}
    this.outStream = {}

    this.state = {
      errors: [],
      messages: [],
      userInRoom: false
    }
  }

  componentDidMount() {
    navigator.mediaDevices.getUserMedia({ video: { width: 1440, height: 900 }, audio: true }).then(stream => {
      this.outStream = stream
      this.channel = createChannel({channel: 'VideoRoomChannel', id: this.props.id},
                                   {received: this.handleChannelResponse})
      const video = document.querySelector('#outgoing-video')
      if ('srcObject' in video) {
        video.srcObject = stream
      } else {
        video.src = window.URL.createObjectURL(stream) // for older browsers
      }
    })
  }

  handleClearNotice = () => this.setState({errors: [], messages: []})

  notifyUser = (content) => this.setState({messages: [content]})

  handleChannelResponse = (data) => {
    if(data.id === this.props.current_user_id) return
    switch(data.type) {
      case 'user_joined':
        this.notifyUser(data.data)
        this.sendWebRtcData()
        this.channel.perform('user_in_room')
        break
      case 'message':
        this.setState({messages: [data.data]})
        break
      case 'user_left':
        this.interlocutorLeft(data.data)
        break
      case 'initialRtcData':
        this.receiveWebRtcData(data.data)
        break
      case 'secondRtcData':
        this.peer.signal(data.data)
        break
    }
  }

  sendWebRtcData = () => {
    if(_.isEmpty(this.peer)) {
      const receiver = { initiator: true,
                         trickle: true,
                         stream: this.outStream,
                         config: {
                           iceServers: this.props.credentials
                         }
                       }
      this.peer = new SimplePeer(receiver)
      this.peer.on('error', err => console.log('error', err))

      this.peer.on('signal', data => {
        console.log('SIGNAL', JSON.stringify(data))
        this.channel.perform('initial_data', data)
      })

      this.peer.on('connect', () => {
        console.log('CONNECT')
        this.peer.send('whatever' + Math.random())
      })

      this.peer.on('data', data => {
        console.log('data: ' + data)
      })

      this.peer.on('stream', stream => {
        let video = document.querySelector('video')
        if ('srcObject' in video) {
          video.srcObject = stream
        } else {
          video.src = window.URL.createObjectURL(stream) // for older browsers
        }
      })
    }
  }

  receiveWebRtcData = (data) => {
    if(_.isEmpty(this.peer)) {
      this.peer = new SimplePeer({ initiator: false,
                                   trickle: true,
                                   stream: this.outStream,
                                   config: {
                                     iceServers: this.props.credentials
                                   }
                                 })

      this.peer.on('signal', data => {
        this.channel.perform('second_data', data)
      })

      this.peer.on('connect', () => {
        console.log('CONNECT')
        this.peer.send('whatever' + Math.random())
      })

      this.peer.on('data', data => {
        console.log('data: ' + data)
      })

      this.peer.on('stream', stream => {
        let video = document.querySelector('video')
        if ('srcObject' in video) {
          video.srcObject = stream
        } else {
          video.src = window.URL.createObjectURL(stream) // for older browsers
        }
      })
    }
    this.peer.signal(data)
  }

  interlocutorLeft = (data) => {
    this.setState({messages: [data]})
    const video = document.querySelector('#local-video')
    setTimeout(() => window.location.href = this.props.redirectUrl, 2000)
  }

  playVideo = (e) => {
    e.preventDefault()
    const video = document.querySelector('#local-video')
    video.play()
    this.outStream.getTracks().forEach(t => t.enabled = true)
  }

  pauseVideoOutgoing = (e) => {
    e.preventDefault()
    this.outStream.getTracks().forEach(t => t.enabled = false)
  }

  stopCall = (e) => {
    e.preventDefault()
    this.outStream.getTracks().forEach(t => t.stop())
    const video = document.querySelector('#outgoing-video')
    video.pause()
    window.location.href = this.props.redirectUrl
  }

  render = () => {
    const { handleClearNotice, pauseVideoOutgoing, playVideo, stopCall } = this
    const { errors, messages } = this.state
    return(
      <React.Fragment>
        <div className='live-chat-video'>
          <video id='local-video' className='local-video' loop={true} autoPlay={true}></video>
          <video id='outgoing-video' className='outgoing-video' loop={true} autoPlay={true} muted={true}></video>
        </div>
        <div className='row mx-0'>
          <button type='button' className='m-1 btn btn-big btn-primary btn-join group' title='Play' onClick={playVideo}>Play</button>
          <button type='button' className='m-1 btn btn-big btn-primary btn-join' title='Pause' onClick={pauseVideoOutgoing}>Pause</button>
          <button type='button' className='m-1 btn btn-big btn-primary' title='Pause' onClick={stopCall}>Stop call</button>
        </div>
        <FrindowNotification
          errors={errors}
          messages={messages}
          clearNotices={handleClearNotice} />
      </React.Fragment>
    )
  }
}
