import React from 'react'
import SimpleSelectField from '../../shared/simple_select_field'
import { genYears } from '../../../utils/dates'

const YearSelect = (props) => {
  const { year, handleYearChange } = props
  const currentYear = (new Date).getUTCFullYear()
  return (
    <div className='form-input-cell select-cell small'>
      <SimpleSelectField
        key='key_month'
        collection={genYears(false, currentYear, currentYear + 3)}
        value={year}
        onChange={handleYearChange}
        emptyKey='Year'
        errorPrompt={false}
      />
    </div>
  )
}

export default YearSelect
