import React from 'react'

const FrindowModal = ({ isOpen, closeModal, title, children, customClass, customTitleClass }) => {
  if(!isOpen) return null
  return (
    <div className='r-modal show'>
      <div className={'r-modal-content ' + customClass}>
        <div className='r-modal-header no-border'>
          <h4 className={'h1 modal-title ' + customTitleClass}>{title}</h4>
          <button aria-label='Close' onClick={closeModal} className='btn btn-close' type='button'></button>
        </div>
        <div className='r-modal-body'>
          {children}
        </div>
      </div>
    </div>
  )
}

export default FrindowModal
