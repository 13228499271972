import React from 'react'
import ConfirmInfo from '../../../get_togethers/confirm_info'
import i18n from '../../../../utils/i18n'

const OrganiseInfo = (props) => {
  const { createUrl, permitted, siteGt, userConfirmed, confirmationUrl } = props
  let button = null
  if(userConfirmed) {
    if(permitted && !siteGt) { button = <a href={createUrl} className='btn btn-big btn-primary btn-add align-self-start'>{i18n.t('getTogethers.startGetTogether').toUpperCase()}</a> }
  } else { button = <ConfirmInfo confirmation_url={confirmationUrl}/> }

  return (
    <div className='card border-0 p-4 mb-4'>
      <h2 className='h1 font-weight-normal'>{i18n.t('getTogethers.organiseInfo1')}</h2>
      <p className='mb-4'>{i18n.t('getTogethers.organiseInfo2')}</p>

      {button}
    </div>
  )
}


export default OrganiseInfo;
