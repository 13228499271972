import React from 'react'
import Intro from './intro'
import AddressDetails from './address_details'
import PendingInfo from './pending_info'
import VerificationCode from './verification_code'
import Outro from './outro'

export default class Steps extends React.PureComponent {
  constructor(props) {
    super(props)
    this.state = { step: this.defaultStep() }
  }

  handleNextStep = () => {
    this.setState({step: this.state.step + 1})
  }

  defaultStep = () => {
    if(this.props.user.account_confirmed_at) { return 4 }
    else if(this.props.shipping_address_provided) {
      return(this.props.user.verification_code_sent_at ? 3 : 2)
    }

    return 0
  }

  renderStep = () => {
    const { address, verify_shipping_address_path, shipping_address_path, root_path } = this.props
    let page = '' 

    switch(this.state.step) {
      case 0:
        page = <Intro onNextStep={this.handleNextStep}></Intro>
        break
      case 1:
        page = <AddressDetails address={address} onNextStep={this.handleNextStep} shippingAddressPath={shipping_address_path}></AddressDetails>
        break
      case 2:
        page = <PendingInfo rootPath={root_path}></PendingInfo>
        break
      case 3:
        page = <VerificationCode onNextStep={this.handleNextStep} verifyShippingAddressPath={verify_shipping_address_path}></VerificationCode>
        break
      case 4:
        page = <Outro rootPath={root_path}></Outro>
        break
    }

    return page
  }

  render = () => { return(this.renderStep()) }
}